import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Button, Form, Grid, Input } from "antd";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { company, loginUser, orgMember } from "../../type/type"
import CreateOrgpreview from "../../images/CreateOrgpreview.png";
import { Dispatch } from "redux";
import {
  addCompanies,
  changeLoaderStatus,
  getActiveBoard,
  initAnalyticsLabel,
} from "../../redux/actions/opening/action"
import Image from "../../components/common/image/Image"
import { createCompany, getSubscriptionDetails } from "../../lib/api"
const { useBreakpoint } = Grid;
interface WorkspaceCreationProps {
  getActiveBoard(data: any):void;
  loginUser: loginUser;
  initCurrentOrg: any;
  members: orgMember;
  initCompanies: (candidate: any) => void;
  initAnalyticsLabel: (label: any) => void;
  changeInLoaderStatus(payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }): void;
}

const WorkspaceCreation = (props: WorkspaceCreationProps) => {
  const history = useHistory();
  const screens = useBreakpoint();

  const [form] = Form.useForm();

  // after click on create button function will call and navigate to the jobs section in dashboard
  const handleCreateWorkspace = () => {
    form.validateFields().then((values) => {
      props.changeInLoaderStatus({
        loader: true,
        message: "Welcome aboard! Setting up your organization",
        skeleton: false,
      })
      const req = {
        ...values,
        members: [props.loginUser.user.id],
      }
      createCompany(req)
        .then((res) => {
          props.initCompanies(res)
            getSubscriptionDetails().then((res)=>{
              props.changeInLoaderStatus({
                loader: false,
                message: "",
                skeleton: false,
              })
              props.getActiveBoard(res?.subscriptions?.job_board)
              history.push({
                pathname: `/o/${props.initCurrentOrg?.id}/ats`,
              });
            }).catch((err)=>{
              console.log("Error getting subscription details",err);
            })
        })
        .catch(() => {
          props.changeInLoaderStatus({
            loader: false,
            message: "",
            skeleton: false,
          })
        })
    });
  };

  return (

      <div className="flex pt-16 w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
        <div className="flex xl:justify-end justify-center h-full items-start w-full">
          <div className="w-1/12"></div>
          {screens.xl && (
            <div className="w-8/12  2xl:w-7/12  flex  justify-end items-center">
              <Image src={CreateOrgpreview} size="full" />
            </div>
          )}
          <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
            <div
              className="bg-white xl:p-8 p-4 xl:ml-10 rounded xl:mt-10 mt-4 "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <div className="flex flex-col space-y-6">
                <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                  {capitalizeFirstLetter("Let's create your first Workspace")}
                </div>
                <div className="flex flex-col space-y-4 lg:text-sm text-xs">
                  <div>
                    You can create, manage and organize your jobs within
                    workspace.
                  </div>
                  <div className="flex flex-col space-y-4 lg:text-sm text-xs">
                    <Form
                      form={form}
                      name="normal_login"
                      className="login-form text-xs w-full"
                      initialValues={{ remember: true }}
                      layout="vertical"
                    >
                      <div className="flex flex-col w-full space-x-0 space-y-0">
                        <Form.Item
                          name="name"
                          label="Workspace name"
                          rules={[
                            {
                              required: true,
                              message: "Please provide your Workspace name!",
                            },
                          ]}
                          className="font-medium w-full"
                        >
                          <div className="flex">
                            <Input className="w-full" style={{ height: 30 }} maxLength={50} showCount={true}/>
                          </div>
                        </Form.Item>
                      </div>
                      <div className="w-full flex justify-end">
                        <Button
                          type="primary"
                          onClick={handleCreateWorkspace}
                          className="w-max text-right"
                        >
                          Create
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="w-1/12"></div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  initCurrentOrg: state.opening.currentOrg,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initCompanies: (companies: company[]) => dispatch(addCompanies(companies)),
  initAnalyticsLabel: (label: any) => dispatch(initAnalyticsLabel(label)),
  changeInLoaderStatus: (payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }) => dispatch(changeLoaderStatus(payload)),
  getActiveBoard:(data: any)=> dispatch(getActiveBoard(data))
});

export default connect(mapStateToProps, mapPropsToState)(WorkspaceCreation);
