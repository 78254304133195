import React, { useRef, useState } from "react"
import { usePopper } from "react-popper"
import { connect } from "react-redux"
// import AddIcon from "../../assets/Add"
import { Scope } from "../../type/type.d"
import { Overlay } from "../hook/Overlay"
import AdvanceSearchPopup from "./AdvanceSearchPopup"
import { Button } from "antd"

const QuickAddCandidate = () => {

    const refer = useRef(null)
    const [open, setOpen] = useState(false)
    let [referenceElement, setReferenceElement] =
        useState<HTMLDivElement | null>()
    let [popperElement, setPoperElement] = useState<HTMLDivElement | null>()
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "bottom-start",
    })
    return (
        <div className="flex items-center" ref={setReferenceElement}>
            
           <Button
                    data-testid="button"
                    // icon={<FilterOutlined />}
                    onClick={() => setOpen(!open)}
                    style={{width:'100%'}}
                >Add filter</Button>
            {open ? (
                <Overlay scope={Scope.Popover} onClose={() => setOpen(!open)} reference={refer}>
                    <div
                        ref={setPoperElement}
                        style={styles.popper}
                        {...attributes.popper}
                    >
                        <AdvanceSearchPopup close={() => setOpen(!open)} refer={refer} />
                    </div>
                </Overlay>
            ) : null}
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    servicesMembership: state.opening.servicesMembership,
    loginUser: state.opening.loginUser,
})
export default connect(mapStateToProps)(QuickAddCandidate)
