import React from "react";
import Button1 from "../../common/button/Button1";
import TickIcon from "../../../assets/Tick";
import { Stage } from "../../../lib/apiRes";

// Define the props expected by the ImportFinalReport component
type ImportFinalReportProps = {
  importFinalAPIReport: { stages: Stage }; // Final import report data
  handleDone: () => void; // Callback function for handling "Done" button click
  mode: string; // Current mode (not used in this component)
};

// ImportFinalReport component definition
const ImportFinalReport = (props: ImportFinalReportProps) => {
  // Check if records and attachments are present in the import report
  const hasRecords =
    props.importFinalAPIReport.stages.result.records.total_count > 0;
  const hasAttachments =
    props.importFinalAPIReport.stages.attachments.length > 0;

  // Render message when no records or attachments are found
  if (!hasRecords && !hasAttachments) {
    return <div>No records found in the import report.</div>;
  }

  return (
    <>
      {/* Main content */}
      <div
        className="flex flex-col w-full h-full space-y-4 pl-2 pr-1"
        style={{ height: "60%" }}
      >
        {/* Section for displaying the report information */}
        <div className="" style={{ height: "10%" }}>
          <div className="text-sm flex flex-col bg-white border border-tacitbase-tertiary1 border-opacity-25 p-2 rounded space-y-2 ">
            <ul className="list-none">
              <li className="flex items-center justify-start space-x-2">
                <span className="inline-block w-2 h-2 rounded-full bg-black "></span>
                <span className="text-sm">
                  The below report shows the total candidates inserted into the
                  candidate database.
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* Section for displaying the import report in a table */}
        <div className="overflow-y-auto h-full flex flex-col space-y-4 ">
          <table className="select-none ">
            <thead className="sticky top-0 bg-gray-100 ">
              <tr className="border border-tacitbase-tertiary1 border-opacity-25">
                {/* Table header */}
                <th
                  style={{
                    width: "auto",
                    maxWidth: "150px",
                    minWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "6px 6px",
                  }}
                  className={`font-semibold text-sm ${"bg-gray-200 "}`}
                >
                  Import candidate report card
                </th>
                {/* Table header */}
                <th
                  style={{
                    width: "auto",
                    maxWidth: "150px",
                    minWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "6px 6px",
                  }}
                  className={`font-semibold text-sm ${"bg-gray-200 "}`}
                >
                  Number of records
                </th>
                {/* Table header */}
                <th
                  style={{
                    width: "auto",
                    maxWidth: "150px",
                    minWidth: "100px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "6px 6px",
                  }}
                  className={`font-semibold text-sm ${"bg-gray-200 "}`}
                >
                  Description or downloads
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {/* Table row for "Total records found" */}
              <tr className="animate-popover_fadeIn hover:bg-gray-100 border rounded border-tacitbase-tertiary1 border-opacity-20 hover:border hover:shadow-md">
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  Total records found
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  {props.importFinalAPIReport.stages.result.records.total_count}
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  Records found in CSV file.
                </td>
              </tr>
              {/* Table row for "Records imported in candidate database" */}
              <tr className="animate-popover_fadeIn hover:bg-gray-100 border rounded border-tacitbase-tertiary1 border-opacity-20 hover:border hover:shadow-md">
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  Records imported in candidate database
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  {props.importFinalAPIReport.stages.result.records.valid_count}
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  Candidates imported to the candidate database.
                </td>
              </tr>
              {/* Table row for "Records could not be imported in database" */}
              <tr className="animate-popover_fadeIn hover:bg-gray-100 border rounded border-tacitbase-tertiary1 border-opacity-20 hover:border hover:shadow-md">
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  Records could not be imported in database
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={`whitespace-no-wrap truncate `}
                >
                  {
                    props.importFinalAPIReport.stages.result.records
                      .invalid_count
                  }
                </td>
                {/* Table cell */}
                <td
                  style={{
                    width: "auto",
                    maxWidth: "200px",
                    minWidth: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    padding: "3px 4px",
                  }}
                  className={``}
                >
                  {/* Render the link to download the CSV file */}
                  {props.importFinalAPIReport.stages.attachments ? (
                    props.importFinalAPIReport.stages.attachments.length > 0 ? (
                      <a
                        target="_blank"
                        download="data.csv"
                        rel="noreferrer"
                        href={
                          props.importFinalAPIReport.stages.attachments[0]
                            ?.presignedLink
                        }
                        className="whitespace-no-wrap truncate underline text-tacitbase-primary1"
                      >
                        {props.importFinalAPIReport.stages.attachments[0]?.name}
                      </a>
                    ) : (
                      <div>No records found</div>
                    )
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
          {/* Additional information section */}
          {props.importFinalAPIReport.stages.attachments ? (
            props.importFinalAPIReport.stages.attachments.length > 0 ? (
              <div className="bg-red-100 text-sm flex flex-col p-2 rounded">
                * You can download the file, correct the data, and re-insert it
                again.
              </div>
            ) : null
          ) : null}
        </div>
        {/* Success message section */}
        <div className="flex flex-col justify-center items-center space-y-2">
          <div className="h-14 w-14 bg-tacitbase-secondary5 rounded-full flex items-center justify-center">
            <TickIcon fill="white" data-testid="tick-icon" />
          </div>
          <div className="text-tacitbase-tertiary1 text-sm">
            Candidates imported successfully
          </div>
          <div className="text-tacitbase-tertiary1 text-sm font-semibold">
            Happy recruitment!
          </div>
        </div>
      </div>

      {/* "Done" button */}
      <div
        className="w-full flex justify-end items-center h-full pr-1"
        style={{ height: "20%" }}
      >
        <Button1 buttonName="Done" click={props.handleDone} />
      </div>
    </>
  );
};

export default ImportFinalReport;
