import React from 'react';
import { Typography } from 'antd';
import { UserType } from '../constant';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

const { Text } = Typography;

interface ErrorMessageProps {
  userType: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ userType }) => {
  let message: string;

  switch (capitalizeFirstLetter(userType)) {
    case capitalizeFirstLetter(UserType.GUEST):
      message = 'Secure share URL to grant access to the member. Do not share publicly.';
      break;
    case capitalizeFirstLetter(UserType.AGENCY):
      message = 'Agency portal URL to grant access to the member. Do not share publicly.';
      break;
    default:
      message = '';
  }

  return <Text type="secondary">{message}</Text>;
};

export default ErrorMessage;
