import { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { Avatar, Spin, Upload } from "antd";
import React from "react";
import { uploadOrgLogo } from "../../lib/api";
import { connect } from "react-redux";
import { orgSettingUpdate } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { loginUser } from "../../type/type";
import { throwFrontEndError, throwSuccess } from "../../service/throwError";
import { SecondaryButton } from "../../ant/Button";

const ImageUpload = (props: {
  loginUser: loginUser;
  orgSettingUpdate(user: any): void;
  applicationName: string;
  applicationId: string;
  applicationImgUrl: string;
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (props.applicationImgUrl)
      setFileList([
        {
          uid: "1",
          name: "Organisation image",
          status: "done",
          url: props.applicationImgUrl,
        },
      ]);
  }, [props.loginUser.user.lastUpdateInstant]);

  // on uploading file set it into state
  const onChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  // checking file size
  const checkFileSize = (file: any) => {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > maxSize) {
      return false;
    }
    return true;
  };

  // function call when click on update profile button
  const fileUpload = ({ onSuccess, onError, file }: any) => {
    if (!checkFileSize(file)) {
      onError();
      return;
    }
    const formData = new FormData();
    formData.append("logo", file);
    formData.set(
      "organisation",
      `{"application":{"id":"${props.applicationId}","name":"${props.applicationName}"}}`
    );
    setUploading(true);
    uploadOrgLogo(props.applicationId, formData)
      .then((res: any) => {
        onSuccess();

        props.orgSettingUpdate(res.application.data);
        throwSuccess("Organisation profile uploaded successfully.");
      })
      .catch((error: any) => {
        throwFrontEndError("Error while uploading avatar")
        onError();
        // Handle errors here
        console.error("Error uploading avatar:", error);
      }).finally(()=>{
        setUploading(false);
      });
  };

  return (
    <div className="flex items-center">
      <div className="mr-8">
        {uploading ? (
          <div className="h-24 w-24 flex items-center justify-center border border-gray-300 rounded-sm">
            <Spin />
          </div>
        ) : props.applicationImgUrl ? (
          <Avatar
            src={props.applicationImgUrl}
            alt="user_image"
            className="h-24 border border-gray-300   rounded w-24"
          />
        ) : (
          <Avatar
            alt="user_image"
            className="h-24 w-24 border border-gray-300  rounded flex items-center justify-center text-3xl font-bold"
            style = {{ color: 'white', backgroundColor: '#f56a00' }}
          >
          {props.applicationName[0] || undefined}
          </Avatar>
        )}
      </div>
      <ImgCrop showGrid rotationSlider aspectSlider>
        <Upload
          onChange={onChange}
          showUploadList={false}
          fileList={fileList}
          customRequest={fileUpload}
          accept="image/png, image/jpeg"
        >
          <SecondaryButton
            onClick={()=>{}}
          >
            Update logo
          </SecondaryButton>
        </Upload>
      </ImgCrop>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
  applicationId: state.opening.applicationId,
  applicationImgUrl: state.opening.applicationImgUrl
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  orgSettingUpdate: (orgData: any) => dispatch(orgSettingUpdate(orgData)),
});
export default connect(mapStateToProps, mapPropsTostate)(ImageUpload);
