import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import { currentApplicant, Scope } from "../../../../type/type.d";
import { Overlay } from "../../../hook/Overlay";
// import Portal from "../../../portal/Portal";
type CardSideSectionMenuItemProps = {
  isActive: boolean;
  icon: any;
  menuName: string;
  popup?: any;
  click: () => void;
  points?: number;
  RewardBtn?: any;
  currentApplicant: currentApplicant;
};
const CardSideSectionMenuItem = (props: CardSideSectionMenuItemProps) => {
  let refer = useRef(null);

  const [open, setOpen] = useState(false);

  let [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "right"],
        },
      },
    ],
  });
  const setFocusToParent = (e: any) => {
    if (e.key == "Tab" && props.menuName === "Archive") {
      document.getElementById("card-input")?.focus();
      // props.click(false);
    }
  };
  return (
    <div>
      <button
        className={`${
          !props.isActive
            ? "cursor-not-allowed bg-gray-500 "
            : "bg-gray-200 hover:bg-gray-200"
        }  w-full uppercase   bg-opacity-60 py-1.5 flex-shrink-0 px-3 mb-2 text-xs font-medium rounded align-middle flex items-center flex-row space-x-2`}
        ref={setReferenceElement}
        onClick={() => {
          setOpen(!open);
          props.click();
        }}
        data-testid="testBtn"
        onKeyDown={(e: any) => {
          setFocusToParent(e);
        }}
        id={props.menuName}
        disabled={!props.isActive}
      >
        {/* {props.isActive ? "dis" : "en"} */}
        {props.icon}
        <div> {props.menuName}</div>
      </button>
      {open && props.popup ? (

        <Overlay
          onClose={() => {
            setOpen(!open);
          }}
          scope={Scope.Popover}
          reference={refer}
        >
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <props.popup
                click={(e: boolean) => {
                  setOpen(e);
                }}
                refer={refer}
                name={props.menuName}
                points={props.points}
              />
            </div>
        </Overlay>

      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
});

export default connect(mapStateToProps)(CardSideSectionMenuItem);
