import React from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
type MailNotificationtype = {
  undo(): void;
};
const MailNotification = (props: MailNotificationtype) => {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-sm">{capitalizeFirstLetter("Sending mail")}</div>
        <button className="submit-btn text-xs" onClick={props.undo}>
          {capitalizeFirstLetter("cancel")}
        </button>
      </div>
    </>
  );
};
export default MailNotification;
