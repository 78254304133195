import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { TrixEditor } from "react-trix";
import CloseIcon from "../../../../assets/Close";
import { CardDescriptionInputProps } from "../../../../type/card";
// import Button from "../../../common/button/Button";
import { addAttachment } from "../../../../lib/api";
import { SOCKET_CLIENT_ID } from "../../../../constant";
const CardDescriptionInput = (props: CardDescriptionInputProps) => {
  const refer = useRef<HTMLDivElement>(null);
  const [Size, ISExceeds] = useState(false);
  const [tooLongBy, longBy] = useState(1);
  var listner = (event: any) => {
    if (event.attachment.file) {
      uploadFileAttachment(event.attachment);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    /* add keydown listner on trix editior to save desc on press cmd+enter */
    const element = document.querySelector("trix-editor");
    if (element) {
      element.addEventListener("keydown", handleKeyPress);
    }
    return () => {
      document.removeEventListener("trix-attachment-add", listner, false);
      const element = document.querySelector("trix-editor");
      document.removeEventListener("mousedown", handleClick);
      if (element) element.removeEventListener("keydown", handleKeyPress);
    };
  }, []);
  const handleClick = (e: any) => {
    if (refer.current && !refer.current.contains(e.target)) {
      if (
        document.querySelector("trix-editor")?.innerHTML === props.description
      ) {
        props.click(e);
        return;
      }
    }
  };

  const setEditorReady = (editor: any) => {
    editor.insertString("");
    document.addEventListener("trix-attachment-add", listner, false);
  };

  /* function of keydown to check key pressed is cmd and enter. if yes then it add description   */
  const handleKeyPress = (event: any) => {
    if (event.keyCode == 13 && event.metaKey) {
      props.create(event);
    }
  };

  function createFormData(file: any) {
    var formData = new FormData();
    formData.append("Content-Type", file.type);
    formData.append("model_name", "applicants");
    formData.append("record_id", props.currentApplicant.id);
    formData.append("opening_id", props.currentOpening.id);
    let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)
   if(clientId){formData.append("socket_client_id", clientId)
  }
    formData.append("list_id", props.currentApplicant.list_id);
    formData.append("file", file);
    return formData;
  }

  function uploadFile(file: any, progressCallback: any, successCallback: any) {
    var formData = createFormData(file);
    addAttachment(formData, props.currentApplicant.id)
      .then((res: any) => {
        var attributes = {
          url: res.attachment[0].presignedLink,
          href: res.attachment[0].presignedLink,
        };
        successCallback(attributes);
      })
      .catch((reason: AxiosError) => {
        toast.error(reason.response?.data.error.type, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  function uploadFileAttachment(attachment: any) {
    uploadFile(attachment.file, setProgress, setAttributes);

    function setProgress(progress: any) {
      attachment.setUploadProgress(progress);
    }

    function setAttributes(attributes: any) {
      attachment.setAttributes(attributes);
    }
  }

  const handleChange = (html: string, text: string) => {
    text.length > 12000
      ? (ISExceeds(true), longBy(text.length - 12000))
      : (ISExceeds(false), longBy(1));
    props.change(html);
  };
  return (
    <React.Fragment>

      <div className="mb-3 pl-1 mr-1" ref={refer}>
        <TrixEditor
          onChange={handleChange}
          onEditorReady={setEditorReady}
          mergeTags={[]}
          value={props.description}
          placeholder="Add a more detailed description…"
          className=" w-full text-sm trix-content resize-none rounded bg-white p-2  placeholder-gray-600"
        />
        <div className="mt-0.5 h-8 flex items-center justify-between">
          <div className="flex flex-row items-center space-x-2">
            {Size ? (
              <button
                className="submit-btn text-sm"
                disabled={true}
                data-testid="CardDescriptionInputTestBtn"
                onClick={props.create}
              >
                <span className="text-xs">Save</span>
              </button>
            ) : (
              <button
                className="submit-btn text-sm"
                disabled={false}
                data-testid="CardDescriptionInputTestBtn"
                onClick={props.create}
              >
                <span className="text-xs">Save</span>
              </button>
            )}
            <button onClick={props.click}>
              <span className="modal-icon h-4 ">
                <CloseIcon />
              </span>
            </button>
          </div>
        </div>
        {Size && (
          <div className="text-red-500 py-2">
            Your text is {tooLongBy} characters too long to save.
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(CardDescriptionInput);
