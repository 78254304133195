import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { COLUMN, OPERATOR, customRoles, filterOperators, operatorMap } from "../../constant"
import { addCandidateDBTableHeader, initSearchFilter, } from "../../redux/actions/opening/action"
import { CustomColumn, TableColumn, customeFilterType, searchDynamicQuery } from "../../type/type"
import PopupTitle from "../common/popupTitle/PopupTitle"
// import DropDownWithIcon from "../common/DropDownWithIcon"
import Dropdown from "../common/customDropdowm/Dropdown"


// Convert array to key-value object
const columnMap: Record<string, {
    id: string,
    name: string,
    description: string,
    custom: boolean
}> = {}


type AdvanceSearchPopupProps = {
    close: () => void,
    refer: any,
    addColumnInCDBTable: (column: CustomColumn) => void,
    candidateDBTableHeader: TableColumn[],
    initSearchFilter: (filterObj: any) => void,
    searchQuery: searchDynamicQuery,
}
const AdvanceSearchPopup = (props: AdvanceSearchPopupProps) => {
    // const columnNameRef = useRef<HTMLInputElement>(null)
    const [column, setColumn] = useState<string>("")
    const [operator, setOperator] = useState<string>("")
    const [value, setValue] = useState<string>("")

    const [columns, setColumns] = useState<customRoles[]>([])

    let defaultOperator: customRoles[] = []
    const [operators, setOperatorByType] = useState<customRoles[]>(defaultOperator)


    useEffect(() => {
        // let abc: customRoles[] = []
        props.candidateDBTableHeader?.forEach(column => {
            columnMap[column.view] = {
                id: column.name,
                name: column.view,
                description: column.type,
                custom: column.custom
            }
        })
        setColumns(Object.values(columnMap))

    }, [props.candidateDBTableHeader])


    /** when add filter for specific column with operator and with value, then remove from list of column, operator.
     * one filter apply to one column with one operator
     */
    const addFilter = (e: any) => {
        if ((e.keyCode === 13 && !e.shiftKey) || e.button == 0) {
            if (column.trim().length > 0 && operator.trim().length > 0 && value.trim().length > 0) {
                const filteredData = columns.filter(item => item.name !== column)
                setColumns(filteredData)
                let customId = columnMap[column].custom ? `custom.${columnMap[column].id}` : columnMap[column].id
                let filterObj: customeFilterType[] = [...props.searchQuery.filters, { column: customId, operator: operatorMap[operator], value: value, view: columnMap[column].name, operatorName: operator }]
                props.initSearchFilter(filterObj)
                setColumn(COLUMN)
                setOperator(OPERATOR)
                setValue("")
            }
        }
    }



    const handleChangeColumn = (e: string) => {
        setColumn(e)
        let getoperator: any[] = []
        getoperator = filterOperators[columnMap[`${e}`].description]
        setOperatorByType(getoperator)
        setOperator(getoperator[0].name)
    }
    // useEffect(() => {
    //     if (column) {
    //         // console.log(columnMap[`${column}`].type, filterOperators[columnMap[`${column}`].type])


    //     }
    // }, [column])
    return (
        <>
            <div className="animate-popover_fadeIn   flex flex-col mt-1 z-20 w-143 bg-white text-gray-800 tracking-normal text-sm font-normal border border-tacitbase-tertiary1 border-opacity-30 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" ref={props.refer}>
                <div className="mt-2 px-2">
                    <PopupTitle PopupTitle={"Apply filter"} close={props.close} />
                </div>
                <div className="text-sm capitalize flex w-full px-2 py-2">

                    <div className="mr-2">
                        <Dropdown
                            options={columns}
                            selectedItem={(e: string) => handleChangeColumn(e)}
                            selectedRole={column.trim().length > 0 ? column : COLUMN}
                        />
                    </div>
                    <div className="mr-2">
                        <Dropdown
                            options={operators}
                            selectedItem={(e: string) => setOperator(e)}
                            selectedRole={operator.trim().length > 0 ? operator : OPERATOR}
                            width="w-48"
                        />
                    </div>
                    <input
                        type={column.trim().length > 0 && column != COLUMN ? columnMap[`${column}`].description : "string"}
                        className="px-2 py-1 bg-white w-full rounded inputCSS"
                        placeholder={column.trim().length > 0 && column != COLUMN ? columnMap[`${column}`].description : "string"}
                        data-testid="member-search"
                        value={value}
                        onKeyDown={addFilter}
                        disabled={column.trim().length > 0 && column != COLUMN ? false : true}
                        onChange={(data) => data && setValue(data.target.value)}
                    />{" "}

                </div>
                <div className=" float-right px-2 mb-2">
                    <button
                        className="submit-btn float-right text-sm"
                        onClick={addFilter}
                        data-testid="add-card"
                    >
                        <span className="text-xs">Add filter</span>
                    </button></div>

            </div>

        </>
    )
}
const mapStateToProps = (state: any) => ({
    candidateDBTableHeader: state.opening.candidateDBTableHeader,
    searchQuery: state.opening.searchQuery,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    addColumnInCDBTable: (table: CustomColumn) =>
        dispatch(addCandidateDBTableHeader(table)),
    initSearchFilter: (filterObj: any) =>
        dispatch(initSearchFilter(filterObj)),
})
export default connect(mapStateToProps, mapPropsToState)(AdvanceSearchPopup)
