import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
// import { TrixEditor } from "react-trix";
import CommentIcon from "../../../assets/Comments";
import Sent from "../../../assets/Sent";
import { EMAIL_TAG } from "../../../constant";
import { addMailOnCard } from "../../../redux/actions/opening/action";
import { currentApplicant, loginUser, orgMember, Scope, SendMail } from "../../../type/type.d"
import ComposeMail from "../../common/composeMail/NewComposeMail"
import CommentInput from "../comment/commentInput/CommentInput";
import { capitalizeFirstLetter, generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import { Overlay } from "../../hook/Overlay"
import TacitbaseAvtar from "../../../ant/Avtar"
import { OrgDomain } from "../../../redux/reducers/initialState";
import DomainModel from "../../../pages/setting/billingSetting/DomainModel";

type CardEmailAndCommentType = {
  loginUser: loginUser;
  currentApplicant: currentApplicant;
  addMailOnCard: (data: SendMail, id: string, tag: string) => void;
  members: orgMember;
  defaultDomain:OrgDomain;
};
const CardEmailAndComment = (props: CardEmailAndCommentType) => {
  const [isEmailSet, setEmailPanal] = useState<boolean>(false);
  const [isCommentPanal, setCommentPanal] = useState<boolean>(true);
  const [openDomainPopup, setOpenDomainPopup] = useState(false);
const refer=useRef( null);
  const showEmail = () => {
    const domainID = Object.keys(props.defaultDomain)[0];
    if(domainID && !props.defaultDomain[domainID].custom){
      setOpenDomainPopup(true);
      return;
    }
    setEmailPanal(true);
    setCommentPanal(false);
  };
  const showComment = () => {
    setEmailPanal(false);
    setCommentPanal(true);
  };
  const closeAll = () => {
    setEmailPanal(false);
    setCommentPanal(false);
  };
  const addMail = (res: SendMail) => {
    props.addMailOnCard(res, props.currentApplicant.id, EMAIL_TAG);
  };
  let reqObj = { ...props.currentApplicant.source, ["id"]: props.currentApplicant.id }
  return (
    <>
      <div className="flex items-center mb-2 ml-3 space-x-2">
        <div className="flex items-center  flex-shrink-0 justify-cente">
          {props.members[props.loginUser.user.id] ? props.members[props.loginUser.user.id].image_url ?
            <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.members[props.loginUser.user.id])}
              src={props.loginUser.user.imageUrl}
              size="default"
            /> : <TacitbaseAvtar
              toolTip={generateTooltipForMember(props.members[props.loginUser.user.id])}
              content={props.members[props.loginUser.user.id].first_name[0] + props.members[props.loginUser.user.id].last_name[0]}
              size="default"
            /> : null}
        </div>
        <div className="flex space-x-2 items-center justify-center">
          {" "}
          <button
            onClick={showComment}
            className={`${
              isCommentPanal ? "border-l-4 border-tacitbase-secondary3" : ""
            } bg-gray-200 rounded text-xs px-2 py-1.5 flex justify-center space-x-1 items-center`}
          >
            <CommentIcon />
            <span>Add comment</span>
          </button>
          {props.currentApplicant.source &&
            props.currentApplicant.source.email && (
              <button
                onClick={showEmail}
                className={`${
                  isEmailSet ? "border-l-4 border-tacitbase-secondary3" : ""
                } bg-gray-200 rounded text-xs px-2 py-1.5 flex justify-center space-x-1 items-center`}
              >
                <Sent /> <span>{capitalizeFirstLetter(EMAIL_TAG)}</span>
              </button>
            )}
        </div>
      </div>
      {isEmailSet &&
        props.currentApplicant.source &&
        props.currentApplicant.source.email && (
        <>
          <Overlay scope={Scope.Popover} onClose={closeAll}>
          <div className="bottom-0 absolute rounded border right-0  mr-3 mb-2 shadow-lbr flex max-h-full w-128">
              <ComposeMail
              refer={refer}
                service="ats"
                entity="applicants"
                close={closeAll}
              record_id={[reqObj]}
                toAddress={[props.currentApplicant.source.email]}
                reduxActionCall={(res: SendMail) => addMail(res)}
              />
            </div>
          </Overlay>
          </>
        )}
      {isCommentPanal && (
        <>
          <CommentInput close={closeAll} />
        </>
      )}
      <DomainModel isModalVisible = {openDomainPopup} handleCancel = {()=>setOpenDomainPopup(false)}/>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
  defaultDomain: state.opening.defaultDomain
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addMailOnCard: (data: SendMail, id: string, tag: string) =>
    dispatch(addMailOnCard(data, id, tag)),
});
export default connect(mapStateToProps, mapPropsToState)(CardEmailAndComment);
