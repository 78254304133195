import React from "react";
import { Empty } from "antd"

type FirstVisitProps = {
  description?: string | boolean
}
const FirstVisit = (props: FirstVisitProps) => {
  return (
    <>
      <Empty description={props.description} ></Empty>
    </>
  );
};
export default FirstVisit;
