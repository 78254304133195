

import React from "react";

const Inbox = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 0H18L20 4V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V4.004L2 0ZM18 6H2V16H18V6ZM17.764 4L16.764 2H3.237L2.237 4H17.764ZM11 11H14L10 15L6 11H9V7H11V11Z" fill="black" />
        </svg>
    );
};
export default Inbox;
