import React from 'react';
import { Card } from 'antd';


const PricingCardSkeleton = () => {
  return (
    <div className="flex flex-wrap justify-center  p-8">
      {[...Array(3)].map((_, index) => (
        <Card
          key={index}
          className="mx-2 rounded-md animate-pulse"
          bordered={false}
          style={{
            width: 300,
            height:index=== 1? 507:423,
            marginTop: index !==1 ? "84px" : "0", 
            textAlign: 'left',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            position: 'relative',
          }}
        >
          <div className="flex justify-between mb-4">
            <div className="w-2/3 h-8 bg-gray-300 animate-pulse rounded-md"></div>
          </div>
          <div className="flex justify-center mb-4">
            <div className="w-full h-8 bg-gray-300 animate-pulse rounded-md"></div>
          </div>
          <div className="h-8 bg-gray-300 w-3/12 animate-pulse rounded-md mb-6"></div>
          <ul style={{ textAlign: 'left', padding: 0 }}>
            {[...Array(5)].map((_, index) => (
              <li key={index} className="flex items-center mb-3">
                <div className="w-4/5 h-4 bg-gray-300 animate-pulse rounded-md"></div>
              </li>
            ))}
          </ul>
        </Card>
      ))}
    </div>
  );
};

export default PricingCardSkeleton;
