import React from "react";
const AttachmentIcons = (fileType: string) => {
  const icons: { [key: string]: React.ReactNode } = {
    pdf: <i className="far fa-file-pdf"></i>,
    doc: <i className="far fa-file-word"></i>,
    docx: <i className="far fa-file-word"></i>,
    dot: <i className="far fa-file-word"></i>,
    dotx: <i className="far fa-file-word"></i>,
    html: <i className="far fa-file-code"></i>,
    txt: <i className="far fa-file-alt"></i>,
    rtf: <i className="far fa-file-alt"></i>,
    odt: <i className="far fa-file-alt"></i>,
    jpg: <i className="far fa-file-image"></i>,
    jpeg: <i className="far fa-file-image"></i>,
    gif: <i className="far fa-file-image"></i>,
    bmp: <i className="far fa-file-image"></i>,
    png: <i className="far fa-file-image"></i>,
    svg: <i className="far fa-file-image"></i>,
    webp: <i className="far fa-file-image"></i>,
    mp3: <i className="far fa-file-audio"></i>,
    m4a: <i className="far fa-file-audio"></i>,
    wav: <i className="far fa-file-audio"></i>,
    ogg: <i className="far fa-file-audio"></i>,
    flac: <i className="far fa-file-audio"></i>,
    mp4: <i className="far fa-file-video"></i>,
    mov: <i className="far fa-file-video"></i>,
    avi: <i className="far fa-file-video"></i>,
    wmv: <i className="far fa-file-video"></i>,
    mpeg: <i className="far fa-file-video"></i>,
    mkv: <i className="far fa-file-video"></i>,
    m4v: <i className="far fa-file-video"></i>,
    "3gp": <i className="far fa-file-video"></i>,
    zip: <i className="far fa-file-archive"></i>,
    rar: <i className="far fa-file-archive"></i>,
  };
  return icons[fileType] || <i className="far fa-file"></i>;
};
export default AttachmentIcons;
