import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Popconfirm, Select,  Spin } from "antd";
import { Opening, company, companyBoards } from "../../../type/type";
import { initCompanies, listOfBoards } from "../../../redux/actions/opening/action";
import { IconButton, SecondaryButton } from "../../../ant/Button";
import { CANDIDATE_ADDED_TO_TRACKER_SUCCESS, CANDIDATES_ADDED_TO_TRACKER_SUCCESS, NO_LIST_FOUND_IN_BOARD, SOCKET_CLIENT_ID } from "../../../constant";
import { CreateCandidateCard, getCompanies, getOpeningLists, getOpenings } from "../../../lib/api";
import { throwSuccess } from "../../../service/throwError";
import { ProjectOutlined } from "@ant-design/icons";
import { Form } from "antd/lib";
import "../../../styles/component/style.css"

const SendCandidateToTracker = (props: {
  currentOpening: any;
  isBulk: any;
  candidateIds: any;
  currentCandidateInPool: any;
  openings: any;
  currentCandidateInEditMode: any;
  clientId: string;
  companyBoard: companyBoards[];
  companies: any;
  initCompanies: (JobBoard: company[]) => void,
  listOfBoards(boardData: any): void,
  isCollapsed: any;
}) => {
  const { currentOpening, isBulk, candidateIds, currentCandidateInEditMode} = props;
  const [listLength, setListLength] = useState<number>(0);
  let element = document.getElementById("errorInSend");
  const [companyOptions, setCompanyOptions] = useState<Record<string, any>>({});
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [selectedOpening, setSelectedOpening] = useState<string|any>("");
  const [selectedOpeningName, setSelectedOpeningName] = useState<string|any>("");
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [loadingOpenings, setLoadingOpenings] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoadingCompanies(true);
    getCompanies().then((res) => {
      if (res) {
        props.initCompanies(res);
      }
      setLoadingCompanies(false);
    });
    setLoadingOpenings(true);
    getOpenings().then((openings) => {
      if (openings && props.listOfBoards) {
        props.listOfBoards(openings);
      }
      setLoadingOpenings(false);
    });
  }, []);


  useEffect(() => {
    if (Object.values(props.companyBoard).length > 0) {
      const companyOptionsObject: Record<string, any> = {};
      Object.values(props.companyBoard).forEach((company: companyBoards) => {
        companyOptionsObject[company.company_id] = {
          ...company,
          id: company.company_id,
          boards: company.boards.map((boardId: any) => props.openings.pageSection.openings[boardId])
        };
      });

      setCompanyOptions(companyOptionsObject);
      setOpenings(companyOptionsObject[Object.values(props.companyBoard)[0].company_id].boards.filter((o: Opening) => !o.access.comment_only));
      setSelectedOpening(companyOptionsObject[Object.values(props?.companyBoard)[0]?.company_id].boards[0]?.id);
      setSelectedOpeningName(companyOptionsObject[Object.values(props?.companyBoard)[0]?.company_id].boards[0]?.name);

    } else {
      if (element) element.innerHTML = "No board found";
    }
  }, [props.companyBoard]);

  useEffect(() => {
    if (selectedOpening) {
      getOpeningLists(selectedOpening).then((res) => {
        if (res) {
          setListLength(res.length);
          if (element) element.innerHTML = "";
        } else {
          setListLength(0);
          if (element) element.innerHTML = NO_LIST_FOUND_IN_BOARD;
        }
      });
    }
  }, [selectedOpening]);

  const selectOpening = (openingId: string) => {
    const selectedBoard = openings.find((o: Opening) => o.id === openingId);
    setSelectedOpening(openingId);
    setSelectedOpeningName(selectedBoard?.name || "");

  };

  const selectCompany = (openingId: string) => {
    setOpenings(companyOptions[openingId].boards.filter((o: Opening) => !o.access.comment_only));
    setSelectedOpening(companyOptions[openingId].boards[0].id);
    setSelectedOpeningName(companyOptions[openingId].boards[0].name);

  };

  const handleSendToTracker = () => {
    if (selectedOpening.trim().length > 0 && listLength > 0) {
      setLoading(true);
      let clientIdFromSession = sessionStorage.getItem(SOCKET_CLIENT_ID) || "";
      const requestPayload = {
        candidates: isBulk ? candidateIds : [currentCandidateInEditMode.candidateId.id],
        opening_id: selectedOpening,
        socket_client_id: clientIdFromSession,
      };

      CreateCandidateCard(requestPayload)
        .then((res) => {
          if (res) {
            if (requestPayload.candidates.length>1) {
              throwSuccess(CANDIDATES_ADDED_TO_TRACKER_SUCCESS);
            } else {
              throwSuccess(CANDIDATE_ADDED_TO_TRACKER_SUCCESS);
            }  
          }
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Popconfirm
      title={`Send to tracker: ${currentOpening.name}`}
      overlayStyle={{ marginRight: '16px' }} // Adjust the padding here
      description={
        <>
        <div className="mr-5">
          <Form layout="vertical">
            <Form.Item name="company" label="Select workspace" style={{ marginTop: 16 }}>
              
                <Select
                  showSearch
                  style={{ width: 300, height: 30 }}
                  placeholder="Select workspace"
                  onChange={(value) => selectCompany(value)}
                  defaultValue={Object.keys(companyOptions)[0]}
                  notFoundContent={loadingCompanies ? <Spin size="small" /> : null}

                >
                  {Object.values(companyOptions).map((company: any) => (
                    <Select.Option key={company.id} value={company.company_id}>
                      {company.company_name}
                    </Select.Option>
                  ))}
                </Select>
              {/* )} */}
            </Form.Item>

            <Form.Item name="opening" label="Select job post" style={{ marginTop: 16 }}>
             
                <Select
                  showSearch
                  style={{ width: 300, height: 30 }}
                  placeholder="Select job post"
                  onChange={(value) => selectOpening(value)}
                  value={selectedOpening}
                  defaultValue={selectedOpeningName}
                  notFoundContent={loadingOpenings ? <Spin size="small" /> : null}

                >
                  {openings.map((opening: Opening) => (
                    <Select.Option key={opening.id} value={opening.id}>
                      {opening.name}
                    </Select.Option>
                  ))}
                </Select>
              {/* )} */}
            </Form.Item>
          </Form>
{selectedOpeningName &&  <>Candidate will be added to the first list of <span className="font-semibold"><br/>{selectedOpeningName}</span>
          <br /> This action is irreversible.</>}
         
          </div>
        </>
      }
      placement="bottom"
      okText="Yes"
      onConfirm={handleSendToTracker}
      cancelText="No"
      okButtonProps={{ loading: loading }}
    >
      {!isBulk ? (
        <SecondaryButton
        onClick={() => {}}
        className="w-full truncate left-align-button"
        icon={<ProjectOutlined />}
        >Send to tracker</SecondaryButton>
      ) : (
        <IconButton
        tooltipTitle="Send to tracker"
          icon={
              <ProjectOutlined />
          }
          className="h-7 w-7"
          onClick={(e: { preventDefault: () => void; stopPropagation: () => void; }) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
    </Popconfirm>
  );
};

const mapStateToProps = (state: {
  opening: {
    currentCandidateInPool: any;
    currentOpening: any;
    openings: any;
    currentCandidateInEditMode: any;
    clientId: any;
    companyBoards: any;
    companies: any;
  };
}) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
  currentOpening: state.opening.currentOpening,
  openings: state.opening.openings,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  clientId: state.opening.clientId,
  companyBoard: state.opening.companyBoards,
  companies: state.opening.companies
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  initCompanies: (candidates: company[]) => dispatch(initCompanies(candidates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendCandidateToTracker);
