import React, { useEffect, useState } from "react";
import FileSelection from "./FileUpload";
import { ImportFileUpload } from "../../../lib/api";
import Button1 from "../../common/button/Button1";
import { Stage } from "../../../lib/apiRes";

// Define the props expected by the FileSelectionForUpload component
interface FileSelectionProps {
  handleNextStep: (value: boolean) => void; // Callback to move to the next step
  handleApiRes: (res: any) => void; // Callback to handle the API response
  data: { stages: Stage }; // Data for the file upload stages
  mode: string; // Mode (e.g., "pending") for the file upload
}

// FileSelectionForUpload component definition
const FileSelectionForUpload = (props: FileSelectionProps) => {
  // State variables
  const [uploadProgress, setUploadProgress] = useState(0);
  const [apiCalled, setApiCalled] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [fileformatError, setFileFormatError] = useState("");
  const [selectedFileData, setSelectedFileData] = useState<{
    file: File | null;
    name: string;
    formData: FormData | null;
  }>({
    file: null,
    name: "",
    formData: null,
  });

  // Function to check if the selected file is a CSV file
  const isCSVFile = (file: File) => {
    return file.type === "text/csv" || file.name.endsWith(".csv");
  };

  // Effect to handle the API response and move to the next step
  useEffect(() => {
    props.handleApiRes(props.data);
    props.data.stages.headers?.length > 0 &&
      (setSelectedFileData({
        file: null,
        name: props.data.stages.name,
        formData: null,
      }),
      setApiCalled(false),
      setApiSuccess(true),
      props.handleNextStep(true));
  }, [props.data && props.mode == "pending"]);

  // Function to handle the "Remove" button click
  const handleRemove = () => {
    setApiCalled(false);
    setApiSuccess(false);
  };

  // Function to handle file selection and upload
  const handleFileSelection = (file: File | null) => {
    const files: any = [];
    if (file && isCSVFile(file)) {
      setFileFormatError("");
      const formData = new FormData();
      formData.append(`file`, file);
      const fileData = {
        name: file.name,
        progress: 0,
      };
      files.push(fileData);
      setSelectedFileData({
        file: file,
        name: file.name,
        formData: formData,
      });

      // Function to handle upload progress
      const onUploadProgress = (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const updatedFiles = [...files];
        updatedFiles.forEach((fileData) => {
          fileData.progress = progress;
          setUploadProgress(fileData.progress);
        });
      };

      setApiCalled(true);
      // Call the API to upload the file
      ImportFileUpload(formData, onUploadProgress).then((res) => {
        setApiCalled(false);
        setApiSuccess(true);
        props.handleApiRes(res);
      });
    } else {
      setFileFormatError("Please select a CSV file.");
    }
  };

  return (
    <>
      <div
        className="flex flex-col w-full h-full space-y-2 pl-2 pr-1 mt-8"
        style={{ height: "60%" }}
      >
        {/* Step description */}
        <div className="" style={{ height: "10%" }}>
          <div className="flex flex-col bg-white border border-tacitbase-tertiary1 border-opacity-25 p-2 rounded space-y-2 ">
            <ul className="list-none">
              <li className="flex items-center justify-start space-x-2">
                <span className="inline-block w-2 h-2 rounded-full bg-black "></span>
                <span className="text-sm">
                  Select and upload a CSV file with{" "}
                  <span className="font-semibold">Column name row</span> which
                  contains{" "}
                  <span className="font-semibold">
                    required fields, First Name, Last Name, Email ID.
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* FileSelection component for selecting and uploading the CSV file */}
        <FileSelection
          onFileSelect={handleFileSelection}
          apiCalled={apiCalled}
          apiSuccess={apiSuccess}
          uploadProgress={uploadProgress}
          selectedFileData={selectedFileData}
          setSelectedFileData={setSelectedFileData}
          handleRemove={handleRemove}
          setFileFormatError={setFileFormatError}
        />
        <div style={{ height: "10%" }}>
          <div
            className={`${
              fileformatError ? "flex" : "invisible"
            } bg-red-100 text-sm flex flex-col p-2 rounded`}
          >
            {fileformatError ? "* " + fileformatError : null}
          </div>
        </div>
      </div>

      <div
        className="w-full flex justify-end items-center h-full pr-1"
        style={{ height: "20%" }}
      >
        {/* "Next" button to move to the next step */}
        <Button1
          buttonName="Next"
          click={props.handleNextStep}
          isDisabled={!apiSuccess}
        />
      </div>
    </>
  );
};

export default FileSelectionForUpload;
