import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TickIcon from "../../../assets/Tick";
import { ASCENDING, DATE, DESCENDING } from "../../../constant";
import { sortingBoard } from "../../../redux/actions/opening/action";

type SortingPopupProps = {
  click: (e: boolean) => void;
  getValue: (value: any) => void;
  sortingBoard: (selectionType: any) => void;
  sortingType: any;
  refer:any;
  applicationId: string;
};
const SortingPopup = (props: SortingPopupProps) => {
 
  const [defaultMenu] = useState([
    {
      type: "A-Z",
      name: ASCENDING,
      active: false,
    },
    {
      type: "Z-A",

      name: DESCENDING,
      active: false,
    },
    {
      type: "Date Added",

      name: DATE,
      active: false,
    },
  ]);
  const isActive = (menuName: string) => {
    props.getValue(menuName);
    props.sortingBoard(menuName);
  };

  return (
    <>
      <div className="bg-white m-2 w-64  rounded shadow-4xl" ref={props.refer}>
        <div className="pt-2 pl-4 pr-4 pb-2">
          <div className="text-xs text-gray-500 leading-10">Sort by</div>
          <div className="flex flex-row p-0.5 w-full">
            <div className="flex flex-col w-full ">
              {defaultMenu.map((menu: any, i: number) => (
                <div
                  className="flex justify-between items-center bg-gray-100 px-2 mb-1 "
                  key={i}
                >
                  <div
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => isActive(menu.name)}
                    onClick={() => isActive(menu.name)}
                    className={`${
                      menu.active ? "text-blue-300" : "text-black"
                    }  flex items-center text-sm  font-semibold rounded h-10 w-full cursor-pointer`}
                  >
                    {menu.type}
                  </div>
                  {props.sortingType[props.applicationId] ? (
                    props.sortingType[props.applicationId]["boards"] ===
                    menu.name ? (
                      <TickIcon />
                    ) : null
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  openings: state.opening.openings.pageSection.openings,
  sortingType: state.opening.sortingType,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  sortingBoard: (selectionType: string) =>
    dispatch(sortingBoard(selectionType)),
});
export default connect(mapStateToProps, mapPropsToState)(SortingPopup);
