import React, { useEffect, useState } from "react";
import {  Popconfirm, Space, Table } from "antd";
import { getApplicantStatus, getCandidates, getTable } from "../../lib/api"
import { Candidate, FormBuilder, Opening, TableColumn } from "../../type/type";
import TacitbaseAvtar from "../../ant/Avtar";
// import { generateSearchQuery } from "../../utils/SearchDynamicQuery";
import {  NOT_AVAILABLE, PoolRoute } from "../../constant";
import { connect } from "react-redux";
import TableAnt from "../../ant/TableAnt";
import { IconButton } from "../../ant/Button";
import { ReloadOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import ApplicantDetailView from "../../components/jobPost/tabs/pool/ApplicantDetailView";
import { Dispatch } from "redux";
import { applicantStatus, initCandidatesAtsPool, initCDBHead } from "../../redux/actions/opening/action"
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import SendPoolCandidateToTracker from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToTracker";
import SendPoolCandidateToReview from "../../components/jobPost/tabs/pool/actions/SendPoolCandidateToReview";
import AddBulkLabelToCandidateInAtsPool from "../../components/jobPost/tabs/pool/actions/AddBulkLabelToCandidateInAtsPool";
import SendMailToPoolCandidate from "../../components/jobPost/tabs/pool/actions/SendMailToPoolCandidate";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { Tag } from "antd/lib"
import ActionButton from "../../components/CDB/AddCandidateActionButton";
import ImportResumePool from "./ImportResumePool";
import Read from "../../Read";

// Type definition for the Redux props
type TableReduxProps = {
  currentOpening: Opening;
  applicationId: string;
  initCDBHead: (table: any) => void;
  initCandidates: (members: Candidate[], currentPage: string) => void;
  candidatePool: any;
  currentTrackerForm: FormBuilder;
  currentTrackerJd:any;
  sources:{[key:string]:{name:string,profile:string}},
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) => void,
  applicantionStatus: {
    [key: string]: Candidate[]
  }
};



const PoolTable = ({
  currentOpening,
  applicationId,
  initCandidates,
  candidatePool,
  currentTrackerJd,
  sources,
  applicantStatus,
  applicantionStatus,
  initCDBHead,
}: TableReduxProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<string>("");
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });

  const history = useHistory();

  useEffect(() => {
    getTable()
      .then((res: { columns: any }) => {
        initCDBHead(res.columns);
      })
  }, []);


  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 249.8px)";
    }
  }, [data]);

  useEffect(() => {
    // Populate data from candidate pool when available
    if (candidatePool[tableParams.pagination.current]) {
      setData(generateData(Object.values(candidatePool[tableParams.pagination.current])));
    } else {
      setData([]);
    }
  }, [candidatePool]);

  useEffect(() => {
    // Fetch data when table parameters or current tracker form changes
    if (tableParams.pagination.current && currentTrackerJd) {
      fetchData({ currentPage: tableParams.pagination.current });
    }
  }, [currentTrackerJd]);

  // Generate data for the table from candidate pool
  const generateData = (result: any) => {
    return result.map((candidate: Candidate) => ({
      key: candidate.id,
      id: candidate.id,
      phone: candidate.phone,
      source: candidate.source?.from,
      email: candidate.email,
      candidates: {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
      },
      created_by: candidate.created_by,
      created_at: candidate.created_at,
      comment_count: candidate.comment_count,
      attachment_count: candidate.attachment_count,
    }));
  };

  // Fetch data from the API
  const fetchData = (reqParams: { currentPage: number }) => {
    if (!currentTrackerJd.id) return
    setLoading(true);
    // searchCandidateDocument(searchQuery)
    getCandidates(reqParams.currentPage,25,currentTrackerJd.id)
      .then((res) => {

        initCandidates(res.items, reqParams.currentPage.toString());
        setTableParams((prev: any) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.total_count,
          },
        }));
        getApplicantStatus({ "ids": res.items.map((items) => items.id) }).then((res: any) => {
          applicantStatus(res)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // Column definitions for the Ant Design table
  const columns = [
    {
      title: "Name",
      dataIndex: "candidates",
      ellipsis: true,
      width: "25%",
      showSorterTooltip: false,
      sorter: (a: any, b: any) =>
        a.candidates?.first_name?.localeCompare(b.candidates?.first_name),
      render: (_: any, record: any) => (
        <Space className="truncate w-full">
          <TacitbaseAvtar content={record.candidates.first_name[0]} />
          <div className="truncate">{`${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}</div>
        </Space>
      ),
    },
    {
      width: "25%",
      title: "Email",
      ellipsis: true,
      dataIndex: "email",
    },
    {
      width: "15%",
      title: "Phone",
      dataIndex: "phone",
      ellipsis: true,
    },
    {
      width: "10%",
      title: "Status",
      dataIndex: "id",
      ellipsis: true,
      render: (sourceKey: string) => {
        return applicantionStatus?.[sourceKey]?.length > 0 &&
          applicantionStatus[sourceKey].some((applicants: Candidate) => applicants.opening_id == currentOpening.id)
          ? <Tag color="processing">{"In tracker"}</Tag>
      : null;    },
    },
    {
      width: "20%",
      title: "Source",
      dataIndex: "source",
      ellipsis: true,
      render: (sourceKey: string) => {
        const sourceName = sources[sourceKey] ? capitalizeFirstLetter(sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
        const sourceImage = sources[sourceKey]?.profile; // Assuming 'image' is a property in your source data
        return sourceKey.trim().length === 0 ? (
          NOT_AVAILABLE
        ) : (
          <Space className="truncate w-full">
            {sourceImage ? (
              <img src={sourceImage} alt={sourceName} style={{ width: 20, height: 20, borderRadius: '50%' }} />
            ) : (
              <TacitbaseAvtar content={sourceName[0]} />
            )}
            <div className="truncate">{sourceName}</div>
          </Space>
        );
      },
    },
    // {
    //   width: "15%",
    //   title: "Sourcer name",
    //   dataIndex: "source",
    //   ellipsis: true,
    // },
    // {
    //   width: "10%",
    //   title: "Activity",
    //   dataIndex: "members",
    //   ellipsis: true,
    // },
    {
      width: "14%",
      title: "Submitted at",
      dataIndex: "created_at",
      ellipsis: true,
      render: (record: any) => <span>{renderCreatedAt(record)}</span>,
    },
  ];
  // Handle row selection changes
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  // Handle table changes
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    fetchData({ currentPage: pagination.current });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    setSelectedRowKey(record.key);
    history.push(
      `/o/${applicationId}/b/${currentOpening.id}/${PoolRoute}/${record.key}`
    );
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  const handleRefreshTab = () => {
    setTableParams((prev: any) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        current:1,
      },}))
    fetchData({
      currentPage: 1,
    });
  };

  // Render bulk action controls for selected rows
  const tableBulkAction = () => (
    <Space className="flex flex-row items-center justify-start h-12 px-4">
      <strong>{selectedRowKeys.length}</strong> Selected
      <AddBulkLabelToCandidateInAtsPool selectedRowKeys={selectedRowKeys} />
      <SendPoolCandidateToTracker
        isBulk={true}
        candidateIds={selectedRowKeys}
      />
      <SendPoolCandidateToReview isBulk={true} candidateIds={selectedRowKeys} />
      <SendMailToPoolCandidate isBulk={true} candidateIds={selectedRowKeys} />
      <Popconfirm
        title="Close review"
        onConfirm={() => {}}
        okText="Yes"
        cancelText="No"
        placement="bottom"
        key={2}
      />
    </Space>
  );

  return (
    <div className="flex flex-col px-4">
      <div className="bg-white flex flex-row w-full h-12 items-center rounded-t">
        {selectedRowKeys.length > 0 ? (
          tableBulkAction()
        ) : (
          <div className="flex flex-1 flex-row px-4 justify-between ">
            <div className="flex  justify-start">
              <Read >
                {currentTrackerJd?.id?.trim().length > 0 && (
                  <div className="flex flex-row space-x-2">
                    <ActionButton />
                    <ImportResumePool />
                  </div>
                )}
              </Read>

            </div>

            <div className="flex  justify-end ">
              <IconButton
                icon={<ReloadOutlined />}
                onClick={handleRefreshTab}
              />
            </div>
          </div>
        )}
      </div><div className=" h-full z-0" style={{ height: "calc(100vh - 166px)" }}>
        <div className="h-full">
          <TableAnt
            rowClassName={(record) => record.key === hoveredRow ? "cursor-pointer" : ""}
            size="small"
            loading={loading}
            columns={columns}
            data={data}
            className="h-full w-full bg-white rounded-b overflow-y-hidden"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ y: "calc(100vh - 130.8px)" }}
            rowSelection={{
              selectedRowKeys,
              preserveSelectedRowKeys: true,
              onChange: onSelectChange,
              selections: [
                Table.SELECTION_ALL,
                Table.SELECTION_INVERT,
                Table.SELECTION_NONE,
                {
                  key: "odd",
                  text: "Select Odd Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 === 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
                {
                  key: "even",
                  text: "Select Even Row",
                  onSelect: (changeableRowKeys) => {
                    const newSelectedRowKeys = changeableRowKeys.filter(
                      (_, index) => index % 2 !== 0
                    );
                    setSelectedRowKeys(newSelectedRowKeys);
                  },
                },
              ],
              columnWidth: 45,
            }}
            onRow={(record) => ({
              onMouseEnter: () => handleRowHover(record),
              onMouseLeave: () => handleRowHover(null),
              onClick: () => onRowClick(record),
            })}
          />
        </div>
        <ApplicantDetailView selectedRow={selectedRowKey} />
      </div></div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initCDBHead: (table: TableColumn[]) => dispatch(initCDBHead(table)),
  initCandidates: (candidates: Candidate[], currentPage: string) =>
    dispatch(initCandidatesAtsPool(candidates, currentPage)),
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) =>
    dispatch(applicantStatus(applicants)),
});

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerForm: state.opening.currentTrackerForm,
  applicationId: state.opening.applicationId,
  currentTrackerJd: state.opening.currentTrackerJd,
  sources: state.opening.candidateSources,
  applicantionStatus: state.opening.applicantStatus,

});

export default connect(mapStateToProps, mapDispatchToProps)(PoolTable);
