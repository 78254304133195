import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { initReviewLabels } from "../../redux/actions/opening/action";
import { Label } from "../../type/type";
import { getLabels } from "../../lib/api";
import { colors, DIVIDER_HEIGHT, REVIEWS } from "../../constant";
import TmailContent from "./TmailContent";
import { Breadcrumb, Divider } from "antd";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import Navbar from "../navbar/Navbar";
import NavSearch from "../navSearch/NavSearch";

export type CandidateReviewBaseHeaderProps = {
  intiReviewLabels: (labels: any) => void;
};
const CandidateReviewBaseHeader = (props: CandidateReviewBaseHeaderProps) => {
  useEffect(() => {
    getLabels(REVIEWS).then((labels) => {
      props.intiReviewLabels(labels);
    });
  }, []);
  return (
    <>
    
    <ContentHeader>
        <div
          className="flex h-12 px-4 py-3  justify-between items-center" >
          <div
            className="flex items-center"          >
            <Breadcrumb
    items={[
      {
        title: 'Mails',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <NavSearch/>
          <Divider type="vertical" style={{height:DIVIDER_HEIGHT,borderColor:`${colors.divider}`}} />

            <Navbar/>
          </div>
        </div>
      </ContentHeader>
      
      <div className="w-full flex h-full flex-col overflow-hidden rounded">
      <TmailContent />
      </div>
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  intiReviewLabels: (labels: Label[]) => dispatch(initReviewLabels(labels)),
});

export default connect(null, mapPropsToState)(CandidateReviewBaseHeader);
