import React, { useEffect, useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GLoginButton from "../../pages/setting/accSetting/GoogleLogin";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { UserConnectedZoomMeet } from "../../lib/apiRes";
import meet from "../../images/meet.png";
import zoom from "../../images/zoom.png";
import { Dispatch } from "redux";
import {
  addZoomAccess,
  removeMeetAccess,
  removeZoomAccessState,
  setCurrentUserZoomMeetAcc,
} from "../../redux/actions/opening/action";
import {
  conferenceIntegrationZoom,
  getZoomMeetUser,
  removeGoogleAccess,
  removeZoomAccess,
} from "../../lib/api";
import {  Table, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { SecondaryButton } from "../../ant/Button";
const { Text } = Typography;

type MeetProps = {
  loginUser: loginUser;
  CurrentUserZoomMeet: UserConnectedZoomMeet;
  removeZoomAccess(): void;
  removeMeetAccess(): void;
  setCurrentUserConferenceAcc(data: UserConnectedZoomMeet): void;
  addZoomAccess(data: string): void;
};
const ZoomMeet = (props: MeetProps) => {

  // getting code from session storage
  let code = sessionStorage.getItem("zoom-code");
  let path = window.location.pathname.split("/");
  let org_id = path[2];

  const [zoomMeetUser, setZoomMeetUser] = useState({
    zoom_email: "",
    google_email: "",
  });
  useEffect(() => {
    sessionStorage.removeItem("zoom-code");
    sessionStorage.removeItem("meet-code");

    getZoomMeetUser(props.loginUser.user.id).then((res) => {
      props.setCurrentUserConferenceAcc(res.user_conference_settings);
    });
  }, []);

  useEffect(() => {
    setZoomMeetUser(props.CurrentUserZoomMeet);
  }, [props.CurrentUserZoomMeet]);

  useEffect(() => {
    if (code) {
      const req = { code: code };
      conferenceIntegrationZoom(req).then((res) => {
        props.addZoomAccess(res.user_connected_zoom);
        sessionStorage.removeItem("zoom-code");
      });
    }
  }, [code]);

  const removeZoomMeetAccess = (id: string) => {
    id == "zoom"
      ? removeZoomAccess(props.loginUser.user.id).then((res) => {
          if (res) props.removeZoomAccess();
        })
      : id == "meet"
      ? removeGoogleAccess(props.loginUser.user.id).then((res) => {
          if (res) props.removeMeetAccess();
        })
      : null;
  };

  // fit the zoom and meet integration into the table
  const dataSource = [
    {
      key: "1",
      type: "Connect your Google account",
      email: zoomMeetUser.google_email,
      description:
        "This integration allows you to schedule and manage interviews and access your calendar to check your availability.",
      logo: meet,
      connect: zoomMeetUser.google_email ? (
        <SecondaryButton
          onClick={() => removeZoomMeetAccess("meet")}
        >
          Disconnect
        </SecondaryButton>
      ) : process.env.REACT_APP_MEET_ACCESS_CLIENT_ID ? (
        <GoogleOAuthProvider
          clientId={process.env.REACT_APP_MEET_ACCESS_CLIENT_ID}
        >
          <GLoginButton buttonName="Connect" />
        </GoogleOAuthProvider>
      ) : null,
    },
    {
      key: "2",
      type: "Connect your Zoom account",
      email: zoomMeetUser.zoom_email,
      description:
        "You can easily add video conferencing to your interviews. It will automatically create Zoom link for scheduled interviews.",
      logo: zoom,
      connect: zoomMeetUser.zoom_email ? (
        <SecondaryButton
          onClick={() => removeZoomMeetAccess("zoom")}
        >
          Disconnect
        </SecondaryButton>
      ) : (
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_ZOOM_ACCESS_URL}&state=${org_id}`}
        >
          <SecondaryButton
          onClick={()=>{}}
          >
            Connect
          </SecondaryButton>
        </a>
      ),
    },
  ];

  // table columns
  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "type",
      width: 60,
      render: (_: any, record: any) => (
        <div className="flex items-center">
          <img
            src={record.logo}
            className="w-12 h-10"
            alt={`${record.type} logo`}
          />
        </div>
      ),
    },
    {
      title: "Integration tool",
      dataIndex: "type",
      key: "type",
      render: (text: string, record: any) => (
        <div className="flex items-center">
          <Text>{text}</Text>
          <Tooltip title={record.description}>
            <InfoCircleOutlined
              className="ml-3"
              style={{ cursor: "pointer" }}
            />
             </Tooltip>
            {record.email && (
              <Text
                className="ml-4 rounded-md border-tacitbase-tertiary1 border flex px-2 border-opacity-20"
              >
                {record.email}
              </Text>
            )}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "connect",
      key: "connect",
      render: ( connect: any) => (
        <div className="flex items-center justify-end">
          {connect}
        </div>
      )
    },
  ];
  return (
    <>
      <div className="rounded-sm border-tacitbase-tertiary1 border border-opacity-10">
        <Table dataSource={dataSource} columns={columns} pagination={false} size="small"  scroll={{ x: "max-content"}} />
      </div>
    </>
  );
};

const mapPropsTostate = (dispatch: Dispatch) => ({
  setCurrentUserConferenceAcc: (data: UserConnectedZoomMeet) =>
    dispatch(setCurrentUserZoomMeetAcc(data)),
  removeMeetAccess: () => dispatch(removeMeetAccess()),
  removeZoomAccess: () => dispatch(removeZoomAccessState()),
  addZoomAccess: (data: string) => dispatch(addZoomAccess(data)),
});

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  CurrentUserZoomMeet: state.opening.calendar.currentUserZoomMeet,
});
export default connect(mapStateToProps, mapPropsTostate)(ZoomMeet);
