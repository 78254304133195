import React, {useState } from 'react'
import { Button, Modal} from "antd";
import './ImportResumeModal.css'
import CloseIcon from '../../assets/Close';
import ResumeConversion from './ResumeConversion';
import { connect } from 'react-redux';
import { UpgradeButton } from '../../ant/Button';
import UpgradePlanModal from '../../pages/setting/billingSetting/UpgradePlanModal';
import { newOrganisation } from '../../lib/apiRes';
import { useOrgMetadata } from '../../pages/setting/billingSetting/OrgMetadata';

type ImportResumeModalProps = {
  currentOrg:newOrganisation;
}
function ImportResumeModal(props:ImportResumeModalProps){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const orgMetadata = useOrgMetadata(props.currentOrg);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
      };
    
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const handleBillingModal = ()=>{
        setIsModalVisible(true);
      }
      const handleBillingModalCancel = () => {
        setIsModalVisible(false);
    };
    return(
        <>
         {
                     
           orgMetadata?.ai_feature  === 'true' ?(
            <Button name="create " className="h-7" iconPosition="start"
             onClick={showModal}>Resume parser</Button>
           ):(
              <UpgradeButton 
              label="Resume parser" 
              onClick={handleBillingModal}
             />
            )
          }
            <Modal
        title={null} 
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null} 
        className="custom-modal"
        closable={false}
        width={1300}
      >
        <div className="custom-modal-header px-4">
          <div className="custom-modal-title">Add candidate</div>
          <Button onClick={handleCancel} type="text" ><CloseIcon border={false}/></Button>
        </div>
        <div className="custom-modal-divider" />
        <div className="custom-modal-content">
         <ResumeConversion handleClose={ handleCancel}/>
        </div>
        <div className=" flex text-sm  items-center justify-center px-4 pb-2">The resume parser may contain errors; please double-check candidate details.</div>

      </Modal>
      <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
</>

    );
}

const mapStateToProps = (state: any) => ({
  currentOrg:state.opening.currentOrg,
});
export default connect(mapStateToProps)(ImportResumeModal);