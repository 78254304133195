import React, { useEffect, useState } from "react";
import { ConfigProvider, Layout, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
import ProfileSetting from "./ProfileSetting";
import CalendarSettings from "./CalendarSettings";
import OrgSettingIndex from "../pages/setting/OrgSettingIndex";
import { OWNER } from "../constant";
import { connect } from "react-redux";
import { loginUser, orgMember } from "../type/type";
import BillingIndex from "../pages/setting/billingSetting/BillingIndex";
import Invitations from "../pages/setting/Invitations";
import Member from "../pages/Members/Member";
import { useHistory, useParams } from "react-router-dom";
import {
  UserOutlined,
  CalendarOutlined,
  TeamOutlined,
  DollarOutlined,
  BankOutlined,
  NotificationOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import ImportReports from "../components/candidateDB/Import/ImportReports";

interface MenuTab {
  name: string;
  key: string;
  subTab?: {
    name: string;
    key: string;
    component: JSX.Element;
    icon?: JSX.Element;
  }[];
  component?: JSX.Element;
  icon?: JSX.Element;
}

type Organisationprops = {
  loginUser: loginUser;
  members: orgMember;
  applicationId: string;
};

const SideNavBar = (props: Organisationprops) => {
  const history = useHistory();
  const { tabname } = useParams<{ tabname: string }>();
  // getting tabName from params and set current tab
  useEffect(() => {
    setActiveKey(tabname);
  }, [tabname]);
  const [activeKey, setActiveKey] = useState(tabname);
  const [collapsed, setCollapsed] = useState(false);

  const handleResize = () => {
    setCollapsed(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // initial check
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // all sub-tabs of setting panel
  function geneRateMenu() {
    let menus: MenuTab[] = [
      {
        name: "Account",
        key: "1",
        icon: <UserOutlined />,
        subTab: [
          {
            name: "profile",
            key: "profile",
            component: <ProfileSetting />,
            icon: <UserOutlined />,
          },
          {
            name: "calendar",
            key: "calendar",
            component: <CalendarSettings />,
            icon: <CalendarOutlined />,
          },
        ],
      },
      {
        name: "organization",
        key: "organization",
        component: <OrgSettingIndex />,
        icon: <BankOutlined />,
      },
      {
        name: "members",
        key: "members",
        component: <Member />,
        icon: <TeamOutlined />,
      },
      {
        name: "billing",
        key: "billing",
        component:
          props.members[props.loginUser.user.id]?.registrations[0].roles
            .length > 0 &&
          props.members[props.loginUser.user.id].registrations[0].roles[0] ==
            OWNER ? (
            <BillingIndex />
          ) : undefined,
        icon: <DollarOutlined />,
      },
      {
        name: "invitations",
        key: "invitations",
        component: <Invitations />,
        icon: <NotificationOutlined />,
      },
      {
        name: "bulk-import",
        key: "bulk-import",
        component: <ImportReports/>,
        icon:<ImportOutlined />
        ,
      },
    ];
    menus = menus.filter(
      (tab) =>
        tab.component !== undefined || (tab.subTab && tab.subTab.length > 0)
    );
    return menus;
  }
  // settings menu

  const menus = geneRateMenu();

  // onclick on tab changing tba via key
  const handleSubMenuClick = (name: string) => {
    setActiveKey(name);
    history.push(`/o/${props.applicationId}/settings/${name}`);
  };

  // rendering tab component
  const renderContent = () => {
    const selectedTab = menus.find((tab) =>
      tab.subTab
        ? tab.subTab.find((subTab) => subTab.key === activeKey)
        : tab.key === activeKey
    );
    if (selectedTab) {
      if (selectedTab.subTab) {
        const selectedSubTab = selectedTab.subTab.find(
          (subTab) => subTab.key === activeKey
        );
        if (selectedSubTab) {
          return selectedSubTab.component;
        }
      } else {
        return selectedTab.component;
      }
    }
    return null;
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <div className="">
          <div className="bg-white">
            <Layout style={{ borderRadius: 4, height: "calc(-50px + 100vh)" }}>
              <Sider
                className="site-layout-background border-r h-full"
                width={collapsed ? 80 : 200}
                collapsible
                theme="light"
                collapsed={collapsed}
                trigger={null}
                onCollapse={setCollapsed}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Menu: {
                        colorText: "black",
                        colorBgContainer: undefined,
                        itemBg: "white",
                      },
                    },
                  }}
                >
                  <Menu
                    mode="inline"
                    className="h-full overflow-y-scroll"
                    selectedKeys={[activeKey]}
                    style={{ height: "100%", borderRight: 0 }}
                  >
                    {menus.map((tab) =>
                      tab.subTab ? (
                        <Menu.SubMenu
                          key={tab.key}
                          title={collapsed ? tab.icon : tab.name}
                          icon={tab.icon}
                        >
                          {tab.subTab.map((subTab) => (
                            <Menu.Item
                              key={subTab.key}
                              onClick={() => handleSubMenuClick(subTab.name)}
                              className="font-normal"
                              data-tabname={subTab.name}
                              icon={subTab.icon}
                            >
                              {collapsed
                                ? null
                                : capitalizeFirstLetter(subTab.name)}
                            </Menu.Item>
                          ))}
                        </Menu.SubMenu>
                      ) : (
                        <Menu.Item
                          key={tab.key}
                          onClick={() => handleSubMenuClick(tab.name)}
                          className="font-normal"
                          icon={tab.icon}
                        >
                          {collapsed ? null : capitalizeFirstLetter(tab.name)}
                        </Menu.Item>
                      )
                    )}
                  </Menu>
                </ConfigProvider>
              </Sider>
              <Content className="h-full bg-white overflow-hidden">
                {renderContent()}
              </Content>
            </Layout>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps)(SideNavBar);
