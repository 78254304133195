import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { updateApplicantAttachment } from "../../../lib/api";
import { updateAttachment } from "../../../redux/actions/opening/action";
import EditAttachmentPopup from "./EditAttachmentPopup";
import { Overlay } from "../../hook/Overlay";
import { Scope } from "../../../type/type.d";
import { IconButton } from "../../../ant/Button"
import { EditOutlined } from '@ant-design/icons'
import { SOCKET_CLIENT_ID } from "../../../constant";

type EditAttachmentType = {
  attachment: any;
  updateAttachmentName(attachment: any): void;
};
const EditAttachment = (props: EditAttachmentType) => {
  const [open, setOpen] = useState(false);
  const [attachmentName, setUpdatedAttachmentName] = useState("");
const refer=useRef(null);
  const showEditAttachmentPopup = (e: any, flag: boolean) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(flag);
  };
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });

  const updateAttachment = (e: any) => {
    e.stopPropagation();
    const attachmentReq = {
      id: props.attachment.id,
      name: attachmentName,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID)

    };
    if ((e.keyCode === 13 && !e.shiftKey) || e.button == 0) {
      e.preventDefault();
      let name = attachmentName.trim();
      if (name.trim().length > 0) {
        updateApplicantAttachment(attachmentReq)
          .then((res) => {
            props.updateAttachmentName(res);
          })
         
      }
    }
  };

  return (
    <>

      <div
        className="flex flex-row items-center justify-end"
        ref={setReferenceElement}
      >
        {/* <div
          onClick={(e: any) => {
            e.stopPropagation(), setOpen(!open);
          }}
          role="button"
          onKeyDown={() => {}}
          tabIndex={0}
          className="text-xs font-medium underline text-gray-500 px-2"
        >
          Edit
        </div> */}
        {/* <Tooltip title={open ? null : "Edit"} > */}
          <div>
            <IconButton
              size="small"
              type="text"
              icon={<EditOutlined   />}
              onClick={(e: any) => {
                e.stopPropagation(), setOpen(!open)
              }}
            />
          </div>
        {/* </Tooltip> */}

        {open ? (
          <Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <EditAttachmentPopup
                click={(e: any, flag: boolean) =>
                  showEditAttachmentPopup(e, flag)
                }
                attachment={props.attachment}
                updateAttachmentClick={updateAttachment}
                updatedAttchmentName={(e: string) =>
                  setUpdatedAttachmentName(e)
                }
                refer={refer}
              />
            </div></Overlay>
        ) : null}
      </div>
    </>
  );
};
const mapPropsTostate = (dispatch: Dispatch) => ({
  updateAttachmentName: (user: any) => dispatch(updateAttachment(user)),
});
export default connect(null, mapPropsTostate)(EditAttachment);
