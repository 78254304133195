
import React from "react";

const Sent = () => {
    return (
        <>
            <svg width="16" height="18" viewBox="0 0 20 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.3333 0H7.5C6.58333 0 5.83333 0.75 5.83333 1.66667V9.16667C5.83333 9.60869 6.00893 10.0326 6.32149 10.3452C6.63405 10.6577 7.05797 10.8333 7.5 10.8333H18.3333C19.2583 10.8333 20 10.0917 20 9.16667V1.66667C20 1.22464 19.8244 0.800716 19.5118 0.488155C19.1993 0.175595 18.7754 0 18.3333 0ZM18.3333 9.16667H7.5V3.05833L12.9167 5.83333L18.3333 3.05833V9.16667ZM12.9167 4.425L7.5 1.66667H18.3333L12.9167 4.425ZM4.16667 9.16667C4.16667 9.30833 4.19167 9.44167 4.20833 9.58333H0.833333C0.373333 9.58333 0 9.20833 0 8.75C0 8.29167 0.373333 7.91667 0.833333 7.91667H4.16667V9.16667ZM2.5 1.25H4.20833C4.19167 1.39167 4.16667 1.525 4.16667 1.66667V2.91667H2.5C2.04167 2.91667 1.66667 2.54167 1.66667 2.08333C1.66667 1.625 2.04167 1.25 2.5 1.25ZM0.833333 5.41667C0.833333 4.95833 1.20833 4.58333 1.66667 4.58333H4.16667V6.25H1.66667C1.20833 6.25 0.833333 5.875 0.833333 5.41667Z" fill="black" />
            </svg>
        </>
    );
};
export default Sent;
