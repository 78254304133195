import { Popconfirm, Space } from "antd";
import React, { useState } from "react";
import { updateInvitationStatusApi } from "../../lib/api";
import { Dispatch } from "redux";
import { withdrawInvitation } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { OutgoingInvitations } from "../../type/type";
import { SecondaryButton } from "../../ant/Button";

// cancel invitation props
type CancelInvitationpProps = {
  withdrawInvitationOfMember: (invitation: OutgoingInvitations) => void;
  invitationId: string;
};
const CancelInvitation = (props: CancelInvitationpProps) => {

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  //function will call on click on Revoke invitation button
  const revokeMember = (id: string) => {
    const req = {
      id: id,
      status: "WITHDRAWN",
    };
    setLoading(true);
    updateInvitationStatusApi(req)
      .then((res: any) => {
        if (res.status == "WITHDRAWN") {
          props.withdrawInvitationOfMember(res);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error updating invitation status:", error);
      }) .finally(() => {
        setLoading(false);
        setOpen(false);
      })
  };
  
  return (
    <Space>
      <Popconfirm
        title="Withdraw the Invitation"
        description="Are you sure you want to withdraw this invitation?"
        okButtonProps={{ loading: loading }}
        onConfirm={() => revokeMember(props?.invitationId)}
        onCancel={() => setOpen(false)}
        okText="Yes"
        cancelText="No"
        visible={open}
      >
        <SecondaryButton
          onClick={() => setOpen(true)}
        >
          <div className="flex justify-center items-center">
           
              Revoke invitation
          </div>
        </SecondaryButton>
      </Popconfirm>
    </Space>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  withdrawInvitationOfMember: (invitations: any) =>
    dispatch(withdrawInvitation(invitations)),
});
export default connect(null, mapPropsToState)(CancelInvitation);
