import React, {  useState } from "react";
import { connect } from "react-redux";
import { Button, Popconfirm } from "antd";
import { loginUser, orgMember } from "../type/type";
import { ACTIVATE_BUTTON, DEACTIVATE_BUTTON, OWNER } from "../constant";
import { activateDeactivateMember } from "../lib/api";
import { updateMember } from "../redux/actions/opening/action";
import ConfirmationMessage from "./ConfirmationMessage";

type MemberActionButtonsProps = {
  selectedMember: string;
  applicationId: string;
  updateMember(data: any): void;
  members: orgMember;
  loginUser: loginUser;
  visible: boolean;
};


const UserProfileAction = (props: MemberActionButtonsProps) => {
  const [open, setOpen] = useState<boolean>(false); // State to track active menu
  const [confirmLoading, setConfirmLoading] = useState(false);
  const isOwner =
    props.members[props.loginUser.user.id].registrations[0].roles[0] === OWNER;
  if (!isOwner) return null;

  // onclick on activate or deactivate member popupConfirmation this function will call
  const handleDisconnectConfirm = () => {
    const req = {
      user_id: props.selectedMember,
      application_id: props.applicationId,
      active:
        !props.members[props.selectedMember].registrations[0].data.activated,
    };
    setConfirmLoading(true);
    activateDeactivateMember(req)
      .then((res: any) => {
        props.updateMember({
          memberID: res.user_id,
          key: "activated",
          value: res.active,
        });
        setConfirmLoading(false);
      })
      .catch((error: any) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setConfirmLoading(false);
        setOpen(false);
      });
  };

 
  // LabeledIconButton is a member action button
  return (
    <>
      <Popconfirm
        open={open}
        title={
          props.members[props.selectedMember].registrations[0].data.activated
            ? "Deactivate a member"
            : "Activate a member"
        }
        okButtonProps={{ loading: confirmLoading }}
        description={
          <div style={{width:"19rem"}}>
            {props.members[props.selectedMember].registrations[0].data
              .activated ? (
                <ConfirmationMessage message="deactivate" firstName={props.members[props.selectedMember].first_name} lastName={props.members[props.selectedMember].last_name}/>
            ) : (
              <ConfirmationMessage message="activate" firstName={props.members[props.selectedMember].first_name} lastName={props.members[props.selectedMember].last_name}/>
            )}
          </div>
        }
        onCancel={(e) => {
          e?.stopPropagation(), e?.preventDefault();
          setOpen(false);
        }}
        onConfirm={handleDisconnectConfirm}
        okText={
          props.members[props.selectedMember].registrations[0].data.activated
            ? DEACTIVATE_BUTTON
            : ACTIVATE_BUTTON
        }
        cancelText="Cancel"
        trigger={["click"]}
      />
      {props.members[props.selectedMember].registrations[0].roles[0] !==
      OWNER ? (
        props.members[props.selectedMember].registrations[0].data.activated ? (
          <div className="py-3">
            <Button
            className="pl-0 underline"
            type="link"
           
              danger
              onClick={() => setOpen(true)}
            >
              Deactivate membership
            </Button>
          </div>
        ) : (
          <div className="py-3">
            <Button
              type="link"
              onClick={() => setOpen(true)}
              className="pl-0"
              style={{
                textDecoration: "underline",
                color: "#009D79",
                backgroundColor: "white",
              }}
            >
              Re-activate membership
            </Button>
          </div>
        )
      ) : null}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateMember: (data: any) => dispatch(updateMember(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileAction);
