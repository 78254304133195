import React, { useState } from 'react';
import { Badge, Button, Collapse, Divider, Popover, Tooltip } from 'antd';
import CandidateLabelFilter from '../candidateDB/CandidateLabelFilter';
import FilterBySource from '../candidateDB/FilterBySource';
import AdvanceSearch from '../candidateDB/AdvanceSearch';
import { FilterOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { initSearchFilter, initSearchQuery } from '../../redux/actions/opening/action';
const { Panel } = Collapse;

interface CDBFiltersProps{
  searchQuery:any;
  initSearchQuery: (query: string) => void;
  initSearchFilter: (filterObj: any) => void;
}

const customPanelStyle = {
  backgroundColor: '#fff', // This sets the background color to white
  borderRadius: 4,
  border: 0,
  overflow: 'hidden',
};

function CDBFilters(props:CDBFiltersProps) {
    const [visible, setVisible] = useState(false);

    const clearAll = () => {
    props.initSearchQuery("");
    props.initSearchFilter([]);
    setVisible(false)
  };

  const  title=(
        <div className="h-full w-full">
          <div className="flex justify-between items-center pl-3">
            <div>Filter</div>
            <Button type="link"  onClick={clearAll} style={{color:'#4B48A5'}}>
              Clear All
            </Button>
          </div>
          <Divider style={{ margin: "8px 0" }} />
        </div>
  )
  const content = (
    <div className='flex flex-col' style={{ width: 300, minHeight: 100, overflowY: "auto",margin:'0' }}>
      {/* <CandidateLabelFilter /> */}
      <Collapse
      accordion
      bordered={false}
      defaultActiveKey={['1']}
      expandIconPosition='left'
    >
      <Panel 
header={
  <><span>Labels{props.searchQuery?.filters?.length>0&&<Badge count={props.searchQuery.filters.filter((item: { column: string; }) => item.column === "label_names").length} offset={[10, 0]} style={{backgroundColor:"#4B48A5"}}/>
            }</span></>
} 
        key="1" 
        style={customPanelStyle}
      >
        <CandidateLabelFilter/>
      </Panel>
      <Panel 
        header={
          <><span>Source{props.searchQuery?.filters?.length>0&&<Badge count={props.searchQuery.filters.filter((item: { column: string; }) => item.column === "source.from").length} offset={[10, 0]} style={{backgroundColor:"#4B48A5"}}/>
                    }</span></>
        } 
        key="2" 
        style={customPanelStyle}
      >
        <p>            <FilterBySource />

        </p>
      </Panel>
      <Panel 
        header={<><span>Advanced filter
          {props.searchQuery?.filters?.length > 0 && (
            <Badge 
              count={props.searchQuery.filters.filter(
                (item: { column: string }) => item.column !== "source.from" && item.column !== "label_names"
              ).length} 
              offset={[10, 0]} 
              style={{ backgroundColor: "#4B48A5" }} 
            />
          )}
          </span></>
          }
        key="3" 
        style={customPanelStyle}
      >
        <p> <AdvanceSearch /></p>
      </Panel>
    </Collapse>
    </div>
  );

  return (
    <div>
      <Popover title={title} content={content}  trigger="click" visible={visible} onVisibleChange={setVisible}
      style={{margin:0,padding:0}}
>
  <Tooltip title="Apply filter">
      <Button
        type="default"
        icon={<FilterOutlined  />}
        onClick={() => setVisible(true)}
        size='small'
        style={{minWidth:'28px',height:'28px'}}
        >
         
        {props.searchQuery.filters.length > 0 && 

        <Badge
        className="site-badge-count-109 "
        count={props.searchQuery.filters.length}
        style={{ backgroundColor: '#4B48A5' }}
      />
    }
      </Button>    
      </Tooltip>
        </Popover>
    </div>
  );
}


const mapStateToProps = (state: any) => ({
    searchQuery: state.opening.searchQuery,
  });

  const mapPropsToState = (dispatch: Dispatch) => ({  
    initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
    initSearchFilter: (filterObj: any) => dispatch(initSearchFilter(filterObj)),

  });


export default connect(mapStateToProps,mapPropsToState) (CDBFilters);
