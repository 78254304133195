import React from "react";
import { Tag, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface TokenInputProps {
  selectedValues: string[]; // Values to display in the token input
  inputValue: string; // Current input value
  onInputChange: (value: string) => void; // Callback for input change
  handleTokenClose: (value: string) => void; // Callback for input change
  disabled?: boolean;
  onTokenAdd: (input: string) => void;
}


const TokenInputField = (props: TokenInputProps) => {
  // Function to handle key press event
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && props.inputValue.trim() !== "") {
      props.onTokenAdd(props.inputValue.trim());
    } else if (e.key === " " && props.inputValue.trim() !== "") {
      e.preventDefault(); // Prevent default behavior of space key

      // Check if the pressed key is space and the input value is not empty after trimming
      props.onTokenAdd(props.inputValue.trim()); // Call onTokenAdd with trimmed input value
    }
  };

  return (
    <div
      className={`border-gray-300 ${
        props.selectedValues.length > 0 && "px-1 border"
      } rounded cursor-text flex flex-wrap w-full items-center py-1`}
      style={{ alignItems: "center" }}
    >
      {props.selectedValues.map((value, index) => (
        <Tag
          key={value}
          closable
          style={{
            border: "1px solid transparent",
            background: "rgba(0, 0, 0, 0.06)",
            maxWidth: 200,
          }}
          onClose={() => props.handleTokenClose(value)}
          closeIcon={
            <div className="absolute right-1 bottom-1 z-10">
              <CloseOutlined />
            </div>
          }
          className={`relative truncate pr-4 px-2 py-1 rounded mr-1 border-none ${"mt-1"} ${
            index <= props.selectedValues.length - 1 && "mb-1"
          }`}
        >
          <span>{value}</span>
        </Tag>
      ))}

      <div className="flex flex-grow">
        <Input
          className="flex flex-grow "
          value={props.inputValue}
          style={{ width: 200 }}
          onChange={(e) => props.onInputChange(e.target.value)}
          onKeyDown={handleKeyPress} // Handle key press event
          // placeholder="Enter emails (use spaces to separate)"
          allowClear
        />
      </div>
    </div>
  );
};

export default TokenInputField;
