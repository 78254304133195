import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux"
import { Dispatch } from "redux"
import {
    activity,
    CandidateDetailReview,
    loginUser,
    orgMember,
    ReviewComment,
    ReviewComments,
} from "../../type/type"
import {
    addCommentReview,
    initCurrentCandidateInReview,
    initReviewComments,
    updateCommentOfReview,
} from "../../redux/actions/opening/action"
import { Divider, Empty, Space, Tabs, Typography } from "antd"
import parse from "html-react-parser"
import TabPane from "antd/es/tabs/TabPane"
import {
    createReviewComment,
    editCommentOfReview,
    getActivity,
    getReviewComments,
} from "../../lib/api"
import {
    ACTIVITY_TAG,
    COMMENT_TAG,
    DATE_TIME_FORMATE,
    SOCKET_CLIENT_ID,
} from "../../constant"
import TacitbaseAvtar from "../../ant/Avtar"
import moment from "moment"
import Tag from "antd/lib/tag";

import Editor from "../../ant/Editor/Editor"
import { LabeledIconButton } from "../../ant/Button"
import { SendOutlined } from "@ant-design/icons"
import {
    AddCommentOnReviewReq,
    EditCommentOfReviewReq,
} from "../../lib/apiReq"
import SlackIcon from "../../assets/SlackIcon"
import DetailReviewActivity from "./DetailReviewActivity"
import { UnprivilegedEditor } from "react-quill"
const { Text } = Typography
const DetailedReviewContent = (props: {
    applicationId: string,
    updateComment(comment: any): void,
    initCurrentCandidateInReview: (candidate: CandidateDetailReview) => void,
    currentCandidateInReview: CandidateDetailReview,
    members: orgMember,
    initReviewComments: (
        comments: ReviewComment[] | activity[],
        selectedReviewId: string,
        tag: string
    ) => void,
    reviewComments: ReviewComments,
    loginUser: loginUser,
    addRComment: (comment: ReviewComment, tag: string) => void
}) => {
    const MAX_COMMENT_LENGTH = 2000;

    const [editingCommentId, setEditingCommentId] = useState<string>("")
    const [editedCommentBody, setEditedCommentBody] = useState("")
    const [newComment, setnewComment] = useState<string>("")
    const [activeTab, setActiveTab] = useState<string>("Rcomments") // State to track active tab
    const [showLoading, setShowLoading] = useState<boolean>(false)
    const [commentValidationMessage, setCommentValidationMessage] = useState<string>("");
    const [isFocused, setIsFocused] = useState(false);

    const handleEdit = (commentId: string, body: string) => {
        setEditingCommentId(commentId)
        setEditedCommentBody(body)
    }
    const handleEditedComment = (content: string) => {
        setEditedCommentBody(content)
    }
    const handleFocus = () => {
        setIsFocused(true)
    }

    // Handle blur event
    const handleBlur = () => {
        setIsFocused(false)
    };

    // const handleEmojiSelect = (emoji: string) => {
    //     console.log(emoji, editor)
    //     const container: any = document.getElementsByClassName('ql-container')
    //     const quill = new Quill(container)
    //     console.log(quill, emoji)
    // }
    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "auto" })
        }
    }
    useEffect(() => {
        scrollToBottom()
    }, [props.reviewComments])

    const saveComment = () => {
        if (!newComment) return
        if (commentValidationMessage.length > 0) return
        setShowLoading(true)
        let commentReq: AddCommentOnReviewReq = {
            body: newComment.trim(),
            review_id: props.currentCandidateInReview.id,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        }
        createReviewComment(commentReq).then((res) => {
            setShowLoading(false)

            props.addRComment(res, COMMENT_TAG)
            setnewComment("")
            setEditingCommentId("")
            setEditedCommentBody("")
        }).catch(() => {
            setShowLoading(false)
        })
        // }
    }
    const handleKeyDownComment = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 13 && e.metaKey) {
            // Save the comment when Command + Enter is pressed
            saveComment()
        }
    }
    const handleKeyDownEditedComment = (
        e: React.KeyboardEvent<HTMLDivElement>
    ) => {
        if (e.keyCode === 13 && e.metaKey) {
            // Save the comment when Command + Enter is pressed
            updateReviewComment()
        }
    }

    const updateReviewComment = () => {
        const postComment: EditCommentOfReviewReq = {
            body: editedCommentBody,
            id: editingCommentId,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        }
        editCommentOfReview(postComment).then((comment) => {
            if (comment) {
                props.updateComment(comment)
                setEditingCommentId("")
                setEditedCommentBody("")
            }
        })
    }

    const customModules = {
        toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            // ['clean']
        ],
    }

    const customFormats = ["bold", "italic", "underline", "list", "bullet"]
    const renderComments = () => {
        const comments =
            props.reviewComments[props.currentCandidateInReview.id] &&
            Object.values(props.reviewComments[props.currentCandidateInReview.id])

        // Sort comments by date in descending order
        const sortedComments = comments
            ? comments.sort(
                (a, b) =>
                    new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
            )
            : [];


        if (sortedComments.length === 0) {
            return (
                <Empty
                    description="No comments yet"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
            )
        }

        if (sortedComments)
            return sortedComments.map(
                (comment: any) => {
                    const isEdited = new Date(comment.created_at) < new Date(comment.updated_at)
                    return (
                    comment.tag == COMMENT_TAG &&
                    props.members[comment.commentor] && (
                        <div key={comment.id}>
                            {

                                    <div
                                        key={comment.id}
                                        className={` ${comment.commentor === props.loginUser.user.id
                                            ? "flex  flex-col justify-end items-end"
                                            : "flex flex-col justify-start items-start"
                                            } mb-2`}
                                    >
                                        <div
                                            className={`flex flex-col space-y-1 ${comment.commentor === props.loginUser.user.id
                                                ? "justify-end"
                                                : "justify-start "
                                                } `}
                                            style={{ minWidth: "auto", maxWidth: "66%" }}
                                        >
                                            <div
                                                className={`flex  ${comment.commentor === props.loginUser.user.id
                                                    ? "justify-end"
                                                    : "justify-start"
                                                } items-center space-x-2`}
                                            >
                                                {comment.commentor === props.loginUser.user.id ? (
                                                    <>
                                                        <Text className="font-normal text-xs ">
                                                            {moment(comment.created_at).format(
                                                                DATE_TIME_FORMATE
                                                            )}
                                                        </Text>
                                                        <Text strong className="font-normal">
                                                            {"You"}
                                                        </Text>
                                                        <TacitbaseAvtar
                                                            size="default"
                                                            src={props.members[comment.commentor].image_url}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <TacitbaseAvtar
                                                            size="default"
                                                            src={props.members[comment.commentor].image_url}
                                                        />
                                                        <Text strong className="font-normal">
                                                            {props.members[comment.commentor].first_name}{" "}
                                                            {props.members[comment.commentor].last_name}
                                                        </Text>
                                                        <Text className="font-normal text-xs">
                                                            {moment(comment.created_at).format(
                                                                DATE_TIME_FORMATE
                                                            )}
                                                        </Text>
                                                    </>
                                                )}{" "}
                                            </div>
                                            {editingCommentId === comment.id ? (
                                                <div style={{
                                                    boxShadow:
                                                        "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                                                }} className="comment-editor border rounded w-96 ">
                                                    <Editor
                                                        modules={customModules}
                                                        onKeyDown={handleKeyDownEditedComment}
                                                        formats={customFormats}
                                                        defaultValue={editedCommentBody}
                                                        value={editedCommentBody}
                                                        initialValue={editedCommentBody}
                                                        onChange={handleEditedComment}
                                                    />
                                                <div className="flex justify-end pb-2 px-3">
                                                    {/* <EmojiPicker onEmojiSelect={(emoji) => handleEmojiSelect(emoji)} /> */}
                                                        <LabeledIconButton
                                                            size="small"
                                                            label=""
                                                            type="primary"
                                                            icon={<SendOutlined />}
                                                            onClick={updateReviewComment}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                    <div className={`space-y-1 ${comment.commentor === props.loginUser.user.id ? "mr-8" : "ml-9"} `}>
                                                    <div
                                                            className=" rounded px-2 py-1 "
                                                        style={{
                                                            background: `${comment.commentor === props.loginUser.user.id
                                                                ? "#F6F4FF66"
                                                                : "#EAFFF666"
                                                                }`,
                                                            boxShadow:
                                                                "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",


                                                        }}
                                                    >
                                                        {parse(comment.body)}
                                                        </div>
                                                        <div className="flex  justify-between items-start">

                                                            {comment.commentor === props.loginUser.user.id &&
                                                            <Tag
                                                                bordered={false}
                                                                onClick={() =>
                                                                    handleEdit(comment.id, comment.body)
                                                                }
                                                                    className="cursor-pointer"
                                                            >
                                                                {"edit"}
                                                            </Tag>


                                                            }

                                                            <div className="flex justify-end w-full items-center space-x-1">
                                                                {comment.source == "slack" &&
                                                                    <Space className="flex justify-end items-center">
                                                                <SlackIcon />
                                                                        <Text className="text-xs" type={"secondary"}>{"Sent from Slack"}</Text>

                                                                    </Space>
                                                                }
                                                                <Space>
                                                                    {isEdited && <Text type="secondary" style={{ fontSize: "12px" }}>{"(edited)"}</Text>}
                                                                </Space>
                                                            </div>
                                                        </div>

                                                </div>
                                            )}
                                        </div>
                                </div>
                            }
                            </div>
                        )
                    )
                }

            )
    }

    const handleTabChange = (key: string) => {
        setActiveTab(key)
        if (key === "Ractivity") {
            // Fetch activity when switching to the "Activity" tab
            getActivity("reviews", props.currentCandidateInReview.id)
                .then((res) => {
                    props.initReviewComments(
                        res,
                        props.currentCandidateInReview.id,
                        ACTIVITY_TAG
                    )
                })
                .finally(() => { })
        }
    }
    // Define tab titles and contents
    const tabData = [
      {
        key: "Rcomments",
        title: "Comments",
        content: (
          <div
            style={{ height: "calc(100vh - 345px)", minHeight: "100px" }}
            className=" py-2  "
          >
            <div className="my-2 pr-2 h-full overflow-y-scroll">
              {renderComments()}
              <div ref={messagesEndRef}></div>
            </div>
          </div>
        ),
      },
      {
        key: "Ractivity",
        title: "Activity",
        content: <DetailReviewActivity />,
      },
      // Add more tab data as needed
    ];
    const handleEditorChange = (
      content: string,
      delta: any,
      source: string,
      editor: UnprivilegedEditor
    ) => {
      if (editor.getText().length > 0) {
        setnewComment(content);

        if (content.trim().length > MAX_COMMENT_LENGTH) {
          // If it exceeds, set the validation message
          setCommentValidationMessage("Your comment is too long");
        } else {
          // If it's within the limit, clear the validation message
          setCommentValidationMessage("");
        }
      }
    };

    useEffect(() => {
      if (props.currentCandidateInReview.id) {
        getReviewComments(props.currentCandidateInReview.id)
          .then((res) => {
            props.initReviewComments(
              res,
              props.currentCandidateInReview.id,
              COMMENT_TAG
            );
          })
          .finally(() => {});
      }
    }, [props.currentCandidateInReview.id]);
    return (
      <>
        <div className="flex flex-col h-full justify-between items-baseline w-full comment-editor">
          <Tabs
            size="small"
            defaultActiveKey={activeTab}
            className="w-full"
            onChange={handleTabChange}
            tabBarStyle={{ marginBottom: 0 }}
          >
            {tabData.map((tab) => (
              <TabPane tab={tab.title} key={tab.key}>
                {tab.content}
              </TabPane>
            ))}
          </Tabs>
          {activeTab == "Rcomments" && (
            <div className="w-full comment-editor sticky bottom-0  bg-white">
              <Divider className="mb-3  mt-2 bg-white" />
              <div
                className={` rounded ${
                  isFocused ? "quill-editor-focused" : "border"
                }`}
                style={{
                  boxShadow:
                    "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                }}
              >
                <Editor
                  onFocus={handleFocus}
                  modules={customModules}
                  onKeyDown={handleKeyDownComment}
                  formats={customFormats}
                  defaultValue={newComment}
                  value={newComment}
                  initialValue={newComment}
                  onChange={handleEditorChange}
                  onBlur={handleBlur}
                />
                <div className="flex w-full py-2 pl-4 pr-2">
                  {/* <EmojiPicker onEmojiSelect={handleEmojiSelect} /> */}
                  <div className="justify-start flex w-full text-red-600">
                    {commentValidationMessage}
                  </div>
                  <div className="flex justify-end">
                    <LabeledIconButton
                      size="small"
                      label=""
                      loading={showLoading}
                      type="primary"
                      icon={<SendOutlined />}
                      onClick={saveComment}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    currentCandidateInReview: state.opening.currentCandidateInReview,
    members: state.opening.members,
    reviewComments: state.opening.reviewComments,
    loginUser: state.opening.loginUser,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    initCurrentCandidateInReview: (candidate: CandidateDetailReview) =>
        dispatch(initCurrentCandidateInReview(candidate)),
    initReviewComments: (
        comments: ReviewComment[] | activity[],
        selectedReviewId: string,
        tag: string
    ) => dispatch(initReviewComments(comments, selectedReviewId, tag)),
    addRComment: (comment: ReviewComment, tag: string) =>
        dispatch(addCommentReview(comment, tag)),
    updateComment: (comment: any) => dispatch(updateCommentOfReview(comment)),
})
export default connect(mapStateToProps, mapPropsToState)(DetailedReviewContent)
