import { Form, Space, Typography } from 'antd'
import React from 'react'
import { agency, fullInvitee, guestUser } from './InviteMemberModal';
import TokenAutocomplete from "./TokenInputField";
const { Text } = Typography

type FulluserProps = {
errorMessage: string;
invitee:fullInvitee[] | guestUser[] |agency[];
handleInputChange:(value: string)=>void;
handleTokenAdd:(input:string)=>void;
handleTokenClose:(emailToRemove: string)=>void;
inputValue:string;
}
const Fulluser = (props:FulluserProps) => {

  return (
        <>
          <Form.Item
            validateStatus={props.errorMessage ? "error" : ""}
            help={props.errorMessage}
            label={
              <div className="w-full">
                <div className="flex justify-between space-x-1 w-full ">
                  <div>Email addresses</div>{" "}<Text type='secondary'>(use spaces to separate)</Text>
                </div>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Please input your work email!",
              },
              { type: "email", message: "Invalid email format" },
            ]}
            className="  w-full relative"
          >
            <Space.Compact style={{ width: "100%" }}>
              <TokenAutocomplete
                selectedValues={props.invitee.map((obj) => obj.email)}
                inputValue={props.inputValue}
                onInputChange={props.handleInputChange}
                onTokenAdd={props.handleTokenAdd}
                handleTokenClose={props.handleTokenClose}
              />
              {/* Any other content or components */}
            </Space.Compact>

            {/* Any other content or components */}
          </Form.Item>
        </>
  )
}

export default Fulluser
