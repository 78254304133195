import React, { useRef, useState } from "react";
import { Scope, TableColumn } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
import TickIcon from "../../../assets/Tick";

interface DropdownProps {
  option: string[];
  name: string;
  selectedValue: string;
  setValue: (selectedColumn: string) => void;
  selectedObjects: { [key: string]: TableColumn | null };
}

const CdbColumnDropdown = (props: DropdownProps) => {
  // State to manage the visibility of the dropdown popup
  const [showPopup, setShowPopup] = useState(false);

  // Reference to the dropdown popup element
  const popupRef = useRef<HTMLDivElement | null>(null);

  const { selectedObjects } = props;

  return (
    <>
      <div className="relative ">
        {/* Dropdown button */}
        <button
          className="input-box cursor-pointer flex items-center justify-between"
          onClick={() => setShowPopup(!showPopup)}
        >
          {/* Display the selected value or "Select" if no value is selected */}
          <div className="overflow-hidden text-sm">
            {props.selectedValue ? props.selectedValue : "Select"}
          </div>
          <div className="text-xl mb-3"> &#8964;</div>
        </button>
        {showPopup ? (
          <Overlay
            onClose={() => setShowPopup(!showPopup)}
            scope={Scope.Popover}
            reference={popupRef}
          >
            <div
              className="popupDiv absolute p-2 bg-white w-full top-9 rounded shadow-2xl z-50"
              ref={popupRef}
            >
              {/* List of options */}
              <div className="flex flex-col space-y-1 min-h-min max-h-48 overflow-y-scroll">
                {props.option?.map((option: string, i: number) => (
                  // Each option in the dropdown
                  <button
                    className={`p-1.5 flex items-center justify-between bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 rounded text-sm cursor-pointer ${
                      selectedObjects[props.name]?.view === option ? "bg-opacity-40" : ""
                    }`}
                    key={i}
                    aria-hidden
                    // Set the selected value and close the dropdown when an option is clicked
                    onClick={() => {
                    
                        props.setValue(option); // Map the column
                      
                      setShowPopup(false);
                    }}
                  >
                    {option}

                    {selectedObjects[props.name]?.view === option}
                    {selectedObjects[props.name]?.view === option && <TickIcon />}
                  </button>
                ))}
              </div>
            </div>
          </Overlay>
        ) : null}
      </div>
    </>
  );
};

export default CdbColumnDropdown;
