import React from "react";

import AttachmentIcons from "../../assets/attachmentIconsOfMail/Icons";
import { Attachment } from "../../type/type";
import { downloadAttachmentOfMail } from "../../lib/api"

const AttachmentPreviewOfMail = ({
  attachment,
}: {
  attachment: Attachment;
}) => {
  // determine file type based on the file extension
  const fileType: string | undefined = attachment.name.split(".").pop();

  const fileIcon = fileType && AttachmentIcons(fileType);

  const downloadFile = () => {
    downloadAttachmentOfMail(attachment.objectKey).then((res: any) => {
      const fileUrl = res.link;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = attachment.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    })
  };
  return (
    <>
      <div className="flex-shrink-0 fa-lg text-tacitbase-secondary3">
        {fileIcon}
      </div>
      <div className="w-full truncate ">{attachment.name}</div>{" "}
      <button onClick={downloadFile}>
        <i className="fa fa-lg fa-download " aria-hidden="true">
          {" "}
        </i>
      </button>
    </>
  );
};

export default AttachmentPreviewOfMail;
