import React from "react";
import { connect } from "react-redux";
import { SelectOptionsProps } from "../../../type/copyCard";
const SelectOptions = (props: SelectOptionsProps): any => {
  return (
    <>
      <div className="text-xs">{props.label}</div>
      <select
        data-testid="SelectOptionsSelectTestBtn"
        className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
        onChange={(e: any) => props.event(e.target.value)}
        onBlur={(e: any) => props.event(e.target.value)}
      >
        {props.value.map((option: any, index: number) => (
          <option
            key={index}
            className="w-full"
            value={option.id}
            selected={option.id === props.comparedId}
          >
            {option.name}
          </option>
        ))}
      </select>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  labels: state.opening.labels,
  members: state.opening.members,
  // comments: state.opening.comments,
});
export default connect(mapStateToProps)(SelectOptions);
