import React from "react";
import { connect } from "react-redux";
import { SelectPositionProps } from "../../../type/copyCard";
const SelectPosition = (props: SelectPositionProps): any => {
  return (
    <>
      <div className="text-xs">{props.label}</div>
      <select
        className="appearance-none cursor-pointer outline-none w-full bg-gray-200 bg-opacity-20"
        onChange={(e: any) => props.event(e.target.value)}
        data-testid="SelectPositionTestBtn"
        onBlur={(e: any) => props.event(e.target.value)}
      >
        {props.value.map((option: any, index: number) => (
          <option
            key={index}
            className="w-full"
            value={index}
            selected={option.id === props.comparedId}
          >
            {index + 1}
          </option>
        ))}
        {props.move ? (
          props.sourceId === props.extraIndexCheck ? null : (
            <option className="w-full" value={props.value.length}>
              {props.value.length + 1}
            </option>
          )
        ) : (
          <option className="w-full" value={props.value.length}>
            {props.value.length + 1}
          </option>
        )}
      </select>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
  currentApplicant: state.opening.currentApplicant,
  keep: state.opening.keepCopyApplicant,
  tasks: state.opening.tasks,
  labels: state.opening.labels,
  members: state.opening.members,
  // comments: state.opening.comments,
});
export default connect(mapStateToProps)(SelectPosition);
