import CloseIcon from "../../../assets/Close";
import React from "react";

interface UploadedFileProps {
  name: string;
  progress: number;
  onClose: () => void;
}

const UploadedFile: React.FC<UploadedFileProps> = ({
  name,
  progress,
  onClose,
}) => {
  return (
    <>
      <div className="flex items-start border rounded w-full space-x-3 px-2 py-1">
        <div className="text-xs w-full font-medium">{name}</div>
        {progress < 100 && (
          <progress value={progress} max="100" className="w-1/4" />
        )}
        {/* <div className="w-full flex items-center justify-center"> */}
        {/* </div> */}
        <div className="flex items-center justify-center">
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </>
  );
};
export default UploadedFile;
