import React, { useState } from "react";
import { disconnectSlackAccountOfMember } from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Integration } from "../../type/type";
import { Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { removeIndividualSlackUser } from "../../lib/api";
import { SLACK } from "../../constant";
import ConfirmationMessage from "../../setting_v1/ConfirmationMessage";

type DisconnectSlackProps = {
  selectedMember: string;
  disconnectSlackAccountOfMember(data: string): void;
  integratedSlackMember: { [key: string]: Integration[] };
};

const Disconnect = (props: DisconnectSlackProps) => {
  const [openDeactivePopup, showDeactivePopup] = useState<boolean>(false);
  const [loading, showloading] = useState<boolean>(false);

  // finding weather slack is connected or not
  const isSlackConnected =
    props.integratedSlackMember &&
    props.integratedSlackMember[props.selectedMember];

    let FirstName="";
    let LastName = "";
   
    props.integratedSlackMember[props.selectedMember].map((integation:Integration)=>{
      if(integation.app_name === SLACK){
        FirstName = integation.first_name,
        LastName = integation.last_name
      }
    })

    // function that disconnect slack
  const userActivationAction = () => {
    showloading(true);
    removeIndividualSlackUser(props.selectedMember)
      .then((res: any) => {
        if (res) {
          showloading(false);
          showDeactivePopup(false);
          props.disconnectSlackAccountOfMember(props.selectedMember);
        }
      })
      .catch((error: any) => {
        showloading(false);
        console.error("Error:", error);
      });
  };

  return (
    <div>
      {isSlackConnected && (
        <Popconfirm
          onCancel={(e) => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            showDeactivePopup(false);
          }}
          open={openDeactivePopup}
          title="Disconnect Slack"
          description={
            <div className="w-72">
              <ConfirmationMessage message="disconnect Slack for" firstName={FirstName} lastName={LastName}/>
            </div>
          }
          onConfirm={userActivationAction}
          okText={"Yes"}
          okButtonProps={{ loading: loading }}
          cancelText="No"
        >
          <CloseOutlined
            onClick={() => showDeactivePopup(true)}
          />
        </Popconfirm>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  integratedSlackMember: state.opening.integratedSlackMember,
});

const mapDispatchToProps = (dispatch: any) => ({
  disconnectSlackAccountOfMember: (data: string) =>
    dispatch(disconnectSlackAccountOfMember(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Disconnect);
