import { Divider, Typography } from 'antd'
import React from 'react'
import ZoomMeet from './Integrations/ZoomMeet';
const {Text} = Typography;

const CalendarSettings = () => {
  return (
    <><div className="p-4 ">
       <Text strong>Calendar settings</Text></div>
      <Divider type="horizontal" className="w-full m-0 " />
    <div className='w-full overflow-y-scroll'style={{ height: "calc(-253px + 100vh)" }}>
      <div className="p-4 flex flex-col">
        <Text strong>My integrations</Text>
        <Text >
        Integrate your favorite tools with Tacitbase for seamless experience.
        </Text>
      </div>
      <div className='p-4'>
       <ZoomMeet/>
      </div>
    </div>
    </>
  )
}

export default CalendarSettings
