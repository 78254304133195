import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import Read from "../../Read";
import { servicesMembership } from "../../redux/reducers/initialState";
import { Candidate, loginUser, PermissionAccess, Scope } from "../../type/type.d"
import { Overlay } from "../hook/Overlay";
import QuickAddCandidatePopup from "./QuickAddCandidatePopup"
import { Divider, Popover, Typography } from "antd"
import { CloseOutlined} from "@ant-design/icons"
import { LabeledIconButton } from "../../ant/Button"
import { ADD_CANDIDATE, CANDIDATE_ADDED_SUCCESS, CANDIDATES, PRIVATE_MODE } from "../../constant"
import { createQuickCandidate, uploadAttachmentOfCandidate } from "../../lib/api"
import { throwSuccess } from "../../service/throwError"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { addCandidateInDB } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import { OrgInfo } from "../../lib/apiRes";
const { Text } = Typography

type QuickAddCandidateType = {
  servicesMembership: servicesMembership;
  loginUser: loginUser;
  addCandidateInDB: (candidate: Candidate) => void;
  org:OrgInfo;

};
const QuickAddCandidate = (props: QuickAddCandidateType) => {
  let accessForCDB: PermissionAccess = {
    admin: false,
    comment_only: false,
    is_secrete_visible: false,
  };
  props.servicesMembership.CDBMember.length > 0
    ? props.servicesMembership.CDBMember.map((member) =>
        member.id == props.loginUser.user.id
          ? (accessForCDB = member.access)
          : {}
      )
    : null;
  const [open, setOpen] = useState(false)
  let refer = useRef(null)
  const [isLoading, setLoading] = useState(false)

  const onCreateCandidate = (req: any, file: any) => {
    setLoading(true)
    createQuickCandidate(PRIVATE_MODE,req).then((res: Candidate) => {
      props.addCandidateInDB(res)
      setOpen(!open)
      if (file && file.getAll("file").length > 0) {
        uploadAttachmentOfCandidate(file, CANDIDATES, res.id,props.org.id,props.loginUser.user.id).then((res) => {
          if (res) {
            setLoading(false)

            throwSuccess(CANDIDATE_ADDED_SUCCESS)
          }
        }).catch(() => {
          setLoading(false)

        })
      } else {
        throwSuccess(CANDIDATE_ADDED_SUCCESS)
        setLoading(false)
      }

    }).catch(() => {
      setLoading(false)

    })
  }
  return (
    <Read access={accessForCDB}>
      <>
        <Popover destroyTooltipOnHide placement="bottomLeft" title={<div className="flex w-full bg-white items-center " >
          <Text className="w-full flex justify-center">
            <Text>{capitalizeFirstLetter(ADD_CANDIDATE)}</Text>
          </Text>
          <LabeledIconButton
            type="text"
            label=""
            onClick={() => setOpen(false)}
            icon={<CloseOutlined />}
          />
        </div>} open={open} onOpenChange={(open) => setOpen(open)} trigger={["click"]}
          content={<Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>
            <div className="w-143">
              <Divider className="my-2" />


              <QuickAddCandidatePopup loading={isLoading} click={() => setOpen(!open)} refer={refer} onCreateCandidate={onCreateCandidate} />
            </div>
          </Overlay >}>
          <LabeledIconButton label="Create"  type="default" className="h-7 " ></LabeledIconButton>
        </Popover >

      </>

    </Read>
  );
};
const mapStateToProps = (state: any) => ({
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
  org:state.opening.currentOrg,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addCandidateInDB: (candidate: Candidate) =>
    dispatch(addCandidateInDB(candidate)),
})
export default connect(mapStateToProps, mapPropsToState)(QuickAddCandidate);



