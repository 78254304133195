import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const CommunicationIntegrationCodeHandler = () => {
    const history: any = useHistory();
    let obj: any = {};
  
    useEffect(() => {
      let params = window.location.search;
      params.split("&").map((i: any, index: number) => {
        let a = i.split("=");
        obj[index] = a[1];
      });
    }, []);

    useEffect(() => {
      sessionStorage.setItem("slackCode", obj[0]);
      history.push({
        pathname: `/o/${obj[1]}/settings/organization`,
        method: "post",
      });
    }, [obj]);
  
    return <></>;
};

export default CommunicationIntegrationCodeHandler;