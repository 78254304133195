import React from "react";
import ActivityIcon from "../../../assets/Activities";
type CardActivityProps = {
  isShowDetailsTrue: (value: boolean) => void;
  showDetails: boolean;
};
const CardActivity = (props: CardActivityProps) => {
  return (
    <React.Fragment>
      <div className=" mb-5 ml-12 relative ">
        <span className=" absolute  mt-1 -ml-8" aria-hidden="true">
          <ActivityIcon />
        </span>
        <div className="flex justify-between w-full mb-2">
          <div className="text-base tracking-wide font-semibold px-1">
            Activity
          </div>
          <button
            data-testid="showDetailsBtn"
            className="bg-gray-200 hover:bg-gray-200 bg-opacity-60 py-1.5 px-3  text-xs font-medium rounded align-middle  cursor-pointer"
            onClick={
              props.showDetails
                ? () => props.isShowDetailsTrue(false)
                : () => props.isShowDetailsTrue(true)
            }
          >
            <div>{props.showDetails ? "Hide Details" : "Show Details"}</div>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CardActivity;
