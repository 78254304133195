import React, { useEffect, useRef, useState } from "react";
import { getTable } from "../../../lib/api";
import { TableColumn } from "../../../type/type";
import Button1 from "../../common/button/Button1";
import CdbColumnDropdown from "./CdbColumnDropdown";

// Define the props expected by the FieldMapping component
interface TableProps {
  fields: string[]; // List of CSV file columns
  handleNextStep: (value: boolean) => void; // Callback for handling next step
  selectedObjects: { [key: string]: TableColumn | null }; // Mapping of CSV file columns to candidate database fields
  mode: string; // Current mode (not used in this component)
  setSelectedObjects: (value: any) => void; // Function to set selected objects
}

// FieldMapping component definition
const FieldMapping = (props: TableProps) => {
  // State variables
  const tableRef = useRef<HTMLTableElement>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [validationError, setValidationError] = useState<string | null>(null);

  // Effect to update fileHeaders when fields prop changes
  useEffect(() => {
    setFileHeaders(props.fields);
  }, [props.fields]);

  // State variable to hold custom columns data
  const [customColumn, setCustomColumn] = useState<TableColumn[]>([]);

  // Effect to fetch custom columns data from the API
  useEffect(() => {
    getTable().then((res) => {
      setCustomColumn(res.columns);
    });
  }, []);

  // Function to check if all required fields are mapped
  const areRequiredFieldsMapped = (selectedObjectsMap: {
    [key: string]: TableColumn | null;
  }) => {
    const requiredFields = ["first_name", "last_name", "email"];
    const mappedFields = Object.values(selectedObjectsMap)
      .map((obj) => obj?.name)
      .filter((name) => typeof name === "string");

    return requiredFields.every((requiredField) =>
      mappedFields.includes(requiredField)
    );
  };

  // Effect to check for required field mapping and update validation error
  useEffect(() => {
    setValidationError(null);
    if (!areRequiredFieldsMapped(props.selectedObjects)) {
      setValidationError("Please map all required fields.");
    }
  }, [props.selectedObjects]);

  // Function to handle field selection and toggle mapping
  const handleFieldSelect = (
    header: string,
    selectedColumn: string,
    rowIndex: number
  ) => {
    // Get the currently mapped column for the current row
    const currentRowMappedColumn = props.selectedObjects[header]?.view;

    // Check if the clicked column is mapped to another row
    const mappedHeader = Object.keys(props.selectedObjects).find(
      (key) => props.selectedObjects[key]?.view === selectedColumn
    );
    // Check if the clicked column is already mapped to the current row
    if (currentRowMappedColumn === selectedColumn) {
      // If yes, remove the mapping
      const updatedSelectedObjects = { ...props.selectedObjects };
      delete updatedSelectedObjects[header];
      props.setSelectedObjects(updatedSelectedObjects);
    } else {
      // If no, find the corresponding custom column and map it to the current row
      const selectedObj =
        customColumn.find((column) => column.view === selectedColumn) || null;

      // If the selected column is already mapped to another row, remove it from that row
      if (mappedHeader) {
        const updatedSelectedObjects = { ...props.selectedObjects };
        delete updatedSelectedObjects[mappedHeader];
        props.setSelectedObjects(updatedSelectedObjects);
      }

      props.setSelectedObjects((prevSelectedObjects: any) => ({
        ...prevSelectedObjects,
        [header]: selectedObj,
      }));
    }
    setSelectedRow(rowIndex); // Update the selected row
  };

  // Effect to scroll to the selected row in the table
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement && selectedRow !== null) {
      const nextRow = tableElement.querySelector(
        `tbody tr:nth-child(${selectedRow + 1})`
      );
      if (nextRow) {
        nextRow.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [selectedRow]);

  // Effect to log the selectedObjects whenever it changes

  return (
    <>
      <div
        className="flex flex-col w-full h-full space-y-2 pl-2 "
        style={{ height: "60%", width: "100%" }}
      >
        <div className="pr-1" style={{ height: "20%" }}>
          <div className="text-sm flex flex-col bg-white border border-tacitbase-tertiary1 border-opacity-25 p-2 rounded space-y-2 ">
            <ul className=" list-none">
              <li className="flex items-center justify-start space-x-2">
                <span className="inline-block w-2 h-2 rounded-full bg-black "></span>
                <span className="text-sm">
                  Map the CSV file columns with your available candidate
                  database fields. Map {""}
                  <span className="font-semibold">
                    required fields, First Name, Last Name, Email ID&nbsp;
                  </span>
                  first.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="overflow-y-scroll h-full ">
          <table className="bg-none " ref={tableRef}>
            <thead className="sticky top-0 bg-gray-100 z-40 ">
              <tr className="w-full ">
                <th
                  className=" w-1/4 bg-gray-100 p-1 "
                  style={{ backgroundColor: "rgba(241, 241, 246)" }}
                >
                  <div className="input-box bg-gray-200 flex items-center justify-start">
                    CSV file Column
                  </div>
                </th>
                <th
                  className=" w-1/4 bg-gray-100  "
                  style={{ backgroundColor: "rgba(241, 241, 246)" }}
                >
                  <div className="input-box flex items-center justify-start">
                    Candidate Database Fields
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="border-collapse">
              {/* Mapping rows for each file header */}
              {fileHeaders?.map((item: string, index: number) => {
                const selectedValue = props.selectedObjects[item]?.view || "";
                return (
                  <tr className="w-full " key={index}>
                    <td className="w-1/4   ">
                      <div className="input-box border-0 bg-gray-200 flex items-center justify-start">
                        {item}
                      </div>
                    </td>
                    <td className="w-1/4 ">
                      <div>
                        {/* Dropdown to select candidate database field */}
                        {Object.values(customColumn)?.length > 0 ? (
                          <CdbColumnDropdown
                            option={Object.values(customColumn).map(
                              (item) => item.view
                            )}
                            name={selectedValue}
                            selectedValue={selectedValue}
                            setValue={(col) =>
                              handleFieldSelect(item, col, index)
                            }
                            selectedObjects={props.selectedObjects}
                          />
                        ) : (
                          // Show "Please add custom column" message if all custom columns are selected
                          <div className="z-30">
                            <CdbColumnDropdown
                              option={["Please add custom column"]}
                              name=""
                              selectedValue={selectedValue}
                              setValue={() => {}}
                              selectedObjects={{}}
                            />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <hr className="border-px border-tacitbase-tertiary1 border-opacity-25 " />
      </div>
      <div
        className="w-full flex justify-between items-center h-full pl-2 pr-1 pb-3"
        style={{ height: "20%" }}
      >
        <div className={`${validationError ? "flex pb-10" : "invisible"}   `}>
          <div className=" bg-red-100  text-sm    p-2 rounded ">
            {validationError
              ? "* Please map all required fields."
              : "Required fields mapped."}
          </div>
        </div>
        <div className="flex ">
          {/* Next button to proceed to the next step */}
          <Button1
            buttonName="Next"
            click={props.handleNextStep}
            isDisabled={!areRequiredFieldsMapped(props.selectedObjects)}
          />
        </div>
      </div>
    </>
  );
};

export default FieldMapping;
