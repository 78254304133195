import React, { useEffect, useState } from "react"
import TableAnt from "../../ant/TableAnt"
import { Avatar, Badge, Divider, Empty, Popconfirm, Space, Tabs, Typography } from "antd"
import TacitbaseAvtar from "../../ant/Avtar"
import {
    CheckOutlined,
    CheckSquareTwoTone,
    ClockCircleOutlined,
    CommentOutlined,
    FileDoneOutlined,
    PaperClipOutlined,
    ReloadOutlined,
    TagOutlined,
} from "@ant-design/icons"
import { IconButton} from "../../ant/Button"
import { CloseReview, getReviewCandidate } from "../../lib/api"
import { Dispatch } from "redux"
import { CandidateDetailReview, CandidateReview, CandidatesInReview, loginUser, orgMember } from "../../type/type.d"
import { initCandidateInReview, initCurrentCandidateInReview } from "../../redux/actions/opening/action"
import { connect } from "react-redux"
import { SELECTION_NONE } from "antd/es/table/hooks/useSelection"
import DetailedReview from "./DetailReview"
import { useHistory } from "react-router-dom"
import AddLabelToBulkReviews from "./AddLabelToBulkReviews"
import { renderCreatedAt } from "../../utils/renderCreatedAt"
import AddMemberToBulkReviews from "./AddMemberToBulkReviews"
import { generateTooltipForMember } from "../../utils/capitalizeFirstLetter"
import SendReviewsToBulkTracker from "./SendReviewsToBulkTracker"
const { Text, Paragraph } = Typography

const generateData = (result: any) => {
    const data: any = []
    result.map((review: CandidateReview) => {
        data.push({
            key: review.id,
            id: review.candidates.id,
            members: [...review.members],
            source: review.candidates.source.from,
            candidates: {
                first_name: review.candidates.first_name,
                last_name: review.candidates.last_name,
            },
            created_by: review.created_by,
            created_at: review.created_at,
            comment_count: review.comment_count,
            attachment_count: review.attachment_count,
            labels: review.labels.length
        })
    })
    return data
}

// Define your tab data here
const tabData: {
    key: string,
    label: string,
    icon: JSX.Element
}[] = [
        { key: "1", label: "Pending", icon: <ClockCircleOutlined /> },
        { key: "2", label: "Closed", icon: <FileDoneOutlined /> },
    ]


type AntTableProps = {
    candidatesInReview: CandidatesInReview,
    initCurrentCandidateInReview: (candidate: CandidateDetailReview) => void,
    initCandidateInReview: (candidates: any, reviewTag: boolean) => void,
    members: orgMember,
    loginUser: loginUser,
    applicationId: string,
    notificationCenter: any
};

const defaultPaginationConfig = {
    pagination: {
        current: 1,
        pageSize: 25,
        position: ["bottomLeft"],
        size: "default",
    },
}
const AntTable = (props: AntTableProps) => {
    const [activeTab, setActiveTab] = useState<string>("1")
    const [hoveredRow, setHoveredRow] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[] | React.Key[]>([])
    const [selectedRowKey, setSelectedRowKey] = useState<string | React.Key>("")
    const [data, sourceData] = useState<any>([])
    const [cardDots, setCardDots] = useState<any>()
    useEffect(() => {
        setCardDots(props.notificationCenter.unreadDotsofServices?.review?.reviews)
    }, [props.notificationCenter]);

    const [tableParams, setTableParams] = useState<any>({
        pagination: {
            current: 1,
            pageSize: 25,
            position: ["bottomLeft"],
            size: "default",
            showSizeChanger: false,
            style: { marginTop: "0px", borderTop: "1px solid rgba(5, 5, 5, 0.06)", paddingTop: "8px" }
        },
    })
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const history = useHistory()

    const handleTabChange = (key: string) => {
      setActiveTab(key);
      sourceData([]);
      fetchData({
        type: key == "1" ? false : true,
        currentPage: defaultPaginationConfig.pagination.current,
        pageSize: defaultPaginationConfig.pagination.pageSize,
      });
    };
    const handleRefreshTab = () => {
      fetchData({
        type: `${activeTab}` == "1" ? false : true,
        currentPage: defaultPaginationConfig.pagination.current,
        pageSize: defaultPaginationConfig.pagination.pageSize,
      });
    };
    useEffect(() => {
        // Dynamically set the height of the ant-table-body
        const tableBody: any = document.querySelector(".ant-table-body")
        if (tableBody) {
            tableBody.style.height = "calc(100vh - 100px)"
        }
    }, [data]);

    const deleteResource = (recordId: string, event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setLoading(true);

      CloseReview({ reviews: [recordId] })
        .then((res: any) => {
          if (res) {
            const updatedReviews = Object.values(
              props.candidatesInReview.reviews
            ).filter((item: any) => {
              return !res.some(
                (result: { id: string; name: string }) => result.id === item.id
              );
            });
            setTimeout(() => {
              setLoading(false);
            }, 500);

            props.initCandidateInReview(
              updatedReviews,
              activeTab == "1" ? false : true
            );
            sourceData(generateData(updatedReviews));
          }
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    };
    const submitBulkReview = () => {
      let req = { reviews: selectedRowKeys };
      setLoading(true);
      CloseReview(req)
        .then((res: any) => {
          setSelectedRowKeys([]);
          const updatedReviews = Object.values(
            props.candidatesInReview.reviews
          ).filter((item: any) => {
            return !res.some(
              (result: { id: string; name: string }) => result.id === item.id
            );
          });
          setTimeout(() => {
            setLoading(false);
          }, 500);

          props.initCandidateInReview(
            updatedReviews,
            activeTab === "1" ? false : true
          );
          sourceData(generateData(updatedReviews));
        })
        .catch(() => {
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
    };

    const submitReviewPopupContent = (record: any) => (
      <>
        <Paragraph>
          Are you sure you want to close the review{" "}
          <div>
            <Text strong>{record.candidates.first_name}</Text>{" "}
            <Text strong>{record.candidates.last_name}</Text>?
          </div>
        </Paragraph>
        <div>{`This action is irreversible.`}</div>
      </>
    );
    const submitBulkReviewPopupContent = () => (
      <>
        <Paragraph>Are you sure you want to close reviews? </Paragraph>
        <div>{`This action is irreversible.`}</div>
      </>
    );

    const renderOnHover = (record: any) => {
      return (
        <>
          <div style={{ position: "relative", zIndex: 1 }}>
            <Space
              className="float-right flex overflow-scroll items-baseline z-10"
              key={record.key}
            >
              {/* <Popconfirm
                        title="Are you sure you want to delete this item?"
                        // onConfirm={() => handleDelete(record.key)}
                        okText="Yes"
                            cancelText="No"
                            disabled={true}
                            // open={popoverVisible}

                        placement="bottom"
                            key={1}
                            trigger={'click'}
                            onPopupClick={(event) => { event.stopPropagation(), event?.preventDefault() }}                    >
                            <ProjectTwoTone onClick={(event) => { event.stopPropagation(), event?.preventDefault() }} style={{ fontSize: '18px' }} />
                    </Popconfirm> */}

              <Popconfirm
                title={"Close review"}
                description={submitReviewPopupContent(record)}
                onConfirm={(event) => deleteResource(record.key, event)}
                okText="Yes"
                cancelText="No"
                placement="bottom"
                key={2}
                trigger={"click"}
              >
                <CheckSquareTwoTone
                  onClick={(event) => {
                    event.stopPropagation(), event?.preventDefault();
                  }}
                  style={{ fontSize: "20px" }}
                />
              </Popconfirm>
            </Space>
          </div>
        </>
      );
    };

    const tableBulkAction = () => {
      return (
        <>
          <Space className="flex h-12 items-center">
            <strong>{selectedRowKeys.length}</strong> Selected of
            {tabData.find((tab) => tab.key === activeTab)?.label}
            {/* <AddLabelToReviews selectedRowKeys={selectedRowKeys} /> */}
            <AddLabelToBulkReviews selectedRowKeys={selectedRowKeys} />
            <AddMemberToBulkReviews selectedRowKeys={selectedRowKeys} />
            <SendReviewsToBulkTracker selectedKeys={selectedRowKeys} />
            <Popconfirm
              title={"Close review"}
              description={submitBulkReviewPopupContent}
              onConfirm={() => submitBulkReview()}
              okText="Yes"
              cancelText="No"
              placement="bottom"
              key={2}
            >
              <IconButton icon={<CheckOutlined />} tooltipTitle="Close review"/>
            </Popconfirm>
          </Space>
        </>
      );
    };

    const fetchData = (reqParams: {
      type: boolean;
      currentPage: number;
      pageSize: number;
    }) => {
        setLoading(true)
        getReviewCandidate(
            reqParams.type,
            reqParams.currentPage,
            reqParams.pageSize
        ).then((res: any) => {
            setLoading(false)
            props.initCandidateInReview(res.items, reqParams.type)
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current:
                        reqParams.currentPage == 1 ? 1 : tableParams.pagination.current,
                    total: res.total_count,
                },
            })
        })
    }

    useEffect(() => {

        if (props.candidatesInReview && Object.keys(props.candidatesInReview.reviews))
            sourceData(generateData(Object.values(props.candidatesInReview.reviews)))
        else sourceData([])
    }, [props.candidatesInReview])

    useEffect(() => {
        fetchData({
            type: activeTab == "1" ? false : true,
            currentPage: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize
        })
    }, [tableParams.pagination.current])

    const handleRowHover = (record: any) => {
        if (record) {
            setHoveredRow(record.key)
            // setPopoverVisible(true)
        }
        else
            setHoveredRow(record)

    }

    const columns = [
        {
            title: <><span className="pl-6">{"Candidate"}</span></>,
            dataIndex: "name",
            showSorterTooltip: false,
            sorter: (a: any, b: any) =>
                a.candidates.first_name.localeCompare(b.candidates.first_name),
            render: (_: any, record: any) => (
                <>
                    <Space className="flex justify-start  items-center">
                        <div className="justify-center items-center  flex w-4">
                            {cardDots ? (
                                cardDots[record.key] ? (
                                    <Badge
                                        status="processing"
                                        size={"default"}
                                    />
                                ) : // </div>
                                    null
                            ) : null}
                        </div>
                        <div className="">
                            <TacitbaseAvtar
                                content={record.candidates.first_name[0]}
                            ></TacitbaseAvtar>
                        </div>
                        <div style={{ maxWidth: "180px",  whiteSpace: "nowrap",  overflow: "hidden",textOverflow: "ellipsis"}}>
                        <Text style={cardDots && cardDots[record.key] ? { fontWeight: "600" } : {}} >
                            {`${record.candidates.first_name} ${record.candidates.last_name}`}
                        </Text>{" "}
                        </div>
                    </Space>
                </>
            ),

            width: "20%",
        },
        {
            title: "Assignees",
            dataIndex: "members",
            render: (_: any, record: any) => {
                const createdByMember = record.created_by
                return (
                    <>
                        <div className="flex items-center">
                            <Avatar.Group size={"small"}>
                                {props.members[createdByMember].image_url ? (
                                    <>
                                        <TacitbaseAvtar
                                            toolTip={generateTooltipForMember(props.members[createdByMember])
                                            }
                                            key={createdByMember}
                                            src={props.members[createdByMember].image_url}
                                        ></TacitbaseAvtar>
                                    </>
                                ) :
                                    <TacitbaseAvtar
                                        content={
                                            props.members[createdByMember].first_name[0] +
                                            props.members[createdByMember].last_name[0]
                                        }
                                        toolTip={
                                            generateTooltipForMember(props.members[createdByMember])
                                        }
                                    ></TacitbaseAvtar>}
                            </Avatar.Group>
                            <Divider type="vertical" style={{ borderColor: "gray" }} />
                            <Avatar.Group size={"small"} maxCount={4}>
                                {record.members.map(
                                    (member: string) =>
                                        member !== record.created_by && props.members[member] && 
                                        props.members[member].registrations[0].data.activated &&
                                        (props.members[member].image_url ? (
                                            <TacitbaseAvtar
                                                key={member}
                                                src={props.members[member].image_url}
                                                toolTip={
                                                    generateTooltipForMember(props.members[member])
                                                }
                                            ></TacitbaseAvtar>
                                        ) : (
                                            <TacitbaseAvtar
                                                    key={member}
                                                content={
                                                    props.members[member].first_name[0] +
                                                    props.members[member].last_name[0]
                                                }
                                                toolTip={
                                                    generateTooltipForMember(props.members[member])
                                                }
                                            ></TacitbaseAvtar>
                                        ))
                                )}
                            </Avatar.Group>
                        </div>
                    </>
                )
            },
        },
        {
            title: <><span className=" flex w-full justify-end items-end px-4 ">{"Created at"}</span></>,

            // align: "right",
            width: "23%",
            ellipsis: true,
            render: (_: any, record: any) => {
                return (
                    activeTab == "1" && record.key === hoveredRow ? (
                        renderOnHover(record)
                    ) :

                        <>
                            <div className="flex justify-between ">
                                <Space>
                                    <span className="space-x-1">
                                        <CommentOutlined />
                                        <span>{record.comment_count}</span>
                                    </span>
                                    <span className="space-x-1">
                                        <PaperClipOutlined />
                                        <span>{record.attachment_count}</span>
                                    </span>
                                    <span className="space-x-1">
                                        <TagOutlined />
                                        <span>{record.labels}</span>
                                    </span>
                                </Space>

                                {renderCreatedAt(record.created_at)}
                            </div>
                        </>
                )
            }
        },
    ]
    const handleTableChange: any = (pagination: any, filters: any, sorter: any) => {

        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                ...pagination
            },
            filters,
            ...sorter,
        })


    }
    const selectionsList = [
        SELECTION_NONE,
        {
            key: 'Me',
            text: 'Select reviews created by me',
            onSelect: (changeableRowKeys: any) => {
                let newSelectedRowKeys = []
                // Assuming creator's login user ID is stored in 'creatorUserId'
                const creatorUserId = props.loginUser.user.id // Replace with actual property storing the user ID

                newSelectedRowKeys = changeableRowKeys.filter((key: any) => {
                    // Retrieve the creator's login user ID from row data
                    const rowData = data.find((row: any) => row.key === key)
                    if (rowData && rowData.created_by === creatorUserId) {
                        return true
                    }
                    return false
                })
                setSelectedRowKeys(newSelectedRowKeys)
            },
        },

    ]




    const onRowClick = (record: any) => {
        setSelectedRowKey(record.key)
        history.push(`/o/${props.applicationId}/reviews/${record.key}`)

    }

    return (
        <>
         <div className="h-12 px-4 border-b items-center justify-between">
                        {selectedRowKeys.length > 0 ?
                            tableBulkAction()
                            : (
                                <>
                                <div className="flex flex-row justify-between">
                                    <Space className="flex flex-row items-baseline justify-between ">
                                        <div className="flex flex-row">
                                        {selectedRowKeys.length > 0 ?
                                            tableBulkAction()
                                            : (
                                                <>
                                                    <div className="">
                                                        <Tabs defaultActiveKey={activeTab} size="small" onChange={handleTabChange}>
                                                            {tabData.map((tab) => (
                                                                <Tabs.TabPane key={tab.key} tab={tab.label} icon={tab.icon} />
                                                            ))}
                                                        </Tabs>
                                                    </div>
                                                </>
                                            )}
                                            </div>
                                             
                                    </Space>
                                    <div className="flex h-12 items-center">


                                             <IconButton
                          tooltipTitle="Refresh tab"
                            icon={
                              <ReloadOutlined  />
                            }
                            onClick={handleRefreshTab}
                          />
                                            </div>
                                            </div>
                                  
                                </>
                            )
                        }
                    </div>
            <TableAnt
                columns={columns}
                data={data}
                bordered={false}
                loading={loading}
                size="small"
                showHeader={true}
                rowSelection={
                    activeTab == "1" ? // Check if the tab is active
                        {
                            selectedRowKeys,
                            preserveSelectedRowKeys: true,
                            onChange: onSelectChange,
                            selections: selectionsList,
                            columnWidth: 45,
                        } : undefined // If tab is not active, don't show row selection
                }
                onRow={(record) => ({
                    onMouseEnter: () => handleRowHover(record),
                    onMouseLeave: () => handleRowHover(null),
                    onClick: () => onRowClick && onRowClick(record)

                })}
                scroll={{ y: "calc(100vh - 181px)" }}

                pagination={tableParams.pagination}
                locale={{
                    emptyText: (
                        <div className="flex h-full items-center justify-center">
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<span>No data available</span>}
                            />
                        </div>
                    ),
                }}
                onChange={handleTableChange}
            />
            <DetailedReview selectedRow={selectedRowKey} />



        </>
    )
}


const mapStateToProps = (state: any) => ({
    candidatesInReview: state.opening.candidatesInReview,
    members: state.opening.members,
    loginUser: state.opening.loginUser,
    applicationId: state.opening.applicationId,
    notificationCenter: state.opening.notificationCenter,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
    // initReviewNotificationDot: (payload: notificationServiceEntityDotChangePayloadType) =>
    //     dispatch(initServiceEntityNotificationsDot(payload)),
    initCurrentCandidateInReview: (candidate: CandidateDetailReview) =>
        dispatch(initCurrentCandidateInReview(candidate)),
    initCandidateInReview: (candidates: any, reviewTag: boolean) =>
        dispatch(initCandidateInReview(candidates, reviewTag)),
})
export default connect(mapStateToProps, mapPropsTostate)(AntTable)