import React, { useEffect, useRef, useState } from "react";
import { IconButton,  SecondaryButton } from "../../../../../ant/Button";
import { Candidate} from "../../../../../type/type.d";
import { connect } from "react-redux";
import { MailOutlined } from "@ant-design/icons";
import { CurrentCandidateInPool, OrgDomain } from "../../../../../redux/reducers/initialState";
import ComposeMail from "../../../../common/composeMail/NewComposeMail";
import { Modal } from "antd";
import { newOrganisation } from "../../../../../lib/apiRes";
import UpgradePlanModal from "../../../../../pages/setting/billingSetting/UpgradePlanModal";
import { useOrgMetadata } from "../../../../../pages/setting/billingSetting/OrgMetadata";
import DomainModel from "../../../../../pages/setting/billingSetting/DomainModel";

type SendMailToPoolCandidateProps = {
  currentCandidateInPool: CurrentCandidateInPool;
  candidatePool: any;
  isBulk: boolean;
  candidateIds: string[];
  currentOrg:newOrganisation;
  defaultDomain:OrgDomain;
};

const SendMailToPoolCandidate: React.FC<SendMailToPoolCandidateProps> = (props) => {
  const [composeMail, setComposeMail] = useState(false);
  const [candidateRecords, setCandidateRecords] = useState<Candidate[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openDomainPopup, setOpenDomainPopup] = useState(false);
  const refer = useRef(null);
  const orgMetadata = useOrgMetadata(props.currentOrg);

  // Update candidate records whenever candidateIds change
  useEffect(() => {
    setCandidateRecords(props.candidateIds.map((id) => props.candidatePool[id]));
  }, [props.candidateIds, props.candidatePool]);

  const handleBillingModalCancel = () => {
    setIsModalVisible(false);
};
  // Function to handle the button click and open the compose mail overlay
  const handleComposeMail = () => {
    if(orgMetadata.bulk_email === "true"){
      const domainID = Object.keys(props.defaultDomain)[0];
      if(domainID && !props.defaultDomain[domainID].custom){
        setOpenDomainPopup(true);
        return;
      }
      setComposeMail(true);
      return;
    }
      setIsModalVisible(true);
  };

  const handleSingleMail = () => {
    const domainID = Object.keys(props.defaultDomain)[0];
    if(domainID && !props.defaultDomain[domainID].custom){
      setOpenDomainPopup(true);
      return;
    }
      setComposeMail(true)
  }

  return (
    <div>
      {/* Render button based on isBulk prop */}
      {props.isBulk ? (
        <IconButton
          onClick={handleComposeMail}
          className="w-full"
          icon={<MailOutlined />}
          tooltipTitle="Compose mail"
        />
      ) : (
        <SecondaryButton
          className="w-full truncate left-align-button"
          icon={<MailOutlined />}
          onClick={handleSingleMail}
        >Compose mail</SecondaryButton>
      )}

      {/* Render the compose mail overlay if composeMail is true */}
      {composeMail && (
       <Modal
       visible={composeMail}
       onCancel={()=>{setComposeMail(false)}}
       footer={null}
       closable={false}
       bodyStyle={{ padding: 0, margin: 0 }}
       width={580} // Adjust the width as per your requirement
       getContainer={false}
       className="mailBox"
     >
            <ComposeMail
              refer={refer}
              entity="candidates"
              toAddress={
                props.isBulk
                  ? candidateRecords.map((item) => item.email)
                  : [props.currentCandidateInPool.candidate.email]
              }
              service="candidates"
              close={() => setComposeMail(false)}
              record_id={
                props.isBulk
                  ? candidateRecords
                  : [props.currentCandidateInPool.candidate]
              }
            />
        </Modal>
      )}
      <UpgradePlanModal isModalVisible = {isModalVisible} handleCancel={handleBillingModalCancel}/>
      <DomainModel isModalVisible = {openDomainPopup} handleCancel = {()=>setOpenDomainPopup(false)}/>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  candidatePool: state.opening.candidatePool,
  currentCandidateInPool: state.opening.currentCandidateInPool,
  currentOrg:state.opening.currentOrg,
  defaultDomain: state.opening.defaultDomain,
});

export default connect(mapStateToProps)(SendMailToPoolCandidate);
