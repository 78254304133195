import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { initMembership } from "../../redux/actions/opening/action";
import {
  Integration,
  loginUser,
  Member,
  orgMember,
  Registration,
} from "../../type/type";
import {  memberTabsInSetting, OWNER, SLACK } from "../../constant";
import slack from "../../images/slack.png";
import { Table, Tabs, Tag, Tooltip, Typography } from "antd";
import { Space } from "antd/lib";
import MemberActionButtons from "./MemberActionButtons";
import ConnectMemberToSlack from "./ConnectMemberToSlack";
import TacitbaseAvtar from "../../ant/Avtar";
import InvitedMembers from "./InvitedMembers";
import Disconnect from "./DisconnectSlack";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import UserProfile from "../../setting_v1/UserProfile";
import { useHistory } from "react-router-dom";
import GuestMembers from "./GuestMembers";
import { InfoCircleOutlined } from "@ant-design/icons";
const { Text } = Typography;
type OrgMemberProps = {
  searchKeyword?: any;
  loginUser: loginUser;
  opening: any;
  members: orgMember;
  applicationId: string;
  initMembers(members: any): void;
  integratedSlackMember: { [key: string]: Integration[] };
  outgoingInvitations: any[];
};

const OrgMember = (props: OrgMemberProps) => {
    // checking isOwner based on that showing action button
  const isOwner =
    props.members[props.loginUser.user.id]?.registrations[0].roles[0] === OWNER;
  const history = useHistory();
  const [memberData, setMemberData] = useState<Member[]>([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(memberTabsInSetting.MEMBERS.key);
  const [invitations, setInvitations] = useState<number>(0);
  const shouldShowIntegrationColumn = isOwner && activeTab === "members";
  // three tabs inside members table
  const menuItems = [
    {
      key: memberTabsInSetting.MEMBERS.key,
      label:(<>
      {memberTabsInSetting.MEMBERS.label}
      <span className="ml-1">
          <Tooltip title="Members have comprehensive access to features and functionalities necessary for your recruiting processes. Like, access and manage all boards and job posts.">
            <InfoCircleOutlined />
          </Tooltip>
        </span>

      </>
        ),
    },
    {
      key:memberTabsInSetting.GUEST.key,
      label:
      (<>
        { memberTabsInSetting.GUEST.label}
        <span className="ml-1">
          <Tooltip title = "Guest can view and interact with only the boards that have been explicitly shared with them via Secure-Share.">
          <InfoCircleOutlined />
          </Tooltip>
          </span>
        </>
        ),
    },
    {
      key: memberTabsInSetting.AGENCIES.key,
      label:
      (<>
        { memberTabsInSetting.AGENCIES.label}
        <span className="ml-1">
          <Tooltip title = " Agency users are external collaborators who have been granted access to specific job posts by your organization. They can source candidates for accessible job posts.">
          <InfoCircleOutlined />
          </Tooltip>
          </span>
        </>
        ),
    },
    {
      key:memberTabsInSetting.DEACTIVE.key,
      label: memberTabsInSetting.DEACTIVE.label,
    },
    {
      key: memberTabsInSetting.INVITED.key,
      label:
        invitations > 0
          ? `${memberTabsInSetting.INVITED.label}(${invitations})`
          : memberTabsInSetting.INVITED.label,
    },
  ];

  function getLoading(value: boolean) {
    setLoading(value);
  }
  // getting invited members
  useEffect(() => {
    setInvitations(props.outgoingInvitations.length);
  }, [props.outgoingInvitations]);

  // showing activate and deactivate member based on selected tab
  useEffect(() => {
    if ( activeTab === "deactivate" || activeTab === "members"){
      let orgMembers: Member[] = [];
      orgMembers = Object.values(props.members);
      const showActiveMember = activeTab !== "deactivate";
      if (activeTab === "members") {
        orgMembers = orgMembers.filter((member) => {
          const roles = member.registrations[0]?.roles || [];
          const isNotAgencyOrGuest = roles[0] !== "Agency" && roles[0] !== "Guest";
          const isActivated = member.registrations[0]?.data?.activated === showActiveMember;
          
          return isNotAgencyOrGuest && isActivated;
        });
      }else{
        orgMembers = orgMembers.filter(
          (member) =>
            member.registrations[0]?.data?.activated === showActiveMember
        );
      }
      setMemberData(orgMembers);

      if (props.searchKeyword) {
        orgMembers = orgMembers.filter((member: Member) => {
          return (
            member.registrations[0].roles.find((r) =>
              r.toUpperCase().includes(props.searchKeyword.toUpperCase())
                ? member
                : null
            ) ||
            member.first_name
              .toUpperCase()
              .includes(props.searchKeyword.toUpperCase()) ||
            member.last_name
              .toUpperCase()
              .includes(props.searchKeyword.toUpperCase()) ||
            member.email
              .toUpperCase()
              .includes(props.searchKeyword.toUpperCase()) ||
            member.username
              .toUpperCase()
              .includes(props.searchKeyword.toUpperCase())
          );
        });
        setMemberData(orgMembers);
      }
    }
  }, [
    activeTab,
    props.searchKeyword,
    props.members,
    props.loginUser.user.id,
    props.applicationId,
  ]);

  // thes are columns of member table
  const columns: any = [
    {
      title: "",
      dataIndex: "image_url",
      width: 50,
      key: "image_url",
      fixed: "left",
      render: (_: any, record: Member) => (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            history.push(
              `/o/${props.applicationId}/settings/members?id=${record.id}`
            );
          }}
        >
          {record.image_url ? (
            <TacitbaseAvtar size="large" src={record.image_url} />
          ) : (
            <TacitbaseAvtar content={record.first_name?record.first_name[0]:record.email[0]} size="large" />
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (_: any, record: Member) => (
        <div
          className="flex flex-col cursor-pointer"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            history.push(
              `/o/${props.applicationId}/settings/members?id=${record.id}`
            );
          }}
        >
          <Text
            ellipsis
            style={{
              margin: 0,
              minWidth: 120,
              maxWidth: 120,
            }}
          >
            {`${record.first_name} ${record.last_name}`}
          </Text>
          <Text
            type="secondary"
            ellipsis
            style={{
              margin: 0,
              minWidth: 130,
              maxWidth: 130,
            }}
          >{`@${record.username}`}</Text>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "registrations",
      key: "registrations",
      render: (registrations: Registration[]) => {
        const role = registrations[0]?.roles[0];
        const isGuest = role === "Guest";
        const tagColor = isGuest ? "#F2FFFC" : "processing";
        const textColor = isGuest ? "#009D79" : "#4B48A5"; 
        return (
          <Tag
            className="w-max h-8 flex justify-center items-center"
            bordered={false}
            color={tagColor}
            style={{ color: textColor }}
          >
            {registrations[0]?.roles[0]}
          </Tag>
        )
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
        <Tooltip title={email}>
          <Text
            type="secondary"
            ellipsis
            style={{
              margin: 0,
              minWidth: 190,
              maxWidth: 190,
            }}
          >
            {email}
          </Text>
        </Tooltip>
      ),
    },

    ...(shouldShowIntegrationColumn
      ? [
          {
            title: "Integration",
            dataIndex: "integration",
            key: "integration",
            render: (_: any, record: Member) => {
              return (
                <>
                  {props.members[props.loginUser.user.id].registrations[0]
                    .roles[0] == OWNER ? (
                    props.integratedSlackMember ? (
                      !props.integratedSlackMember[record.id] ? (
                        <ConnectMemberToSlack member={record} />
                      ) : (
                        <Space className="flex">
                          <div className=" border flex h-8 items-center justify-center rounded px-2 py-1 space-x-2">
                            <div>
                              <img
                                width={14}
                                src={slack}
                                alt="slack user"
                              ></img>
                            </div>
                            <div className="flex justify-center items-center">
                              {props.integratedSlackMember[record.id].map(
                                (integration: Integration) =>
                                  integration.app_name == SLACK ? (
                                    <Text
                                      className="text-xs"
                                      ellipsis
                                      style={{
                                        margin: 0,
                                        minWidth: 100,
                                        maxWidth: 100,
                                      }}
                                    >
                                      {`${integration.first_name} ${integration.last_name}`}
                                    </Text>
                                  ) : (
                                    <></>
                                  )
                              )}
                            </div>
                            <Disconnect selectedMember={record.id} />
                          </div>
                        </Space>
                      )
                    ) : null
                  ) : null}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "Joined on",
      dataIndex: "insert_instant",
      key: "dateAdded",
      render: (date: string) => (
        <>
          <Text type="secondary">{renderCreatedAt(date)}</Text>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "dateAdded",
      render: (_: any, record: Member) => (
        <>
          {props.members[record.id]?.registrations[0].roles[0] !== OWNER && (
            <MemberActionButtons
              selectedMember={record.id}
              getLoading={getLoading}
            />
          )}
        </>
      ),
    },
  ];

  // on changing a tab showing loading of .5 seconds
  function OnTabChange(key: string) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setActiveTab(key);
  }

  return (
    <div className="px-2">
      <div
        className="rounded-sm"
        style={{ maxHeight: "calc(100vh - 339px)" }}
      >
        <Tabs
          className="activation-tab"
          defaultActiveKey="members"
          items={menuItems}
          onChange={OnTabChange}
        />
        {(activeTab === "deactivate" || activeTab === "members") && (
            <Table
            columns={columns}
            dataSource={memberData}
            scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
            loading={loading}
            rowKey="id"
            pagination={false}
            rowClassName="member-table"
            size="small"
          />
           )}

        {(activeTab === "guest" || activeTab === "agency" )&&(
          <GuestMembers 
            loading={loading} 
            activeTab = {activeTab}
            getLoading = {getLoading}/>
          )}
        {activeTab === "invited" &&(
          <InvitedMembers loading={loading} />
        )}
      </div>
      <UserProfile />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  opening: state.opening,
  members: state.opening.members,
  applicationId: state.opening.applicationId,
  integratedSlackMember: state.opening.integratedSlackMember,
  outgoingInvitations: state.opening.outgoingInvitations,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initMembers: (members: any) => dispatch(initMembership(members)),
});
export default connect(mapStateToProps, mapPropsToState)(OrgMember);
