import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Card, Typography } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import { freePlanApi } from "../../lib/api";
import { useHistory } from "react-router-dom";
import { Plan } from "../../type/type";
const { Text } = Typography;
type FreePlanProps = {
  currentOrg: any;
  freePlans:Plan[];
};
const FreePlan = (props: FreePlanProps) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleFreePlan = () => {
    setLoading(true);
    freePlanApi(props.currentOrg.customer.id)
      .then(() => {
        setLoading(false);
        history.push(`/o/start-setup`);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div className="pricing-cards-container flex justify-center">
      {props.freePlans?.map((plan) => (
        <Card
          key={plan.id}
          className="rounded-md"
          bordered={false}
          style={{
            width: 301,
            height: 395,
            marginTop: "84px",
            textAlign: "left",
            boxShadow: "4px 4px 10px 1px #0000001A",
          }}
        >
          <div>
            <Text className="font-medium text-2xl">Free</Text>
          </div>
          <div className="my-4">
            <Text className="text-2xl font-bold">${0}</Text>
          </div>
          <div className="flex items-center justify-center  h-10 mb-4">
            <Button
              key={plan.id}
              type="primary"
              onClick={handleFreePlan}
              loading={loading}
              style={{
                width: "100%",
                margin: "20px 0",
              }}
            >
              Get started
            </Button>
          </div>

          <ul style={{ textAlign: "left" }}>
            {plan.product.features.map((feature) => (
              <div key={feature.name} style={{ marginBottom: "8px" }}>
                <CheckCircleOutlined
                  style={{ color: "#001f3f", marginRight: "8px" }}
                />
                {feature.name}
              </div>
            ))}
          </ul>
        </Card>
      ))}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOrg: state.opening.currentOrg,
});
export default connect(mapStateToProps)(FreePlan);
