import React, { useState } from "react";
import { connect } from "react-redux";
import { Popover, Popconfirm } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { LabeledIconButton } from "../../ant/Button";
import { Integration, loginUser, orgMember } from "../../type/type";
import {
  disconnectSlackAccountOfMember,
  updateMember,
} from "../../redux/actions/opening/action";
// import DeactivateMember from "./DeactivateMember"
import { ACTIVATE_BUTTON, DEACTIVATE_BUTTON, OWNER } from "../../constant";
import { activateDeactivateMember } from "../../lib/api";
import ConfirmationMessage from "../../setting_v1/ConfirmationMessage";
type MemberActionButtonsProps = {
  selectedMember: string;
  applicationId: string;
  updateMember(data: any): void;
  members: orgMember;
  loginUser: loginUser;
  disconnectSlackAccountOfMember(data: string): void;
  getLoading: (value: boolean) => void;
  integratedSlackMember: { [key: string]: Integration[] };
};

const MemberActionButtons = (props: MemberActionButtonsProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openSlackDisconnectedPopup, showSlackDisconnectedPopup] =
    useState<boolean>(false);

  const isOwner =
    props.members[props.loginUser.user.id].registrations[0].roles[0] === OWNER;
  if (!isOwner) return null;

  // onclick on activate or deactivate member popupConfirmation this function will call
  const handleDisconnectConfirm = () => {
    props.getLoading(true);
    const req = {
      user_id: props.selectedMember,
      application_id: props.applicationId,
      active:
        !props.members[props.selectedMember].registrations[0].data.activated,
    };
    setConfirmLoading(true);
    activateDeactivateMember(req)
      .then((res: any) => {
        props.updateMember({
          memberID: res.user_id,
          key: "activated",
          value: res.active,
        });
        props.getLoading(false);
        setConfirmLoading(false);
        showSlackDisconnectedPopup(false); 
      })
      .catch((error: any) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setConfirmLoading(false);
        props.getLoading(false);
      });
  };

  const hide = (open: boolean) => {
    if (!open) {
      setOpen(open);
    }
  };

  // LabeledIconButton is a member action button
  return (
    <>
    <Popover
      onOpenChange={(open) => hide(open)}
      open={open}
      placement="bottom"
      trigger={["click"]}
      content={
        <div
        role="button"
        onClick={() => showSlackDisconnectedPopup(true)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            showSlackDisconnectedPopup(true);
          }
        }}
        tabIndex={0}
      >
        {props.members[props.selectedMember].registrations[0].data.activated
          ? "Deactivate"
          : ACTIVATE_BUTTON}
      </div>
      
      }
    >
      <LabeledIconButton
        onClick={() => setOpen(true)}
        size="small"
        type="text"
        label=""
        icon={<EllipsisOutlined />}
      />
    </Popover>
    {isOwner && props.loginUser.user.id !== props.selectedMember && (
        <Popconfirm
         placement="topLeft"
          open={openSlackDisconnectedPopup}
          title={
            props.members[props.selectedMember].registrations[0].data.activated
              ? "Deactivate a member"
              : "Activate a member"
          }
          okButtonProps={{ loading: confirmLoading }}
          description={
            <div className="w-72">
              {props.members[props.selectedMember].registrations[0].data
                .activated ? (
                  <ConfirmationMessage message="deactivate" firstName={props.members[props.selectedMember].first_name} lastName={props.members[props.selectedMember].last_name}/>
              ) : (
                <ConfirmationMessage message="activate" firstName={props.members[props.selectedMember].first_name} lastName={props.members[props.selectedMember].last_name}/>
              )}
            </div>
          }
          onCancel={(e) => {
            e?.stopPropagation(),
              e?.preventDefault(),
              showSlackDisconnectedPopup(false);
          }}
          onConfirm={handleDisconnectConfirm}
          okText={
            props.members[props.selectedMember].registrations[0].data.activated
              ? DEACTIVATE_BUTTON
              : ACTIVATE_BUTTON
          }
          cancelText="Cancel"
          trigger={["click"]}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  integratedSlackMember: state.opening.integratedSlackMember,
});

const mapDispatchToProps = (dispatch: any) => ({
  updateMember: (data: any) => dispatch(updateMember(data)),
  disconnectSlackAccountOfMember: (data: string) =>
    dispatch(disconnectSlackAccountOfMember(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberActionButtons);
