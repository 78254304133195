import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import TickIcon from "../../assets/Tick";
import Button1 from "../../components/common/button/Button1";
import { checkPhoneOtp, sendOtpToUserPhone } from "../../lib/api";
import { phoneVerify } from "../../redux/actions/opening/action";
import throwError from "../../service/throwError";
import { tokenVerify } from "../../service/tokenVerify";
import { loginUser } from "../../type/type";
type mobileVerificationprops = {
  loginUser: loginUser;
  phoneVerify(): void;
  validatePhone?: (value: number) => void;
  phoneField?: number;
};
const MobileVerification = (props: mobileVerificationprops) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const [timeLeft, setTimeLeft] = useState(0);
  const [mobile, setMobile] = useState("");

  const [enterOTPDivOn, setEnterOTPDivOn] = useState(false);
  const setMobileNumber = (e: any) => {
    setMobile(e);
    if (props.validatePhone) props.validatePhone(0);
  };
  const handleOtpChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newOtp = [...otp];
    if (props.validatePhone) props.validatePhone(0);
    if (e.target.value.length === 1 && index < 6) {
      newOtp[index] = e.target.value;
      setOtp(newOtp);
      index != 5 ? inputsRef.current[index + 1].focus() : null;
    } else if (e.target.value.length === 0 && index >= 0) {
      newOtp[index] = e.target.value;
      setOtp(newOtp);
      index != 0 ? inputsRef.current[index - 1].focus() : null;
    }
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  const verifyPhone = (e: any) => {
    e.preventDefault();

    setTimeLeft(60);
    const req = {
      user_id: props.loginUser.user.id,
      mobile_number: "+" + mobile,
    };
    sendOtpToUserPhone(req).then((res) => {
      if (res) {

        setEnterOTPDivOn(true);
        inputsRef.current[0].focus();
      }
    });
  };
  const otpVerify = (e: any) => {
    e.preventDefault();

    if (mobile.trim().length > 0) {
      const req = {
        user_id: props.loginUser.user.id,
        mobile_number: "+" + mobile,
        otp: otp.join(""),
      };
      checkPhoneOtp(req)
        .then((res) => {
          if (res) {
            props.phoneVerify();
            if (props.validatePhone) props.validatePhone(1);
          }
        })
        .catch((reason: AxiosError) => {
          if (props.validatePhone) props.validatePhone(2);

          throwError(reason);
        });
    }
  };

  const phoneInputCSS = {
    height: 32,
    borderTop: "0px ",
    borderLeft: "0px",
    borderRight: "0px",
    width: "100%",
  };
  const phoneButtonCSS = {
    border: 0,
    height: 32,
    borderRight: "1px solid rgba(15, 10, 13, 0.3)",
    borderBottom: "1px solid rgba(15, 10, 13, 0.3)",
  };

  return (
    <>
      <div className="flex flex-col shadow-6xl  ">
        <div className="flex flex-col">
          {" "}
          <div className="border border-gray-300 rounded tracking-wide font-normal flex flex-col">
            <div className="p-2">
              {" "}
              <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                <button className="focus:outline-none">
                  <TickIcon fill="green" />
                </button>
                <div className="text-sm">
                  Your email
                  <span className="font-semibold">
                    {" "}
                    {props.loginUser.user.email}
                  </span>{" "}
                  has been verified.
                </div>{" "}
              </div>
            </div>
            {props.loginUser.user.data.verifiedPhone && (
              <div className=" px-2 pb-2">
                {" "}
                <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                  <button className="focus:outline-none">
                    <TickIcon fill="green" />
                  </button>
                  <div className="text-sm">
                    Your mobile
                    <span className="font-semibold">
                      {" "}
                      {props.loginUser.user.mobilePhone}
                    </span>{" "}
                    has been verified.
                  </div>{" "}
                </div>
              </div>
            )}

            {!tokenVerify() && (
              <div className="flex items-center space-x-8  p-6">
                <div className="text-sm font-light tracking-wide ">
                  <Link
                    className="focus:outline-none text-sm border-none   font-light cursor-pointer"
                    to={`/login`}
                  >
                    &nbsp;{" "}
                    <span className="text-blue-700 font-semibold">
                      Go back to login
                    </span>
                  </Link>
                </div>
              </div>
            )}

            {!props.loginUser.user.data.verifiedPhone && (
              <>
                <h1 className=" w-full text-xs tracking-wider uppercase px-4 font-medium">
                  Verify Phone Number
                </h1>

                <div className="px-4 pb-4">
                  {enterOTPDivOn ? (
                    <div className="flex flex-col  w-full">
                      <h1 className="h-3 w-full text-xs tracking-wider mb-4  ">
                        Enter OTP sent to your mobile number{" "}
                      </h1>
                      <div className="flex items-center w-full">
                        {" "}
                        <div className=" w-full flex space-x-4">
                          {" "}
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              id="enterOtp"
                              value={digit}
                              className={` h-12   w-1/12 text-center border border-tacitbase-tertiary1 border-opacity-30 rounded bg-white  tracking-wider px-2  focus:border-tacitbase-secondary3 focus:outline-none text-sm`}
                              ref={(ref: HTMLInputElement) =>
                                (inputsRef.current[index] = ref)
                              }
                              onChange={(e) => handleOtpChange(e, index)}
                            />
                          ))}
                        </div>{" "}
                      </div>
                      <div className="text-red-400 text-sm my-2 h-4 flex items-center ">
                        {props.phoneField == 2 && "Please enter otp."}
                      </div>
                      <div className="flex flex-row justify-between items-center  ">
                        <Button1
                          buttonName="Submit"
                          textColor="white"
                          shadow="3xl"
                          // svg={<LoginIcon fill="#ffff" />}
                          click={otpVerify}
                        ></Button1>{" "}
                      </div>

                      <div className="flex flex-row items-center justify-between pt-6">
                        {timeLeft == 0 ? (
                          <button
                            className="focus:outline-none text-sm border-none text-blue-700 font-light cursor-pointer"
                            onClick={(e) => (
                              setOtp(["", "", "", "", "", ""]), verifyPhone(e)
                            )}
                          >
                            Resend Code?
                          </button>
                        ) : (
                          <p className="text-sm border-none  font-light">
                            {" "}
                            Resend OTP in{" "}
                            <span className="font-medium">
                              {timeLeft}&nbsp;Seconds
                            </span>{" "}
                          </p>
                        )}

                        <button
                          onClick={() => setEnterOTPDivOn(false)}
                          className="focus:outline-none text-sm border-none text-blue-700 font-light cursor-pointer"
                        >
                          Change Phone Number
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex flex-col ">
                        <h1 className="h-3 w-full text-xs tracking-wider mb-4">
                          Please enter your country and mobile number for OTP
                          verification.
                        </h1>
                        <div className=" border border-tacitbase-tertiary1 border-opacity-30 rounded bg-white w-1/2 tracking-wider  h-8 focus:border-tacitbase-secondary3 focus:outline-none">
                          <PhoneInput
                            data-testid="mobileInput"
                            country={"us"}
                            value="1"
                            enableSearch
                            searchStyle={{
                              marginLeft: "10px",
                              width: "60%",
                              height: "30px",
                            }}
                            searchPlaceholder="Search"
                            inputStyle={phoneInputCSS}
                            buttonStyle={phoneButtonCSS}
                            countryCodeEditable={false}
                            inputProps={{
                              name: "phone",
                              country: "us",
                              required: true,
                            }}
                            onChange={(phone) => setMobileNumber(phone)}
                          />
                        </div>
                        <div className="text-red-400 text-sm my-2 h-4 flex items-center ">
                          {props.phoneField == 2 &&
                            "Please verify your phone number."}
                        </div>

                        <div className="flex flex-row justify-between items-center  ">
                          <Button1
                            buttonName="Verify"
                            textColor="white"
                            shadow="3xl"
                            click={verifyPhone}
                            testId="verifyPhone"
                          ></Button1>{" "}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  phoneVerify: () => {
    dispatch(phoneVerify());
  },
});
export default connect(mapStateToProps, mapPropsTostate)(MobileVerification);
