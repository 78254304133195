
import React, { Component } from "react";
import { connect } from "react-redux";
import { Divider, Typography } from "antd";
import InviteMemberModal from "../../components/orgMember/InviteMemberModal";
import OrgMember from "../../components/orgMember/OrgMember";
const { Text } = Typography;

type props = {
  applicationName: string;
};
class Members extends Component<props> {
  state = {
    searchedValue: "",
  };

  render() {
    return (
      <>
        <div className="p-4">
        <Text strong>Member settings</Text>
        </div>
        <Divider type="horizontal" className="w-full m-0 " />
        <div className="flex justify-between">
          <div className=" flex  flex-col px-4 pt-4">
            <Text strong>Member management</Text>
            <Text>
              Update your team member details including active status, slack
              integration.
            </Text>
          </div>
          <div className="p-4">
            <InviteMemberModal />
          </div>
        </div>
        <OrgMember/>
      </>
    );
  }
}
const mapStateToProps = (state: any) => ({
  applicationName: state.opening.applicationName,
});
export default connect(mapStateToProps)(Members);
