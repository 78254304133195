import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import EyeIconSvg from "../../../../assets/EyeIconSvg";
import TickIcon from "../../../../assets/Tick";
import { getUserWatcherOfModel, setUserWatcherOfModel } from "../../../../lib/api"
import { WatcherSubscription } from "../../../../lib/apiRes";
import { initCurrentCardWatcher } from "../../../../redux/actions/opening/action";



const WatcherCardSettings = () => {
  const params: { card_id: string } = useParams()
  const [watchStatus, setWatchStatus] = useState(false)
useEffect(()=>{

  getUserWatcherOfModel(params.card_id,"card").then((res)=>{
    // console.log(res);
    
    res.watcher.watch? setWatchStatus(true): setWatchStatus(false);
    
  })
},[])

  const addWatcherToThisEntity = () => {
    

    setUserWatcherOfModel(params.card_id,"card",true).then((res)=>{
      console.log(res);
      setWatchStatus(true)
      
    })
  };
  const removeWatcherFromThisEntity = () => {
    setUserWatcherOfModel(params.card_id,"card",false).then((res)=>{
      console.log(res);
      setWatchStatus(false)

      
    })
  };

  

  return (
    <React.Fragment>
      <button
        className="  w-full uppercase    py-1.5 flex-shrink-0   text-xs font-medium rounded align-middle flex items-center flex-row space-x-2"
        data-testid="testBtn"
        onClick={
          watchStatus ? removeWatcherFromThisEntity : addWatcherToThisEntity
        }
        id="Members"
      >
        <EyeIconSvg />
        <div>{watchStatus ? "Watching" : "Watch"} </div>

        {watchStatus ? (
          <div className="-mt-1">
            <TickIcon fill="black" />{" "}
          </div>
        ) : null}
      </button>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  opening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  notificationReduxState: state.opening.notification,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  initCurrentCardWatcher: (data: WatcherSubscription) =>
    dispatch(initCurrentCardWatcher(data)),
});
export default connect(mapStateToProps, mapPropsToState)(WatcherCardSettings);
