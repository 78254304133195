import { Form, Input } from 'antd'
import React from 'react'

type GuestuserProps = {
    EmailValidator :(_:any,value:any)=>void;
}
const Guestuser = (props:GuestuserProps) => {
  return (
        <>
        <div className="flex w-full gap-4">
         <div className="w-1/2">
       <Form.Item
        className="  w-full relative"
        rules={[
         {
           required: true,
           message: "Please input first name!",
         }
       ]}
        name="first_name"
        label={
         <div className="w-full">
           <div className="flex justify-between space-x-1 w-full ">
             <div>First name</div>{" "}
           </div>
         </div>
       }>
         <Input placeholder="Enter first name"/>
        </Form.Item>
        </div>
        <div className="w-1/2">
        <Form.Item
        className="  w-full relative"
        rules={[
         {
           required: true,
           message: "Please input last name!",
         }
       ]}
        name="last_name"
        label={
         <div className="w-full">
           <div className="flex justify-between space-x-1 w-full ">
             <div>Last name</div>{" "}
           </div>
         </div>
       }>
       <Input placeholder="Enter last name"/>    
        </Form.Item>
        </div>
        </div>
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              validator: props.EmailValidator,
            },
          ]}
          className="  w-full relative"
        >
          <Input placeholder="Enter email address"/>
        </Form.Item>
      </>
  )
}

export default Guestuser
           