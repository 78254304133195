import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { addDomainOfOrg } from "../../../redux/actions/opening/action";
import { Domain } from "../../../type/type";
import { OrgDomain } from "../../../redux/reducers/initialState";
import domainImage from "../../../images/domain.jpeg"; // renamed for clarity
import Image from "../../../components/common/image/Image";
import {  Button, Divider, Form, Input, Typography } from "antd";
import FormItem from "../../../ant/FormItem";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import TickIcon from "../../../assets/Tick";
import VerifyDomain from "./VerifyDomain";
import { useForm } from "antd/es/form/Form";
import {  TACITMAIL_DOMAIN } from "../../../constant";
import { validateInput } from "../../../utils/Validations";
import { SecondaryButton } from "../../../ant/Button";
import { addCustomDomainReq } from "../../../lib/apiReq";
import { addCustomDomain } from "../../../lib/api";

const { Text } = Typography;

type ConferenceIntegrationProps = {
  addDomainOfOrg(domainRes: Domain): void;
  defaultDomain: OrgDomain;
  applicationId: string;
};
const ConferenceIntegration = (props:ConferenceIntegrationProps) => {
  const [isCustomDomainSet, setCustomDomain] = useState<boolean>(false);
  const [defaultDomain, setDefaultDomain] = useState<string>("");
  const [defaultDomainName, setDefaultDomainName] = useState<string>("")
  const [domainStatus, setDomainStatus] = useState<string>("");
  const [domainId, setDomainID] = useState<string>("");
  const [isDomainSwitch,setDomainSwitch] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  useEffect(()=>{
    const domainID = Object.keys(props.defaultDomain)[0];
      if(domainID && props.defaultDomain[domainID].custom){
        setCustomDomain(true);
        setDefaultDomain(props?.defaultDomain[domainID]?.name);
        setDefaultDomainName(props?.defaultDomain[domainID]?.default_from);
        setDomainStatus(props?.defaultDomain[domainID]?.status);
        setDomainID(domainID)
     }
  },[props.defaultDomain,isCustomDomainSet]);
  const [form] = useForm();
  const handleAddCustomeDomain = () => {
    setDomainSwitch(true);
    setDefaultDomain("");
    form.setFieldsValue({ default_name: "" });
  };

  const addDomain = () => {
    form.validateFields().then((values:any)=>{
        let req: addCustomDomainReq = {
        name: values.domain_name,
        from_name: values.default_name,
      };
      setLoading(true);
      addCustomDomain(req).then((res) => {
        setLoading(false);
        setDomainID(res.id);
        props.addDomainOfOrg(res);
        setDomainSwitch(false);
      }).catch(()=>{
        setLoading(false);
      });
    })
    }
  
  return (
    <div className="p-4">
      <Divider type="horizontal" className="w-full m-0 " />
      <div className="flex mb-2 pt-4 flex-col">
        <Text strong>Domain configuration</Text>
      </div>
      {isCustomDomainSet ?(
        <div className="flex w-full ">
        <Form  className="w-full" layout="vertical">
          <div className="flex flex-col w-full justify-end space-y-2">
            <div className="flex w-full justify-end">
              <div className="flex justify-start  items-center w-full">
                  <div className="w-full text-xs flex flex-col gap-2">
                      <FormItem  label="Name">
                        <Input
                          value={defaultDomain}
                          disabled={true}
                        />
                      </FormItem>
                        <FormItem label={capitalizeFirstLetter("Default address")}>
                          <Input value={defaultDomainName} disabled={true} />
                        </FormItem>
                    {isCustomDomainSet  ? (
                      domainStatus === "ACTIVE" ? (
                        <div className="flex flex-col shadow-6xl">
                          <div className="flex flex-col">
                            {" "}
                            <div className="border-2 border-gray-300  tracking-wide font-normal flex flex-col">
                              <div className=" ">
                                {" "}
                                <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                                  <button className="focus:outline-none">
                                    <TickIcon fill="green" />
                                  </button>
                                  <Text>
                                    Domain
                                    <span className="font-semibold">
                                      {" "}
                                      {defaultDomain}
                                    </span>{" "}
                                    has been verified.
                                  </Text>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <VerifyDomain domainId={domainId} />
                      )
                    ) : (
                      <VerifyDomain domainId={domainId} />
                    )}
                  </div>
                </div>
           
            </div>
          </div>
        </Form>
      </div>
      ):(
        <div className="flex flex-col space-y-4 w-full space-y-1 h-max">
          <div className="flex w-full ">
            <Form form={form} className="w-full" layout="vertical">
              <div className="flex flex-col w-full justify-end space-y-2">
                <div className="flex w-full justify-end">
                  <div className="flex justify-start  items-center w-full">
                      <div className="w-full text-xs">
                       {isDomainSwitch &&( <div className="mb-2">
                          <FormItem name="domain_name" label="Name"
                          rules={[
                            {
                              validator: (_, value) => {
                                const domainVerify:any = validateInput(value, "domain");
                                if (domainVerify) {
                                  return Promise.reject(new Error(domainVerify));
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                            <Input
                              type="text"
                              data-testid="domainName"
                              value={defaultDomain}
                              onChange={(e)=>setDefaultDomain(e.target.value)}
                              disabled={
                                !isDomainSwitch &&
                                (defaultDomain === TACITMAIL_DOMAIN ||
                                  props.defaultDomain[domainId])
                                  ? true
                                  : false
                              }
                            />
                          </FormItem>
                          {(isDomainSwitch && !isCustomDomainSet) &&(<Text style={{ fontWeight: "400" }}>
                            This is the name of domain you want to configure with Tacitmail service.
                            e.g. myorg.com
                          </Text>)}
                        </div>)}
                            {isDomainSwitch &&(   <div className="mb-2">
                         {(!isDomainSwitch && isCustomDomainSet) && (<div>
                          <Text style={{ fontWeight: "400" }}>
                            Add your default from address for the organization email
                            e.g. myorg.jobs@myorg.com
                          </Text>
                          <Text className="font-medium">
                              Custom from address will have one time additional fee.
                            </Text>
                            </div>)}
                        </div>)}
                        {isDomainSwitch && (
                          <div className="flex justify-end space-x-2">
                            <Button
                              onClick={() => setDomainSwitch(false)}
                            >Cancel</Button>
                            <SecondaryButton
                              disabled={
                                props.defaultDomain[props.applicationId]
                                  ? true
                                  : false
                              }
                              onClick={addDomain}
                              loading={loading}
                            >Add domain</SecondaryButton>
                          </div>
                        )}
                        {props.defaultDomain[domainId]  ? (
                          props.defaultDomain[domainId].status == "ACTIVE" ? (
                            <div className="flex flex-col shadow-6xl">
                              <div className="flex flex-col">
                                {" "}
                                <div className="border-2 border-gray-300  tracking-wide font-normal flex flex-col">
                                  <div className=" ">
                                    {" "}
                                    <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                                      <button className="focus:outline-none">
                                        <TickIcon fill="green" />
                                      </button>
                                      <Text>
                                        Domain
                                        <span className="font-semibold">
                                          {" "}
                                          {props.defaultDomain[domainId].name}
                                        </span>{" "}
                                        has been verified.
                                      </Text>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <VerifyDomain domainId={domainId} />
                          )
                        ) : (
                          <VerifyDomain domainId={domainId} />
                        )}
                      </div>
                    </div>
               
                </div>
              </div>
            </Form>
          </div>
          <div className="customer-form-main-headers flex flex-col space-y-2">
              {(!isCustomDomainSet && !isDomainSwitch) && (
                <div className="py-1  border-none  cursor-pointer flex items-center justify-end space-x-2 ">
                  <SecondaryButton
                    onClick={handleAddCustomeDomain}
                    data-testid="addCustomDomain"
                  >
                    <Image src={domainImage} size="6" />
                    {capitalizeFirstLetter("Add custom domain")}
                  </SecondaryButton>
                </div>
              )}
            </div>
        </div> 
      )}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  CurrentUserZoomMeet: state.opening.calendar.currentUserZoomMeet,
  defaultDomain: state.opening.defaultDomain,
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  addDomainOfOrg: (domainRes: Domain) => dispatch(addDomainOfOrg(domainRes)),
});
export default connect(mapStateToProps, mapPropsTostate)(ConferenceIntegration);
