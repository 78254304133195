import React from "react";
import { EditCommentActionButtonProps } from "../../../../type/card";

const EditCommentActionButton = (props: EditCommentActionButtonProps) => {
  const isTabKeyPress = (e: any) => {
    if (e.keyCode == "13") {
      props.showCommentInput({
        Input: true,
        style: "shadow-md",
      });
      props.clickEdit;
    }
  };
  return (
    <>
      <div className="flex text-xs space-x-1 px-2 py-0.5 text-gray-500  cursor-pointer font-medium ">
        <div
          data-testid="testDivBtn"
          onClick={() => {
            props.showCommentInput({
              Input: true,
              style: "shadow-md",
            });
            props.clickEdit;
          }}
          role="button"
          tabIndex={0}
          onKeyDown={isTabKeyPress}
        >
          Edit
        </div>
      </div>
    </>
  );
};
export default EditCommentActionButton;
