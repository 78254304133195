import React from "react";
import TickIcon from "../../../assets/Tick";
import CloseIcon from "../../../assets/Close";

// Define the structure of each step
interface FormStep {
  id: number;
  heading: string;
  description:string
}

// Define the props expected by the FormSteps component
type FormStepsProps = {
  currentStep: number; // The current step in the form
};

// FormSteps component definition
const FormSteps = (props: FormStepsProps) => {
  // Define an array of steps with their IDs, headings, and associated components
  const steps: FormStep[] = [
    {
      id: 0,
      heading: "Choose a CSV file:",
      description:"Select and upload a CSV file with Header row."
    },
    {
      id: 1,
      heading: "Map Fields",description:"Map the CSV file columns with your available candidate database fields."
    },
    {
      id: 2,
      heading: "Validate Mapping",description:"Make sure the data shown in the table is n correct format and columns are mapped respectively."
    },
    {
      id: 3,
      heading: "Import Candidates",
      description:"Import candidates in your candidate database."
    },
  ];

  return (
    <div className="form-steps flex items-center justify-center h-full">
      <div className="flex items-start justify-center space-x-8 h-full ">
        {steps.map((step, index) => (
          <div
            key={step.id}
            className={`step-item ${
              index === props.currentStep ? "active" : ""
            } ${
              index < props.currentStep ? "completed" : ""
            } flex flex-col items-center justify-center space-y-2  w-40`}
          >
            {/* Step circle with either an exclamation mark, a tick icon, or a close icon */}
            <div
              className={`step-circle flex items-center justify-center ${
                index < steps.length - 1 ? "with-line" : ""
              }`}
            >
              {/* Conditional rendering based on the current step */}
              {index === props.currentStep ? (
                // Exclamation mark for the current step
                <div className="text-white text-lg" data-testid="stageIcon">!</div>
              ) : index < props.currentStep ? (
                // Tick icon for completed steps
                <TickIcon fill="white" />
              ) : (
                // Close icon for upcoming steps
                <CloseIcon />
              )}
            </div>
            {/* Step heading */}
            <div className=" text-center  text-sm break-words font-semibold leading-4    ">{step.heading}</div>
            {props.currentStep<0 &&( <div className="text-xs text-center">{step.description}</div>)}

          </div>
        ))}
      </div>
    </div>
  );
};

export default FormSteps;
