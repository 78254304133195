import { Divider, Form, Input, Typography } from 'antd'
import { FormInstance } from 'antd/lib';
import React from 'react'
import PhoneInput from 'react-phone-input-2';
const {Text} = Typography;
type AgencyProps = {
EmailValidator :(_:any,value:any)=>void;
form:FormInstance;
}
const Agency = (props:AgencyProps) => {

  const removeCode = (phone: string, country: any) => {
    return phone.substring(country.dialCode.length)
  }

  const addPhone = (value:any, country:any)=>{
  const phoneNumber: string = removeCode(value, country)
  props.form.setFieldValue('phone', `+${country.dialCode} ${phoneNumber}`);
  }
  return (
        <>
        <Form.Item
          name="agency_name"
          label="Agency name"
          rules={[
            {
              required: true,
              message: "Please input agency name!",
            },
          ]}
          className="  w-full relative"
        >
          <Input placeholder="Enter agency name"/>
        </Form.Item>
        <Divider className='w-full mt-4 mb-3'/>
        <div className='mb-2'>
          <Text className='font-medium'>Contact person details</Text>
        </div>
        <div className="flex w-full gap-4">
         <div className="w-1/2">
       <Form.Item
        className="  w-full relative"
        rules={[
         {
           required: true,
           message: "Please input first name!",
         }
       ]}
        name="first_name"
        label={
         <div className="w-full">
           <div className="flex justify-between space-x-1 w-full ">
             <div>First name</div>{" "}
           </div>
         </div>
       }>
         <Input placeholder="Enter first name"/>
        </Form.Item>
        </div>
        <div className="w-1/2">
        <Form.Item
        className="  w-full relative"
        rules={[
         {
           required: true,
           message: "Please input last name!",
         }
       ]}
        name="last_name"
        label={
         <div className="w-full">
           <div className="flex justify-between space-x-1 w-full ">
             <div>Last name</div>{" "}
           </div>
         </div>
       }>
       <Input placeholder="Enter last name"/>    
        </Form.Item>
        </div>
        </div>
        <Form.Item
          name="email"
          label="Email address"
          rules={[
            {
              required: true,
              validator: props.EmailValidator
            },
          ]}
          className="  w-full relative"
        >
          <Input placeholder="Enter email address"/>
        </Form.Item>
        <Form.Item
        name= "phone"
        label = "Phone number"
         >
        <PhoneInput
           enableSearch
           countryCodeEditable={false}
           country={"in"}
           onChange={addPhone}
         />{" "}
        </Form.Item>
      </>
  )
}

export default Agency
