import moment from "moment"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
// import Attachment from "../../assets/Attachment"
import { DATE_TIME_FORMATE } from "../../constant"
import { currentMailThreads } from "../../redux/reducers/initialState"
import { MailPreview } from "../../type/type"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Badge, Divider, List, Typography } from "antd"
import TacitbaseAvtar from "../../ant/Avtar"
import AttachmentIcon from "../../assets/AttachmentIcon"
const { Text } = Typography
// import { Atta } from "@ant-design/icons";

type TmailPreviewProps = {
  mail: MailPreview,
  setMailId(id: string, key: string): void,
  currentMailThread: currentMailThreads, 
  notificationCenter: any;

}
const TmailPreview = (props: TmailPreviewProps) => {  
  const [mailDots, setMailDots] = useState<any>()
  useEffect(() => {
    setMailDots(props.notificationCenter.unreadDotsofServices?.inbox?.mail)
  }, [props.notificationCenter])
  return (
    <>
      {/* <div
        role={"button"}
        id={`tacitmail-${props.mail.message.id}}`}
        onClick={() =>
          props.setMailId(props.mail.message.id, props.mail.message.key)
        }
        onKeyDown={() => {}}
        tabIndex={0}
        className={` hover:shadow hover:border-b rounded   border-tacitbase-tertiary1   border-opacity-20 space-x-2 justify-start items-start`}
      >
        <div
          className={`${props.mail.message.read ? "bg-white" : "bg-white "} ${
            Object.keys(props.currentMailThread)[0] == props.mail.message.id
              ? "border border-tacitbase-secondary3 border-l-4 "
              : "border-tacitbase-tertiary1  border-opacity-20 hover:border-opacity-30  hover:border-b"
          } border rounded   `}
        >
          <div className="flex space-x-2 justify-start items-start py-1 px-2">
            <div className="flex-col w-full ">
              <div className="flex justify-between w-full ">
                <div className="flex  flex-shrink-0">
                  <div
                    className={`${
                      props.mail.message.read
                        ? "font-normal text-gray-600"
                        : "font-medium"
                    } text-xs leading-none w-32 truncate`}
                  >
                    {props.mail.message.to[0]}
                  </div>
                  <div className={`font-light text-xs  leading-none`}>
                    {props.mail.msg_count}
                  </div>
                </div>
                <div
                  className={`${
                    props.mail.message.read
                      ? "font-light text-gray-600"
                      : "font-medium"
                  } leading-none flex flex-shrink-0`}
                  style={{ fontSize: "10px" }}
                >
                  {moment(props.mail.message.created_at).format(
                    DATE_TIME_FORMATE
                  )}
                </div>
              </div>
              <div className="flex space-x-1 w-full flex-shrink-0">
                <div
                  className={`${
                    props.mail.message.read
                      ? "font-light text-gray-600"
                      : "font-medium "
                  } ${
                    props.mail.message.subject.length > 50 ? "w-full" : ""
                  } text-xs  leading-5 flex-shrink-0   truncate`}
                >
                  {props.mail.message.subject}
                </div>
                {props.mail.message.subject.length < 50 &&
                  props.mail.message.body && (
                    <>
                      <div
                        className={`${
                          props.mail.message.read
                            ? "font-normal text-gray-600"
                            : "font-light "
                        } text-xs leading-5`}
                      >
                        -
                      </div>

                      <div
                        className={`${
                          props.mail.message.read
                            ? "font-normal text-gray-600"
                            : "font-light "
                        } text-xs truncate leading-5`}
                      >
                        {props.mail.message.body}
                      </div>
                    </>
                  )}
              </div>
              <div className="flex space-x-2 text-xs justify-start items-center">
                <div className="flex space-x-1 justify-center items-center">
                  <AttachmentIcon />
                  <div className="text-xs">
                    {props.mail.message.attachments.length}
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end" data-testid="mailCard">

               {mailDots ?(
                         mailDots[
                          props.mail.message?.id
                          ] ? (
                            <Badge status="processing"  size={"default"} style={{height:15}} />
                          ) : null
                        ): null}
            </div>

            </div>
          </div>
          <div
            className={`${
              props.mail.message.read
                ? "font-normal text-gray-600"
                : "font-medium"
            } flex px-2  rounded-b py-0.5 space-x-1 border-t border-opacity-5 border-tacitbase-tertiary1 w-full text-xs justify-between`}
          >
            Destination: {capitalizeFirstLetter(props.mail.source.name)}
          </div>
        </div>
      </div> */}

      <List.Item
        role="button"
        id={`tacitmail-${props.mail.message.id}}`}
        onClick={() =>
          props.setMailId(props.mail.message.id, props.mail.message.key)
        }
        className={`${props.mail.message.read ? "bg-indigo-300 bg-opacity-5" : "bg-white "
          } ${Object.keys(props.currentMailThread)[0] == props.mail.message.id
          ? " border-tacitbase-secondary3 border-l-4 h-full bg-indigo-500 bg-opacity-10 rounded"
            : ""
          } w-full`}
        onMouseEnter={(e) => {
          e.currentTarget.style.boxShadow = "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.boxShadow = ""
        }} >
        <div className="w-full"
        >
          <div className="flex justify-between items-center w-full ">
            <div className="flex space-x-2  flex-shrink-0">
              <TacitbaseAvtar content={props.mail.message.to[0][0]} />

              <div
                className={`${props.mail.message.read
                  ? "font-normal text-gray-600 bg-opacity-5"
                  : "font-bold"
                  } text-sm  w-32 truncate`}
              >
                {props.mail.message.to[0]}
              </div>
              <div className={`font-light text-xs `}>

                {props.mail.msg_count}
              </div>
            </div>
            <div
              className={`  flex font-light text-xs flex-shrink-0  text-gray-600 space-x-4`}
              // style={{ fontSize: "10px" }}
            >
              <AttachmentIcon size="15" />

              {moment(props.mail.message.created_at).format(
                DATE_TIME_FORMATE
              )}
            </div>
          </div>
          <div className=" flex flex-col pl-8 w-full ">
            <Text
              className={`${props.mail.message.read
                ? "font-light text-gray-600"
                : "font-semibold "
                } ${props.mail.message.subject.length > 50 ? "w-full" : ""
                } text-sm leading-relaxed flex-shrink-0 truncate`}
            >
              {props.mail.message.subject}
            </Text>
            <Text
              className={`${props.mail.message.read
                ? "font-normal text-gray-600"
                : "font-semibold "
                } text-xs truncate w-full `}
            >
              {props.mail.message.body}
            </Text>

          </div>


          <Divider style={{ height: "0.1px", margin: "6px" }}
          />
          <div
            className={`flex  space-x-1 pl-8 w-full text-xs justify-between`}
          >
            <div className="w-full">Destination: {capitalizeFirstLetter(props.mail.source.name)}</div>
            <div className=" flex justify-end" data-testid="mailCard">
            {mailDots && mailDots[props.mail.message?.id] ? (
              <Badge
                status="processing"
                size="default"
                style={{ height: 15 }}
              />
            ) : null}
            </div>
          </div>
        </div>

      </List.Item >
      <Divider style={{ height: "0px", margin: "0px" }} />

      {/* </List > */}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  currentMailThread: state.opening.currentMailThread,
  notificationCenter: state.opening.notificationCenter,

})
export default connect(mapStateToProps)(TmailPreview)
