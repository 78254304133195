import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
// import AddIcon from "../../assets/Add"
import { FormBuilder, InitLabel, searchDynamicQuery } from "../../type/type.d"
import { initSearchFilter } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import { IS, operatorMap } from "../../constant"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { getAllBuildForm } from "../../lib/api"
import TickIcon from "../../assets/Tick"

const FilterBySource = (props: {
    candidateLabels: InitLabel,
    initSearchFilter: (filterObj: any) => void,
    searchQuery: searchDynamicQuery,
}) => {
    const [sourceForms, setForms] = useState<string[]>(["tacitbase"])
    const [addedFilterSource, setFilterSource] = useState<string[]>([])

    const refer = useRef(null)
    // let [referenceElement, setReferenceElement] =
    //     useState<HTMLDivElement | null>()
    // let [popperElement, setPoperElement] = useState<HTMLDivElement | null>()
    // const { styles, attributes } = usePopper(referenceElement, popperElement, {
    //     placement: "bottom-start",
    // })

    useEffect(() => {
        if (props.searchQuery.filters.length == 0) {
            setFilterSource([])
        }
    }, [props.searchQuery])
    const addCardLabel = (source: string) => {
        let found: boolean
        found = addedFilterSource.includes(
            source
        )
        if (found) {
            setFilterSource(addedFilterSource.filter((labelId: string) => labelId != source))
            let updatedFilters = props.searchQuery.filters.filter(
                (filter: any) =>
                    !(filter.column === "source.from" && filter.value === source)
            )
            props.initSearchFilter(updatedFilters)
        } else {
            setFilterSource([...addedFilterSource, source])
            let filterObj: any[] = [...props.searchQuery.filters, { column: "source.from", operator: operatorMap[IS], value: source, view: "Source", operatorName: IS, id: "" }]
            props.initSearchFilter(filterObj)
        }
    }
    useEffect(() => {
        getAllBuildForm()
            .then((form: FormBuilder[]) => {
                setForms([...sourceForms, ...form.map((form) => form.title)])
            })
          
    }, [])

    return (
        <>
            {/* <div className="flex items-center" ref={setReferenceElement}>
                <Button
                    data-testid="button"
                    onClick={() => setOpen(!open)}
                    style={{width:'100%'}}
                >Filter by source</Button>

                {
                    open ? (
                        <Overlay scope={Scope.Popover} onClose={() => setOpen(!open)} reference={refer}>
                            <div
                                ref={setPoperElement}
                                style={styles.popper}
                                {...attributes.popper}
                            > */}
                                <div className="animate-popover_fadeIn flex flex-col  h-auto mt-1 z-10 w-72 bg-white text-gray-800 tracking-normal  text-sm font-normal "
                                    ref={refer}
                                    style={{marginLeft:'-4px'}}
                                    >
                                    {/* <div className="mt-3">
                                        <PopupTitle PopupTitle={"Sources"} close={() => setOpen(!open)} />
                                    </div> */}
                                    {sourceForms.length > 0 ? <>
                                        <div className="flex flex-col">
                                            <div className="label-panal ">
                                                {sourceForms.length > 0
                                                    ? sourceForms.map(
                                                        (Formlabel: string) =>
                                                            Formlabel && (
                                                                <>
                                                                    <button onClick={() => addCardLabel(Formlabel)} className="flex w-full mt-2 p-1 px-3 justify-between hover:bg-gray-200 rounded">
                                                                        <div className="truncate" >{capitalizeFirstLetter(Formlabel)}
                                                                        </div>
                                                                        {props.searchQuery.filters.map((filterItem) => (
                                                                            filterItem.value == Formlabel &&
                                                                            <div className="flex" key={filterItem.id}> < TickIcon />
                                                                            </div>
                                                                        ))
                                                                        }
                                                                    </button >

                                                                </>
                                                            )
                                                    )
                                                    : null}
                                            </div>
                                        </div>
                                    </> : <div className="m-2">{capitalizeFirstLetter("No source found")}</div>}
                                </div>
                            {/* </div>
                        </Overlay>
                    ) : null
                }
            </div > */}

        </>
    )
}
const mapStateToProps = (state: any) => ({
    servicesMembership: state.opening.servicesMembership,
    loginUser: state.opening.loginUser,
    candidateLabels: state.opening.candidateLabels,
    searchQuery: state.opening.searchQuery,
})

const mapPropsToState = (dispatch: Dispatch) => ({
    initSearchFilter: (filterObj: any) =>
        dispatch(initSearchFilter(filterObj)),
})
export default connect(mapStateToProps, mapPropsToState)(FilterBySource)
