import React, { useEffect, useState } from "react";
import PricingSection from "./PricingSection";
import { getBillingActivePlans } from "../../lib/api";
import { Plan } from "../../type/type";
const PricingTable = () => {
    const [plans, setPlans] = useState<Plan[]>([]);
    const [freePlans, setFreePlans] = useState<Plan[]>([]);
    const [loading,setLoading] = useState(false)

  useEffect(() => {
    setLoading(true);
  
    Promise.all([
      getBillingActivePlans("tacitbase"),
      getBillingActivePlans("free")
    ])
      .then(([tacitbaseRes, freeRes]) => {
        setPlans(tacitbaseRes.plans);
        setFreePlans(freeRes.plans);
      })
      .catch((error) => {
        console.error("Error fetching billing plans:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  

  return (
    <div>
      <PricingSection freePlans = {freePlans}  loading = {loading}  plans={plans}/>
    </div>
  );
};

export default PricingTable;
