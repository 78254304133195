import React, { useEffect, useRef, useState } from "react";
import CardPreviewSideSectionMenu from "./sideMenu/CardSideSectionMenu";
import * as constant from "../../../constant";
import {
  archieveUnArchiveApplicant,
  getCardEvents,
} from "../../../lib/api";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  getCardReward,
  sendBackArchivedItem,
  setCardCalEvents,
  updateApplicantArchiveStatus,
} from "../../../redux/actions/opening/action";
import { CardPreviewSideSectionProps } from "../../../type/card";
import MoveUpIcon from "../../../assets/MoveUp";
import { Event, Transaction } from "../../../lib/apiRes";
import { useParams } from "react-router-dom"
import CardEventSchedularPopup from "../../calender/cardScheduler/CardEventPopupIndex";
import Calender from "../../../assets/Calender";
import moment from "moment"
import CardWatcher from "./sideMenu/CardWatcher";
import { Overlay } from "../../hook/Overlay";
import { Scope } from "../../../type/type.d"
const CardSideSection = (props: CardPreviewSideSectionProps) => {
  const params: { card_id: string } = useParams();
  const [addEventPopup, setAddEventPopup] = useState(false);
  const [editEventPopup, setEditEventPopup] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState("");
const refer=useRef(null);
  const [calenderEvents, setCalenderEvents] = useState<Event[]>();




  useEffect(() => {
    // fetching card calendar events
    getCardEvents(params.card_id).then((res) => {
      res.Card_Events
        ? props.setCardEvents(res.Card_Events)
        : props.setCardEvents([]);
    });
  }, []);
  // setting  current card events in redux
  useEffect(() => {
    setCalenderEvents(Object.values(props.cardEvents));
  }, [props.cardEvents]);

  const isArchive = (list: string) => {
    const req = {
      id: props.currentApplicant.id,
      archive: true,
      socket_client_id: sessionStorage.getItem(constant.SOCKET_CLIENT_ID),
    };
    if (list == "Archive")
      archieveUnArchiveApplicant(req).then((applicant: any) => {
        props.archiveUnArchive(applicant);
      });
  };
  const setFocusToParent = (e: any) => {
    if (e.key == "Tab") {
      document.getElementById("card-input")?.focus();
    }
  };
  const sendBackToBoard = (archivedItem: any) => {
    const req = {
      id: archivedItem.id,
      archive: false,
      socket_client_id: sessionStorage.getItem(constant.SOCKET_CLIENT_ID),
    };

    archieveUnArchiveApplicant(req).then((res: any) => {
      props.sendBackArchivedApplicant(res);
    });
  };

  return (
    <React.Fragment>
      <div className=" float-right p-1 space-y-3 w-3/12 text-gray-700">
        <div
          className="items-center  pr-2 cursor-pointer text-sm font-normal"
          aria-disabled="true"
        >
          <div>
            {" "}
            <button className="px-3 bg-gray-200 hover:bg-gray-200  w-full uppercase   bg-opacity-60  flex-shrink-0   text-xs font-medium rounded align-middle flex items-center flex-row space-x-2">
              {" "}
              <CardWatcher />
            </button>
          </div>
        </div>
        <CardPreviewSideSectionMenu
          click={(list: any) => isArchive(list)}
          listName={constant.ADD_TO_CARD}
          list={constant.CARD_PREVIEW_MENU}
        />

        <CardPreviewSideSectionMenu
          listName={constant.ACTION}
          list={constant.ACTION_MENU.ul}
          click={(list: string) => isArchive(list)}
        />
        {props.currentApplicant ? (
          props.currentApplicant.archive ? null : (
            <CardPreviewSideSectionMenu
              click={(list: any) => isArchive(list)}
              list={constant.ACTION_MENU.ul2}
            />
          )
        ) : null}

        {props.currentApplicant ? (
          props.currentApplicant.archive ? (
            <>
              <div className="items-center mt-1 pr-2 cursor-pointer text-sm font-normal">
                <div
                  className="bg-gray-200 text-sm hover:bg-gray-200 bg-opacity-60 py-1.5 px-3 mb-2 rounded align-middle"
                  role="button"
                  data-testid="sendToBoard"
                  tabIndex={0}
                  onKeyDown={(e: any) => {
                    setFocusToParent(e);
                  }}
                  onClick={() => {
                    sendBackToBoard(props.currentApplicant);
                  }}
                >
                  <div className="flex items-center flex-row  space-x-1">
                    {" "}
                    <MoveUpIcon />
                    <span>Send to tracker</span>
                  </div>
                </div>
              </div>
            </>
          ) : null
        ) : null}
        {props.currentApplicant ? (
          !props.currentApplicant.archive ? (
            <div className=" text-xs font-medium ">
              <span className="uppercase"> Event</span>
              <div
                className="items-center  mt-2 pr-2 cursor-pointer text-sm font-normal"
                aria-disabled
              >
                <button
                  className="bg-gray-200 hover:bg-gray-200  w-full uppercase   bg-opacity-60 py-1.5 px-3 mb-2 text-xs font-medium rounded align-middle flex items-center flex-row space-x-2"
                  aria-hidden
                  onClick={() => {
                    setAddEventPopup(true);
                  }}
                >
                  <div className="flex items-center flex-row  space-x-2">
                    {" "}
                    <Calender />
                    <span>Schedule Event</span>
                  </div>
                </button>{" "}
                <div className="flex-col flex space-y-2">
                  {calenderEvents ? (
                    calenderEvents.map((event, i: number) => (
                      <div
                        aria-hidden
                        style={{
                          backgroundColor:
                            moment(new Date(event.start_date)).format("") <
                            moment(new Date().getTime()).format("")
                              ? "rgb(15, 10, 13,0.3)"
                              : event.color,
                        }}
                        className={`flex flex-col rounded text-xs items-start justify-center px-3 py-1.5 space-y-0  text-white text-opacity-90`}
                        key={i}
                        onClick={() => (
                          setSelectedEvent(event.id), setEditEventPopup(true)
                        )}
                      >
                        <div className="overflow-hidden whitespace-nowrap w-full capitalize">
                          {event.summary}
                        </div>
                        <div>
                          {moment(new Date(event.start_date)).format(
                            "DD MMM YYYY - h:mm a"
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="z-50 w-192 bg-white rounded  mt-12 ">
                {editEventPopup ? (
                   <Overlay
                   onClose={() => {
                    setEditEventPopup(false);
                   }}
                   scope={Scope.Popover}
                   reference={refer}
                 >
                  <CardEventSchedularPopup
                    type="edit"
                    card_id={props.currentApplicant.id}
                    event_id={selectedEvent}
                    click={(e: boolean) => setEditEventPopup(e)}
                  /></Overlay>
                ) : null}
              </div>
            </div>
          ) : null
        ) : null}
      </div>

      {addEventPopup ? (
          <Overlay
          onClose={() => {
            setAddEventPopup(false);
          }}
          scope={Scope.Popover}
          reference={refer}
        >
        <CardEventSchedularPopup
          click={(e: boolean) => setAddEventPopup(e)}
          refer={refer}
          type="create"
        /></Overlay>
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  roles: state.opening.roles,
  cal_id: state.opening.calendar.currentCalenderId,
  cardEvents: state.opening.calendar.currentCardEvents,
  boardEvents: state.opening.calendar.currentBoardEvents,
  currentApplicantRewards: state.opening.currentApplicantRewards,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  archiveUnArchive: (applicantID: string) =>
    dispatch(updateApplicantArchiveStatus(applicantID)),
  sendBackArchivedApplicant: (archivedApplicant: any) =>
    dispatch(sendBackArchivedItem(archivedApplicant)),
  initCurrentCardReward: (Rewards: Transaction[], cardID: string) =>
    dispatch(getCardReward(Rewards, cardID)),

  setCardEvents: (events: Event[]) => dispatch(setCardCalEvents(events)),
});
export default connect(mapStateToProps, mapPropsToState)(CardSideSection);
