import React, { useEffect, useState } from "react";
import Pagination from "../candidateDB/Pagination";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { MailPreview, notificationServiceEntityDotChangePayloadType } from "../../type/type";
import { TMAIL_MENU } from "../../constant";
// import usermails from "../mock/mail.json"
import TmailPreview from "./TmailPreview";
import TmailThread from "./TmailThread";
import { connect } from "react-redux";
import { getUserInbox } from "../../lib/api"
import { Dispatch } from "redux";
import { getAllMailsPagewise, initServiceEntityNotificationsDot } from "../../redux/actions/opening/action";
import { userMails } from "../../redux/reducers/initialState";
import { List } from "antd"
import FirstVisit from "../candidateReviewBase/DefaultForNoSelectedCandidate"
type TmailContentType = {
  getAllMailsPagewise(
    currentPage: string,
    mail: MailPreview[],
    tab: string
  ): void;
  initMailNotificationDot(payload:notificationServiceEntityDotChangePayloadType ): void;

  userMails: userMails;
};
const TmailContent = (props: TmailContentType) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [defaultActiveTab, setAtiveTab] = useState<string>("inbox");
  const [TmailTab, setTmailTab] = useState<any>({});
  const [selectedMailId, setMailId] = useState<{ id: string; key: string }>({
    id: "",
    key: "",
  });
  const setCurrentPageNumber = (pageNumber: number) => {
    setCurrentPage(pageNumber)
    setMailId({ id: "", key: "" })
  };
  const changeTab = (e: number) => {
    setTotalCount(0)
    Object.keys(TmailTab).map((menu: any, i: any) => {
      if (i === e) {
        setAtiveTab(menu);
        TmailTab[menu].active = true;
      } else {
        TmailTab[menu].active = false;
      }
    });
    setMailId({ id: "", key: "" })
  };
  const setCurrentmailId = (id: string, key: string) => {
    setMailId({ id: id, key: key });
  };
  useEffect(() => {
    setTmailTab(TMAIL_MENU);
    getUserInbox(defaultActiveTab, currentPage, pageSize)
      .then((res) => {
        if (res) {
          props.getAllMailsPagewise(
            currentPage.toString(),
            res.items,
            defaultActiveTab
          )
          setTotalCount(res.total_count)
          setPageSize(res.per_page)
        }
      })
     
  }, [defaultActiveTab, currentPage, pageSize]);

  // if (isMailsEmpty) return <FirstVisit description={`Nothing to see here!`} />;
  const changePerPageSize = (size: number) => {
    setPageSize(size)
  };


  return (
    <>
      <div className="flex space-x-1 overflow-y-scroll w-full h-full bg-white pl-2 py-4">
        <div className="flex-col w-60 flex-shrink-0 flex ">
          <div className="flex flex-col space-y-2 w-full px-2">
            {Object.keys(TmailTab).map(
              (menu: string, index: number) =>
                menu && (
                  <div
                    key={menu}
                    role={"button"}
                    onClick={() => changeTab(index)}
                    onKeyDown={() => {}}
                    tabIndex={0}
                    className={`${
                      menu === defaultActiveTab
                        ? "border-l-4  border-tacitbase-secondary3 border "
                        : ""
                    } hover:bg-color-grey hover:bg-opacity-25 bg-white flex  rounded px-2 py-1 justify-start items-start space-x-2`}
                  >
                    {TmailTab[menu].icon}
                    <span className="text-xs">
                      {capitalizeFirstLetter(menu)}
                    </span>
                    {/* <span className="text-xs">(120 unread)</span> */}
                  </div>
                )
            )}
          </div>
        </div>
        <div className=" w-full overflow-y-scroll h-full flex space-x-0">
          {totalCount > 0 && <div
            className={`rounded-md flex border bg-white border-tacitbase-secondary3 border-opacity-25`}
          >
            <div className="justify-between w-96 flex-col flex h-full flex-shrink-0">
              <div className="overflow-y-scroll h-full rounded-md">
                <div className="flex flex-col  ">
                  <List
                    size="small"
                  >

                    {props.userMails[defaultActiveTab] &&
                      props.userMails[defaultActiveTab][currentPage]
                      ? Object.values(
                        props.userMails[defaultActiveTab][currentPage]
                      ).map(
                        (umail: MailPreview) =>
                          umail && (
                            <TmailPreview
                              key={umail.source.id}
                              mail={umail}
                              setMailId={(id: string, key: string) =>
                                setCurrentmailId(id, key)
                              }
                            />
                          )
                      )
                      : null}
                  </List>
                </div>
              </div>
              <Pagination
                className="justify-end pagination-bar border shadow-lg border-tacitbase-secondary3 border-opacity-30  bg-white "
                currentPage={currentPage}
                totalCount={totalCount}
                pageSize={pageSize}
                onPageChange={(e: any) => setCurrentPageNumber(e)}
                selectedOption={(e: any) => changePerPageSize(parseInt(e))}
              />{" "}
            </div>
          </div>
          }
          <div className="w-full overflow-y-scroll border  border-tacitbase-secondary3 border-opacity-25 bg-white rounded">
            {selectedMailId.id && selectedMailId.key ? (
              <TmailThread
                selectedMailId={selectedMailId.id}
                tab={defaultActiveTab}
                refKey={selectedMailId.key}
                currentPage={currentPage.toString()}
              />
            ) : (

                <div className="w-full h-full flex justify-center items-center  ">
                  {totalCount == 0 ? <FirstVisit />
                    : <FirstVisit description={"No conversation selected"} />
                  }
                </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  userMails: state.opening.userMails,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initMailNotificationDot: (payload:notificationServiceEntityDotChangePayloadType) =>
  dispatch(initServiceEntityNotificationsDot(payload)),
  getAllMailsPagewise: (
    currentPage: string,
    mail: MailPreview[],
    tab: string
  ) => dispatch(getAllMailsPagewise(currentPage, mail, tab)),
});
export default connect(mapStateToProps, mapPropsToState)(TmailContent);
