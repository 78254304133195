import React, { useState } from "react";

const CheckList = ({ checkLists, selectCheck }: any) => {
  const keepType: {
    key: string;
    count: number;
  }[] = [];
  const [selectedKeep, setSelectedKeep] = useState(keepType);

  const selectKeep = (e: any) => {
    let keepArray: any[] = selectedKeep;
    if (!e.target.checked) {
      var index = keepArray.indexOf(e.target.defaultValue);
      if (index !== -1) {
        keepArray.splice(index, 1);
      }
      setSelectedKeep(keepArray);
    } else {
      keepArray.push(e.target.value);
      setSelectedKeep(keepArray);
    }
    selectCheck(selectedKeep);
  };

  if (!checkLists) return null;
  return (
    <>
      <span className="text-xs font-extrabold">keep...</span>
      {checkLists.map((keepItem: any, i: number) => (
        <div key={i}>
          <input
            type="checkbox"
            data-testid="CheckListTestInputBtn"
            className="form-checkbox"
            value={keepItem.key}
            onChange={selectKeep}
          />
          <span className="ml-3">{keepItem.key + `(${keepItem.count})`}</span>
        </div>
      ))}
    </>
  );
};
export default CheckList;
