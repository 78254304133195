import React, { useState } from "react"
import { connect } from "react-redux"
import { CandidatesInReview, InitLabel, Label } from "../../type/type"
import { IconButton } from "../../ant/Button"
import { CheckOutlined, LoadingOutlined, TagOutlined } from "@ant-design/icons"
import { addLabelsToBulkReviewReq } from "../../lib/apiReq"
import { addLabelToBulkReview, addLabelToCDBAndReview, deleteLabelFromReview, editLabelOfCDB } from "../../lib/api"
import { addLabelInReview, addLabelsToMultipleReviews, removeLabelFromReviews, updateReviewLabel } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import BulkLabel from "../common/AddLabelPopover"
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor"
import Mixed from "../../assets/Images/Mixed"
import { REVIEWS, SOCKET_CLIENT_ID } from "../../constant"
import { throwSuccess } from "../../service/throwError"

const AddLabelToReview = (props: {
    applicationId: string,
    saveReviewLabel(label: Label): void;
    selectedRowKeys: React.Key[],
    reviewLabels: InitLabel,
    addLabelsToMultipleReviews: (data: any) => void,
    candidatesInReview: CandidatesInReview,
    updateReviewLabel(updatedLabel: any): void,
    removeLabelFromReviews: (rId: string[], label: string[]) => void
}) => {
    const [labels, setLabels] = useState<any>([])
    const [loader, showLoader] = useState(false)
    const [loadedLabel, setloadedLabel] = useState("")

    const handleCreateLabel = (label: any) => {
        const labelreq = {
            name: label.value,
            color: label.color,
            entity: REVIEWS,
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),

        }
        addLabelToCDBAndReview(labelreq).then((lres) => {
            props.saveReviewLabel(lres)


        // props.saveReviewLabel(props..id, {
        //     id: res.labels.id,
        //     name: lres.name,
        //     color: lres.color,
            // })
        })
        setLabels([...labels, label])

    }
    const handleEditLabel = (label: any) => {
        const req = {
            id: label.id,
            color: label.color,
            name: label.value,
            entity: "review",
            socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        }
        editLabelOfCDB(req).then((applicant_label: any) => {
            props.updateReviewLabel(applicant_label)
        })
        setLabels([...labels, label])
    }

    const determineIcon = (label: Label) => {
        const selectedRowCount = props.selectedRowKeys.length
        const labelCount = props.selectedRowKeys.filter(item =>
            props.candidatesInReview.reviews[item] && props.candidatesInReview.reviews[item].labels.includes(label.id)
        ).length;
        const isLoading = label.id === loadedLabel

        if (isLoading && loader) {
            // Show loading icon for the current label being processed
            return <LoadingOutlined style={{ color: determineTextColor(label.color) }} />
        }

        if (labelCount === 0) {
            // If the label count is zero, return nothing
            return null
        } else if (labelCount === selectedRowCount) {
            // If the label count equals the total number of selected rows, return a checkmark icon
            return <CheckOutlined style={{ color: determineTextColor(label.color) }} />
        } else {
            // Otherwise, return a mixed symbol
            return (
                <div className="items-center justify-center flex">
                    <Mixed fill={determineTextColor(label.color)} height={16} />
                </div>
            )
        }
    }

    const addLabelToReviews = (labelData: Label) => {
        setloadedLabel(labelData.id)
        const reviewIdsWithLabel: React.Key[] = props.selectedRowKeys.filter((item: React.Key) => props.candidatesInReview.reviews[item] && props.candidatesInReview.reviews[item].labels.includes(labelData.id)).map((item: React.Key) => item)
        const reviewIdsWithLabels: React.Key[] = props.selectedRowKeys.filter((item: React.Key) => props.candidatesInReview.reviews[item] && !props.candidatesInReview.reviews[item].labels.includes(labelData.id)).map((item: React.Key) => item)

        if (reviewIdsWithLabel.length == 0) {
            showLoader(true)
            let req: addLabelsToBulkReviewReq = {
                review_ids: props.selectedRowKeys,
                label_ids: [labelData.id],
                socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
            }
            addLabelToBulkReview(req).then((res: any) => {
                props.addLabelsToMultipleReviews(res)
                throwSuccess(`Added label ${labelData.name} for ${props.selectedRowKeys.length} reviews.`)
                showLoader(false)

            }).catch(() => {
                showLoader(false)
            })
        } else if (reviewIdsWithLabel.length == props.selectedRowKeys.length) {
            showLoader(true)

            deleteLabelFromReview({
                review_ids: props.selectedRowKeys,
                label_ids: [labelData.id],
                socket_client_id: "props.clientId",
            }).then((res) => {
                throwSuccess(`Removed label ${labelData.name}`)
                props.removeLabelFromReviews(res.review_ids, res.label_ids)
                showLoader(false)

            }).catch(() => {
                showLoader(false)

            })
        } else if (reviewIdsWithLabel.length < props.selectedRowKeys.length) {
            showLoader(true)

            let req: addLabelsToBulkReviewReq = {
                review_ids: reviewIdsWithLabels,
                label_ids: [labelData.id],
                socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
            }
            addLabelToBulkReview(req).then((res: any) => {
                throwSuccess(`Added label ${labelData.name} for ${props.selectedRowKeys.length} reviews.`)
                props.addLabelsToMultipleReviews(res)
                showLoader(false)

            }).catch(() => {
                showLoader(false)

            })

        }

    }

    return (
        <div>
            <BulkLabel determineIcon={determineIcon} onAddLabel={addLabelToReviews} element={<>
                <IconButton tooltipTitle="Add label" icon={<TagOutlined ></TagOutlined>} />
            </>} onEditLabel={handleEditLabel} onCreateLabel={handleCreateLabel} defaultTitle="Create Label" initialLabels={Object.values(props.reviewLabels)} />
        </div>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    reviewLabels: state.opening.reviewLabels,
    candidatesInReview: state.opening.candidatesInReview,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    addLabelsToMultipleReviews: (data: any) =>
        dispatch(addLabelsToMultipleReviews(data)),
    updateReviewLabel: (label: any) => dispatch(updateReviewLabel(label)),
    removeLabelFromReviews: (rId: string[], label: string[]) =>
        dispatch(removeLabelFromReviews(rId, label)),
    saveReviewLabel: (label: any) => dispatch(addLabelInReview(label)),


})
export default connect(mapStateToProps, mapPropsToState)(AddLabelToReview)
