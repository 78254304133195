import React, { useEffect, useState } from "react"
import { getCompanies, getOpenings } from "../../lib/api"
import { connect } from "react-redux"
import {
  companyMember,
  initCompanies,
  initServiceEntityNotificationsDot,
  listOfBoards,
} from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import PageContentSectionName from "./sectionName/PageContentSectionName"
import Sorting from "../common/sorting/Sort"
import { } from "../../lib/api"
import {
  Member,
  Opening,
  company,
  loginUser,
  notificationServiceEntityDotChangePayloadType,
  orgMember,
} from "../../type/type"
import BoardLoader from "./boardPreview/LoaderOfBoard"
import { colors, DIVIDER_HEIGHT, FETCHING_BOARDS } from "../../constant"
import SearchBox from "./jobPostHeader/JobPostSearchBox"
import Navbar from "../navbar/Navbar"
import { Breadcrumb, Divider } from "antd"
import ContentHeader from "../analyticsVersion1/ContentHeader"
import JobPostActionButton from "./jobPostHeader/JobPostActionButton"
import { IconButton } from "../../ant/Button"
import { ReloadOutlined } from "@ant-design/icons"
import NavSearch from "../navSearch/NavSearch"

export type HomepageProps = {
  listOfBoards(boardData: any): void,
  applicationId: string,
  loginUser: loginUser,
  initBoardNotificationDot(
    payload: notificationServiceEntityDotChangePayloadType
  ): void,
  initCompanies: (JobBoard: company[]) => void,
  initCompanyMembers: (members: Member[]) => void,
  members: orgMember,

}

const Homepage = (props: HomepageProps) => {
  // const params: { org_id: string } = useParams();
  const [loading, setLoading] = useState<boolean>(false)
  const [sortValue, setSortValue] = useState("")
  const [searchdKeyword, setSearchedValue] = useState("")
  const [refresh, setRefresh] = useState<boolean>(false); // Refresh state


  useEffect(() => {
    setLoading(true)
    getCompanies().then((res) => {
      props.initCompanies(res)
    })
    getOpenings().then((openings) => {
      setLoading(false)
      props.listOfBoards(openings)
    })
    return () => {
      setLoading(false)
      setSortValue("")
      setSearchedValue("")
    }
  }, [refresh])
  const get = (value: any) => {
    setSortValue(value)
  }
  const getSearchKeyword = (e: string) => {
    setSearchedValue(e)
  }

  const refreshData = () => {
    setSortValue("") // Clear the sort value
    setSearchedValue("") // Clear the searched keyword
    setRefresh(!refresh); // Toggle refresh state
  };

  return (
    <React.Fragment>
      <div
        className="flex flex-col" >
        <ContentHeader>

          <div
            className="flex flex-row items-center px-4  py-3 justify-between"
            style={{ height: "48px" }}
          >
            <div className="flex items-center">
              <Breadcrumb
                items={[
                  {
                    title: 'Job posts',
                  }
                ]}
              />  </div>
            <div className="flex flex-row items-center">
              <NavSearch />
              <Divider
                type="vertical"
                style={{
                  height: DIVIDER_HEIGHT,
                  borderColor: `${colors.divider}`,
                }}
              />
              <Navbar />

            </div>          </div>
        </ContentHeader>



        <div className="flex py-2 px-4 bg-white border-b justify-between items-center">
          <div className="flex flex-row items-center">
            <JobPostActionButton />
          </div>
          <div className="flex-row space-x-2 flex justify-end items-center">
            <SearchBox onSearchChange={getSearchKeyword} />
            <Sorting c={(value: any) => get(value)} />
            <Divider
              type="vertical"
              style={{
                height: DIVIDER_HEIGHT,
                borderColor: `${colors.divider}`,
              }}
            />

            <IconButton
              tooltipTitle="Refresh tab"
              icon={
                <ReloadOutlined />
              }
              onClick={refreshData}
            />
          </div>
        </div>
      </div>

      {!loading ? (
        <PageContentSectionName
          sortValue={sortValue}
          searchedValue={searchdKeyword}
        />
      ) : (
        <div className="overflow-y-scroll h-full bg-white rounded pl-4">
          <BoardLoader active={true} text={FETCHING_BOARDS} />
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  members: state.opening.members,

})
const mapPropsTostate = (dispatch: Dispatch) => ({
  listOfBoards: (boardData: Opening[]) => dispatch(listOfBoards(boardData)),
  initBoardNotificationDot: (
    payload: notificationServiceEntityDotChangePayloadType
  ) => dispatch(initServiceEntityNotificationsDot(payload)),
  initCompanies: (candidates: company[]) =>
    dispatch(initCompanies(candidates)),
  initCompanyMembers: (members: Member[]) => dispatch(companyMember(members)),

})

export default connect(mapStateToProps, mapPropsTostate)(Homepage)
