import React, { useEffect, useState } from "react";
import Button1 from "../../common/button/Button1";
import { CSVCandidateImport } from "../../../lib/api";
import { TableColumn } from "../../../type/type";

// Define the props expected by the DataPreview component
interface DataPreviewProps {
  uploadedFileData: any; // Data of the uploaded CSV file
  selectedObjects: { [key: string]: TableColumn | null }; // Mapping of CSV file columns to candidate database fields
  mode: string; // Current mode (not used in this component)
  handleNextStep: (value: boolean) => void; // Callback for handling next step
  handleBackStep: (value: boolean) => void; // Callback for handling previous step
  setImportFinalAPIReport: (data: any) => void; // Function to set the import final API report
}

// DataPreview component definition
const DataPreview: React.FC<DataPreviewProps> = ({
  uploadedFileData,
  selectedObjects,
  handleNextStep,
  handleBackStep,
  setImportFinalAPIReport,
}) => {
  // State variables
  const [previewObjHeader, setPreviewObjHeader] =
    useState<[string, string][]>();
  const [previewObjData, setPreviewObjData] = useState<any>();
  const [fileRowData, setFileRowData] = useState<any>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  // Effect to update state when the uploadedFileData changes
  useEffect(() => {
    setFileRowData(uploadedFileData.stages.preview_rows);
    setFileHeaders(uploadedFileData.stages.headers);
  }, [uploadedFileData]);

  // Function to handle the import button click
  const handleImportClick = () => {
    const req = {
      import_id: uploadedFileData.stages.import_id,
      valid_input_key: uploadedFileData.stages.attachments[1].objectKey,
      header_mapping: selectedObjects,
    };
    setApiCalled(true);

    // Call the CSVCandidateImport API and handle the response
    CSVCandidateImport(req)
      .then((res) => {
        setImportFinalAPIReport(res);
        setApiSuccess(true);
        handleNextStep(true);
        setApiCalled(false);
      })
      .catch(() => {
      
        setApiSuccess(false);
        setApiCalled(false);
      });
  };

  // Effect to update previewObjHeader and previewObjData based on selectedObjects and fileRowData
  useEffect(() => {
    // Extract mapped table headers and their settings from the mapping object
    const mappedHeaders = Object.entries(selectedObjects).reduce(
      (acc: [string, string][], [fileHeader, tableHeader]) => {
        if (tableHeader !== null && tableHeader.name) {
          acc.push([fileHeader, tableHeader.view]);
        }
        return acc;
      },
      []
    );
    setPreviewObjHeader(mappedHeaders);

    // Get the indices of mapped headers in the file headers
    const headerIndices: number[] = mappedHeaders.map(([fileHeader]) =>
      fileHeaders.findIndex((header) => header === fileHeader)
    );

    // Transform data to include only the mapped columns
    const mappedData: string[][] = fileRowData.map((rowData: any) =>
      headerIndices.map((index) => rowData[index])
    );
    setPreviewObjData(mappedData);
  }, [selectedObjects, uploadedFileData, fileRowData]);

  return (
    <>
      {apiCalled && !apiSuccess ? (
        <>
          <div
            className="flex flex-col justify-center items-center w-full h-full  pl-2 pr-1 pb-16"
            style={{ height: "100%" }}
          >
            <div className="flex flex-col justify-center items-center  w-full h-full">
              <div className=" w-full    text-2xl font-semibold   rounded  flex items-center justify-evenly">
                <div className="flex justify-end items-end">
                  <div
                    style={{ borderTopColor: "#7152F2" }}
                    className="w-14 h-14 border-4 border-gray-300  border-solid rounded-full animate-spin"
                  ></div>
                </div>
              </div>

              <div className="text-tacitbase-tertiary1 text-opacity-50 ">
                Your import is in progress.
              </div>
              <div className="text-tacitbase-tertiary1 text-opacity-50 ">
                We will notify you once the import is complete.
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div
            className="flex flex-col w-full h-full space-y-4 pl-2 "
            style={{ height: "60%" }}
          >
            <div className="h-10 pr-1">
              <div className="text-sm flex flex-col bg-white border border-tacitbase-tertiary1 border-opacity-25 p-2 rounded space-y-2">
                <ul className="list-none">
                  <li className="flex items-center justify-start space-x-2">
                    <span className="inline-block w-2 h-2 rounded-full bg-black "></span>
                    <span className="text-sm">
                      This is the preview of {previewObjData?.length} candidate
                      records that will be imported into the candidate database.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
              <div className="customTable overflow-y-scroll overflow-x-scroll h-full box w-full flex flex-col flex-grow-0 rounded">
              <table className="select-none border-collapse">
                <thead className="sticky top-0 bg-gray-100 z-10">
                  <tr className="p-1 w-max border border-tacitbase-tertiary1 border-opacity-25">
                    {/* Render mapped table headers */}
                    {previewObjHeader?.map(([fileHeader, tableHeader]) => (
                      <th
                        key={fileHeader}
                        className="font-semibold text-sm bg-gray-200"
                      >
                        <span className="flex items-center">{tableHeader}</span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {/* Render preview data rows */}
                  {previewObjData?.map(
                    (rowData: string[], rowIndex: number) => (
                      <tr
                        key={rowIndex}
                        className="animate-popover_fadeIn hover:bg-gray-100 border rounded border-tacitbase-tertiary1 border-opacity-20 hover:border hover:shadow-md"
                      >
                        {rowData.map((cellData: string, cellIndex: number) => (
                          <td
                            key={cellIndex}
                            className="whitespace-no-wrap truncate"
                          >
                            {cellData}
                          </td>
                        ))}
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <hr className="border-px border-tacitbase-tertiary1 border-opacity-25 " />
          </div>
          {/* <hr className="border-px border-tacitbase-tertiary1 border-opacity-25" /> */}
          <div
            className="w-full flex justify-between items-center h-full pl-2 pr-1 "
            style={{ height: "20%" }}
          >
            {/* Back button to go back to the previous step */}
            <Button1 buttonName="Back" click={() => handleBackStep(true)} />
            {/* Import Candidate button to initiate the import process */}
            <Button1
              buttonName="Import Candidate"
              click={handleImportClick}
              isDisabled={apiCalled && !apiSuccess}
            />
          </div>
        </>
      )}
    </>
  );
};

export default DataPreview;
