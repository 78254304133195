import React from "react";
import { Attachment, Mail, Member, orgMember } from "../../type/type"
import Tacitmail from "../../assets/Tacitmail";
import parse from "html-react-parser";
import { DATE_TIME_FORMATE, TACITMAIL } from "../../constant";
import moment from "moment";
import { connect } from "react-redux";
import AttachmentPreviewOfMail from "./AttachmentPreview";
import ShowMailDetailsPopup from "./ShowMailDetailsPopup";
import { seperateMailAddress } from "../../utils/seperateMailAddress";
import TacitbaseAvtar from "../../ant/Avtar"
type MailThreadType = {
  setMailId(id: string): void;
  mail: Mail;
  isCardThread?: boolean;
  members: orgMember;
};
const MailThread = (props: MailThreadType) => {
  let address = seperateMailAddress(props.mail.from);
  let emailExists = Object.values(props.members).find(
    (member: Member) => member.id == props.mail.user_id
  );

  return (
    <div className="flex py-2 px-3 bg-white space-x-3 justify-start items-start">
      <div className="flex">
      {emailExists ? (
        <TacitbaseAvtar
          src={emailExists.image_url}
          key={emailExists.id}
          size="default"
        />
      ) : address.name == TACITMAIL ? (
        <Tacitmail />
          ) : (
          <TacitbaseAvtar
            size="default"
            content={address.name[0]}
          />
        )}
      </div>

      <div className="flex-col flex w-full trix-content">
        <div className="w-full text-xs flex justify-between">
          <div
            className={`${
              props.isCardThread ? "w-56" : "w-128"
            } leading-none truncate overflow-hidden`}
          >
            <span className=" font-semibold leading-none">{address.name}</span>
            {props.isCardThread ? null : (
              <span
                style={{ fontSize: "11px" }}
                className="font-light truncate overflow-hidden"
              >
                {address.email}
              </span>
            )}
          </div>
          <div className="text-xs flex-shrink-0 justify-start items-start flex space-x-2">
            <div className="flex text-xs leading-none">
              {moment(props.mail.created_at).format(DATE_TIME_FORMATE)}
            </div>
            <button onClick={() => props.setMailId(props.mail.id)}>
              <i className="fa fa-reply-all px-1 " aria-hidden="true"></i>
            </button>
            {/* <button onClick={() => props.setMailId(props.mail.id)}>
              <i className="fa fa-share px-1" aria-hidden="true"></i>
            </button> */}
          </div>
        </div>
        <div className="text-xs font-light space-x-1 flex justify-start items-center leading-snug">
          <span style={{ fontSize: "11px" }}>to {props.mail.to.map((address: string, index: number) => address &&
            <span key={index} className="pr-1">{address}
              {index !== props.mail.to.length - 1 && <span>&#44;</span>}
            </span>
          )}</span>
          <ShowMailDetailsPopup mail={props.mail} />
        </div>
        <div className="text-xs mt-1 pb-2">{parse(props.mail.body)}</div>
        <div className="flex flex-wrap">
          {props.mail.attachments.length > 0 &&
            props.mail.attachments.map((attachment: Attachment) => (
              <>
                {" "}
                <div className="text-xs mb-1 mr-1 flex border rounded space-x-2 w-40 px-2 py-1 ">
                  <AttachmentPreviewOfMail attachment={attachment} />
                </div>
              </>
            ))}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
});

export default connect(mapStateToProps)(MailThread);
