import React, { useEffect, useRef, useState } from "react";
import PopupTitle from "../../../common/popupTitle/PopupTitle"
import TacitbaseInput from "../../../../ant/Input";
import {
  currentApplicant,
  GetOpening,
  Label,

} from "../../../../type/type";
import ColorSelection from "../../../createBoardPopup/colorSelection/ColorSelection"
import { connect } from "react-redux";
import {
  saveBoardLabel,
  addLabelToCurrentCandidateInCDB,
  updateCandidateLabel,
} from "../../../../redux/actions/opening/action";
import { Dispatch } from "redux"
import {
  addLabelToCDBAndReview,
  attachLabelToCandidate,
  editLabelOfCDB,
} from "../../../../lib/api";
import LabelInputOfCandidate from "./LabelInputForCandidate";
import { CurrentCandidateInEditMode } from "../../../../redux/reducers/initialState";
import { attachLabelToCandidateReq } from "../../../../lib/apiReq";
import Emoji from "../../../Emoji";
import { Button } from "antd";
import { colorDictionary } from "../../../../constant"

export type AddLabelOnCandidateProps = {
  currentOpening: GetOpening;
  width?: string;
  currentApplicant: currentApplicant;
  addLabelToCurrentCandidateInCDB(data: any, candidateId: string): void;
  click: (e: boolean) => void;
  updateCandidateLabel(updatedLabel: any): void;
  refer: any;
  clientId: string;
  currentCandidateInEditMode: CurrentCandidateInEditMode;
};
const AddLabelOnCandidate = (props: AddLabelOnCandidateProps) => {
  let islimitexceed = false;
  const [isdisable ,setIsdisable] = useState(true);
  const [loading, setLoading] = useState(false);
  const labelInputRef = useRef<HTMLInputElement>(null);
  const [labelInput, showLabelInput] = useState<boolean>(false);
  const [selectedColorCode, setSelectedColorCode] = useState("#F58231");
  const [labelName, setLabelName] = useState<string>("");
  const [updateFlag, setUpdateFlag] = useState(false);

  const [updateLabelObj, setUpdateLabelObj] = useState({
    color: "",
    id: "",
    name: "",
  });
  useEffect(() => {
    let bgColor: string = "";
    Object.keys(colorDictionary).forEach((color: string) => {
      if (colorDictionary[color] === selectedColorCode) {
        bgColor = colorDictionary[color];
      }
    });    
    setSelectedColorCode(bgColor);
  }, []);
  const createLabelOfSearch = (e: any) => {
    if(e.length >0 && e.length<50){
      setIsdisable(false);
    }
    showLabelInput(!labelInput);
    setLabelName(e);
    // console.log("this is event",e);
  };
  useEffect(()=>{
    
    if (labelName.length<=0 || labelName.length>=50){
      setIsdisable(true);
  }else{
    setIsdisable(false);
  }
  },[labelName])

  const validateLabelName = (e: any) => {
    setLabelName(e.target.value);
  };


  
  
  // function to add emoji with label name
  const onEmojiSelect = (emoji: string): void => {
    // console.log("Selected Emoji:", emoji);
    setLabelName((prevLabelName) => prevLabelName + emoji);
  };

  const setSelectedColor = (color: string) => {
    setSelectedColorCode(colorDictionary[color]);
  };
  const updateLabel = (label: Label) => {
    setIsdisable(false);
    setSelectedColorCode(label.color);
    setUpdateLabelObj(label);
    setLabelName(label.name);
    showLabelInput(true);
    setUpdateFlag(true);
  };
  const saveLabel = () => {
    setLoading(true);
    const req = {
      id: updateLabelObj.id,
      color: selectedColorCode || updateLabelObj.color,
      name: labelName || updateLabelObj.name,
      entity: "candidates",
    };
    editLabelOfCDB(req).then((applicant_label: any) => {
      props.updateCandidateLabel(applicant_label);
      showLabelInput(false);
      setLabelName("");
      setLoading(false);
      setUpdateFlag(false);
    }).catch(() => { setLoading(false)});
  };

  // const containsEmoji = (text: any) => {
  //   const emojiRegex =
  //     /[\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  //   return emojiRegex.test(text);
  // };

  const createLabel = () => {
    if (labelName.length > 0) {
      if (labelName.length <= 0 || labelName.length >= 50) {
        islimitexceed = true
        showLabelInput(true);
      } else {
        
        const label = {
          name: labelName,
          color: selectedColorCode,
          entity: "candidates",
          socket_client_id: props.clientId,
        };
        setLoading(true);
        addLabelToCDBAndReview(label).then((label) => {
          let req: attachLabelToCandidateReq = {
            candidate_id: props.currentCandidateInEditMode.candidateId,
            label_id: label.id,
            socket_client_id: props.clientId,
          };
          attachLabelToCandidate(req).then((candidate_labels) => {
            props.addLabelToCurrentCandidateInCDB(
              candidate_labels.labels,
              props.currentCandidateInEditMode.candidateId
            );
            showLabelInput(false);
            setLabelName("");
            setLoading(false);
            setUpdateFlag(false);
          })
        }).catch(() => { setLoading(false)});
      }
    } else {
      labelInputRef.current && labelInputRef.current.focus();
    }
  };
    
  return (
    <React.Fragment>
      <div
        ref={props.refer}
        className={`flex flex-col mt-1 z-10  bg-white text-gray-800 tracking-normal text-sm border border-gray-300  font-normal rounded ${
          props.width ? props.width : `w-72`
        }`}
      >
        <div className="my-3">
          {labelInput ? (
            <PopupTitle
              PopupTitle="Label"
              close={props.click}
              back={() => {
                showLabelInput(false);
              }}
            />
          ) : (
            <PopupTitle PopupTitle="Label" close={props.click} />
          )}
        </div>
        {labelInput ? (
          <>
            <div className="my-2 mx-3 relative">
              <TacitbaseInput
                type="text "
                placeholder="Enter label name"
                value={labelName}
                onChange={validateLabelName}
                suffix={<Emoji onEmojiSelect={onEmojiSelect} />}
                validator={(value) => {
                  if (value.length >= 50 || islimitexceed===true) {
                      return 'Maximum 50 characters allowed';
                  }
                }}
              />
            </div>
            <div className="mx-3">
              <div className="color-preview-panel">
                <div className="flex flex-row  flex-wrap h-90 w-full  text-white ">
                  {Object.keys(colorDictionary).map((color: string, idx) => {
                    return (
                      <div key={idx}>
                        <ColorSelection
                          size="7"
                          colorName={colorDictionary[color]}
                          isSet={selectedColorCode === colorDictionary[color]}
                          click={() => setSelectedColor(color)}
                          showMore={false}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="m-3  ">
              <Button
              type="primary"
                className="submit-btn text-sm"
                data-testid="saveLabelTestBtn"
                onClick={updateFlag ? saveLabel : createLabel}
                disabled={isdisable}
                loading={loading}
              >
                <span className="text-xs">
                  {" "}
                  {updateFlag ? "Save" : "Create"}
                </span>
              </Button>
            </div>
          </>
        ) : (
          <LabelInputOfCandidate
            update={(label: any) => updateLabel(label)}
            click={(e: any) => createLabelOfSearch(e)}
            flag={false}
            close={props.click}
          />
        )}
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addLabelToCurrentCandidateInCDB: (data: any, candidateId: string) =>
    dispatch(addLabelToCurrentCandidateInCDB(data, candidateId)),
  saveOpeningLabel: (label: any, openingId: string) =>
    dispatch(saveBoardLabel(label, openingId)),
  updateCandidateLabel: (updatedLabel: any) =>
    dispatch(updateCandidateLabel(updatedLabel)),
});
export default connect(mapStateToProps, mapPropsToState)(AddLabelOnCandidate);
