import React, {  useState } from "react";

import usernamePreviewImg from "../../images/UsernamePreviewAtSetup.png";
import SetupUsername from "../../components/signUp/SetupUsername";
import Image from "../../components/common/image/Image";
import { Grid } from "antd";
import { connect } from "react-redux";

const { useBreakpoint } = Grid;
const Username = () => {
  const [username, setUsername] = useState("");
  const screens = useBreakpoint();

  return (

      <div className="flex  pt-6    w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
        <div className="w-1/12"></div>
        {screens.xl && (
          <div className="w-8/12  2xl:w-7/12  flex  justify-start items-center">
            <div className="relative">
              {" "}
              <Image src={usernamePreviewImg} size="full" />
              <div
                className=" absolute  xl:left-96 xl:mx-4 w-6/12  h-full truncate"
                style={{ left: "28%", top: "69%" }}
              >
                <div
                  className="flex flex-col  items-start justify-start text-xs"
                  style={{ width: "90%" }}
                >
                  <div className="w-full">
                    <div className=" h-auto   rounded w-full flex flex-col  justify-start px-1.5 py-1 truncate">
                      <div className="w-full">
                        {username ? (
                          <>
                            <span className=" text-tacitbase-secondary3 break-words truncate w-1/12 ">
                              @{username}
                            </span>
                            &nbsp;Please review the candidate.
                          </>
                        ) : (
                          <>
                            {" "}
                            <span className=" text-tacitbase-secondary3">
                              @my_name
                            </span>
                            &nbsp;Please review the candidate.
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
          <div
            className="bg-white     xl:p-8 p-4 rounded xl:mt-10 mt-4   "
            style={{
              width: 463,
              boxShadow: "2px 4px 10px 0px #00000040",
              border: "1px solid #D9D9D9",
            }}
          >
            <SetupUsername UsernameForPreview={(value) => setUsername(value)} />
          </div>
        </div>
      <div className="w-1/12"></div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(Username);
