import { Applicant, Attachment, Mail, MailPreviewWithTag, SendMailtag } from "../../type/type"
import { InitCurrentApplicantMailThread } from "./initialState"

export function add_applicant(state: any, action: any) {
  action.data.members =action.data.members?action.data.members: [];
  action.data.labels = action.data.labels ? action.data.labels : [];
  
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants:
          state.boardList[action.data.list_id]?.applicants?.length > 0
            ? [...state.boardList[action.data.list_id].applicants, action.data]
            : [action.data],
      },
    },
    applicantMembers: {
      ...state.applicantMembers,
      [action.data.id]: state.applicantMembers[action.data.id]
        ? [...state.applicantMembers[action.data.id], ...action.data.members]
        : action.data.members,
    },
  };
}

export function init_current_card_mails_preview(state: any, action: any) {
  return {
    ...state,
    currentApplicantMailPreview: {
      [action.candidateId]: action.data,
    },
  };
}
export function add_mail_on_current_card(state: any, action: {
  data: SendMailtag, cardId: string, tag: string
}) {
  const comments: { [key: string]: MailPreviewWithTag } = {}


  const mailActivity = {
    ...action.data,
    ["msg_count"]: 1,
    ["tag"]: action.tag,
    ["message"]: action.data.mails.success[0]
  }
  if (action.data.mails.success && action.data.mails.success.length > 0) {
    comments[action.data.mails.success[0].id] = mailActivity
  }
  
  return {
    ...state,
    comments: {
      ...state.comments,
      [action.cardId]: {
        ...state.comments[action.cardId],
        ...comments
      },
    }
  }
}
export function init_current_card_mail_thread(state: any, action: any) {
  return {
    ...state,
    currentApplicantMailThread: {
      ...state.currentApplicantMailThread,
      [action.mailID]: action.data,
    },
  };
}

export function add_mail_in_current_thread_of_card(
  state: any,
  action: { data: Mail; mailId: string }
) {
  return {
    ...state,
    currentApplicantMailThread: {
      [action.mailId]: {
        ...state.currentApplicantMailThread[action.mailId],
        messages: [...state.currentApplicantMailThread[action.mailId].messages,action.data]
      },
    },
  };
}

export function edit_applicant_name(state: any, action: any) {
  return {
    ...state,
    currentApplicant:
      action.data.id === state.currentApplicant.id
        ? {
            ...state.currentApplicant,
            name: action.data.name,
          }
        : state.currentApplicant,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.map(
          (applicant: any) =>
            applicant.id === action.data.id
              ? {
                  ...applicant,
                  name: action.data.name,
                }
              : applicant
        ),
      },
    },
  };
}
export function init_archived_applicant(state: any, action: any) {
  return {
    ...state,
    archivedApplicants: {
      [action.openingID]: action.data ? action.data : [],
    },
  };
}
export function drag_card(state: any, action: any) {
  var { destination, source } = action.data;
  var movableCard: any[] = [];
  var updatedCard: any[] = [];

  movableCard = state.boardList[source?.droppableId]?.applicants;
  var removeApplicant = movableCard.splice(source.index, 1);

  var draggedDestination =
    destination.droppableId === source.droppableId
      ? source.droppableId
      : destination.droppableId;

  updatedCard = state.boardList[draggedDestination].applicants;
  updatedCard.splice(parseInt(destination.index), 0, ...removeApplicant);

  return {
    ...state,
    boardList: {
      ...state.boardList,
      [source.droppableId]: {
        ...state.boardList[source.droppableId],
        applicants: [...movableCard],
      },
      [draggedDestination]: updatedCard
        ? {
            ...state.boardList[draggedDestination],
            applicants: [...updatedCard],
          }
        : state.boardList[draggedDestination],
    },
    currentApplicant:
      action.data.draggableId === state.currentApplicant.id
        ? {
            ...state.currentApplicant,
            list_id: destination.droppableId,
          }
        : state.currentApplicant,
  };
}

export function copy_applicant_attachments(
  state: any,
  action: {
    data: {
      list_id: string;
      record_id: string;
      attachments: Attachment[];
    };
  }
) {
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.map(
          (applicant: any) =>
            applicant.id === action.data.record_id
              ? {
                  ...applicant,
                  attachment_count: action.data.attachments.length,
                }
              : applicant
        ),
      },
    },
  };
}

export function copy_applicant(state: any, action: any) {
  var arr: any[] = [];
  let copiedlabels: any = {};
  let labelIDs: any[] = [];

  const { applicant } = action.data;
  arr = state.boardList[applicant.list_id].applicants;
  arr.splice(action.index, 0, applicant);
  action.data.member_ids = action.data.member_ids ? action.data.member_ids : [];
  if (action.data.labels) {
    action.data.labels.map((label: any) => {
      copiedlabels[label.id] = label;
      labelIDs.push(label.id);
    });
  }
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [applicant.list_id]: arr
        ? {
            ...state.boardList[applicant.list_id],
            applicants: [...arr],
          }
        : state.boardList[applicant.list_id],
    },
    labels: {
      ...state.labels,
      ...copiedlabels,
    },
    applicantMembers: {
      ...state.applicantMembers,
      [applicant.id]: state.applicantMembers[applicant.id]
        ? [...state.applicantMembers[applicant.id], ...action.data.member_ids]
        : [...action.data.member_ids],
    },
    applicantLabels: {
      ...state.applicantLabels,
      [applicant.id]: state.applicantLabels[applicant.id]
        ? [...state.applicantLabels[applicant.id], ...labelIDs]
        : [...labelIDs],
    },
  };
}
export function init_current_applicant(state: any, action: any) {
  return {
    ...state,
    currentApplicant: action.data,
    showSearch: false,
    attachment: {
      [action.data.id]: [],
    },
  };
}

export function attachments(state: any, action: any) {
  var currentAppAttachments: any = {};
  action.data
    ? (currentAppAttachments[action.applicantID] = action.data)
    : currentAppAttachments;
  return {
    ...state,
    attachment: currentAppAttachments,
  };
}

export function attachments_loader(state: any) {
  return {
    ...state,
    attachmentLoader: !state.attachmentLoader,
  };
}

export function move_applicants_of_list(state: any, action: any) {
  const { src_list, dst_list } = action.data;
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [src_list.id]: {
        ...state.boardList[src_list.id],
        applicants: [],
      },
      [dst_list.id]: {
        ...state.boardList[dst_list.id],
        applicants: [
          ...state.boardList[dst_list.id].applicants,
          ...state.boardList[src_list.id].applicants,
        ],
      },
    },
  };
}
export function move_applicant_another_board(state: any, action: any) {
  var { src_applicant, position } = action.data;
  const applicant = state.boardList[src_applicant.list_id].applicants
    ? state.boardList[src_applicant.list_id].applicants
    : [];
  applicant.splice(parseInt(position), 0, src_applicant);
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [src_applicant.list_id]: {
        ...state.boardList[src_applicant.list_id],
        applicants: [...applicant],
      },
    },
  };
}

export function copy_applicant_another_board(state: any, action: any) {
  var { applicant, position } = action.data;
  const copiedApplicant = state.boardList[applicant.list_id].applicants
    ? state.boardList[applicant.list_id].applicants
    : [];
  copiedApplicant.splice(parseInt(position), 0, applicant);
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [applicant.list_id]: {
        ...state.boardList[applicant.list_id],
        applicants: [...copiedApplicant],
      },
    },
  };
}

export function move_applicant(state: any, action: any) {
  var { from, dst_applicant } = action.data;

  var movableCard: any[] = [];
  var updatedCard: any[] = [];
  var removeIndex = state.boardList[from.list_id].applicants
    .map((item: any) => item.id)
    .indexOf(from.id);
  movableCard = state.boardList[from.list_id].applicants;
  var removeApplicant = movableCard.splice(removeIndex, 1);
  if (from.opening_id === dst_applicant.opening_id) {
    var destinationList =
      from.list_id === dst_applicant.list_id
        ? from.list_id
        : dst_applicant.list_id;
    updatedCard = state.boardList[destinationList].applicants;
    updatedCard.splice(
      parseInt(dst_applicant.position),
      0,
      ...removeApplicant
    );
  }

  return {
    ...state,
    boardList: {
      ...state.boardList,
      [from.list_id]: {
        ...state.boardList[from.list_id],
        applicants: [...movableCard],
      },
      [dst_applicant.list_id]: updatedCard
        ? {
            ...state.boardList[dst_applicant.list_id],
            applicants: [...updatedCard],
          }
        : state.boardList[dst_applicant.list_id],
    },
    currentApplicant: {
      ...state.currentApplicant,
      list_id: dst_applicant.list_id,
    },
  };
}

export function archived_applicants_of_list(state: any, action: any) {
  var archived: Applicant[] = [];
  archived = state.boardList[action.listID].applicants;
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.listID]: {
        ...state.boardList[action.listID],
        applicants: [],
      },
    },
    archivedApplicants: {
      ...state.archivedApplicants,
      [action.openingID]: [
        ...state.archivedApplicants[action.openingID],
        ...archived,
      ],
    },
  };
}
export function update_archive_applicant_status(state: any, action: any) {
  return {
    ...state,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.filter(
          (applicant: any) => applicant.id !== action.data.id
        ),
      },
    },
    currentApplicant:
      action.data.id === state.currentApplicant.id
        ? {
            ...state.currentApplicant,
            archive: true,
          }
        : state.currentApplicant,

    archivedApplicants: {
      ...state.archivedApplicants,
      [action.data.opening_id]: state.archivedApplicants[action.data.opening_id]
        ? [...state.archivedApplicants[action.data.opening_id], action.data]
        : [action.data],
    },
  };
}
export function update_applicant_desc(state: any, action: any) {
  return {
    ...state,
    currentApplicant:
      action.data.id === state.currentApplicant.id
        ? {
            ...state.currentApplicant,
            description: action.data.description,
          }
        : state.currentApplicant,
    boardList: {
      ...state.boardList,
      [action.data.list_id]: {
        ...state.boardList[action.data.list_id],
        applicants: state.boardList[action.data.list_id].applicants.map(
          (applicant: any) =>
            applicant.id === action.data.id
              ? {
                  ...applicant,
                  description: action.data.description,
                }
              : applicant
        ),
      },
    },
  };
}

// export function add_attachments(state: any, action: any) {
//   return {
//     ...state,
//     attachment: [...state.attachment, ...action.data.attachment],
//   };
// }
export function add_attachments(state: any, action: any) {
  // var attchments_count = 0;

  return {
    ...state,
    attachment: {
      ...state.attachment,
      [action.data.recordID]: state.attachment[action.data.recordID]
        ? [...state.attachment[action.data.recordID], ...action.data.attachment]
        : [action.data.attachment],
    },
    boardList: {
      ...state.boardList,
      [action.listID]: {
        ...state.boardList[action.listID],
        applicants: state.boardList[action.listID].applicants?.map(
          (applicant: any) =>
            applicant.id === action.data.recordID
              ? {
                  ...applicant,
                  attachment_count: applicant.attachment_count + 1,
                }
              : applicant
        ),
      },
    },
  };
}

export function remove_attachemnt(state: any, action: any) {
  return {
    ...state,
    attachment: {
      ...state.attachment,
      [action.attachment.recordID]: state.attachment[
        action.attachment.recordID
      ].filter((a: any) => a.id != action.attachment.id),
    },
    boardList: {
      ...state.boardList,
      [action.listID]: {
        ...state.boardList[action.listID],
        applicants: state.boardList[action.listID].applicants.map(
          (applicant: any) =>
            applicant.id === action.attachment.recordID
              ? {
                  ...applicant,
                  attachment_count: applicant.attachment_count - 1,
                }
              : applicant
        ),
      },
    },
  };
}

export function update_attachment(state: any, action: any) {
  return {
    ...state,
    attachment: {
      ...state.attachment,
      [action.attachment.recordID]: state.attachment[
        action.attachment.recordID
      ].map((record: any) =>
        record.id === action.attachment.id
          ? {
              ...record,
              name: action.attachment.name,
            }
          : record
      ),
    },
  };
}
export function clear_card_data(state: any) {
  return {
    ...state,
    comments: {},
    attachment: {},
    activity: [],
    currentApplicantRewards: {},
    currentApplicant: {},
    currentApplicantMailThread:InitCurrentApplicantMailThread
  };
}

export function send_back_archived_item(state: any, action: any) {
  action.data.labels = state.applicantLabels[action.data.id]
    ? state.applicantLabels[action.data.id]
    : [];
  action.data.members = state.applicantMembers[action.data.id]
    ? state.applicantMembers[action.data.id]
    : [];

  return {
    ...state,
    currentApplicant:
      state.currentApplicant.id === action.data.id
        ? {
            ...state.currentApplicant,
            archive: false,
          }
        : state.currentApplicant,
    archivedApplicants: {
      ...state.archivedApplicants,
      [action.data.opening_id]: state.archivedApplicants[
        action.data.opening_id
      ].filter((card: any) => card.id !== action.data.id),
    },
    boardList: state.boardList[action.data.list_id]
      ? {
          ...state.boardList,
          [action.data.list_id]: {
            ...state.boardList[action.data.list_id],
            applicants: [
              ...state.boardList[action.data.list_id].applicants,
              action.data,
            ],
          },
        }
      : state.boardList,
  };
}
