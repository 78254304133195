import React, { useEffect, useState } from "react";
import { Button, Col, Grid, Image, Row } from "antd";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import {
  CheckOutlined,
  // ClockCircleOutlined,
  CloseOutlined,
  LoadingOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { getAnalyticsListLabel, getCompanies, getOrgDetails, getStages } from "../../lib/api"
import {
  // changeLoaderStatus,
  current_org,
  initAnalyticsLabel,
  tokenExchange,
  update_current_org_creation_stages,
} from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import OrgNamePreview from "../../images/OrgNamePreview.png";
import { newOrganisation, Role, SetupStage } from "../../lib/apiRes"
import { useHistory } from "react-router-dom";
import { loginUser } from "../../type/type";
import { orgConfigurationMiddleware } from "../../service/orgConfigurationMiddleware";

const { useBreakpoint } = Grid;

// Props interface for OrgCreationStatus component
interface OrgCreationStatusProps {
  currentOrg: (orgData: any) => void;
  initCurrentOrg: newOrganisation;
  initAnalyticsLabel: (label: any) => void,
  updateOrgCreationStages: (orgData: any) => void;
  loginUser: loginUser;
  exchangeToken(
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    applicationRoles: Role[]
  ): void;
}

const OrgCreationStatus: React.FC<OrgCreationStatusProps> = ({
  currentOrg,
  initCurrentOrg,initAnalyticsLabel,
  updateOrgCreationStages,
  loginUser,
  // changeInLoaderStatus,
}) => {
  const screens = useBreakpoint();
  const history = useHistory();

  // State to store setup stages
  const [setupStages, setSetupStages] = useState<SetupStage[] | undefined>();
  const [nextButtonLoading, setNextButtonLoading] = useState<boolean>(false);
  const [startSpin, setStartSpin] = useState(false);

  // this useEffect will check if there is any workspace available or not
  useEffect(() => {
    // Fetch stages when component mounts
    let orgId = localStorage.getItem("orgId");
    orgId &&
      getStages(orgId).then((newStages) => {
        updateOrgCreationStages(newStages);
      });
  }, []);
  useEffect(() => {
    setSetupStages(initCurrentOrg.setup_stages);
  }, [initCurrentOrg.setup_stages]);

  const navigateUser = () => {
    setNextButtonLoading(true);
    getOrgDetails(initCurrentOrg.id)
    .then((appRes) => {
      currentOrg(appRes);
    orgConfigurationMiddleware(initCurrentOrg, loginUser).then((flag) => {
      if (flag) {
        getCompanies().then((res) => {
          setNextButtonLoading(false)
          if (res.length > 0) {
            history.push(`/o/${initCurrentOrg.id}/ats`)
          }
          else {
            sessionStorage.removeItem("orgCreated")
            sessionStorage.removeItem("slackCode")
            getAnalyticsListLabel()
              .then(analyticsLabels => {
                initAnalyticsLabel(analyticsLabels)
                setNextButtonLoading(false)
              })
              .catch(error => {
                setNextButtonLoading(false)
                console.error("Error fetching analytics labels:", error)
              })
            history.push({
              pathname: `/o/workspace`,
            })
          }
        })

      }

    });
  }).catch((error)=>{
    console.log(error)
    setNextButtonLoading(false)
  })
  };

  return (

      <div className="flex pt-16 w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
        <div className="w-1/12"></div>
        {screens.xl && (
          <div className="w-8/12  2xl:w-7/12  flex  justify-end items-center ">
            <div className="relative top-0 ">
              <Image src={OrgNamePreview} preview={false} />
              <div
                className="flex w-full items-center justify-start space-x-2 absolute "
                style={{ top: "10%", left: "61.8%" }}
              >
                <div className="w-1/4" style={{ width: "10%" }}>
                  <div className="truncate font-semibold text-white">
                    {initCurrentOrg.name && initCurrentOrg.name}
                  </div>
                </div>{" "}
              </div>
              <div
                className="flex w-full items-center justify-start space-x-2 absolute "
                style={{ top: "53.5%", left: "51%" }}
              >
                <div className="w-1/4" style={{ width: "22%" }}>
                  <div className="truncate font-semibold">
                    {initCurrentOrg.name && initCurrentOrg.name}
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        )}
          <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
            <div
              className="bg-white xl:p-8 p-4 rounded xl:mt-10 mt-4 "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <div className="flex flex-col space-y-6">
                <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
                  {capitalizeFirstLetter("Organisation setup")}
                </div>
                <div className="flex flex-col space-y-4 lg:text-sm text-xs">
                  <div> Welcome to Tacitbase,</div>
                  <div>
                    Time to power up your hiring game! Let&apos;s team up and
                    discover top talent effortlessly!
                  </div>
                  <div>
                    Your organization setup is on the way, stay relax. If you
                    find any difficulty please contact{" "}
                    <a
                      href="https://support.tacitbase.com/"
                      style={{ color: "#009D79" }}
                    >
                      support.
                    </a>{" "}
                  </div>
                </div>
                <div className="flex flex-col space-y-4 lg:text-sm text-xs">
                  <div className="flex flex-col space-y-2">
                    {setupStages?.map((currentStage, index) => (
                      <Row
                        gutter={[16, 16]}
                        className="flex items-center"
                        key={index}
                      >
                        <Col span={2} className="flex justify-end">
                          {currentStage.stage === "failed" ? (
                            <CloseOutlined style={{ color: "#ff0000" }} />
                          ) : currentStage.stage === "doing" ? (
                            <LoadingOutlined style={{ color: "#4B48A5" }} />
                          ) : currentStage.stage === "done" ? (
                            <CheckOutlined style={{ color: "#008000" }} />
                          ) : (
                            <LoadingOutlined style={{ color: "#4B48A5" }} />
                          )}
                        </Col>
                        <Col
                          span={22}
                          className="p-2 bg-gray-100 border-b rounded flex items-center justify-between text-tacitbase-secondary3"
                        >
                          {currentStage.name}

                          {currentStage.stage === "failed" ? (
                            currentStage.name == "Setting up subscription" ? (
                              <div className="">
                                {" "}
                                <ReloadOutlined
                                  style={{ color: "#4B48A5" }}
                                  spin={startSpin}
                                  onClick={() => (
                                    setStartSpin(true),
                                    setTimeout(() => {
                                      history.push({
                                        pathname: `/o/choose-plan`,
                                      }),
                                        setStartSpin(false);
                                    }, 500)
                                  )}
                                />
                              </div>
                            ) : null
                          ) : null}
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <div className="w-full flex justify-end">
                      <Button
                        type="primary"
                        className="w-max text-right"
                        onClick={() => navigateUser()}
                        loading = {nextButtonLoading}
                        disabled={
                          !setupStages?.every(
                            (currentsStage) => currentsStage.stage === "done"
                          )
                        }
                      >
                        Next
                      </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
      <div className="w-1/12"></div>
    </div>
  );
};

// Map state to props
const mapStateToProps = (state: any) => ({
  initCurrentOrg: state.opening.currentOrg,
  loginUser: state.opening.loginUser,
});

// Map dispatch to props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  // changeInLoaderStatus: (payload: {
  //   loader: boolean;
  //   message: string;
  //   skeleton: boolean;
  // }) => dispatch(changeLoaderStatus(payload)),
  initAnalyticsLabel: (label: any) => dispatch(initAnalyticsLabel(label)),

  updateOrgCreationStages: (orgData: any) =>
    dispatch(update_current_org_creation_stages(orgData)),
  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  exchangeToken: (
    token: any,
    applicant_id: string,
    applicantion_name: string,
    application_desc: string,
    application_imgURL: string,
    applicationRoles: Role[]
  ) =>
    dispatch(
      tokenExchange(
        token,
        applicant_id,
        applicantion_name,
        application_desc,
        application_imgURL,
        applicationRoles
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCreationStatus);
