import React, { useRef } from "react";
import { Input, Button, Form, Divider } from "antd";

type EditProfilePopupProps = {
  click: (e: any, flag: boolean) => void;
  attachment: any;
  refer?: any;
  updatedAttchmentName(attachment: string): void;
  updateAttachmentClick: (e: any) => void;
};

const EditAttachmentPopup = (props: EditProfilePopupProps) => {
  const attachmentRef = useRef<any>(null);

  const validateCommentLength = () => {
    if (attachmentRef.current) {
      const currentValue = attachmentRef.current.input?.value || "";
      props.updatedAttchmentName(currentValue);
    }
  };

  return (
    <div
      className="flex flex-col h-auto z-10 w-52 rounded"
      ref={props.refer}
      role="none"
      onClick={(e: any) => e.stopPropagation()}
      onKeyDown={(e: any) => e.stopPropagation()}
    >
      <div className="flex">
        <div className="flex w-full items-center font-medium">
          Edit attachment
        </div>
      </div>
     <Divider className="my-2"/>
      <Form
        layout="vertical"
        className="mt-1"
        onFinish={props.updateAttachmentClick}
      >
        <Form.Item
          label="Attach file name :"
          name="attachmentName"
          initialValue={props.attachment.name}
        >
          <Input
            className="w-full rounded"
            placeholder="Enter label name"
            ref={attachmentRef}
            data-testid="input"
            onClick={(e: any) => e.stopPropagation()}
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                props.updateAttachmentClick(e);
              }
            }}
            onChange={validateCommentLength}
          />
        </Form.Item>
        <Form.Item>
        <Button
          type="primary"
          className="mt-1 w-full"
          onClick={props.updateAttachmentClick}
        >
          Update
        </Button>
        </Form.Item>
        </Form>
    </div>
  );
};

export default EditAttachmentPopup;
