import React, { useEffect, useRef, useState } from "react";
import {
  Attachment,
  loginUser,
  Mail,
  Member,
  orgMember,
  Scope,
} from "../../type/type.d";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
// import parse from "html-react-parser";
import { TrixEditor } from "react-trix";
import DeleteIcon from "../../assets/DeleteIcon";
import ShowMeberInInput from "../subNavbar/addBoardMember/ShowMeberInInput";
import { connect } from "react-redux";
import SearchedResultPopup from "../subNavbar/addBoardMember/SearchedResultPopup";

// import { sendMail } from "../../lib/api";
// import { sendMailReq } from "../../lib/apiReq";
import MailThread from "./MailThread";
// import mailSend from "../mock/mailSend.json"
import { toast } from "react-toastify";
import MailNotification from "./MailNotification";
import { createFormData } from "../../utils/createFormData";
import { addAttachmentToMail } from "../../lib/api";
import { AxiosError } from "axios";
import AttachmentIcon from "../../assets/Attachment";
import UploadedFile from "../common/composeMail/ShowProgress";
import { Overlay } from "../hook/Overlay";
import { sendMailReq } from "../../lib/apiReq"

type MailProps = {
  mail: Mail;
  callApi?: (e: any) => void;
  service: string;
  members: orgMember;
  entity: string;
  selectMailId: string;
  clientId: string;
  record_id: string;
  isCardThread?: boolean;
  source: { id: string; name: string; email: string };
  loginUser: loginUser;
};
const DetailMail = (props: MailProps) => {
  // const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const [reply, setReply] = useState("");
  const bccinputRef = useRef<HTMLInputElement>(null);
  const ccinputRef = useRef<HTMLInputElement>(null);
  const [ccMembers, setCcMember] = useState<string[]>([]);
  const [bccMembers, setBccMember] = useState<string[]>([]);
  const [searchedCcMember, getSearchedCcMember] = useState("");
  const [searchedBccMember, getSearchedBccMember] = useState("");
  const [isExpandThread, expandThread] = useState<boolean>(false);
  const [addCC, setCC] = useState<boolean>(false);
  const [addBcc, setBcc] = useState<boolean>(false);
  const [mailBody, setMailBody] = useState<string>("");
  const [shouldCallApi, setshouldCallApi] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const refer=useRef(null);

  let fileInput = useRef<HTMLInputElement>(null);
  const [uploadedAttchments, setUploadedAttachments] = useState<Attachment[]>(
    []
  );

  const paragraphs = `<p>On ${props.mail.created_at}, ${props.mail.from} wrote:</p>
    <blockquote>${props.mail.body}</blockquote>`;

  let orgMembers = Object.values(props.members)
    .filter((m: Member) => m.email != props.loginUser.user.email)
    .filter(
      (member: Member) =>
        member.registrations[0].data.activated &&
        (searchedCcMember.trim().length > 0 ||
          searchedBccMember.trim().length > 0) &&
        (member.first_name
          .toUpperCase()
          .includes(
            searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
          ) ||
          member.last_name
            .toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ) ||
          member.email
            .toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ) ||
          member.username
            .toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ))
    );
  const removeMemberFromSelectedCc = (index: number) => {
    setCcMember((a: string[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };
  const discardMail = () => {
    setReply("");
    setCC(false);
    setBcc(false);
    setCcMember([]);
    setBccMember([]);
  };
  const removeMemberFromSelectedBcc = (index: number) => {
    setBccMember((a: string[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };
  const searchKeywordInCc = (e: any) => {
    getSearchedCcMember(e.target.value);
  };
  const searchKeywordInBcc = (e: any) => {
    getSearchedBccMember(e.target.value);
  };
  const handleInputChangeOfCc = (member: Member) => {
    var updatedList = [...ccMembers];
    if (!ccMembers.includes(member.id)) {
      updatedList = [...ccMembers, member.id];
    }
    setCcMember(updatedList);
  };
  const handleInputChangeOfBcc = (member: Member) => {
    var updatedList = [...bccMembers];
    if (!bccMembers.includes(member.id)) {
      updatedList = [...bccMembers, member.id];
    }
    setBccMember(updatedList);
  };
  useEffect(() => {
    if (bccinputRef.current) {
      bccinputRef.current.value = "";
      bccinputRef.current.focus();
    }
    getSearchedBccMember("");
  }, [bccMembers]);
  useEffect(() => {
    if (ccinputRef.current) {
      ccinputRef.current.value = "";
      ccinputRef.current.focus();
    }
    getSearchedCcMember("");
  }, [ccMembers]);
  const send = () => {
    return new Promise<boolean>((resolve) => {
      toast.warn(
        <MailNotification
          undo={() => {
            toast.dismiss(), resolve(false);
          }}
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "customId",
          autoClose: false,
        }
      );
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  };
  const sentMailAnyway = async () => {
    var editorElement: any = document.querySelector("trix-editor");
    if (editorElement) editorElement.setAttribute("disabled", true);
    setshouldCallApi(true);
    let callApi = await send();
    if (callApi) {
      let ccMembersMail = ccMembers.map(
        (id: string) => props.members[id].email
      );
      let bccMembersMail = bccMembers.map(
        (id: string) => props.members[id].email
      );
      let attachments: { objectKey: string; name: string }[] =
        uploadedAttchments.map((attachment: Attachment) => {
          return { objectKey: attachment.objectKey, name: attachment.name };
        });
      let reqObb: sendMailReq = {
        service: props.service,
        entity: props.entity,
        input: {
          keys: [],
          records: [{
            id: props.source.id,
            address: [props.source.email],
          }],
        },
        message: {
          id: props.mail.id,
          cc: [...ccMembersMail],
          bcc: [...bccMembersMail],
          to: [props.source.email],
          subject: props.mail.subject,
          body: mailBody,
          attachments: attachments,
        },
      };
      toast.update("customId", {
        render: () => <div className="text-sm"> Sending mail</div>,
        type: toast.TYPE.INFO,
        autoClose: false,
      });

      if (props.callApi) props.callApi(reqObb);
      setReply("");
    } else {
      setshouldCallApi(false);
      if (editorElement) editorElement.removeAttribute("disabled");
    }
  };

  const handleChange = (html: string) => {
    setMailBody(html);
  };
  useEffect(() => {
    if (reply.trim().length > 0) {
      const element: any = document.getElementsByClassName(
        "trix-button-group trix-button-group--file-tools"
      );
      if (element) element[0].remove();
    }
    return () => {};
  }, [reply]);
  function uploadFile(file: any) {
    const files: any = [];
    const formData = createFormData(file, props.entity);
    formData.append("socket_client_id", props.clientId);
    for (let i = 0; i < file.target.files.length; i++) {
      formData.append(`file`, file.target.files[i]);
      const fileData = {
        name: file.target.files[i].name,
        progress: 0,
      };
      files.push(fileData);
    }
    setUploadedFiles(uploadedFiles.concat(files));
    const onUploadProgress = (progressEvent: any) => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      const updatedFiles = [...files];
      updatedFiles.forEach((fileData) => {
        fileData.progress = progress;
      });
      setUploadedFiles(uploadedFiles.concat(files));
    };

    addAttachmentToMail(formData, onUploadProgress)
      .then((res: any) => {
        setUploadedAttachments([...uploadedAttchments, ...res]);
      })
      .catch((reason: AxiosError) => {
        toast.error(reason.response?.data.error.type, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  useEffect(() => {
    if (reply.trim().length > 0) {
      const emailThread: any = document.getElementById(reply);

      if (emailThread) {
        if (emailThread.getBoundingClientRect().bottom > window.innerHeight) {
          // Check if the email thread is not in view
          // Scroll the email thread to the bottom
          const messageBottom =
            emailThread.offsetTop + emailThread.offsetHeight;
          const isScrolledToBottom =
            messageBottom - document.documentElement.scrollTop ===
            window.innerHeight;
          if (!isScrolledToBottom) {
            emailThread.scrollIntoView({ behavior: "auto" });
          }
        }
      }
    }
  }, [reply]);
  const clickOnSetReply = (id: string) => {
    props.mail.initiator.trim().length > 0 &&
    props.mail.initiator !== props.loginUser.user.id
      ? ccMembers.push(props.mail.initiator)
      : null;
    setReply(id);
   
    let memberId: string
    
    if (props.mail.cc.length > 0) {
      props.mail.cc.map((c: string) => {
        if (Object.keys(props.members).includes(c))
          ccMembers.push(props.members[c].email);
        else {
          memberId =
            Object.values(props.members).find(
              (member: Member) => {if (member.email === c)
              return member.id}
            )?.id || memberId;
          if (memberId !== props.loginUser.user.id) {
            if (!ccMembers.includes(memberId))
              ccMembers.push(memberId);
          }
        }
      });
    }
  };

  return (
    <>
      <MailThread
        isCardThread={props.isCardThread ? true : false}
        mail={props.mail}
        setMailId={(id: string) => clickOnSetReply(id)}
      />
      <div>
        {reply.trim().length > 0 && reply === props.mail.id && (
          <div className="px-3 " id={reply}>
            <div className="animate-popover_fadeIn  border border-tacitbase-tertiary1 border-opacity-20 rounded mb-2 shadow  max-h-full overflow-y-scroll w-full">
              <div
                className={`${
                  shouldCallApi ? "bg-gray-100" : "bg-white"
                } overflow-y-scroll h-full w-full`}
              >
                <div className="flex flex-col h-full">
                  <div className=" p-2 bg-opacity-20 inline-block space-x-1 text-xs ">
                    <i className="fa fa-reply text-xs" aria-hidden="true"></i>
                    <span>Reply</span>
                  </div>
                  <div className="flex space-x-2 px-2 mb-1 text-xs items-center">
                    <div className="w-6 flex justify-end">To:</div>
                    <span className="text-xs w-auto flex bg-color-LightGray p-1 border space-x-1  border-opacity-25 border-tacitbase-tertiary1 rounded">
                      {" "}
                      <div>{props.source.name}</div>
                      <div className="">{`(${props.source.email})`}</div>
                    </span>
                  </div>

                  {addBcc && addCC ? null : (
                    <div className="flex ml-8 px-2 mb-1 items-start space-x-1 text-xs justify-start">
                      <div>Add</div>
                      {!addCC && ccMembers.length == 0 && (
                        <button
                          onClick={() => setCC(!addCC)}
                          className="underline"
                        >
                          cc
                        </button>
                      )}
                      {!addBcc && (
                        <button
                          onClick={() => setBcc(!addBcc)}
                          className="underline"
                        >
                          bcc
                        </button>
                      )}
                    </div>
                  )}
                  <div className="flex flex-col space-y-2 ">
                    {addCC || ccMembers.length > 0 ? (
                      <div className="w-full mt-1 ">
                        <div className="flex space-x-2 px-2 text-xs ">
                          <div className="w-6 flex justify-end">Cc:</div>
                          <div
                            className={`rounded inputCSSOnlyForFocus ${
                              ccinputRef.current
                                ? "border border-tacitbase-secondary3"
                                : "border border-tacitbase-tertiary1 border-opacity-25"
                            } flex flex-wrap  items-center w-full `}
                          >
                            <div
                              className={`${
                                ccMembers.length == 0 ? "w-full" : ""
                              } flex px-2 pb-1 flex-wrap `}
                            >
                              {ccMembers
                                ? ccMembers.map(
                                    (memberID: string, index: number) =>
                                      memberID && (
                                        <ShowMeberInInput
                                          key={index}
                                          index={index}
                                          memberId={memberID}
                                          removeMemberFromSelectedList={(
                                            idx: number
                                          ) => removeMemberFromSelectedCc(idx)}
                                        />
                                      )
                                  )
                                : null}
                              <div className="w-96 relative">
                                <input
                                  type="text"
                                  className={`${
                                    ccMembers.length == 0 ? "w-full" : ""
                                  } placeholder-italic outline-none text-sm mt-1`}
                                  onChange={(e: any) => searchKeywordInCc(e)}
                                  ref={ccinputRef}
                                  disabled={shouldCallApi ? true : false}
                                  onBlur={() => ccinputRef.current?.blur()}
                                />
                                <div>
                                  {searchedCcMember.trim() ? (
                                     <Overlay onClose={() => getSearchedCcMember("")
                                    } scope={Scope.Popover} reference={refer} >
                                    <SearchedResultPopup
                                    refer={refer}
                                      click={(member: Member) =>
                                        handleInputChangeOfCc(member)
                                      }
                                      selectedMember={ccMembers}
                                      searchResult={orgMembers}
                                      searchInObject={{}}
                                      searchInObjectKey={""}
                                    /></Overlay>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {addBcc && (
                      <div className="w-full ">
                        <div className="flex space-x-2 px-2 text-xs ">
                          <div className="w-6 flex justify-end">Bcc:</div>
                          <div
                            className={`rounded inputCSSOnlyForFocus ${
                              bccinputRef.current
                                ? "border border-tacitbase-secondary3"
                                : "border border-tacitbase-tertiary1 border-opacity-25"
                            } flex flex-wrap  items-center w-full `}
                          >
                            <div
                              className={`${
                                bccMembers.length == 0 ? "w-full" : ""
                              } flex px-2 pb-1 flex-wrap `}
                            >
                              {bccMembers
                                ? bccMembers.map(
                                    (memberID: string, index: number) =>
                                      memberID && (
                                        <ShowMeberInInput
                                          key={index}
                                          index={index}
                                          memberId={memberID}
                                          removeMemberFromSelectedList={(
                                            idx: number
                                          ) => removeMemberFromSelectedBcc(idx)}
                                        />
                                      )
                                  )
                                : null}
                              <div className="w-96 relative">
                                <input
                                  type="text"
                                  className={`${
                                    bccMembers.length == 0 ? "w-full" : ""
                                  } placeholder-italic outline-none text-sm mt-1`}
                                  onChange={(e: any) => searchKeywordInBcc(e)}
                                  ref={bccinputRef}
                                  disabled={shouldCallApi ? true : false}
                                  onBlur={() => bccinputRef.current?.blur()}
                                />
                                <div>
                                  {searchedBccMember.trim() ? (
                                   <Overlay onClose={() => getSearchedBccMember("")
                                  } scope={Scope.Popover} reference={refer} >
                                  <SearchedResultPopup
                                  refer={refer}
                                      click={(member: Member) =>
                                        handleInputChangeOfBcc(member)
                                      }
                                      selectedMember={bccMembers}
                                      searchResult={orgMembers}
                                      searchInObject={{}}
                                      searchInObjectKey={""}
                                    /></Overlay>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="h-full w-full  overflow-y-scroll ">
                    <div className="p-2">
                      <TrixEditor
                        onChange={handleChange}
                        mergeTags={[]}
                        value={mailBody}
                        className={`w-full h-full overflow-y-scroll max-h-143 text-sm trix-content resize-none rounded ${
                          shouldCallApi ? "bg-gray-100" : "bg-white"
                        } p-2  placeholder-gray-600`}
                      />

                      <div className="text-xs pt-2">
                        <div className="flex items-center">
                          <button
                            className=""
                            onClick={() => expandThread(!isExpandThread)}
                          >
                            <svg
                              className="h-3 bg-gray-200 rounded px-0.5 fill-current cursor-pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M5 10a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4z" />
                            </svg>
                          </button>
                        </div>
                        {isExpandThread && paragraphs}
                      </div>
                    </div>
                    <div className="flex w-100 flex-col space-y-1 px-2">
                      {uploadedFiles.map((file: any, index) => (
                        <UploadedFile
                          key={index}
                          name={file.name}
                          progress={file.progress}
                          onClose={() => {
                            const files = [...uploadedFiles];
                            files.splice(index, 1);
                            setUploadedFiles(files);
                          }}
                        />
                      ))}
                    </div>

                    <div className="justify-between w-full flex p-1">
                      <div className="flex items-center space-x-1">
                        <button
                          className="submit-btn text-xs"
                          onClick={sentMailAnyway}
                          disabled={shouldCallApi ? true : false}
                        >
                          {capitalizeFirstLetter("send")}
                        </button>
                        <div>
                          <input
                            type="file"
                            ref={fileInput}
                            style={{ display: "none" }}
                            onChange={(file: any) => uploadFile(file)}
                            multiple
                          />
                          <button
                            className="items-center cursor-pointer focus:outline-none border-none"
                            onClick={() => fileInput.current?.click()}
                            data-testid="add-attachment"
                          >
                            <AttachmentIcon />
                          </button>
                        </div>
                      </div>
                      <button onClick={discardMail}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div></div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
  loginUser: state.opening.loginUser,

});

export default connect(mapStateToProps)(DetailMail);
