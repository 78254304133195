// import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import "react-phone-input-2/lib/style.css";
import TickIcon from "../../assets/Tick";
import { orgtokenVerify, tokenVerify } from "../../service/tokenVerify";
import { Link } from "react-router-dom";
import Tacitbase from "../../images/Tacitbase_WT_7.png";
import { VERIFICATION_TITLE } from "../../constant";
import { loginUser } from "../../type/type";

import MobileVerification from "./MobileVerification";

type RegistrationProps = {
  loginUserAc(token: any, userData: any): void;
  loginUser: loginUser;
  email: string;
  back: any;
};
const RegistrationForm2 = (props: RegistrationProps) => {
  const [showLoader, setShowLoader] = useState(0);
  const history = useHistory();
  useEffect(() => {
    document.title = `${VERIFICATION_TITLE}`;

    if (tokenVerify()) {
      if (orgtokenVerify()) {
        if (props.loginUser.user.verified) {
          history.push("/o");
        }
      } else if (
        props.loginUser.user["data"] !== undefined &&
        props.loginUser.user.data.verifiedPhone
      ) {
        console.log("", setShowLoader);

        // setTimeout(() => setShowLoader(1), 2000);
        // setTimeout(() => {
        //   history.push("/registration/avatar");
        // }, 4000);
      }
    }
    return () => {};
  }, [props.loginUser]);
  return (
    <React.Fragment>
      <div className="bg-color-LightGray">
        <div className="flex flex-row py-1.5 justify-between items-center bg-black bg-opacity-50 border-b">
          <div className="flex justify-center items-center">
            <img
              src={Tacitbase}
              alt=""
              width="36px"
              height="36px"
              className={` mx-2 items-center  justify-center flex p-0.5  rounded focus:outline-none `}
            />
            <div className=" font-semibold font-jakarta text-white  break-words  text-2xl leading-4">
              Tacitbase
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row divide-x py-10 2xl:py-16 justify-center ">
        <form className="flex flex-col w-1/2 items-center">
          <div className="w-10/12 ">
            <h1 className="text-4xl  tracking-wider font-normal">
              VERIFY YOUR ACCOUNT{" "}
            </h1>

            <div className="flex flex-col shadow-6xl mt-10">
              <div className="flex flex-col">
                {" "}
                <div className="border-2 border-gray-300 p-4   tracking-wide font-normal flex flex-col">
                  <h1 className="text-xl">Verify Email</h1>
                  <div className="mt-2 ">
                    {" "}
                    <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                      <button className="focus:outline-none">
                        <TickIcon fill="green" />
                      </button>
                      <h1>
                        Your email
                        <span className="font-semibold">
                          {" "}
                          {props.loginUser.user.email}
                        </span>{" "}
                        has been verified.
                      </h1>{" "}
                    </div>
                  </div>
                  {!tokenVerify() ? (
                    <div className="flex items-center space-x-8  p-6">
                      <div className="text-sm font-light tracking-wide ">
                        {/* <button
                            className="text-blue-700 font-normal cursor-pointer focus:outline-none"
                            onClick={() => props.history.push("/login")}
                          ></button> */}

                        <Link
                          className="focus:outline-none text-sm border-none   font-light cursor-pointer"
                          to={`/login`}
                        >
                          &nbsp;{" "}
                          <span className="text-blue-700 font-semibold">
                            Go back to login
                          </span>
                        </Link>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {props.loginUser.user.data.verifiedPhone ? (
              <div className="flex flex-col shadow-6xl mt-10">
                <div className="flex flex-col">
                  {" "}
                  <div className="border-2 border-gray-300 p-4   tracking-wide font-normal flex flex-col">
                    <h1 className="text-xl">Verify Phone</h1>
                    <div className="mt-2 ">
                      {" "}
                      <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                        <button className="focus:outline-none">
                          <TickIcon fill="green" />
                        </button>
                        <h1>
                          Your mobile
                          <span className="font-semibold">
                            {" "}
                            {props.loginUser.user.mobilePhone}
                          </span>{" "}
                          has been verified.
                        </h1>{" "}
                      </div>
                    </div>
                    {!tokenVerify() ? (
                      <div className="flex items-center space-x-8  p-6">
                        <div className="text-sm font-light tracking-wide ">
                          {/* <button
                            className="text-blue-700 font-normal cursor-pointer focus:outline-none"
                            onClick={() => props.history.push("/login")}
                          ></button> */}

                          <Link
                            className="focus:outline-none text-sm border-none   font-light cursor-pointer"
                            to={`/login`}
                          >
                            &nbsp;{" "}
                            <span className="text-blue-700 font-semibold">
                              Go back to login
                            </span>
                          </Link>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <MobileVerification />
            )}

            {showLoader == 1 ? (
              <div className="justify-center bg-black bg-opacity-50 items-center flex   fixed inset-0  outline-none focus:outline-none">
                <div className="flex items-center  rounded relative w-5/12 justify-center overflow-y-scroll z-20">
                  <div className="bg-gray-100 w-full  mt-28  h-40 text-2xl font-semibold   rounded  flex items-center justify-evenly">
                    Redirecting, Please wait...
                    <div className="flex justify-end items-end">
                      <div
                        style={{ borderTopColor: "#3B82F6" }}
                        className="w-28 h-28 border-4 border-gray-300  border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(RegistrationForm2);
