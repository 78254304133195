import React from "react";
import CloseIcon from "../../../assets/Close";
import TickIcon from "../../../assets/Tick";
type getIconProps = {
  status: string;
};
// Function to determine the appropriate icon based on conditions
export const GetIcon = (props: getIconProps) => {
  if (props.status == "success") {
    return (
      <div
        data-testid="AcceptIcon"
        className=" rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-secondary5  border border-tacitbase-secondary5 shadow-sm"
      >
        <TickIcon size="8" fill="#ffff" />
      </div>
    );
  } else if (props.status == "pending") {
    return (
      <div
        data-testid="TentativeIcon"
        className=" rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-secondary4 text-xs border border-tacitbase-secondary4 border-opacity-25 text-white"
        style={{ fontSize: "8px" }}
      >
        !
      </div>
    );
  } else if (props.status == "failed") {
    return (
      <div
        data-testid="DeclinedIcon"
        className=" rounded top-4 h-3.5 w-3.5 flex items-center justify-center left-4 bg-tacitbase-primary2 border border-tacitbase-primary2  border-opacity-80  text-white"
      >
        <CloseIcon size="8" fill="#ffff" />
      </div>
    );
  }

  return <></>;
};
