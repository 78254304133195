import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Integration,
  loginUser,
  Member,
  orgMember,
  Registration,
} from "../../type/type";
import { OWNER } from "../../constant";
import { Table, Tag, Tooltip, Typography } from "antd";
import MemberActionButtons from "./MemberActionButtons";
import TacitbaseAvtar from "../../ant/Avtar";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { useHistory } from "react-router-dom";
const { Text } = Typography;
type GuestMembersProps = {
  activeTab:string;
  loginUser: loginUser;
  members: orgMember;
  applicationId: string;
  integratedSlackMember: { [key: string]: Integration[] };
  loading: boolean;
  getLoading:(value: boolean)=>void;
};

const GuestMembers = (props: GuestMembersProps) => {
  const history = useHistory();
  const [memberData, setMemberData] = useState<Member[]>([]);

  useEffect(()=>{
    let userType:string;
    if(props.activeTab === "guest"){
      userType = "Guest";
    }else if(props.activeTab === "agency"){
      userType = "Agency"
    }
    const members = Object.values(props.members).filter(
      (member) =>
        member.registrations[0]?.roles[0] === userType && member.registrations[0]?.data?.activated === true
    ); 
    setMemberData(members);
  },[props.activeTab,props.members])

  // thes are columns of member table
  const columns: any = [
    {
      title: "",
      dataIndex: "image_url",
      width: 50,
      key: "image_url",
      fixed: "left",
      render: (_: any, record: Member) => (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            history.push(
              `/o/${props.applicationId}/settings/members?id=${record.id}`
            );
          }}
        >
          {record.image_url ? (
            <TacitbaseAvtar size="large" src={record.image_url} />
          ) : (
            <TacitbaseAvtar content={record.first_name?record.first_name[0]:record.email[0]} size="large" />
          )}
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (_: any, record: Member) => (
        <div
          className="flex flex-col cursor-pointer"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            history.push(
              `/o/${props.applicationId}/settings/members?id=${record.id}`
            );
          }}
        >
          <Text
            ellipsis
            style={{
              margin: 0,
              minWidth: 120,
              maxWidth: 120,
            }}
          >
            {`${record.first_name} ${record.last_name}`}
          </Text>
          <Text
            type="secondary"
            ellipsis
            style={{
              margin: 0,
              minWidth: 130,
              maxWidth: 130,
            }}
          >{`@${record.username}`}</Text>
        </div>
      ),
    },
    ...(props.activeTab === "agency"
      ? [
        {
      title: "Agency name",
      dataIndex: "agency_name",
      key: "agency_name",
      fixed: "left",
      width:150,
      render: (_: any, record: Member) => (
        <Text
        ellipsis
        style={{
          margin: 0,
          minWidth: 120,
          maxWidth: 120,
        }}
      >
        {`${record.registrations[0].data?.agency_name?record.registrations[0].data?.agency_name:""}`}
      </Text>
      ),
    },
  ]
  : []),
    {
      title: "Role",
      dataIndex: "registrations",
      key: "registrations",
      render: (registrations: Registration[]) => {
        const role = registrations[0]?.roles[0];
        const isGuest = role === "Guest";
        const tagColor = isGuest ? "#F2FFFC" : "processing";
        const textColor = isGuest ? "#009D79" : "#4B48A5"; 
        return (
          <Tag
            className="w-max h-8 flex justify-center items-center"
            bordered={false}
            color={tagColor}
            style={{ color: textColor }}
          >
            {registrations[0]?.roles[0]}
          </Tag>
        )
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string) => (
        <Tooltip title={email}>
          <Text
            type="secondary"
            ellipsis
            style={{
              margin: 0,
              minWidth: 190,
              maxWidth: 190,
            }}
          >
            {email}
          </Text>
        </Tooltip>
      ),
    },
    {
      title: "Joined on",
      dataIndex: "insert_instant",
      key: "dateAdded",
      render: (date: string) => (
        <>
          <Text type="secondary">{renderCreatedAt(date)}</Text>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "dateAdded",
      render: (_: any, record: Member) => (
        <>
          {props.members[record.id]?.registrations[0].roles[0] !== OWNER && (
            <MemberActionButtons
              selectedMember={record.id}
              getLoading={props.getLoading}
            />
          )}
        </>
      ),
    },
  ];

  return (
  <div style={{ maxHeight: "calc(100vh - 339px)" }}>
    <Table
      columns={columns}
      dataSource={memberData}
      scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
      size="small"
      pagination={false}
      loading={props.loading}
    />
  </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  applicationId: state.opening.applicationId,
  integratedSlackMember: state.opening.integratedSlackMember,
});
export default connect(mapStateToProps)(GuestMembers);
