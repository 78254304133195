// import { AxiosError } from "axios";
import { connect } from "react-redux";
import React from "react";
import { Dispatch } from "redux";
import { updateCareerPageDetails } from "../../redux/actions/opening/action";
import OrgImageUpload from "./OrgImageUpload";
import { Divider, Form, Input, Typography } from "antd";
import FormItem from "../../ant/FormItem";
import CareerPageSetting  from "./CareerPageSetting";
import { loginUser } from "../../type/type";
const { Text } = Typography;

type Organisationprops = {
  applicationName: any;
  loginUser: loginUser;
  updateCareerPageDetails(details: { [key: string]: string }): void;
};

const OrgSetting = (props: Organisationprops) => {
  return (
    <>
      <div className="w-full">
        <div className=" flex p-4 flex-col">
          <Text strong>General information</Text>
          <Text>
            Update your organization’s basic details.
          </Text>
        </div>
        <div className="p-4">
          <div className="font-semibold mb-4">
            <Text strong>Logo</Text>
          </div>
          <OrgImageUpload />
          <Form layout="vertical">
            <div className="flex mt-4  gap-8">
              <div className="w-full">
                <FormItem name="display_name" label="Display name">
                  <Input disabled defaultValue={props.applicationName} />
                </FormItem>
              </div>
            </div>
          </Form>
          <Divider />
          <CareerPageSetting />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateCareerPageDetails: (details: { [key: string]: string }) =>
    dispatch(updateCareerPageDetails(details)),
});
export default connect(mapStateToProps, mapPropsToState)(OrgSetting);
