import React from "react";
import TacitbaseAvtar from "../ant/Avtar";

interface UserProfile {
  id:string,
  image: string;
  display_name: string;
  email: string;
  firstName?:string;
  lastName?:string
}

interface MemberCardProps extends UserProfile {
  imageClassName?: string;
  displayNameClassName?: string;
  emailClassName?: string;
}

const MemberCard: React.FC<MemberCardProps> = ({
  image,
  display_name,
  email,
  imageClassName,
  displayNameClassName,
  emailClassName,
}) => {
  return (
    <div className="flex items-center space-x-2">
      <div className={imageClassName}>
        <TacitbaseAvtar size="large" src={image} />
      </div>
      <div className="flex flex-col">
        <div className={`${displayNameClassName?displayNameClassName :null} font-medium`}>{display_name}</div>
        <div className={emailClassName}>{email}</div>
      </div>
    </div>
  );
};

export default MemberCard;
