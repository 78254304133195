import React, { useState } from "react";
import { Divider, Form, Input, Typography } from "antd";
import ImageUpload from "../pages/setting/accSetting/ImageUpload";
import FormItem from "../ant/FormItem";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
import { Dispatch } from "redux";
import { updateUser } from "../redux/actions/opening/action";
import { loginUser } from "../type/type";
import { connect } from "react-redux";
import { updateUserInformation } from "../lib/api";
import { throwSuccess } from "../service/throwError";
import { SecondaryButton } from "../ant/Button";
const { Text } = Typography;

// profile settings props
type ProfileSettingProps = {
  loginUser: loginUser;
  updateUser(user: any): void;
  applicationName: string;
  applicationId: string;
};

const ProfileSetting = (props: ProfileSettingProps) => {
  // state that manage whether the input is disabled or not
  const [fieldEditable, setFiledEditable] = useState<boolean>(false);

  // initial form value sets from redux
  const [userData, setUserData] = useState({
    firstName: props.loginUser.user.firstName,
    lastName: props.loginUser.user.lastName,
    email: props.loginUser.user.email,
    phone: props.loginUser.user.mobilePhone,
    timezone: props.loginUser.user.timezone,
    password: "",
  });

  // after click on edit button enable editable field
  function handleEditInformation() {
    setFiledEditable(true);
  }

  // Function to compare the initial and new values
  const getChangedValues = (initialValues: any, newValues: any) => {
    return Object.keys(newValues).reduce((acc: any, key) => {
      if (newValues[key] !== initialValues[key]) {
        acc[key] = newValues[key];
      }
      return acc;
    }, {});
  };

  const [form] = Form.useForm();

  // after click on update button updating user info and sets it in the redux
  function handleSubmit() {
    form.validateFields().then((values) => {
      const changedValues = getChangedValues(userData, values);
      // Check if there are any changes
      if (Object.keys(changedValues).length === 0) {
        setFiledEditable(false);
        return;
      }

      let formData = new FormData();
      formData.set(
        "user",
        JSON.stringify({
          user: {
            id: props.loginUser.user.id,
            ...changedValues,
          },
        })
      );
      updateUserInformation(props.loginUser.user.id, formData)
        .then((res: any) => {
          setFiledEditable(false);
          props.updateUser(res);
          throwSuccess("User profile updated successfully.");
        })
        .catch((error) => {
          console.log("ERR:", error);
        });
      setUserData({
        ...userData,
        ...changedValues,
      });
    });
  }

  return (
    <>
      <div className="p-4">
        <Text strong>Profile settings</Text>
      </div>
      <Divider type="horizontal" className="w-full m-0 " />
      <div
        className="w-full overflow-y-scroll  "
        style={{ height: "calc(-253px + 100vh)" }}
      >
      <div className="2xl:w-1/2 xl:3/4 lg:w-3/4 md:w-full sm:w-full">
        <div className=" flex flex-col p-4">
          <Text strong>General information</Text>
          <Text>
            Update your basic details.
          </Text>
        </div>
        <div className="p-4">
          <div className="font-semibold mb-4">
            <Text strong>Profile picture</Text>
          </div>
          <ImageUpload />
          <Form
            form={form}
            initialValues={userData}
            className="mt-4"
            layout="vertical"
          >
            <div className="flex mt-4 sm:flex-col md:flex-col lg:flex-row flex-col gap-8">
              <div className="w-full">
                <FormItem
                  name="firstName"
                  label={capitalizeFirstLetter("first name")}
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                >
                  <Input disabled={!fieldEditable} />
                </FormItem>
              </div>
              <div className="w-full">
                <FormItem
                  name="lastName"
                  label={capitalizeFirstLetter("last name")}
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <Input disabled={!fieldEditable} />
                </FormItem>
              </div>
            </div>
            <div className="flex mt-4 sm:flex-col md:flex-col lg:flex-row flex-col gap-8">
              <div className="w-full">
                <FormItem
                  name="email"
                  label={capitalizeFirstLetter("registered email")}
                  rules={[
                    { required: true, message: "Please enter your email" },
                    { type: "email", message: "Please enter a valid email" },
                  ]}
                >
                  <Input disabled={true} />
                </FormItem>
              </div>
              <div className="w-full">
                <FormItem name="phone" label={capitalizeFirstLetter("phone")}>
                  <Input disabled={true} />
                </FormItem>
              </div>
            </div>
          </Form>
          <div className="flex mt-4  items-center justify-end">
            {!fieldEditable && (
              <SecondaryButton onClick={handleEditInformation}>
                Edit information
              </SecondaryButton>
            )}
            {fieldEditable && (
              <SecondaryButton onClick={handleSubmit}>Update</SecondaryButton>
            )}
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
  applicationId: state.opening.applicationId,
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  updateUser: (user: any) => dispatch(updateUser(user)),
});
export default connect(mapStateToProps, mapPropsTostate)(ProfileSetting);
