import React, { useEffect } from "react";
import { useState } from "react";
//Api's
import {
  getActivity,
  getApplicantComments,
  getRecordMailPreview,
} from "../../../lib/api";
import { connect } from "react-redux";

//Redux
import { Dispatch } from "redux";
import { initComments } from "../../../redux/actions/opening/action";
import { useParams } from "react-router";
import { ACTIVITY_TAG, COMMENT_TAG, EMAIL_TAG } from "../../../constant";
import {
  activity,
  comment,
  currentApplicant,
  InitComments,
  orgMember,
} from "../../../type/type";
import ShowActivity from "../activity/ShowCardActivity";
import ShowComments from "./commentInput/ShowComments";
import CardMailPreview from "./CardMailPreview";

export type CommentProps = {
  currentApplicant: currentApplicant;
  initComments(comments: any, tag: string, applicant_id: string): void;
  comments: InitComments;
  members: orgMember;
  reload: boolean;
  showDetails: boolean;
};
const Comment = (props: CommentProps) => {
  const [flag, setFlag] = useState(false);
  const params: { card_id: string } = useParams();

  let arr = props.comments[props.currentApplicant.id]
    ? Object.values(props.comments[props.currentApplicant.id]).sort(
        (prev: comment | activity | any, next: comment | activity | any) =>
          prev.tag == EMAIL_TAG && next.tag == EMAIL_TAG
            ? prev.message.created_at < next.message.created_at
              ? 1
              : -1
            : prev.tag == EMAIL_TAG
            ? prev.message.created_at < next.created_at
              ? 1
              : -1
            : next.tag == EMAIL_TAG
            ? prev.created_at < next.message.created_at
              ? 1
              : -1
            : prev.created_at < next.created_at
            ? 1
            : -1
      )
    : [];

  useEffect(() => {
    async function fetchMyAPI() {
      if (props.currentApplicant.id) {
        const res = await Promise.all([
          getActivity("cards", params.card_id).catch(() => {
           
          }),
          getApplicantComments(props.currentApplicant.id).catch(() => {
         
          }),
        ]);
        getRecordMailPreview(props.currentApplicant.id)
          .then((res) => {
            props.initComments(res.items, EMAIL_TAG, props.currentApplicant.id);
          })
        
        if (res) {
          res[0]
            ? props.initComments(
                res[0],
                ACTIVITY_TAG,
                props.currentApplicant.id
              )
            : null;
          res[1]
            ? props.initComments(res[1], COMMENT_TAG, props.currentApplicant.id)
            : null;
          setFlag(true);
        }
      }
    }
    fetchMyAPI();
    return () => {};
  }, [props.currentApplicant.id, props.reload]);

  if (!flag) return null;
  return (
    <React.Fragment>
      {props.comments[props.currentApplicant.id] &&
        arr.map((comment: any) => {
          switch (comment.tag) {
            case COMMENT_TAG:
              if (COMMENT_TAG)
                return (
                  <div className="ml-3">
                    <ShowComments comment={comment} />
                  </div>
                );
              break;
            case ACTIVITY_TAG:
              if (props.showDetails)
                return (
                  <div className="ml-3">
                    <ShowActivity activity={comment} />
                  </div>
                );
              break;
            case EMAIL_TAG:
              return <CardMailPreview mail={comment} />;
            default:
              break;
          }
        })}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  members: state.opening.members,
  reload: state.opening.reloadActivity,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
});
export default connect(mapStateToProps, mapPropsToState)(Comment);
