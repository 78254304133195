// SearchInput.js
import React from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { initSearchQuery } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { searchDynamicQuery } from "../../type/type";

type SearchInputProps = {
    searchQuery: searchDynamicQuery;
    initSearchQuery: (query: string) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({searchQuery,initSearchQuery}) => {
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    initSearchQuery(e.target.value);
  };

  return (
    <Input.Search
      placeholder="Search..."
      onChange={handleSearchChange}
      value={searchQuery.query}
      allowClear
      className="w-60"
    />
  );
};

const mapStateToProps = (state: any) => ({
    searchQuery: state.opening.searchQuery,
  });

const mapPropsToState = (dispatch: Dispatch) => ({
   
    initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
  });
export default connect(mapStateToProps, mapPropsToState)(SearchInput);
