import React, { useEffect, useState } from "react";
import ImgCrop from "antd-img-crop";
import { Avatar, Spin, Upload } from "antd";
import { connect } from "react-redux";
import { updateUser } from "../../../redux/actions/opening/action";
import { uploadAvtar } from "../../../lib/api";
import { Dispatch } from "redux";
import { loginUser } from "../../../type/type";
import  { throwFrontEndError, throwSuccess } from "../../../service/throwError";
import { SecondaryButton } from "../../../ant/Button";

const ImageUpload = (props: {
  loginUser: loginUser;
  updateUser(user: any): void;
  userImage: string;
  applicationName: string;
}) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);

  // getting user image form redux and set it into fileList in first render
  useEffect(() => {
    if (props.loginUser.user.imageUrl) {
      setFileList([
        {
          uid: "1",
          name: "UserImage",
          status: "done",
          url: props.loginUser.user.imageUrl,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [props.loginUser.user.imageUrl]);

  const onChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  // function call click on update button
  const fileUpload = ({ file }: any) => {
    if (!checkFileSize(file)) {
      throwFrontEndError("Profile image should be less than 10 MB")
      return;
    }
    setUploading(true);

    const formData = new FormData();
    formData.append("avtar", file);

    uploadAvtar(props.loginUser.user.id, formData)
      .then((res: any) => {
        props.updateUser(res.user);
        throwSuccess("Profile photo updated successfully")
      })
      .catch((error: any) => {
        throwFrontEndError("Error while uploading avatar")
        console.error("Error uploading avatar:", error);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  // check file size before uploading
  const checkFileSize = (file: any) => {
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    return file.size <= maxSize;
  };

  return (
    <div className="flex items-center">
      <div className="mr-8">
        {uploading ? (
          <div className="h-24 w-24 flex items-center justify-center border border-gray-300 rounded-full">
          <Spin />
        </div>
        ) : props.userImage ? (
          <Avatar
            src={props.userImage}
            alt="user_image"
            className="h-28 border border-gray-300   rounded-full w-28"
          />
        ) : (
          <Avatar
            src={props.applicationName[0] || undefined}
            alt="user_image"
            className="h-28 rounded-full border-gray-300 border-2 border-solid w-28"
          />
        )}
      </div>
      <ImgCrop showGrid rotationSlider aspectSlider>
        <Upload
          onChange={onChange}
          showUploadList={false}
          fileList={fileList}
          customRequest={fileUpload}
          accept="image/png, image/jpeg"
        >
          <SecondaryButton
            className="mr-4"
            onClick={()=>{}}
          >
            Update picture
          </SecondaryButton>
        </Upload>
      </ImgCrop>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  userImage: state.opening.loginUser.user.imageUrl,
  applicationName: state.opening.applicationName,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUser: (user: any) => dispatch(updateUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
