import React, { useState } from "react"
import { connect } from "react-redux"
import { CandidatesInReview, Member, orgMember } from "../../type/type.d"
import { IconButton } from "../../ant/Button"
import { CheckOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons"
import { addMemberToBulkReview } from "../../lib/api"
import { addMembersToMultipleReviews } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import Mixed from "../../assets/Images/Mixed"
import MemberAddPopover from "../common/MemberPopupAnt"
import { throwSuccess } from "../../service/throwError"
import getOrgMembers from "../../utils/getOrgMember"

const AddMemberToBUlkReviews = (props: {
    applicationId: string,
    selectedRowKeys: React.Key[],
    addMemberOnReview(members: any): void,
    candidatesInReview: CandidatesInReview,
    orgMembers: orgMember
}) => {

    const [loader, showLoader] = useState(false)
    const [loadedMember, setloadedMember] = useState("")



    const determineIcon = (member: Member) => {
        const selectedRowCount = props.selectedRowKeys.length
        const memberCount = props.selectedRowKeys.filter(item => props.candidatesInReview.reviews[item] &&
            props.candidatesInReview.reviews[item].members.includes(member.id)
        ).length
        const isLoading = member.id === loadedMember
        if (isLoading && loader) {
            // Show loading icon for the current label being processed
            return <LoadingOutlined />
        }
        if (memberCount === 0) {
            // If the member count is zero, return nothing
            return null
        } else if (memberCount === selectedRowCount) {
            // If the member count equals the total number of selected rows, return a checkmark icon
            return <CheckOutlined />
        } else {
            // Otherwise, return a mixed symbol
            return (
                <div className="items-center justify-center flex">
                    <Mixed height={14} fill="#000" />
                </div>
            )
        }
    }

    const addMemberOnReview = (memberData: Member) => {
        setloadedMember(memberData.id)
        const memberIdsExistInReviews: React.Key[] = props.selectedRowKeys.filter((item: React.Key) => props.candidatesInReview.reviews[item].members && props.candidatesInReview.reviews[item].members.includes(memberData.id)).map((item: React.Key) => item)
        const memberIdsNotExistInReviews: React.Key[] = props.selectedRowKeys.filter((item: React.Key) => props.candidatesInReview.reviews[item].members && !props.candidatesInReview.reviews[item].members.includes(memberData.id)).map((item: React.Key) => item)
        if (memberIdsExistInReviews.length == 0) {
            showLoader(true)

            let req: any = {
                review_ids: props.selectedRowKeys,
                members: [memberData.id],
            }
            addMemberToBulkReview(req).then((res) => {
                props.addMemberOnReview(res)
                showLoader(false)
                throwSuccess(`Assigned to ${memberData.first_name} ${memberData.last_name}`)

            })
        } else if (memberIdsExistInReviews.length == props.selectedRowKeys.length) return
        else if (memberIdsExistInReviews.length < props.selectedRowKeys.length) {
            showLoader(true)

            let req: any = {
                review_ids: memberIdsNotExistInReviews,
                members: [memberData.id],
            }
            addMemberToBulkReview(req).then((res) => {
                showLoader(false)
                throwSuccess(`Assigned to ${memberData.first_name} ${memberData.last_name}`)

                props.addMemberOnReview(res)

            })

        }

    }

    return (
        <div>
            <MemberAddPopover defaultTitle="" initialMembers={getOrgMembers()} determineIcon={determineIcon} onAddMember={addMemberOnReview} element={<IconButton tooltipTitle="Add member" icon={<UserOutlined ></UserOutlined>} />}
            />

        </div>
    )
}
const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    orgMembers: state.opening.members,
    candidatesInReview: state.opening.candidatesInReview,
})
const mapPropsToState = (dispatch: Dispatch) => ({
    addMemberOnReview: (members: any) =>
        dispatch(addMembersToMultipleReviews(members)),


})
export default connect(mapStateToProps, mapPropsToState)(AddMemberToBUlkReviews)
