import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetIcon } from "./GetImportReportIcon";
import { ImportReport, Stage } from "../../../lib/apiRes";
import moment from "moment";
import { DATE_TIME_FORMATE } from "../../../constant";
import FileSelectionForUpload from "./FileSelection";
import FormSteps from "./FormSteps";
import FieldMapping from "./FieldMapping";
import DataPreview from "./DataPreview";
import { TableColumn } from "../../../type/type";
import ImportFinalReport from "./ImportFinalReport";
import { getImportReports } from "../../../lib/api";
import Button1 from "../../common/button/Button1";
import Image from "../../common/image/Image";
import csvIntoDb from "../../../images/csvIntoDb.png";
import CloseIcon from "../../../assets/Close";
import { Typography } from "antd";
type FileUploadDataType = { stages: Stage };
const {Text} = Typography;
const ImportTabContent = () => {
  // Define initial stage data for FileUploadData and ImportFinalAPIReport
  let initialStage = {
    stages: {
      id: "",
      name: "",
      headers: [],
      preview_rows: [],
      result: {
        status: false,
        records: {
          total_count: 0,
          valid_count: 0,
          invalid_count: 0,
        },
      },
      attachments: [],
      import_id: "",
      created_at: "",
      updated_at: "",
    },
  };

  // State variables
  const [importRecords, setImportRecords] = useState<ImportReport[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [mode, setModeTo] = useState<string>("");

  const [FileUploadData, setFileUploadData] =
    useState<FileUploadDataType>(initialStage);
  const [selectedObjects, setSelectedObjects] = useState<{
    [key: string]: TableColumn | null;
  }>({});
  const [importFinalAPIReport, setImportFinalAPIReport] =
    useState<FileUploadDataType>(initialStage);
  // Function to move to the next step
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  // Function to move to the previous step
  const handleBackStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };
  // Fetch import reports on component mount
  useEffect(() => {
    getImportReports().then((res) => {
      setImportRecords(res);
    });
  }, []);
  // Function to handle step selection when clicking on a report
  const handleStep = (report: ImportReport) => {
    setSelectedObjects({})
    setFileUploadData({ stages: report?.stages[0] });
    setModeTo(report.result.status);
    report.result.status == "success"
      ? (setImportFinalAPIReport({ stages: report?.stages[1] }),
        setCurrentStep(3))
      : setCurrentStep(0);
  };

  return (
    <div className="h-full w-full flex flex-col">
      {/* The main layout of the ImportTabContent */}
      <div className="flex border-b px-4 items-center font-medium" style={{height:'54px'}}>
      <Text strong>Bulk import</Text>
        </div>
      <div className="flex h-full   select-none my-4 bg-white overflow-hidden rounded ">
        {/* Left panel to show the list of import reports */}
        <div className="flex-col w-1/4 flex-shrink-0 flex  h-full ">
          <div className=" w-full flex text-sm flex-shrink-0">
            <div className="flex w-full ">
              <div className="border-b border-tacitbase-secondary3  flex pl-2 "></div>
              <div className="flex text-xs ">
                <button
                  className={`
                       border-t-4 border-l border-r 
                      
                   px-2 py-1 w-full rounded-t-default  border-tacitbase-secondary3  text-sm break-words font-semibold leading-4     `}
                >
                  Reports
                </button>
              </div>{" "}
              <div className="border-b border-tacitbase-secondary3 w-full flex pl-3 "></div>
            </div>
          </div>
          {importRecords && importRecords.length == 0 && (
            <div className="bg-white animate-popover_fadeIn overflow-y-scroll w-full max-w-full border-r transition-all duration border-tacitbase-secondary3 justify-between flex-col h-full">
              <div className=" h-full justify-center items-center flex-grow-0 flex-shrink-0 flex-auto w-full px-1 max-w-full">
                <div className="px-6 flex justify-center items-center h-full bg-white flex-col text-xs font-semibold">
                  <div>No more imports reported yet</div>
                </div>
              </div>
            </div>
          )}
          {importRecords && importRecords.length > 0 && (
            <div className="flex flex-col border-r transition-all duration border-tacitbase-secondary3 h-full overflow-y-scroll">
              <div className="flex flex-col space-y-1 ml-2 mr-1 my-2 ">
                {importRecords.map((report, index) => (
                  <div
                    className="bg-white rounded border shadow-lg border-tacitbase-tertiary1 border-opacity-25 p-2 h-24"
                    key={index}
                    onKeyDown={() => {}}
                    onClick={() => handleStep(report)}
                    tabIndex={0}
                    role="button"
                  >
                    <div className="flex items-center justify-between w-full h-8">
                      <div className="flex text-sm break-words font-semibold leading-4     ">
                        Candidate Import :{" "}
                        {moment(report.created_at).format(DATE_TIME_FORMATE)}
                      </div>
                      <div>
                        <GetIcon status={report?.result?.status} />
                      </div>
                    </div>
                    <div className="flex flex-col  px-1  rounded-b   w-full text-xs justify-between space-y-0.5 ">
                      <div className="flex flex-col w-full space-y-0.5">
                        <div className="flex space-x-2 w-full">
                          <div className="w-3/6">Total records</div>
                          <div className="w-max">:</div>
                          <div className="w-2/6">
                            {report?.result?.records?.total}
                          </div>
                        </div>
                        {report.result.status == "success" ? (
                          <div>
                            <div className="flex space-x-2 w-full">
                              <div className="w-3/6">Records imported</div>
                              <div className="w-max">:</div>
                              <div className="w-2/6">
                                {report?.result?.records?.imported}
                              </div>
                            </div>
                            <div className="flex space-x-2 w-full">
                              <div className="w-3/6">Error records</div>
                              <div className="w-max">:</div>
                              <div className="w-2/6">
                                {report?.result?.records?.not_imported}
                              </div>
                            </div>
                          </div>
                        ) : report?.result?.status == "error" ? (
                          <div>
                            <div className="flex space-x-2 w-full">
                              <div className="w-3/6">Import aborted</div>
                              <div className="w-max"></div>
                              <div className="w-2/6"></div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="flex space-x-2 w-full">
                              <div className="w-3/6"></div>
                              <div className="w-max"></div>
                              <div className="w-2/6"></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* Right panel to show the form steps and content */}

        <div className="flex flex-col w-9/12 " style={{  paddingTop: 3 }}>
          <div className=" w-full flex text-sm flex-shrink-0">
            <div className="flex w-full ">
              <div className="border-b border-tacitbase-secondary3  flex pl-2 "></div>
              <div className="flex text-xs  ">
                <button
                  className={`
                      
                   px-2 py-1 w-full border-b rounded-t-default  border-tacitbase-secondary3  `}
                >
                  <span className="invisible">Reports</span>
                </button>
              </div>{" "}
              <div className="border-b border-tacitbase-secondary3 w-full flex pl-3 "></div>
            </div>
          </div>

            <button
              className={`${currentStep ==-1?"p-2 invisible":"p-2 flex items-center justify-end cursor-pointer" }`}
              onClick={() => (
                setCurrentStep(-1),
                setSelectedObjects({}),
                setImportFinalAPIReport(initialStage),
                setFileUploadData(initialStage)
              )}
            >
              <CloseIcon />
            </button>
          
          <div
            className="flex items-center justify-center pt-2"
            style={{ height: "20%" }}
          >
            <FormSteps currentStep={currentStep} />
          </div>{" "}
          {currentStep == -1 ? (
            <>
              <div
                className="flex flex-col w-full h-full space-y-4 pl-2 items-center justify-center"
                style={{ height: "60%" ,marginTop:'10%'}}
              >
                <Image src={csvIntoDb} size={600} />{" "}
              </div>
              <div
                className="w-full flex justify-end items-center h-full pr-8"
                style={{ height: "20%" }}
              >
                {/* Back button to go back to the previous step */}
                <Button1
                  buttonName="Start new Import"
                  click={() => setCurrentStep(0)}
                />{" "}
                {/* Import Candidate button to initiate the import process */}
              </div>
            </>
          ) : null}
          {/* Conditional rendering based on the current step */}
          {currentStep === 0 ? (
            // Render FileSelectionForUpload component for step 1
            <>
              <FileSelectionForUpload
                handleNextStep={handleNextStep}
                handleApiRes={(data) => setFileUploadData(data)}
                data={FileUploadData}
                mode={mode}
              />
            </>
          ) : currentStep === 1 ? (
            // Render FieldMapping component for step 2
            <>
              <FieldMapping
                fields={FileUploadData.stages.headers}
                handleNextStep={handleNextStep}
                setSelectedObjects={setSelectedObjects}
                selectedObjects={selectedObjects}
                mode={mode}
              />
            </>
          ) : currentStep === 2 ? (
            // Render DataPreview component for step 3
            <>
              <DataPreview
                uploadedFileData={FileUploadData}
                selectedObjects={selectedObjects}
                handleNextStep={handleNextStep}
                handleBackStep={handleBackStep}
                setImportFinalAPIReport={setImportFinalAPIReport}
                mode={mode}
            
              />
            </>
          ) : currentStep === 3 ? (
            // Render ImportFinalReport component for step 4
            <>
              <ImportFinalReport
          
                handleDone={() => (
                  setCurrentStep(-1),
                  setSelectedObjects({}),
                  setImportFinalAPIReport(initialStage),
                  setFileUploadData(initialStage)
                )}
                importFinalAPIReport={importFinalAPIReport}
                mode={mode}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  candidatesInReview: state.opening.candidatesInReview,
});
const mapPropsTostate = () => ({});
export default connect(mapStateToProps, mapPropsTostate)(ImportTabContent);
