// GenericSelect.tsx
import React, { useState } from "react";
import { Select } from "antd";


interface OptionType<T> {
  label: string;
  value: T;
}

interface GenericSelectProps<T> {
  options: Array<OptionType<T>>;
  onChange?: (value: T[], selectedOptions: any) => void;
  dropdownStyle?: React.CSSProperties; // Define dropdownStyle in props
}

function GenericSelect<T>({
  options,
  onChange,
  dropdownStyle,
  ...rest
}: GenericSelectProps<T>) {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (value: T[], selectedOptions: any) => {
    setIsOpen(false);
    if (onChange) {
      onChange(value, selectedOptions);
    }
  };

  const handleDropdownVisibleChange = (open: boolean) => {
    setIsOpen(open);
  };


  const filterOption = (input: string, option: OptionType<T> | undefined) =>
  option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;


  return (
    <Select
      mode="multiple"
      allowClear
      style={{ width: "100%" }}
      placeholder="Please select"
      showSearch  
      filterOption={filterOption} 
      onChange={handleChange}
      options={options}
      popupMatchSelectWidth={false}
      dropdownStyle={dropdownStyle}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      open={isOpen}
      {...rest}
    />
  );
}

export default GenericSelect;
