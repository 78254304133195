import React from 'react';

const CommentNewIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.2257 7.17183C9.76168 7.17183 9.40208 7.53143 9.40208 7.9754C9.40208 8.41937 9.76168 8.77897 10.2257 8.77897C10.6496 8.77897 11.0092 8.41937 11.0092 7.9754C11.0092 7.53143 10.6496 7.17183 10.2257 7.17183ZM4.60074 7.17183C4.13668 7.17183 3.77708 7.53143 3.77708 7.9754C3.77708 8.41937 4.13668 8.77897 4.60074 8.77897C5.02463 8.77897 5.38423 8.41937 5.38423 7.9754C5.38423 7.53143 5.02463 7.17183 4.60074 7.17183Z" fill="black" fillOpacity="0.85"/>
        <path d="M16.6738 5.64348C15.7444 4.36407 14.4042 3.44223 12.877 3.03187V3.03388C12.5334 2.65218 12.1457 2.30062 11.7118 1.98723C8.42317 -0.403398 3.80665 0.325843 1.40598 3.61446C-0.528616 6.28633 -0.44625 9.88031 1.52652 12.4337L1.54259 15.0975C1.54259 15.1618 1.55263 15.2261 1.57272 15.2863C1.59799 15.3668 1.63888 15.4416 1.69306 15.5062C1.74724 15.5709 1.81364 15.6243 1.88846 15.6633C1.96328 15.7023 2.04506 15.7262 2.12911 15.7335C2.21316 15.7409 2.29784 15.7316 2.3783 15.7062L4.92161 14.9046C5.5946 15.1437 6.28969 15.2803 6.98076 15.3185L6.97071 15.3265C8.76067 16.6303 11.1071 17.022 13.2587 16.3109L15.812 17.1426C15.8763 17.1627 15.9426 17.1747 16.0109 17.1747C16.3665 17.1747 16.6538 16.8874 16.6538 16.5319V13.8399C18.4236 11.4372 18.4698 8.11647 16.6738 5.64348ZM5.20286 13.4783L4.96179 13.3778L2.97295 14.0006L2.95286 11.9113L2.79214 11.7305C1.09259 9.65732 0.980089 6.67004 2.57116 4.4783C4.50777 1.82249 8.22027 1.23589 10.868 3.15241C13.5238 5.08299 14.1125 8.78946 12.1939 11.4292C10.5848 13.637 7.70397 14.4526 5.20286 13.4783ZM15.3479 13.1368L15.1872 13.3377L15.2073 15.427L13.2386 14.764L12.9975 14.8645C11.8725 15.2823 10.6732 15.3165 9.56223 15.0051L9.55822 15.0031C11.0851 14.5348 12.4188 13.5839 13.3591 12.293C14.8939 10.1776 15.143 7.51982 14.2511 5.25374L14.2631 5.26178C14.7252 5.59325 15.1491 6.00709 15.5087 6.50732C16.9671 8.50821 16.8848 11.2323 15.3479 13.1368Z" fill="black" fillOpacity="0.85"/>
        <path d="M7.41324 7.17183C6.94918 7.17183 6.58958 7.53143 6.58958 7.9754C6.58958 8.41937 6.94918 8.77897 7.41324 8.77897C7.83713 8.77897 8.19673 8.41937 8.19673 7.9754C8.19673 7.53143 7.83713 7.17183 7.41324 7.17183Z" fill="black" fillOpacity="0.85"/>
        </svg>
    );
};

export default CommentNewIcon;

