import React from "react";
const CardCopyIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Card Copy</title>
      <g
        id="Icons-/-16x16-/-Card-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M15.5,3 C15.7761424,3 16,3.22385763 16,3.5 L16,13 C16,14.1045695 15.1045695,15 14,15 L2.5,15 C2.22385763,15 2,14.7761424 2,14.5 C2,14.2238576 2.22385763,14 2.5,14 L14,14 C14.5522847,14 15,13.5522847 15,13 L15,3.5 C15,3.22385763 15.2238576,3 15.5,3 Z M12,1 C13.1045695,1 14,1.8954305 14,3 L14,11 C14,12.1045695 13.1045695,13 12,13 L2,13 C0.8954305,13 1.3527075e-16,12.1045695 0,11 L0,3 C-1.3527075e-16,1.8954305 0.8954305,1 2,1 L12,1 Z M12,2 L2,2 C1.44771525,2 1,2.44771525 1,3 L1,3 L1,11 C1,11.5522847 1.44771525,12 2,12 L2,12 L12,12 C12.5522847,12 13,11.5522847 13,11 L13,11 L13,3 C13,2.44771525 12.5522847,2 12,2 L12,2 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default CardCopyIcon;
