import {  Row, Col, Typography } from "antd";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PricingTable from "./PricingTable";
const {Text} =  Typography;
type PlanPageProps = {
  stripeCustomerStatus: any;
  applicationId: string;
};

const PlanPage = (props: PlanPageProps) => {
  const history = useHistory();

  useEffect(() => {
    // Redirect if payment method is already set up
    if (props.stripeCustomerStatus.setup.payment_method) {
      history.push(`/o/${props.applicationId}/ats`);
    }
  }, [props.stripeCustomerStatus, props.applicationId, history]);

  if (!props.applicationId) return null;

  return (
    <div
      className="w-full h-full overflow-y-scroll 2xl:flex 2xl:items-center 2xl:justify-center"
    >
      <Row justify="center" align="middle" className="w-full">
       

        <Col
          xs={24} sm={24} md={20} lg={20} xl={20} xxl={18}
          className="flex flex-col justify-center items-center w-full px-4"
        >
          <Text className="font-extrabold text-gray-900 text-4xl">Flexible Plans for Your Hiring Needs</Text>
          <Text type="secondary" className="mt-4">Free forever. Upgrade for advanced customization, free job boards, onboarding support and AI powered features.</Text>
          <div
            className="p-0.5"
            style={{ marginTop: "1rem" }}
          >
            <PricingTable/>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  stripeCustomerStatus: state.opening.billingCustomer.status,
});

export default connect(mapStateToProps)(PlanPage);
