import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TACITBASE } from "../../constant";
import {
  getInvitations,
  getOrgDetails,
  updateInvitationStatusApi,
} from "../../lib/api";
import {
  current_org,
  initIncomingInvitations,
  updateOrgNameId,
} from "../../redux/actions/opening/action";
import { loginUser } from "../../type/type";
import { Avatar, Button, Divider, Table, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { orgConfigurationMiddleware } from "../../service/orgConfigurationMiddleware";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { SecondaryButton } from "../../ant/Button";
const { Text } = Typography;

type AccSettingProps = {
  loginUser: loginUser;
  applicationName: string;
  updateIdName(orgData: any): void;
  currentOrg(orgData: any): void;
  initIncomingInvitations(data: any): void;
  incomingInvitations: any;
};

const AccSetting = (props: AccSettingProps) => {
  document.title = `Invitations | ${props.applicationName
    .charAt(0)
    .toUpperCase()}${props.applicationName.slice(1)} | ${TACITBASE} `;
  const [invitations, showInvitations] = useState([]);
  const history = useHistory();
  const [loadingState, setLoadingState] = useState({
    join: false,
    decline: false,
  });

  // getting incoming invitations in first render of component
  useEffect(() => {
    getInvitations(props.loginUser.user.email).then((res: any) => {
      showInvitations(res);
      props.initIncomingInvitations(res);
    });
  }, []);

  // function that decline invitation
  const declineOrg = (orgid: string) => {
    showInvitations(invitations.filter((org: any) => org.id != orgid));
  };

  // on click on decline invitation button this function will call in decline invitation
  const updateInvitationStatus = async (req: any, applicantion_id: string) => {
    updateInvitationStatusApi(req).then((res: any) => {
      if (res) {
        if (req.status == "ACCEPTED") {
          setLoadingState({ join: false, decline: false });

          history.push({
            pathname: `/o/${applicantion_id}/ats`,
          });
        } else {
          declineOrg(req.id);
        }
      }
    });
  };

  // on click on join invitation this function will call and accept invitation
  const lunchInvitedOrganisation = (
    applicantion_id: any,
    e: any,
    id: string,
    orgRole: string
  ) => {
    e.stopPropagation();

    // setLoading(true)

    setLoadingState({ join: true, decline: false });
    getOrgDetails(applicantion_id).then((appRes) => {
      const statusUpdate = {
        id: id,
        status: "ACCEPTED",
      };
      updateInvitationStatusApi(statusUpdate).then((res) => {
        if (res) {
          if (res.status == "ACCEPTED") {
            setLoadingState({ join: false, decline: false });
          }

          orgConfigurationMiddleware(appRes, props.loginUser).then((flag) => {
            if (flag) {
              // updateInvitationStatus(statusUpdate, appRes.application.id);
              sessionStorage.removeItem("slackCode");

              let updatedOrg = {
                applicationId: applicantion_id,
                id: appRes.application.id,
                applicationName: appRes.application.name,
                verified: true,
                data: {
                  logo: "",
                  description: "",
                },
                roles: orgRole,
              };
              props.updateIdName(updatedOrg);
              props.currentOrg(appRes);
              history.push({
                pathname: `/o/${applicantion_id}/ats`,
              });
            }
          });
        }
      });
    });
  };

  // columns of invitations table
  const columns = [
    {
      title: "",
      dataIndex: "logo",
      key: "logo",
      width: 50,
      render: (logo: string, record: any) => (
        <Avatar
          shape="square"
          size="large"
          src={logo || null}
          className={!logo ? "bg-tacitbase-primary1 uppercase" : ""}
        >
          {!logo ? record.application_name?.[0] : null}
        </Avatar>
      ),
    },
    {
      title: "Organization",
      dataIndex: "application_name",
      key: "application_name",
      render: (application_name: any) => (
        <Text>{capitalizeFirstLetter(application_name)}</Text>
      ),
    },
    {
      title: "Invited by",
      dataIndex: "inviter",
      key: "inviter",
      render: (inviter: any) => `${inviter.first_name} ${inviter.last_name}`,
    },
    {
      title: "Date of invitation",
      dataIndex: "created_at",
      key: "date",
      render: (created_at: any) => `${renderCreatedAt(created_at)}`,
    },
    {
      title: "",
      key: "actions",
      render: (text: any, record: any) => (
        <div className="flex justify-end gap-8">
          <Button
            danger
            loading={loadingState.decline}
            onClick={(e: any) => {
              e.stopPropagation();
              updateInvitationStatus(
                { id: record.id, status: "REJECTED" },
                record.application_id
              );
            }}
          >
            Decline
          </Button>
          <SecondaryButton
            loading={loadingState.join}
            onClick={(e) =>
              lunchInvitedOrganisation(
                record.application_id,
                e,
                record.id,
                record.role
              )
            }
          >
            Accept
          </SecondaryButton>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <Text strong>Invitations settings</Text>
      </div>
      <Divider type="horizontal" className="w-full m-0 " />
      <div className="p-4">
        <div className="rounded-sm border-tacitbase-tertiary1 border border-opacity-10">
          <Table
            scroll={{ x: "max-content"}}
            columns={columns}
            dataSource={invitations}
            rowKey="id"
            size="small"
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
  incomingInvitations: state.opening.incomingInvitations,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  initIncomingInvitations: (data: any) =>
    dispatch(initIncomingInvitations(data)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),

  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
});

export default connect(mapStateToProps, mapPropsTostate)(AccSetting);
