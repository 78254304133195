import { Button, Divider, Form, Space, Typography } from 'antd'
import React, { useEffect,  useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { checkPhoneOtp, sendOtpToUserPhone } from '../../../lib/api'
import { loginUser } from '../../../type/type'
import { AxiosError } from 'axios'
import throwError from '../../../service/throwError'
import { InputOTP } from 'antd-input-otp'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { phoneVerify } from '../../../redux/actions/opening/action'
import {
  CheckOutlined,
  EditOutlined,
  LoadingOutlined
} from "@ant-design/icons"
const { Text } = Typography
type PhoneVerificationProps = {
  loginUser: loginUser,
  phoneVerify(): void,
  onChangeMobileInput: (mobileNumber: string) => void,
  mobileNumber: string
}
const PhoneVerification: React.FC<PhoneVerificationProps> = ({
  loginUser,
  phoneVerify,
  onChangeMobileInput,
  mobileNumber
}) => {
  const [mobile, setMobile] = useState(mobileNumber)
  const [validMobile, isValidMobile] = useState(true)
  const [otpValues, setOtpValues] = useState<string[]>([])
  const [showEnterOTP, setShowEnterOTP] = useState(false)
  const [isOtpVerified, setIsOtpVerified] = useState(false); // State to track OTP verification status
  const [loadingState, setLoadingState] = useState({ sendOTPButton: false, verifyOTPButton: false, resendOTP: false })
  const setMobileNumber = (e: any) => {
    isValidMobile(e !== "")
    setMobile(e)
    onChangeMobileInput(e)
  }
  const [isOtpComplete, setIsOtpComplete] = useState(false)



  const verifyPhone = (isResend = false) => {
    const req = {
      user_id: loginUser.user.id,
      mobile_number: "+" + mobile,
    };

    setShowEnterOTP(true)
    setLoadingState((prevState) => ({ ...prevState, [isResend ? 'resendOTP' : 'sendOTPButton']: true }))

    if (validMobile) {
      sendOtpToUserPhone(req)
        .then((res) => {
          if (res) {
            setLoadingState((prevState) => ({ ...prevState, [isResend ? 'resendOTP' : 'sendOTPButton']: false }))
            setShowEnterOTP(true)
          }
        })
        .catch(() => {
          setLoadingState((prevState) => ({ ...prevState, [isResend ? 'resendOTP' : 'sendOTPButton']: false }))
        });
    }
  }

  useEffect(() => {
    verifyPhone()
  }, []);




useEffect(() => {
  if (otpValues.length === 6 && otpValues.every(value => value !== "")) {
    setIsOtpComplete(true)
  } else {
    setIsOtpComplete(false)
  }
}, [otpValues])

useEffect(() => {
  if (isOtpComplete) {
    otpVerify()
  }
}, [isOtpComplete])



  const otpVerify = (e?: any) => {
    e?.preventDefault()
    setLoadingState({ ...loadingState, verifyOTPButton: true })

    if (mobile.trim().length > 0) {
      const req = {
        user_id: loginUser.user.id,
        mobile_number: "+" + mobile,
        otp: otpValues.join(""),
      }
      checkPhoneOtp(req)
        .then((res:any) => {
          if (res) {
            phoneVerify()
            setTimeout(() => {
            setIsOtpVerified(true);
          }, 1000);
            setLoadingState({ ...loadingState, verifyOTPButton: false })
          }
        })
        .catch((reason: AxiosError) => {
          throwError(reason)
          setLoadingState({ ...loadingState, verifyOTPButton: false })
        })
    }
  }
  return (
    <>
      <Divider />
      <Form
        name="normal_login"
        className="login-form text-xs w-full"
        initialValues={{ remember: true }}
        layout="vertical"

      >
        {!showEnterOTP &&
          <div className="flex w-full space-x-6">
            <Form.Item
              className="font-medium w-full"
              name="firstName"
              label="Phone number"
              rules={[{ required: true, message: "Please input your mobile!" }]}
            >
              <div className="w-full flex relative">
                <div className="w-full">
                  <PhoneInput
                    enableSearch={true}
                    containerStyle={{ width: "100%" }}
                    containerClass={"react-phone-input"}
                    country={"us"}
                    inputStyle={{
                      height: 29,
                      width: "100%",
                      border: validMobile ? "" : "1px solid red",
                    }}
                    value={mobileNumber}
                    buttonStyle={{
                      border: validMobile ? "" : "1px solid red",
                    }}
                    onChange={setMobileNumber}
                    placeholder="Enter phone number"
                  />

                </div>
                <div className="absolute right-0" style={{ bottom: -1 }}>
                  <Button
                    htmlType="submit"
                    className="w-full"
                    type='primary'
                    onClick={() => verifyPhone()}
                    loading={loadingState.sendOTPButton}
                  >
                    {"Send OTP"}
                  </Button>
                </div>

              </div>

            </Form.Item>
          </div>
        }
        {showEnterOTP && (
          <>
            {showEnterOTP && (
              <div className="mb-3">
                <Space>
                  <Text>Enter the OTP sent to <strong>{mobile}</strong></Text>
                  <button
                    className="underline flex items-center justify-center space-x-2"
                    style={{ color: "#009D79" }}
                    onClick={() => { setShowEnterOTP(false), setOtpValues([]) }}

                  >
                    <EditOutlined size={12} onClick={() => setShowEnterOTP(false)}
                        />
                  </button>
                </Space>
              </div>
            )}
            <Form.Item
              className="font-medium w-full "
              name="OTP"
              rules={[
                {
                  validator: async () => Promise.resolve(),
                  required: true,
                  message: "Please input your mobile!",
                },
              ]}
              
            >
              <div className="flex flex-col space-y-2 justify-start items-start">
                <InputOTP
                  inputType="numeric"
                  onChange={setOtpValues}
                  value={otpValues}
                  wrapperStyle={{ display: "flex", justifyContent: "start" }}
                  inputStyle={{
                    height: 29,
                    fontSize: 16,
                    fontWeight: "normal",
                    display: "flex",
                                    }}
                  length={6}
                 // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus

                  />
                <button className="underline flex items-center space-x-2" style={{ color: "#009D79" }} 
                  onClick={() => verifyPhone(true)}
                >
                  <span>Resend OTP</span> <span> {loadingState.resendOTP && (
                    <div className={` flex justify-center items-center `}>
                      <div
                        style={{ borderTopColor: "#009D79" }}
                        className="w-4 h-4 border-2 border-gray-300 border-solid rounded-full animate-spin"
                      ></div>
                    </div>
                  )}</span>
                </button>
              </div>

            </Form.Item>
            {showEnterOTP && loadingState.verifyOTPButton && (<div className="flex justify-center w-full">
              {/* <Button
                htmlType="submit"
                className="w-max"
                onClick={(e) => otpVerify(e)}
                loading={loadingState.verifyOTPButton}
                type='primary'
              >
                Verify phone
              </Button> */}
              {/* <Button
              type="text"
              loading={loadingState.verifyOTPButton}
              style={{color:'green'}}
              /> */}
              <LoadingOutlined style={{ color: "#07bc0c" }} />


            </div>)
            }
             {isOtpVerified && (
              <div className="flex justify-center w-full">
                <CheckOutlined style={{ color: "#07bc0c" }} />
              </div>
            )}
          </>
        )}
      </Form >

    </>
  )
}


const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
})

const mapPropsToState = (dispatch: Dispatch) => ({
  phoneVerify: () => {
    dispatch(phoneVerify())
  },
})

export default connect(mapStateToProps, mapPropsToState)(PhoneVerification);

