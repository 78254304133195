import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { connect } from "react-redux";
// import { IntegratedMember,  Orgs, Plan, Product, ThirdPartyIntegrations, loginUser } from "../../type/type.d"
import { Domain, IntegratedMember,  Orgs, Plan, Product, ThirdPartyIntegrations, loginUser } from "../../type/type.d"
import { Avatar, Button } from "antd"
import { useHistory } from "react-router-dom";
import {
  getDomain,
  getAllSources,
  getAnalyticsListLabel,
  getAvailableIntegrations,
  getBillingActivePlans,
  // getBillingActivePlans,
  getCompanies,
  getIntegratedSlackMember,
  getInvitations,
  getOrgDetails,
  getSubscriptionDetails,
  getUser,
  updateInvitationStatusApi,
} from "../../lib/api";
import {
  add_current_plan,
  addDomainOfOrg,
  changeLoaderStatus,
  clearTabStates,
  current_org,
  getActiveBoard,
  initAnalyticsLabel,
  initCurrentOrg,
  initJobPlatform,
  initSources,
  integratedSlackMember,
  // addStripeStatus,
  showOrg,
  updateCareerPageDetails,
  updateOrgNameId,
} from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
// import { OWNER } from "../../constant";
import { OWNER, PlansName } from "../../constant";
import { orgConfigurationMiddleware } from "../../service/orgConfigurationMiddleware";
import { Source } from "../../lib/apiRes";
type orgSwitcherProps = {
  loginUser: loginUser;
  applicationId: string;
  updateIdName(orgData: any): void;
  add_current_plan:(product:Product[])=>void;
  addOrg(orgData: any): void;
  initOrg(): void;
  clearTabStates(): void;
  currentOrg(orgData: any): void;
  initAnalyticsLabel: (label: any) => void,
  changeInLoaderStatus(payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }): void;
  integratedSlackMember(IntegratedMember: IntegratedMember[]): void,
  updateCareerPageDetails(details: { [key: string]: string }): void;
  initSources(sources:Source[]):void;
  initJobPlatform(Integrations: ThirdPartyIntegrations[]):void;
  getActiveBoard(data: any):void;
  addDomainOfOrg(domainRes: Domain): void;

};

interface LoadingState {
  [key: string]: {
    join: boolean;
    decline: boolean;
  };
}

const OrgSwitcher = (props: orgSwitcherProps) => {
  const [invitations, setInvitations] = useState([]);
  const [loadingState, setLoadingState] = useState<LoadingState>({});
  useEffect(() => {

    getUser(props.loginUser.user.id).then((res)=>{
      if (!res?.verified) {
        history.push("/register/verification/email");
      } else if (!res?.data?.verifiedPhone) {
        history.push("/register/verification/phone");
      } else if (!res?.username) {
        history.push("/register/setup/username");
      }     
    })  
    props.initOrg();
    localStorage.removeItem("orgId");

    props.changeInLoaderStatus({ loader: false, message: "", skeleton: false });
    props.clearTabStates();
    getInvitations(props.loginUser.user.email).then((invitations: any) => {
      setInvitations(invitations);
    });


  }, []);
  const history = useHistory();
  const [clicked, setClicked] = useState(false);
  const handleClick = (e: any) => {
    e.preventDefault();
    setClicked(true);
    history.push('/create-org')
   
  };

  const lunchOrg = (applicantion_id: string, role: string) => {
    sessionStorage.setItem("pageLoading", true.toString());
    props.changeInLoaderStatus({
      loader: true,
      message: "Stepping into your organization... Almost there!",
      skeleton: false,
    });

    getOrgDetails(applicantion_id).then((appRes) => {
      localStorage.setItem("orgId",appRes.application.id);
      props.updateCareerPageDetails(appRes.application.data);
      getBillingActivePlans("tacitbase").then((res) => {
        const unique: Product[] = [];
        if (res.plans && Array.isArray(res.plans)) {
            res.plans.forEach((plan: Plan) => {
                if (!unique.some((item) => item.id=== plan.product.id)) {
                    unique.push(plan.product);
                }
            });
            props.add_current_plan(unique);
        } 
    }).catch((err) => {
        console.log("Error fetching billing plans:", err);
    });
      return orgConfigurationMiddleware(appRes, props.loginUser).then((flag) => {
        localStorage.setItem("orgId", appRes.application.id)
        if (flag) {
          getSubscriptionDetails().then((res)=>{
            props.getActiveBoard(res?.subscriptions?.job_board)
          }).catch((err)=>{
            console.log("Error getting subscription details",err);
          })
          sessionStorage.removeItem("slackCode")
          let updatedOrg = {
            applicationId: applicantion_id,
            id: appRes.application.id,
            applicationName: appRes.application.name,
            verified: true,
            data: {
              logo: "",
              description: "",
            },
            roles: role,
          }
          props.updateIdName(updatedOrg)
          props.currentOrg(appRes);

          if (
            appRes.setup_stages.every(
              (currentStage) => currentStage.stage === "done"
            )
          ) {
            if (
              (appRes.customer?.subscriptions?.data[0]?.status === "active" ||
              appRes.customer?.subscriptions?.data[0]?.status === "trialing")||(  (appRes.customer.metadata.plan).toLowerCase() === PlansName.free.toLowerCase())
            ) {
              getDomain().then((res) => {
                props.addDomainOfOrg(res);
              });
              return getCompanies().then((res) => {
                if (res.length > 0) {
                  history.push(`/o/${applicantion_id}/ats`);
                  return getAnalyticsListLabel().then((analyticsLabels) => {
                    props.initAnalyticsLabel(analyticsLabels);
                  }).then(() => {
                    return getIntegratedSlackMember().then((Slackmembers) => {
                      integratedSlackMember(Slackmembers);
                      return getAllSources().then((source) => {
                        props.initSources(source);
                        return getAvailableIntegrations().then((integ) => {
                          props.initJobPlatform(integ)
                        })
                      })
                    });
                  })
                } else {
                  history.push(`/o/workspace`)
                }
              })
              
            } else if (role.toUpperCase() === OWNER.toUpperCase()) {
              history.push("/o/subscription-expired")
            } else {
              history.push("/contact-admin")
            }
          } else if (
            appRes.setup_stages.every(
              (currentStage) => currentStage.stage === "init"
            )
          ) {
            history.push("/o/choose-plan")
          } else {
            sessionStorage.setItem("pageLoading", true.toString())
            history.push(`/o/start-setup`)
          }
        } else {
          // Flag is false, handle accordingly
          console.log("Token exchange failed or flag is false")
        }
      })
    }).finally(() => {
      sessionStorage.setItem("pageLoading", false.toString());
      props.changeInLoaderStatus({
        loader: false,
        message: "",
        skeleton: false,
      })
    }).catch((error) => {
      // Handle errors from orgConfigurationMiddleware or getOrgDetails
      console.error("Error in token exchange middleware or getOrgDetails:", error)
      sessionStorage.setItem("pageLoading", false.toString());
      props.changeInLoaderStatus({
        loader: false,
        message: "",
        skeleton: false,
      });
    });
  };

  const lunchInvitedOrganisation = (
    applicantion_id: any,
    e: any,
    id: string,
    orgRole: string
  ) => {
    e.stopPropagation();

    setLoadingState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id], 
        join: true,
      },
    }));
  
    getOrgDetails(applicantion_id).then((appRes) => {
      const statusUpdate = {
        id: id,
        status: "ACCEPTED",
      };
      updateInvitationStatusApi(statusUpdate).then((res) => {
        if (res) {
          if (res.status == "ACCEPTED") {
            setLoadingState((prevState) => ({
              ...prevState,
              [id]: {
                join: false,
                decline: false, 
              },
            }));
          }

          orgConfigurationMiddleware(appRes, props.loginUser).then((flag) => {
            if (flag) {
              // updateInvitationStatus(statusUpdate, appRes.application.id);
              sessionStorage.removeItem("slackCode");

              let updatedOrg = {
                applicationId: applicantion_id,
                id: appRes.application.id,
                applicationName: appRes.application.name,
                verified: true,
                data: {
                  logo: "",
                  description: "",
                },
                roles: orgRole,
              };
              props.updateIdName(updatedOrg);
              props.currentOrg(appRes);
              history.push({
                pathname: `/o/${applicantion_id}/ats`,
              });
              getAnalyticsListLabel()
              .then(analyticsLabels => {
                props.initAnalyticsLabel(analyticsLabels);
              })
              .catch(error => {
                console.error("Error fetching analytics labels:", error);
              });
            }
          });
        }
      });
    });
  };

  const declineOrg = (orgid: string) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [orgid]: {
        join: false,
        decline: false, 
      },
    }));

    let newInvitations = invitations.filter((org: any) => org.id != orgid);
    setInvitations(newInvitations); 
  };
  const updateInvitationStatus = async (req: {id:string,status:any}, applicantion_id: string) => {
    updateInvitationStatusApi(req).then((res: any) => {
      if (res) {
        if (req.status == "ACCEPTED") {
          setLoadingState((prevState) => ({
            ...prevState,
            [req?.id]: {
              join: false,
              decline: false, 
            },
          }));

          props.changeInLoaderStatus({
            loader: false,
            message: "",
            skeleton: true,
          });
          history.push({
            pathname: `/o/${applicantion_id}/ats`,
          });
          getAnalyticsListLabel()
          .then(analyticsLabels => {
            initAnalyticsLabel(analyticsLabels);
          })
          .catch(error => {
            console.error("Error fetching analytics labels:", error);
          });
        } else {
          declineOrg(req.id);
        }
      }
    });
  };
  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClickhover = (
    index: any,
    applicationId: string,
    roles: string
  ) => {
    setClickedIndex(index);
    lunchOrg(applicationId, roles);
    setTimeout(() => {
      setClickedIndex(null);
    }, 500);
  };

  return (
    <>
      <div>
        <div className=" flex flex-col space-y-6">
          <div className="w-full flex items-center justify-start font-semibold md:text-lg text-base">
            {capitalizeFirstLetter("Welcome")}
            &nbsp;{props.loginUser.user.firstName},
          </div>
          <div className="flex flex-col space-y-5 lg:text-sm text-xs ">
            <div>
              Great to see you! Make smarter hiring decisions-because your team
              deserves the best of the best!
            </div>
            <div className="font-semibold">Happy hiring!!!</div>

            <div className="flex flex-col w-full justify-end"></div>

            <div className=" xl:max-h-80 2xl:max-h-128 overflow-y-scroll flex flex-col space-y-4">
              {invitations?.length > 0 && (
                <>
                  <hr />
                  <div>Invitations</div>
                  <div className="flex flex-col space-y-2 h-full mr-2 ">
                    {invitations?.length > 0
                      ? invitations.map((invitation: any, key: number) => (
                          <div
                          className=" rounded  py-2 mx-1 flex items-center space-x-2  w-full h-20"
                          style={{
                            backgroundColor: "#F9F9FF", boxShadow:
                              "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
                          }}

                            onKeyDown={() => {}}
                            tabIndex={0}
                            role="button"
                            key={key}
                          >
                            <div
                              className="w-2/12 flex justify-center"
                              style={{ width: "15%" }}
                            >

                            <Avatar
                              shape="square"
                              size={"large"}
                              className="bg-tacitbase-primary1 uppercase"
                            >
                              {invitation.application_name[0]}
                            </Avatar>
                            </div>
                            <div
                              className="flex flex-col w-4/12 justify-start space-y-1"
                              style={{ width: "60.5%" }}
                            >
                              <div className="font-medium">
                                {invitation.application_name}
                              </div>
                              <div
                                className="text-xs px-0.5 py-0.5  w-max rounded border"
                                style={{
                                  borderColor: "#3B3898",
                                  color: "#3B3898",
                                }}
                              >
                                {invitation.role}
                              </div>
                              <div className="text-xs">
                                Invited by: {invitation.inviter.first_name}{" "}
                                {invitation.inviter.last_name}
                              </div>
                            </div>

                            <div
                            className="flex flex-col justify-start space-y-2 "
                              // style={{ width: "10%" }}
                            >
                              <Button
                                size="small"
                                // style={{ backgroundColor: "#4B48A5", color: "white" }}
                                htmlType="submit"
                                danger
                                className="login-form-button w-full"
                                loading={loadingState[invitation.id]?.decline}
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  updateInvitationStatus(
                                    {
                                      id: invitation.id,
                                      status: "REJECTED",
                                    },
                                    invitation.application_id
                                  );
                                }}
                                // type="primary"
                              >
                                Decline
                              </Button>
                              <Button
                                size="small"
                                style={{
                                  backgroundColor: "#4B48A5",
                                  color: "white",
                                }}
                                htmlType="submit"
                                loading={loadingState[invitation.id]?.join}
                                className="login-form-button"
                                onClick={(e) => {
                                  lunchInvitedOrganisation(
                                    invitation.application_id,
                                    e,
                                    invitation.id,
                                    invitation.role
                                  );
                                }}
                              >
                                Join
                              </Button>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </>
              )}
              {props.loginUser.user.registrations?.length > 0 && (
                <>
                  <hr />
                  <div>Organizations</div>

                  <div className="flex flex-col space-y-2 h-full pb-2 mx-1 ">
                    {props?.loginUser?.user?.registrations
                      ?.sort((a: Orgs, b: Orgs) => {
                        return a?.username?.localeCompare?.(b?.username);
                      })
                      .map((org: any, index: any) => (
                        <div
                          className=" rounded  py-2 flex items-center space-x-2  w-full h-20"
                          key={index}
                          style={{
                            backgroundColor:
                              clickedIndex === index ? "#E5E7EB" : "#F9F9FF",
                            cursor: "pointer",
                            transition: "background-color 0.5s ease",
                            boxShadow:
                              clickedIndex === index
                                ? "0 0 10px -3px rgba(0, 0, 0, 0.5)"
                                : "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",


                          }}
                          onClick={() =>
                            handleClickhover(
                              index,
                              org.applicationId,
                              org.roles[0]
                            )
                          }
                          onKeyDown={() => {}}
                          tabIndex={0}
                          role="button"
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#E5E7EB")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor = "#F9F9FF")
                          }
                        >
                          <div
                            className="w-2/12 flex justify-center"
                            style={{ width: "15%" }}
                          >
                            {org["data"] && org.data.logo ? (
                              <Avatar
                                shape="square"
                                size={"large"}
                                src={org["data"].logo}
                              ></Avatar>
                            ) : (
                              <Avatar
                                shape="square"
                                size={"large"}
                                className="bg-tacitbase-primary1 uppercase"
                              >
                                {org.username?.[0]}
                              </Avatar>
                            )}
                          </div>
                          <div
                            className="flex flex-col w-4/12 justify-start space-y-1"
                            style={{ width: "54.5%" }}
                          >
                            <div className="font-medium">{org.username}</div>
                            <div
                              className="text-xs px-0.5 py-0.5  w-max rounded border"
                              style={{
                                borderColor: "#3B3898",
                                color: "#3B3898",
                              }}
                            >
                              {org.roles ? org.roles[0] : ""}
                            </div>
                            <div className="text-xs invisible">
                              Invited by:{" "}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                style={{
                  color: clicked ? "#4CAF50" : "#009D79",
                  transition: "color 0.3s",
                }}
                className="hover:text-green-900 hover:underline"
                onClick={handleClick}
              >
                Create new organization
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapPropsToState = (dispatch: Dispatch) => ({
  addOrg: (orgData: any) => dispatch(showOrg(orgData)),
  initOrg: () => dispatch(initCurrentOrg()),
  clearTabStates: () => dispatch(clearTabStates()),
  initAnalyticsLabel: (label: any) => dispatch(initAnalyticsLabel(label)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),

  currentOrg: (orgData: any) => dispatch(current_org(orgData)),
  changeInLoaderStatus: (payload: {
    loader: boolean;
    message: string;
    skeleton: boolean;
  }) => dispatch(changeLoaderStatus(payload)),
  updateCareerPageDetails: (details: { [key: string]: string }) =>
    dispatch(updateCareerPageDetails(details)),
  integratedSlackMember: (IntegratedMember: IntegratedMember[]) => dispatch(integratedSlackMember(IntegratedMember)),
  initSources: (sources: Source[]) => dispatch(initSources(sources)),
  initJobPlatform: (Integrations: ThirdPartyIntegrations[]) => dispatch(initJobPlatform(Integrations)),
  add_current_plan:(data:Product[]) => dispatch(add_current_plan(data)),
  getActiveBoard:(data: any)=> dispatch(getActiveBoard(data)),
  addDomainOfOrg: (domainRes: Domain) => dispatch(addDomainOfOrg(domainRes)),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

export default connect(mapStateToProps, mapPropsToState)(OrgSwitcher);
