import React, { useState } from "react";
import CardDescription from "../cardDescription/CardDescription";
// import Activity from "../activity/Activity";
import CardPreviewLabel from "../cardLabel/CardLabel";
// import CommentInput from "../comment/commentInput/CommentInput";
import CardMember from "../cardMember/CardMember";
import Attachment from "../attachement/Attachment";
import CardActivity from "../cardActivity/CardActivity";
import Comment from "../comment/CardHistory";
import { connect } from "react-redux";
import { Opening } from "../../../type/type";
import CardEmailAndComment from "../cardEmailCommentAction/CardEmailAndComment";
import CardLinkedDetails from "../CardLinkedDetails"

type CardContentProps = {
  currentOpening: Opening;
};
const CardContent = (props: CardContentProps) => {
  const [showDetails, isShowDetailsTrue] = useState(true);

  return (
    <div
      className={`${
        props.currentOpening.access.comment_only ? "w-full pr-10" : "w-9/12"
      } max-w-full p-1`}
    >
      <CardLinkedDetails />
      <div className="flex flex-wrap mb-2 ml-12 ">
        <CardMember />
        <CardPreviewLabel />
      </div>

      <CardDescription />
      <Attachment />
      {/* <CommentInput /> */}
      <CardEmailAndComment />

      <CardActivity
        isShowDetailsTrue={(value: boolean) => isShowDetailsTrue(value)}
        showDetails={showDetails}
      />
      <Comment showDetails={showDetails} />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});
export default connect(mapStateToProps)(CardContent);
