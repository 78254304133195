import React from "react";

import { Form, Input } from "antd";

const SetSubject = (props: { onchange: (value: string) => void }) => {
  return (
    <>
      <Form.Item
  label={<span className="text-xs">Subject</span>}
  name="subject"
        data-testid="subject-input"
        rules={[{ required: true, message: "Please enter subject!" }]}
      >
        <Input
          onChange={(e) => {
            props.onchange(e.target.value);
          }}
        />
      </Form.Item>
    </>
  );
};
export default SetSubject;
