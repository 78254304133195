import React from 'react';

const CloseInCircle = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2572 7.11708C15.2572 7.00905 15.1689 6.92065 15.0608 6.92065L13.4403 6.92802L10.9997 9.83762L8.56149 6.93048L6.93849 6.92311C6.83046 6.92311 6.74207 7.00905 6.74207 7.11954C6.74207 7.16619 6.75925 7.21039 6.78872 7.24722L9.98314 11.053L6.78872 14.8564C6.75905 14.8924 6.74259 14.9374 6.74207 14.984C6.74207 15.0921 6.83046 15.1805 6.93849 15.1805L8.56149 15.1731L10.9997 12.2635L13.4378 15.1707L15.0584 15.178C15.1664 15.178 15.2548 15.0921 15.2548 14.9816C15.2548 14.9349 15.2376 14.8907 15.2081 14.8539L12.0186 11.0506L15.213 7.24476C15.2425 7.21039 15.2572 7.16373 15.2572 7.11708Z" fill="#F10505"/>
        <path d="M11 0C4.92545 0 0 4.92545 0 11C0 17.0746 4.92545 22 11 22C17.0746 22 22 17.0746 22 11C22 4.92545 17.0746 0 11 0ZM11 20.1339C5.9567 20.1339 1.86607 16.0433 1.86607 11C1.86607 5.9567 5.9567 1.86607 11 1.86607C16.0433 1.86607 20.1339 5.9567 20.1339 11C20.1339 16.0433 16.0433 20.1339 11 20.1339Z" fill="#F10505"/>
        </svg>
    );
};

export default CloseInCircle;

