import { Typography } from 'antd';
import React from 'react'

type ConfirmationMessageProps = {
message:string;
firstName:string;
lastName:string;
}
const { Paragraph, Text } = Typography;
const ConfirmationMessage = (props:ConfirmationMessageProps) => {
  return (
    <Paragraph>
    Are you sure you want to <Text>{props.message}</Text>{' '}
    <Text strong>
      {props.firstName}{' '}
      {`${props.lastName}?`}
    </Text>
    <br />
    This action is irreversible.
  </Paragraph>
  )
}

export default ConfirmationMessage
