import React, { useEffect, useRef, useState } from "react";
import { Divider, Empty, Popconfirm, Space, Table } from "antd";
import {
  getCandidates,
  getTable,
  searchCandidateDocument,
} from "../../lib/api";
import {
  Candidate,
  FormBuilder,
  Opening,
  TableColumn,
  initCDbMembersCDB,
  loginUser,
  searchDynamicQuery,
} from "../../type/type";
import {
  COLLECTION_CDB_CANDIDATES,
  colors,
  DATE_FORMATE,
  DIVIDER_HEIGHT,
  NOT_AVAILABLE,
} from "../../constant";
import { connect } from "react-redux";
import TableAnt from "../../ant/TableAnt";
// import { IconButton } from "../../ant/Button";
import { LockOutlined, ReloadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import {
  initCDBHead,
  initCandidates,
  initSearchFilter,
  initSearchQuery,
} from "../../redux/actions/opening/action";
import SendCDBCandidateToTracker from "./actions/SendCDBCandidateToTracker";
import SendCDBCandidateToReview from "./actions/SendCDBCandidateToReview";
import AddBulkLabelToCandidateInCDB from "./AddBulkLabelsToCDBCandidates";
import SendMailToCDBCandidate from "./actions/SendMailToCDBCandidate";
import { capitalizeFirstLetter, truncateText } from "../../utils/capitalizeFirstLetter";
import moment from "moment";
import ApplicationDetailedViewCDB from "./ApplicationDetailedViewCDB";
import { generateSearchQuery } from "../../utils/SearchDynamicQuery"; // Import your utility
import TacitbaseAvtar from "../../ant/Avtar";
import { servicesMembership } from "../../redux/reducers/initialState";
import CandidateDBTableHeader from "./CandidateDBTableHeader";
import { IconButton } from "../../ant/Button";
import CDBSearchBox from "./CDBSearchBox";
import CDBFilters from "./CDBFilters";

// Type definition for the Redux props
type TableReduxProps = {
  currentOpening: Opening;
  applicationId: string;
  initCandidates: (members: Candidate[], currentPage: string) => void;
  candidatePool: any;
  currentTrackerForm: FormBuilder;
  candidateDBTableHeader: any;
  initCDBHead: (table: any) => void;
  candidateDB: initCDbMembersCDB;
  searchQuery: searchDynamicQuery;
  sources: { [key: string]: { name: string; profile: string } };
  servicesMembership: servicesMembership;
  loginUser: loginUser;
  initSearchQuery: (query: string) => void;
  initSearchFilter: (filterObj: any) => void;
};

const CDBTable = ({
  applicationId,
  initCandidates,
  candidateDBTableHeader,
  initCDBHead,
  searchQuery,
  sources,
  initSearchQuery,
  initSearchFilter,
  candidateDB
}: TableReduxProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [selectedRowKeyData, setSelectedRowKeyData] = useState<any[]>([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<string>("");
  const [error, setError] = useState(false); // Error state
  const [headerError, setheaderError] = useState(false); // Error state
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const columns: any[] = [
    ...candidateDBTableHeader.map((heading: TableColumn) => ({
      title: heading.secret? <><LockOutlined/> {capitalizeFirstLetter(heading.view)}</>: capitalizeFirstLetter(heading.view),
      dataIndex: heading.custom? heading.view : heading.name.toLowerCase(),
      key: heading.name.toLowerCase(),
      width: 170,
      className: heading.secret? 'bg-red-50' : '', 
      fixed: ["first_name", "last_name"].includes(heading.name.toLowerCase())
        ? "left"
        : undefined, // Fix the first two columns

      render: (text: any) => {
        if (text === undefined || text === null) {
          return null;
        }

        const isNameColumn = ["first_name", "last_name"].includes(
          heading.name.toLowerCase()
        );
        const displayText = isNameColumn
          ? capitalizeFirstLetter(text.toString().trim())
          : text.toString().trim();

        return (
          <div>
            {heading.type === "paragraph" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: truncateText(displayText, 15),
                }}
              />
            ) : (
              truncateText(displayText, 15)
            )}
          </div>
        );
      },
      onHeaderCell: () => {
        return {
          style: {
            backgroundColor: heading.secret ? '#FDE7E7' : '', // Apply color conditionally
          },
        };
      },
    })),
    {
      // width: "20%",
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 150,
      ellipsis: true,
      render: (sourceKey: any) => {
        const sourceName = sources[sourceKey.from]
          ? capitalizeFirstLetter(sources[sourceKey?.from]?.name)
          : capitalizeFirstLetter(sourceKey?.from);
        const sourceImage =
          sources[sourceKey?.from]?.profile &&
          sources[sourceKey?.from]?.profile;
        return sourceKey.from.trim().length === 0 ? (
          NOT_AVAILABLE
        ) : (
          <Space className="truncate w-full">
            {sourceImage ? (
              // <img src={sourceImage} alt={sourceName} style={{ width: 20, height: 20, borderRadius: '50%' }} />
              <TacitbaseAvtar src={sourceImage} />
            ) : (
              <TacitbaseAvtar content={sourceName[0]} />
            )}
            <div className="truncate">{truncateText(sourceName, 11)}</div>
          </Space>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      align: "right",
      width: 150,
      render: (text: any) => {
        if (text === undefined || text === null) {
          return null;
        }
        return <div>{moment(text).format(DATE_FORMATE)}</div>;
      },
    },
  ];

  useEffect(() => {
    getTable()
      .then((res: { columns: any }) => {
        initCDBHead(res.columns);
      })
      .catch(() => {
        setheaderError(true);
      });
  }, []);

  const history = useHistory();

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 180.8px)";
    }
  }, [data]);

  useEffect(() => {
    // Clean up the previous timeout if it exists
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      if (tableParams.pagination.current) {
        fetchData({ currentPage: tableParams.pagination.current });
      } else {
        setError(true);
      }
    }, 100); // Debounce delay

    // Cleanup on unmount
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [tableParams.pagination.current, searchQuery]);


  const generateData = (items: any[]) => {
    return items.map((item) => {
      // Create a new object spreading all fields from the original item
      let newItem = {
        ...item,
        key: item.id,
      };

      // Check if the 'customs' object exists and is not empty
      if (item.customs && Object.keys(item.customs).length > 0) {
        // Spread the fields from 'customs' into the newItem
        newItem = {
          ...newItem,
          ...item.customs,
        };
        // Remove the 'customs' property from newItem
        delete newItem.customs;
      }

      return newItem;
    });
  };

  useEffect(() => {
    // Populate data from candidateDB when available
    if (candidateDB[tableParams.pagination.current]) {
      setData(generateData(Object.values(candidateDB[tableParams.pagination.current])));
    } else {
      setData([]);
    }
  }, [candidateDB]);

  // Rest of y
  const fetchData = (reqParams: { currentPage: number }) => {
    setLoading(true);
    setError(false);
    const searchQueryParam =
      (searchQuery && searchQuery.query.trim().length > 0) ||
      searchQuery.filters.length > 0
        ? generateSearchQuery(
            searchQuery.query,
            searchQuery.filters,
            reqParams.currentPage,
            COLLECTION_CDB_CANDIDATES
          )
        : null;

    const apiCall = searchQueryParam
      ? searchCandidateDocument(searchQueryParam)
      : getCandidates(reqParams.currentPage, 25);

    apiCall
      .then((res: { items: Candidate[]; total_count: any }) => {
        setLoading(false);
        initCandidates(res.items, reqParams.currentPage.toString());

        setTableParams((prev: any) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.total_count,
          },
        }));
      })
      .catch((error: any) => {
        setError(true);
        console.error("Error fetching data:", error);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle row selection changes
  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: any) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRowKeyData(selectedRows);
  };

  // Handle table changes
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    setSelectedRowKey(record?.id);
    history.push(`/o/${applicationId}/db/${record?.id}`);
  };

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  const handleRefreshTab = () => {
    initSearchQuery("");
    initSearchFilter([]);
    setSelectedRowKeys([]);
  setSelectedRowKeyData([]);
    fetchData({
      currentPage: tableParams.pagination.current,
    });
  };

  useEffect(()=>{
    initSearchQuery("");
  },[])

  // Render bulk action controls for selected rows
  const tableBulkAction = () => (
    <div className="flex h-12 flex-row justify-between  px-4 border-b"><Space className="flex  items-center ">
      <strong>{selectedRowKeys.length}</strong> Selected
      <AddBulkLabelToCandidateInCDB selectedRowKeys={selectedRowKeys} />
      <SendCDBCandidateToTracker
        isBulk={true}
        candidateIds={selectedRowKeys}
        isCollapsed={true} />
      <SendCDBCandidateToReview isBulk={true} candidateIds={selectedRowKeys} />
      <SendMailToCDBCandidate
        isBulk={true}
        candidateIds={selectedRowKeys}
        cadidatesData={selectedRowKeyData} />
      <Popconfirm
        title="Close review"
        onConfirm={() => { } }
        okText="Yes"
        cancelText="No"
        placement="bottom"
        key={2} />
    </Space>
    {/* {searchQuery?.filters?.length>0 || searchQuery?.query?.length>0 && */}
    <div className="flex flex-row items-center">
      <div className="flex flex-row space-x-2 items-center">
        <CDBSearchBox /><CDBFilters />
        </div>
    <Divider
                    type="vertical"
                    style={{
                      height: DIVIDER_HEIGHT,
                      borderColor: `${colors.divider}`,
                    }} />

                  <IconButton
                    tooltipTitle="Refresh tab"
                    icon={<ReloadOutlined />}
                    onClick={handleRefreshTab} 
                    disabled={true}/>
    </div>
</div>
  );

  return (
    <>
    {selectedRowKeys?.length > 0 ? (
              tableBulkAction()
            ) : (
              <div className="h-12 flex flex-row justify-between w-full px-4 items-center border-b">
                <div className=" flex flex-row space-x-2 justify-start w-full">
                  <CandidateDBTableHeader />
                </div>
                <div className="flex justify-end items-center">
                  <Divider
                    type="vertical"
                    style={{
                      height: DIVIDER_HEIGHT,
                      borderColor: `${colors.divider}`,
                    }} />

                  <IconButton
                    tooltipTitle="Refresh tab"
                    icon={<ReloadOutlined />}
                    onClick={handleRefreshTab} />
                </div>
              </div>
            )}
      {error || headerError ? (
        <div className="flex justify-center items-center h-full ">
          <Empty
            // image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Unable to fetch data , please try again..."
          />
        </div>
      ) : (
        <><div className="">
          </div><div className="bg-white h-full z-0  pr-0.5">
              <div className="h-full">
                <TableAnt
                  rowClassName={(record) => record.id === hoveredRow ? "cursor-pointer" : ""}
                  size="small"
                  loading={loading}
                  columns={columns}
                  data={data}
                  className="h-full w-full bg-white rounded overflow-y-hidden"
                  pagination={tableParams.pagination}
                  onChange={handleTableChange}
                  scroll={{ x: 4000, y: "calc(100vh)" }}
                  rowSelection={{
                    selectedRowKeys,
                    preserveSelectedRowKeys: true,
                    onChange: onSelectChange,
                    selections: [
                      Table.SELECTION_ALL,
                      Table.SELECTION_INVERT,
                      Table.SELECTION_NONE,
                      {
                        key: "odd",
                        text: "Select Odd Row",
                        onSelect: (changeableRowKeys) => {
                          const newSelectedRowKeys = changeableRowKeys.filter(
                            (_, index) => index % 2 === 0
                          );
                          setSelectedRowKeys(newSelectedRowKeys);
                        },
                      },
                      {
                        key: "even",
                        text: "Select Even Row",
                        onSelect: (changeableRowKeys) => {
                          const newSelectedRowKeys = changeableRowKeys.filter(
                            (_, index) => index % 2 !== 0
                          );
                          setSelectedRowKeys(newSelectedRowKeys);
                        },
                      },
                    ],
                    columnWidth: 45,
                  }}
                  onRow={(record) => ({
                    onMouseEnter: () => handleRowHover(record),
                    onMouseLeave: () => handleRowHover(null),
                    onClick: () => onRowClick(record),
                  })}
                  />
              </div>
              <ApplicationDetailedViewCDB
                selectedRow={selectedRowKey}
                selectedRowKeyData={selectedRowKeyData}
                pageNumber={tableParams.pagination.current} />
            </div></>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initCDBHead: (table: TableColumn[]) => dispatch(initCDBHead(table)),
  initCandidates: (candidates: Candidate[], currentPage: string) =>
    dispatch(initCandidates(candidates, currentPage)),
  initSearchQuery: (query: string) => dispatch(initSearchQuery(query)),
  initSearchFilter: (filterObj: any) => dispatch(initSearchFilter(filterObj)),
});

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerForm: state.opening.currentTrackerForm,
  applicationId: state.opening.applicationId,
  candidateDBTableHeader: state.opening.candidateDBTableHeader,
  candidateDB: state.opening.candidateDB,
  searchQuery: state.opening.searchQuery,
  sources: state.opening.candidateSources,
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(CDBTable);
