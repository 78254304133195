import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { conferenceIntegrationGoogle } from "../../../lib/api";
import { addMeetAccess } from "../../../redux/actions/opening/action";
import { useDispatch } from "react-redux";
import { SecondaryButton } from "../../../ant/Button";
type googleProps = {
  buttonName: string;
};   

const GLoginButton = (props: googleProps) => {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const req = { code: codeResponse.code };
      await conferenceIntegrationGoogle(req).then((res) => {
        dispatch(addMeetAccess(res.user_connected_meet));
      });
    },

    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.events.public.readonly https://www.googleapis.com/auth/calendar.freebusy",
  });

  return (
    <div>
      {process.env.REACT_APP_MEET_ACCESS_CLIENT_ID ? (
        <>
          <SecondaryButton
            onClick={() => login()}
          >
              {props.buttonName}
          </SecondaryButton>
        </>
      ) : null}
    </div>
  );
};

export default GLoginButton;
