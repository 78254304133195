import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
// import AddIcon from "../../assets/Add"
import { InitLabel, Label, customeFilterType, searchDynamicQuery } from "../../type/type.d"
import ShowLabelPreview from "../card/cardLabel/labelPreview/ShowLabelPreview"
import { initSearchFilter } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import { IS, SEARCH_LABEL_PLACEHOLDER, operatorMap } from "../../constant"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Input } from "antd"
const { Search } = Input;

const CandidateLabelFilter = (props: {
    candidateLabels: InitLabel,
    initSearchFilter: (filterObj: any) => void,
    searchQuery: searchDynamicQuery,
}) => {
    const [labels, setCandidateLabels] = useState<Label[]>([])
    const [addedFilterLabels, setFilerLabel] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState("");

    const searchKeyword = (e: any) => {
        const value = e.target.value
        setSearchValue(value);
        if (value.length > 0) {
            let filterlabels: Label[] = labels.filter((label: Label) => {
                return label.name.toUpperCase().includes(e.target.value.toUpperCase())
            })
            setCandidateLabels(filterlabels)
        } else setCandidateLabels(Object.values(props.candidateLabels))
    }


    useEffect(() => {
        setCandidateLabels(Object.values(props.candidateLabels))
        let updatedFilters: string[] = props.searchQuery.filters
            .filter((filter: customeFilterType) => filter.column === "label_names" && filter.id)
            .map((filter: customeFilterType) => filter.id ? filter.id : "")

        setFilerLabel(updatedFilters);

    }, [props.candidateLabels])
    useEffect(() => {
        if (props.searchQuery.filters.length == 0) {
            setFilerLabel([])
        }
    }, [props.searchQuery])
    const addCardLabel = (labelData: Label) => {
        let found: boolean
        found = addedFilterLabels.includes(
            labelData.id
        )
        if (found) {
            setFilerLabel(addedFilterLabels.filter((labelId: string) => labelId != labelData.id))
            let updatedFilters = props.searchQuery.filters.filter(
                (filter: customeFilterType) =>
                    !(filter.column === "label_names" && filter.value === labelData.name)
            )
            props.initSearchFilter(updatedFilters)
        } else {
            setFilerLabel([...addedFilterLabels, labelData.id])
            let filterObj: customeFilterType[] = [...props.searchQuery.filters, { column: "label_names", operator: operatorMap[IS], value: labelData.name, view: "label", operatorName: IS, id: labelData.id }]
            props.initSearchFilter(filterObj)
        }
    }

    return (
        <div className="flex flex-col z-10 w-72 bg-white text-gray-800 text-sm font-normals" style={{marginLeft:'-8px'}}>
            <div className="m-2">
                <Search
                    value={searchValue}
                    placeholder={SEARCH_LABEL_PLACEHOLDER}
                    onChange={(e: any) => searchKeyword(e)}
                    data-testid="label-search-input"
                    allowClear
                    // size="default"
                    // suffix={<Emoji onEmojiSelect={onEmojiSelect} />}
                />
            </div>
            {labels.length > 0 ? (
                <div className="flex flex-col">
                    <div className="label-panal ">
                        {labels.length > 0
                            ? labels.map(
                                (label: Label) =>
                                    label && (
                                        <ShowLabelPreview
                                            key={label.id}
                                            clickOnLabel={(label) => addCardLabel(label)}
                                            label={label}
                                            searchInObject={props.candidateLabels}
                                            flag={false}
                                            searchInArray={addedFilterLabels}
                                        />
                                    )
                            )
                            : null}
                    </div>
                </div>
            ) : (
                <div className="m-2">{capitalizeFirstLetter("No label found")}</div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    servicesMembership: state.opening.servicesMembership,
    loginUser: state.opening.loginUser,
    candidateLabels: state.opening.candidateLabels,
    searchQuery: state.opening.searchQuery,
})

const mapPropsToState = (dispatch: Dispatch) => ({
    initSearchFilter: (filterObj: any) =>
        dispatch(initSearchFilter(filterObj)),
})
export default connect(mapStateToProps, mapPropsToState)(CandidateLabelFilter)
