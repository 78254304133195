import React from "react";

const Spinner = () => {
  return (
    <>
      <div className="spinner space-x-0.5 ">
        <div className="rect1 bg-color-grey"></div>
        <div className="rect2 bg-color-grey"></div>
        <div className="rect3 bg-color-grey"></div>
        <div className="rect4 bg-color-grey"></div>
        <div className="rect5 bg-color-grey"></div>
      </div>
    </>
  );
};
export default Spinner;
