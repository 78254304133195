import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../../type/type";

import CheckCircle from "../../../assets/CheckCircle";
import CloseInCircle from "../../../assets/CloseInCircle";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { phoneVerify } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";

import PhoneVerification from "./PhoneVerification";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

type PleaseVerifyEmailProps = {
  loginUser: loginUser;
  phoneVerify(): void;
};

const EmailVerified: React.FC<PleaseVerifyEmailProps> = ({ loginUser }) => {
  const [verifiedPhone, setVerifiedPhone] = useState(false);
  const history = useHistory();
  const [mobile, setMobile] = useState(loginUser.user.mobilePhone)

  useEffect(() => {
    loginUser.user.data.verifiedPhone
      ? setVerifiedPhone(true)
      : setVerifiedPhone(false);
  }, [loginUser]);
  const UserNavigationToUsername = () => {
    history.push("/register/setup/username");
  };

  return (
    <div>
      <div className="flex flex-col space-y-6 ">
        <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Account verification")}
        </div>
        <div className="flex flex-col space-y-6 lg:text-sm text-xs">
          <div>
            <div className="text-md font-light tracking-wide">
              Hey{" "}
              <span className="font-medium">{loginUser.user.firstName},</span>
            </div>
            <div>
            This extra step helps us ensure the security of your account & optimizes Tacitbase experience.
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="flex items-start space-x-2">
              <CheckCircle />
              <div>
                Your email{" "}
                <span className="font-medium">{loginUser.user.email}</span> is
                verified.
              </div>
            </div>
            <div className="flex items-start space-x-2">
              {!verifiedPhone && <CloseInCircle />}{" "}
              {verifiedPhone && <CheckCircle />}
              <div>
                Your phone{" "}
                <span className="font-medium">
                  {mobile}
                </span>{" "}
                is {verifiedPhone ? null : "not"} verified.
              </div>
            </div>
          </div>

          {!verifiedPhone && <PhoneVerification onChangeMobileInput={(e) => {
            setMobile(e)
          }} mobileNumber={mobile}
          />}
          {verifiedPhone && (
            <div className="flex justify-end w-full">
              <Button
                htmlType="submit"
                className="w-max"
                type="primary"
                onClick={() => UserNavigationToUsername()}
              >
                Next
              </Button>
            </div>
          )}
          <div></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  phoneVerify: () => {
    dispatch(phoneVerify());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerified);
