import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AddIcon from "../../assets/Add";
import { FILTER_OPERATORS } from "../../constant";
import { addCustomColumn } from "../../lib/api";
import { AddCustomColumnReq } from "../../lib/apiReq";
import { addCandidateDBTableHeader } from "../../redux/actions/opening/action";
import { CustomColumn, TableColumn } from "../../type/type";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import Dropdown from "../common/customDropdowm/Dropdown";
import PopupTitle from "../common/popupTitle/PopupTitle";
type EditTablePopupProps = {
  close: () => void;
  refer:any;
  addColumnInCDBTable: (column: CustomColumn) => void;
  candidateDBTableHeader: TableColumn[];
};
const EditTablePopup = (props: EditTablePopupProps) => {
  const columnNameRef = useRef<HTMLInputElement>(null);
  const [selectedItem, setSelectedItem] = useState<string>("string");
  const [isAddNewColumn, addNewcolumns] = useState<boolean>();
  const [secret, isSecretVisible] = useState<boolean>(false);
  const addNewClick = () => {
    addNewcolumns(true);
  };
  useEffect(() => {
    columnNameRef.current && columnNameRef.current.focus();
  });
  const addColumn = () => {
    if (columnNameRef.current) {
      let customes: TableColumn = {
        name: columnNameRef.current?.value,
        type: selectedItem,
        secret: secret,
        custom: true,
        view: columnNameRef.current?.value,
      };
      let customColumnReq: AddCustomColumnReq = {
        customs: [customes],
      };

      addCustomColumn(customColumnReq).then((res) => {
        res.map((c: CustomColumn) => {
          props.addColumnInCDBTable(c);
          isSecretVisible(false);
        });
        addNewcolumns(!isAddNewColumn);
      });
    }
  };
  return (
    <>
      <div className="customTable animate-popover_fadeIn justify-center bg-black bg-opacity-50 items-center flex fixed inset-0  outline-none focus:outline-none">
        <div className="flex items-start  relative w-full h-full justify-center overflow-y-scroll ">
          <div className="z-30 w-192 bg-gray-50 rounded  mt-12" ref={props.refer}>
            <div className="mt-2 px-2">
              <PopupTitle PopupTitle={"Add custom column"} close={props.close} />
            </div>
            <div className="customTable flex-col bg-gray-100  ">
              <div className="px-2 pt-2">
                <div className="border p-1 rounded border-tacitbase-tertiary1 border-opacity-20 ">
                  <table className="select-none ">
                    <thead className="sticky top-0">
                      <tr>
                        <th className="text-sm font-medium px-2">
                          {capitalizeFirstLetter("column name ")}
                        </th>
                        <th className="text-sm font-medium px-3">
                          {capitalizeFirstLetter("column  type")}
                        </th>
                        <th className="text-sm font-medium px-2">
                          {capitalizeFirstLetter("secracy ")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.candidateDBTableHeader.map(
                        (heading: TableColumn, index: number) => (
                          <>
                            <tr key={index}>
                              <td className=" w-4/12 px-2">
                                {capitalizeFirstLetter(heading.view)}
                              </td>
                              <td className=" w-4/12 px-3">
                                {capitalizeFirstLetter(heading.type)}
                              </td>
                              <td className=" w-4/12 px-2">
                                <input
                                  type="checkbox"
                                  data-testid="checkboxTestBtn"
                                  className="cursor-pointer mx-1"
                                  checked={heading.secret ? true : false}
                                />
                                {capitalizeFirstLetter("secret")}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                    <tr className="bg-gray-200">
                      {isAddNewColumn ? (
                        <td className="w-4/12">
                          <input
                            type="text"
                            className={`inputCSS placeholder-italic w-full  rounded p-1 text-sm`}
                            placeholder={capitalizeFirstLetter("column name")}
                            ref={columnNameRef}
                            onBlur={() => columnNameRef.current?.blur()}
                          />{" "}
                        </td>
                      ) : (
                        <td className=" w-4/12 px-2">
                          {" "}
                          <div className="flex items-center justify-start space-x-1">
                            <AddIcon size={"10px"} />
                            <button onClick={() => addNewClick()}>
                              {capitalizeFirstLetter("add new column")}
                            </button>
                          </div>
                        </td>
                      )}
                      <td className=" w-4/12 px-2">
                        <Dropdown
                          width={"w-full"}
                          selectedItem={(e: any) => setSelectedItem(e)}
                          options={FILTER_OPERATORS}
                          selectedRole={selectedItem}
                        />{" "}
                      </td>
                      <td className=" w-4/12 px-2">
                        <input
                          type="checkbox"
                          data-testid="checkboxTestBtn"
                          className="cursor-pointer mx-1"
                          onChange={(e) => isSecretVisible(e.target.checked)}
                          checked={secret}
                        />
                        {capitalizeFirstLetter("secret")}{" "}
                      </td>
                    </tr>{" "}
                  </table>{" "}
                </div>
              </div>
              <div className="flex justify-end w-full my-1 px-1">
                <button
                  onClick={addColumn}
                  className="submit-btn z-20 text-sm capitalize"
                  data-testid="clickOnslack"
                >
                  {capitalizeFirstLetter("add")}
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  candidateDBTableHeader: state.opening.candidateDBTableHeader,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addColumnInCDBTable: (table: CustomColumn) =>
    dispatch(addCandidateDBTableHeader(table)),
});
export default connect(mapStateToProps, mapPropsToState)(EditTablePopup);
