import React, { useState } from "react"
import BoardPreview from "../boardPreview/BoardPreview"
import { connect } from "react-redux"
import { OrganizedBoard, company, companyBoards, loginUser, orgMember } from "../../../type/type.d"
// import BoardPercentage from "../BoardPercentage"
import { Avatar, Col, Divider, Input, Row, Space, Typography } from "antd"
import BoardLoader from "../boardPreview/LoaderOfBoard"
import TacitbaseAvtar from "../../../ant/Avtar"
import AddManagerToWorkspace from "../../Company/AddManagerToWorkspace"
import { servicesMembership } from "../../../redux/reducers/initialState"
import { OWNER } from "../../../constant"
import { capitalizeFirstLetter, generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import { updateCompantDetails } from "../../../lib/api"
import { editCompanyDetails } from "../../../redux/actions/opening/action"
import { Dispatch } from "redux"
import CreateJobPost from "../../createBoardPopup/createBoardPreview/CreateJobPostModal"
const { Text } = Typography

export type PageContentSectionNameProps = {
  companyBoard: companyBoards[], // Assuming you have a prop named companyBoard
  openings: OrganizedBoard, // Assuming you have a prop named openings
  sortValue: any,
  filterValue?: any,
  companies: { [key: string]: company },
  searchedValue: any,
  members: orgMember,
  servicesMembership: servicesMembership,
  loginUser: loginUser,
  editCandidate: (company: company) => void;

}
const UUID_UNCATEGORIZED = "00000000-0000-0000-0000-000000000000"
const PageContentSectionName = (props: PageContentSectionNameProps) => {
  const [editingKey, setEditingKey] = useState<string>("")
  const [workspaceName, setWorkspaceName] = useState<string>("");

  let filteredCompanies = Object.values(props.companyBoard).filter(company => {
    // Check if the user is a member of the workspace
    const isMember = props.companies[company.company_id] && props.companies[company.company_id].members?.includes(props.loginUser.user.id)
    // Check if the user has access to at least one board within the workspace
    const hasBoardAccess = company.boards.some(boardId => {
      const boardData = props.openings.pageSection.openings?.[boardId]
      return boardData && boardData.members?.includes(props.loginUser.user.id) || boardData?.member_id == props.loginUser.user.id
    })
    return isMember || hasBoardAccess
  });
  if (props.searchedValue.trim().length > 0) {
    filteredCompanies = filteredCompanies.reduce((searchResult: companyBoards[], company: companyBoards) => {
      const matchingBoardIds = []

      for (const boardId of company.boards) {
        const boardData = props.openings.pageSection.openings?.[boardId] // Use optional chaining
          if (boardData && boardData.name.toLowerCase().includes(props.searchedValue.toLowerCase())) {
            matchingBoardIds.push(boardId) // Add matching board IDs to the array
          }
      }

      // If there are matching board IDs, include them in the result
      if (matchingBoardIds.length > 0) {
        searchResult.push({
          ...company,
          boards: matchingBoardIds, // Update boards with matching IDs
        })
      }

      return searchResult
    }, [])

  }

  const handleInputChange = (value: string
  ) => {
    setWorkspaceName(value)

  }
  const OnPressEnter = (event: any
  ) => {
    if (event.keyCode == "13") {
      if (workspaceName.length) {
        updateCompantDetails({
          "id": editingKey, "name": workspaceName
        }).then((res) => {
          props.editCandidate(res)
          setEditingKey("")
          // throwSuccess("Workspace updated successfully.")
        })
      } else {
        setEditingKey("")

      }
    }
  }

  let accessForWorkspace = props.members[props.loginUser.user.id]?.registrations[0].roles[0] == OWNER

  return (
    <>
      <div className="overflow-y-scroll h-full mb-1 bg-white">
        {filteredCompanies.length == 0 ? <><BoardLoader active={false} text="No job post found" /></>
          : filteredCompanies.map((company: any) => company && (
            <div className="" key={company.company_id}>
              <div className="  rounded px-2" key={company.company_id}>
                <Space className=" px-3 pt-5  rounded  items-center justify-start flex  w-full ">
                  <Avatar shape="square" size={"small"} className="">{capitalizeFirstLetter(company.company_name?.[0])}</Avatar>

                  <div className=" truncate" >
                    {editingKey == company.company_id && accessForWorkspace && company.company_id !== UUID_UNCATEGORIZED ? (
                      <Input
                        defaultValue={company.company_name}
                        maxLength={50}
                        key={company.company_id}
                        onKeyDownCapture={(value: any) =>
                          handleInputChange(value.target.value)}
                        // value={company.company_name}
                        onInput={(value: any) =>
                          handleInputChange(value.target.value)
                        }

                        onKeyDown={OnPressEnter}
                        // onEnded={(value: any) =>
                        //   onEnded(value.target.value)
                        // }
                        style={{ width: "max-content", maxWidth: "100%" }}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                      />
                    ) : (
                      <Text
                          key={company.company_id}
                        style={{ margin: 0, padding: 0 }}
                          onClick={() => setEditingKey(company.company_id)}
                          className="text-base font-medium"
                      >
                        {company.company_name}
                      </Text>
                    )}
                  </div>
                  <Avatar.Group maxCount={4} size={"small"} className="items-center justify-start flex">
                    {props.companies[company.company_id]?.members.map((memberId) => props.members[memberId] && props.members[memberId].registrations[0].data.activated &&
                      props.members[memberId].image_url ?
                      <TacitbaseAvtar toolTip={generateTooltipForMember(props.members[memberId])} size="small" key={memberId} src={props.members[memberId].image_url} >

                      </TacitbaseAvtar>
                      : <TacitbaseAvtar size="small" content={props.members[memberId]?.first_name?.[0] + props.members[memberId]?.last_name?.[0]} />

                    )}


                  </Avatar.Group>
                  {props.companies[company.company_id] && props.companies[company.company_id].members.length > 0 &&
                    <Divider type="vertical" style={{ margin: 0 }}></Divider>}
                  {accessForWorkspace && company.company_id !== UUID_UNCATEGORIZED &&
                      <AddManagerToWorkspace workspaceId={company.company_id} />
                  }
                </Space>
                {/* <CompanyAnalytics analytics={company.analytics} /> */}
              </div><div className="mx-5">
                <Divider style={{ margin: "18px 0px 16px 0px" }} /></div>
              <Row gutter={[10, 10]} wrap className="w-full pl-4">
                {company.boards.length > 0 ? company.boards.map((boardId: string) => boardId && (
                  <Col key={boardId} xs={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }}  >
                    <BoardPreview
                      key={boardId}
                      boards={props.openings.pageSection.openings[boardId]} // Assuming openings is your Redux state containing boards
                    />
                  </Col>
                )) : <CreateJobPost />}
              </Row>
            </div>
          ))}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  companyBoard: state.opening.companyBoards, // Assuming companyBoards is your Redux state containing companyBoard data
  openings: state.opening.openings, // Assuming openings is your Redux state containing boards
  companies: state.opening.companies,
  members: state.opening.members,
  servicesMembership: state.opening.servicesMembership,
  loginUser: state.opening.loginUser,
})
const mapPropsToState = (dispatch: Dispatch) => ({
  editCandidate: (candidate: company) =>
    dispatch(editCompanyDetails(candidate)),

})
export default connect(mapStateToProps, mapPropsToState)(PageContentSectionName)
