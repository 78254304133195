import React, { useState } from "react";
import { Button, Form, Input, Select, Tooltip, Typography } from "antd";
import { connect } from "react-redux";
import { updateCareerPageDetails } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { loginUser } from "../../type/type";
import FormItem from "../../ant/FormItem";
import { throwFrontEndError, throwSuccess } from "../../service/throwError";
import { updateDescription } from "../../lib/api";
import { CopyOutlined, ExportOutlined } from "@ant-design/icons";
import { LabeledIconButton } from "../../ant/Button";
import { newOrganisation } from "../../lib/apiRes";
import Editor from "../../ant/Editor/Editor";
import { SecondaryButton } from "../../ant/Button";
import { regexPatterns } from "../../constant";
const { Text } = Typography;

type Organisationprops = {
  applicationId: string;
  applicationName: string;
  applicationImgUrl: string;
  applicationDesc: string;
  updateCareerPageDetails(details: { [key: string]: string }): void;
  loginUser: loginUser;
  careerPageDetails: CareerPageDetails;
  orgData: newOrganisation;
};

export type CareerPageDetails = {
  mission_statement?: string;
  website?: string;
  location?: string;
  company_size?: string;
  linkedin?: string;
  twitter?: string;
  facebook?: string;
  insta?: string;
  youtube?: string;
  description?: string;
};

const CareerPageSetting = (props: Organisationprops) => {
  const [form] = Form.useForm();
  const [changed, isChanged] = useState(false);
  const [editable, setEditable] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);

  // updating careerPage setting on click on Update button
  function updateValues() {
    if (changed) {
      form.validateFields().then((values: any) => {
        const data = {
          application: {
            id: props?.applicationId,
            name: props?.applicationName,
            data: values,
          },
        };
        setUpdateButtonLoading(true);
        updateDescription(props?.applicationId, data).then(() => {
          setUpdateButtonLoading(false);
          setEditable(false);
          props.updateCareerPageDetails(values);
          throwSuccess("Organization details updated successfully.");
        }).catch(()=>{
          throwFrontEndError("Error while updating the career page details");
          setUpdateButtonLoading(false);
        });
      });
    } else {
      setEditable(false);
      return;
    }
  }

  // redirecting user to their career page
  function redirectToURL() {
    const fullUrl = `${process.env.REACT_APP_CAREER_PAGE_URL}/${props.orgData.career_page_url_suffix}`;
    if (props.orgData.career_page_url_suffix.length > 0) {
      window.open(fullUrl, "_blank");
    }
  }

  // function that copy the career page url
  function handleCopy() {
    const fullUrl = `${process.env.REACT_APP_CAREER_PAGE_URL}/${props.orgData.career_page_url_suffix}`;
    if (props.orgData.career_page_url_suffix.length > 0) {
      navigator.clipboard.writeText(fullUrl).then(() => {
        setTooltipVisible(true);
        setTimeout(() => setTooltipVisible(false), 1000);
      });
    }
  }

  const handleEditorChange = (value: any) => {
    if (value) {
      form.setFieldsValue({ description: value });
      isChanged(true);
    }
  };

  const companySizeOptions = [
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001+",
  ];

  // this function take url and return boolean valueof link valid or not
  const validateUrl = (url: string | undefined, pattern: RegExp) =>pattern.test(url ? url : "");


  // validator function
  const validateSocialMedia = (
    value: string | undefined,
    pattern: RegExp,
    platforms: string
  ) => {
    if(!value) return Promise.resolve();
    if (!validateUrl(value, pattern)) {
      return Promise.reject(`Invalid ${platforms} URL`);
    }
    return Promise.resolve();
  };
  
  return (
    <div>
      <div className="flex  flex-col">
        <div >
          <div className=" flex flex-col">
            <Text strong>Career page</Text>
            <Text className="font-normal">
              Any changes you make will show up on your public company career
              page.
            </Text>
          </div>
        </div>
        <Form
          initialValues={props.careerPageDetails}
          form={form}
          layout="vertical"
          className="mt-4 space-y-8"
          onValuesChange={() => isChanged(true)}
        >
          <FormItem name="public_url" label="Public page URL">
            <div className="flex space-x-1">
              <Input
                disabled={true}
                defaultValue={
                  props.orgData.career_page_url_suffix.length > 0
                    ? `${process.env.REACT_APP_CAREER_PAGE_URL}/${props.orgData.career_page_url_suffix}`
                    : "Not available"
                }
              />
              {props.orgData.career_page_url_suffix.length > 0 && (
                <Tooltip
                  className="w-8"
                  title={"Copied to clipboard!"}
                  visible={tooltipVisible}
                >
                  <Button
                    className="flex items-center justify-center"
                    style={{ borderRadius: "4px" }}
                  >
                    <CopyOutlined onClick={handleCopy} />
                  </Button>
                </Tooltip>
              )}
              {props.orgData.career_page_url_suffix.length > 0 && (
                <LabeledIconButton
                  onClick={redirectToURL}
                  label={""}
                  icon={<ExportOutlined />}
                />
              )}
            </div>
          </FormItem>
          <div className="flex gap-4 sm:flex-col md:flex-col lg:flex-row flex-col w-full">
            <div className="w-full">
              <FormItem name="location" label="Location">
                <Input
                  value={props.careerPageDetails?.location}
                  disabled={!editable}
                  type="text"
                />
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem name="company_size" label="Organization size">
                <Select
                  value={
                    editable ? props.careerPageDetails?.company_size : undefined
                  }
                  disabled={!editable}
                  className="w-full h-full"
                >
                  {companySizeOptions.map((option, index) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>
            </div>
          </div>
          <FormItem name="mission_statement" label="Mission statement">
            <Input
              value={props.careerPageDetails?.mission_statement}
              disabled={!editable}
              type="text"
            />
          </FormItem>
          <FormItem name="description" label="Company description">
            <div
              className={`${
                editable ? "setting-description" : "setting-disable"
              }`}
            >
              <Editor
                onChange={handleEditorChange}
                value={props.careerPageDetails.description}
                readOnly={!editable}
              />
            </div>
          </FormItem>
          <div className=" flex flex-col">
            <Text strong>Website and social links</Text>
            <Text >
              Update your website and social links so applicants can easily
              connect with you across all your platforms.
            </Text>
          </div>
          <div className="flex sm:flex-col md:flex-col lg:flex-row flex-col gap-4 w-full">
            <div className="w-full">
              <FormItem
               name="website" 
               label="Website"
               rules={[
                {
                  validator: (_, value) =>
                    validateSocialMedia(
                      value,
                      regexPatterns.website,
                      ""
                    ),
                },
              ]}
               >
                <Input
                  value={props.careerPageDetails?.website}
                  disabled={!editable}
                />
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem
                name="linkedin"
                label="LinkedIn"
                rules={[
                  {
                    validator: (_, value) =>
                      validateSocialMedia(
                        value,
                        regexPatterns.linked_in,
                        "LinkedIn"
                      ),
                  },
                ]}
              >
                <Input
                  value={props.careerPageDetails?.linkedin}
                  disabled={!editable}
                />
              </FormItem>
            </div>
          </div>
          <div className="flex sm:flex-col md:flex-col lg:flex-row flex-col gap-4 w-full">
            <div className="w-full">
              <FormItem
                name="twitter"
                label="Twitter"
                rules={[
                  {
                    validator: (_, value) =>
                      validateSocialMedia(
                        value,
                        regexPatterns.twitter,
                        "Twitter"
                      ),
                  },
                ]}
              >
                <Input
                  value={props.careerPageDetails?.twitter}
                  disabled={!editable}
                />
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem
                name="facebook"
                label="Facebook"
                rules={[
                  {
                    validator: (_, value) =>
                      validateSocialMedia(
                        value,
                        regexPatterns.facebook,
                        "Facebook"
                      ),
                  },
                ]}
              >
                <Input
                  value={props.careerPageDetails?.facebook}
                  disabled={!editable}
                />
              </FormItem>
            </div>
          </div>
          <div className="flex sm:flex-col md:flex-col lg:flex-row flex-col gap-4 w-full">
            <div className="w-full">
              <FormItem
                name="youtube"
                label="Youtube"
                rules={[
                  {
                    validator: (_, value) =>
                      validateSocialMedia(
                        value,
                        regexPatterns.youtube,
                        "Youtube"
                      ),
                  },
                ]}
              >
                <Input
                  value={props.careerPageDetails?.youtube}
                  disabled={!editable}
                />
              </FormItem>
            </div>
            <div className="w-full">
              <FormItem
                name="insta"
                label="Instagram"
                rules={[
                  {
                    validator: (_, value) =>
                      validateSocialMedia(
                        value,
                        regexPatterns.insta,
                        "Instagram"
                      ),
                  },
                ]}
              >
                <Input
                  value={props.careerPageDetails?.insta}
                  disabled={!editable}
                />
              </FormItem>
            </div>
          </div>
        </Form>
        <div className="flex w-full justify-end">
          {editable ? (
            <SecondaryButton onClick={updateValues} loading={updateButtonLoading}>Update</SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => setEditable(true)}>
              Edit
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
  applicationImgUrl: state.opening.applicationImgUrl,
  applicationDesc: state.opening.applicationDesc,
  careerPageDetails: state.opening.careerPageDetails,
  orgData: state.opening.currentOrg,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateCareerPageDetails: (details: { [key: string]: string }) =>
    dispatch(updateCareerPageDetails(details)),
});
export default connect(mapStateToProps, mapPropsToState)(CareerPageSetting);
