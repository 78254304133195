import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import TickIcon from "../../../assets/Tick";
import { verfityDomain } from "../../../lib/api";
import { changeDomainStatus } from "../../../redux/actions/opening/action";
import { OrgDomain } from "../../../redux/reducers/initialState";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { Typography } from "antd"
import { SecondaryButton } from "../../../ant/Button";

const { Text } = Typography
type ConferenceIntegrationProps = {
  changeDomainStatus(domainId: string): void;
  defaultDomain: OrgDomain;
  applicationId: string;
  domainId: string;
};
const ConferenceIntegration = (props: ConferenceIntegrationProps) => {
  const [isverifyDomain, setVerifyDomain] = useState(false);
  const verifyDomain = () => {
    verfityDomain(props.defaultDomain[props.domainId].id)
      .then((res) => {
        props.changeDomainStatus(res.id);
        setVerifyDomain(true);
      })
      
  };

  const copyText = (index: string) => {
    var copyText: any = document.getElementById(index);
    // if (copyText) copyText.innerHTML.select();
    navigator.clipboard.writeText(copyText.innerHTML);

    var tooltiptext: any = document.getElementById(`ns-${index}`);
    tooltiptext.innerHTML = "Copied to clipboard ";
  };

  const outFunc = (index: string) => {
    var tooltiptext: any = document.getElementById(`ns-${index}`);
    tooltiptext.innerHTML = "Copy to clipboard";
  };

return (
  <>
    {props.defaultDomain[props.domainId] &&
      props.defaultDomain[props.domainId].name_servers && (
        <>
          <div className="flex my-2 border rounded border-tacitbase-tertiary1 border-opacity-30 flex-col w-full">
            <div className="bg-color-LightGray flex w-full rounded">
              <Text className="w-2/5 border-r  border-b break-all p-1">
                Record name
              </Text>
              <Text className="w-1/4 border-r  border-b break-all p-1">Type</Text>
              <Text className="w-full p-1 ">value</Text>
            </div>
            <div className=" flex w-full rounded ">
              <Text className="w-2/5 border-r break-all p-1">
                {props.defaultDomain[props.domainId].name}
              </Text>
              <Text className="w-1/4 border-r break-all p-1">
                {capitalizeFirstLetter("ns")}
              </Text>
              <div className="w-full break-words p-1 ">
                {props.defaultDomain[props.domainId].name_servers.map(
                  (server: string, index: number) =>
                    server && (
                      <div key={index} className="w-full py-1 flex justify-between break-words space-x-2 ">
                        <Text id={index.toString()}>{server}</Text>
                        <div className="tooltip">
                          <span className={`tooltiptext`} id={`ns-${index}`}>
                            Copy to clipboard
                          </span>

                          <button
                            onClick={() => copyText(index.toString())}
                            onMouseOut={() => outFunc(index.toString())}
                            onBlur={() => {}}
                            data-testid="copyToClickBoard"
                          >
                            <i
                              className="  fa fa-clipboard cursor-pointer hover:bg-color-LightGray p-1"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
          {!isverifyDomain && (
            <div className="flex  justify-end">
              <SecondaryButton
                onClick={verifyDomain}
              >
                Verify domain
                </SecondaryButton>
            </div>
          )}
          {isverifyDomain && (
            <div className="flex flex-col shadow-6xl">
              <div className="flex flex-col">
                {" "}
                <div className="border-2 border-gray-300  tracking-wide font-normal flex flex-col">
                  <div className=" ">
                    {" "}
                    <div className="flex text-md items-center font-light tracking-wide space-x-4 bg-green-50 p-2 ">
                      <button className="focus:outline-none">
                        <TickIcon fill="green" />
                      </button>
                    <Text>
                        Domain
                        <span className="font-semibold">
                          {" "}
                          {props.defaultDomain[props.domainId].name}
                        </span>{" "}
                        has been verified.
                    </Text>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
  </>
);
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationName: state.opening.applicationName,
  applicationId: state.opening.applicationId,
  CurrentUserZoomMeet: state.opening.calendar.currentUserZoomMeet,
  defaultDomain: state.opening.defaultDomain,
});

const mapPropsTostate = (dispatch: Dispatch) => ({
  changeDomainStatus: (domainId: string) =>
    dispatch(changeDomainStatus(domainId)),
});
export default connect(mapStateToProps, mapPropsTostate)(ConferenceIntegration);
