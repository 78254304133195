import React, { useState } from "react";
import { Badge, Button, Card, message, Switch, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Plan } from "../../type/type";
import { connect } from "react-redux";
import { createCheckoutSession } from "../../lib/api";
import FreePlan from "./FreePlan";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
const { Text } = Typography;
// Define types for Redux state
interface PricingCardProps {
  plans: Plan[];
  stripeCustomerID: string;
  freePlans: Plan[];
}

const PricingCard: React.FC<PricingCardProps> = ({
  plans,
  stripeCustomerID,
  freePlans,
}) => {
  const [billingInterval, setBillingInterval] = useState<"month" | "year">(
    "month"
  );
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null);

  const filteredPlans = plans.filter(
    (plan) => plan.interval === billingInterval && plan.active
  );

  const handleSelectPlan = async (id: string) => {
    setLoadingPlanId(id);
    const lineItems = [
      {
        price: id,
        quantity: 1,
      },
    ];
    const req = {
      customer: stripeCustomerID,
      line_items: lineItems,
      mode: "subscription",
      ui_mode: "hosted",
      success_url: `${process.env.REACT_APP_STRIPE_CHECKOUT_RETURN_URL}/o/start-setup`,
    };

    try {
      const sessionData = await createCheckoutSession(req);
      window.location.href = sessionData.url;
    } catch (error) {
      message.error("There was a problem with the checkout process.");
      console.error("Checkout error:", error);
    } finally {
      setLoadingPlanId(null);
    }
  };

  return (
    <div>
      <div className="flex  justify-center mb-6">
        <Text 
        style={{
          color:billingInterval === "month" ? "#4D4D4D" : ""
        }}
        className={`mr-2 ${billingInterval === "month" ? "font-semibold" : "font-normal"}`}>Monthly</Text>
        <Switch
          style={{
            backgroundColor: "#4B48A5",
          }}
          checked={billingInterval === "year"}
          onChange={(checked) => setBillingInterval(checked ? "year" : "month")}
        />
        <Text 
        style={{
          color:billingInterval === "year" ? "#4D4D4D" : ""
        }}
        className={`ml-2 ${billingInterval === "year" ? "font-semibold" : "font-normal"}`}>Yearly</Text>
      </div>

      <div className="pricing-cards-container flex gap-4 justify-center">
        <FreePlan freePlans = {freePlans} />
        {filteredPlans.sort((a,b)=>a.product.name.localeCompare(b.product.name)).map((plan) => {
          const isPremium = plan.product.name === "Premium";
          const cardContent = (
            <Card
              key={plan.id}
              className="rounded-md"
              bordered={false}
              style={{
                width: 301,
                height:isPremium?480:395,
                textAlign: "left",
                marginTop: !isPremium ? "84px" : "0", 
                boxShadow:"4px 4px 10px 1px #0000001A",
                background: plan.product.name === "Premium" ? "#F0EFFE" : "",
                border:isPremium ?"1px solid #4B48A5":"none",
              }}
            >
              <div className="flex justify-between">
                <Text
                  className="font-medium text-2xl"
                >
                  {capitalizeFirstLetter(plan.product.name)}
                </Text>
              </div>
              <div className="my-4">
              <Text className="text-2xl font-bold">
              {" "}
              ${plan.amount / 100}{" "}
              </Text>
              <Text style={{ fontSize: "16px" }}>/ {plan.interval}</Text>
              </div>
              <div className="flex items-center h-10 mb-4 justify-center">
                <Button
                  key={plan.id}
                  type="primary"
                  onClick={() => handleSelectPlan(plan.id)}
                  loading={loadingPlanId === plan.id}
                  className="rounded-md my-5"
                  style={{
                    width: "100%",
                  }}
                >
                    Get started
                </Button>
              </div>
              <ul style={{ textAlign: "left" }}>
                {plan.product.features.map((feature) => (
                  <div key={feature.name} style={{ marginBottom: "8px" }}>
                    <CheckCircleOutlined
                      style={{
                        color:
                          plan.product.name === "Premium"
                            ? "#009D79"
                            : "#001f3f", // Change icon color for Premium plan
                        marginRight: "8px",
                      }}
                    />
                    {feature.name}
                  </div>
                ))}
              </ul>
            </Card>
          );
          return isPremium ? (
            <Badge.Ribbon key={plan.id} text="Most Popular" color="#009D79">
              {cardContent}
            </Badge.Ribbon>
          ) : (
            cardContent
          );
        })}
      </div>
    </div>
  );
};

// Updated mapStateToProps with TypeScript support
const mapStateToProps = (state: any) => ({
  stripeCustomerID: state.opening?.currentOrg?.customer?.id,
});

export default connect(mapStateToProps)(PricingCard);
