import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux";
import Sent from "../../assets/Sent";
import { currentApplicant } from "../../type/type";
import { DETAILS, SHOW_LESS, SHOW_MORE } from "../../constant"

type CardLinkedDetailsProps = {
  currentApplicant: currentApplicant;
};
const CardLinkedDetails = (props: CardLinkedDetailsProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [showMoreDetails, setShowMoreDetails] = useState(true)
  const toggleShowMore = () => {
    setShowMoreDetails(!showMoreDetails)
  }
  useEffect(() => {
    if (contentRef.current && contentRef.current.offsetHeight > 96) {
      setShowMoreDetails(true)
    } else { setShowMoreDetails(false) }
  }, [contentRef, props.currentApplicant])
  return (
    <React.Fragment>
      {props.currentApplicant.candidate_id && props.currentApplicant.source ? (
        <div ref={contentRef} className="mb-1">
          <div className=" ml-12 text-sm relative">
            <span className=" absolute  -ml-8" aria-hidden="true">
            </span>
            <div className="text-sm normal-case tracking-wide font-semibold px-1">
              {DETAILS}
            </div>
          </div>
          <div className=" ml-12 text-sm relative">
            <span className=" absolute  -ml-8" aria-hidden="true">
              <Sent />
            </span>
            <div className="flex w-full ml-1">
              <div>{props.currentApplicant.source.email}</div>
            </div>
          </div>
          {props.currentApplicant.source.phone && <div className=" ml-12 text-sm relative">
            <span className="absolute  -ml-8" aria-hidden="true">
              <i className="fa fa-phone" />
            </span>
            <div className="flex w-full ml-1">
              <div>{props.currentApplicant.source.phone}</div>
            </div>
          </div>}
          <div className={`${showMoreDetails && "line-clamp-3"}`}>
            {props.currentApplicant.source.customs && Object.keys(props.currentApplicant.source.customs).map((item: any) => props.currentApplicant.source.customs[item] && (
              <div key={item} className=" ml-12 text-sm relative" >
                <span className="absolute  -ml-8" aria-hidden="true">
                </span>
                <div className="text-sm ml-1 select-text">
                  <li><span className="font-semibold mr-1">{item}:</span>
                    <span className="break-all select-all">{props.currentApplicant.source.customs[item]}</span>
                  </li>
                </div>
              </div>
            ))}
          </div>
          {contentRef.current && contentRef.current.offsetHeight > 96 &&
            <button onClick={
              () => toggleShowMore()
            } className="text-xs text-tacitbase-secondary3 opacity-80 ml-12">
              {showMoreDetails ? SHOW_MORE : SHOW_LESS}
            </button>
          }
        </div>
      ) : null}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,

  currentApplicant: state.opening.currentApplicant,
  members: state.opening.members,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(CardLinkedDetails);
