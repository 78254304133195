import React, { useRef, useState } from "react"
import { usePopper } from "react-popper"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import DeleteAttachmentPopup from "./DeleteAttachmentPopup"
import { Scope, currentApplicant, loginUser } from "../../../type/type.d"
import { deleteAttachment } from "../../../redux/actions/opening/action"
import { deleteApplicantAttachment } from "../../../lib/api"
import { Overlay } from "../../hook/Overlay"
import { IconButton } from "../../../ant/Button"
import { DeleteOutlined } from '@ant-design/icons'

type DeleteAttachmentType = {
  loginUser: loginUser,
  attachment: any,
  clientId: string,
  currentApplicant: currentApplicant,
  removeAttachment(attachment: any, listID: string): void,
}
const DeleteAttachment = (props: DeleteAttachmentType) => {
  const [open, setOpen] = useState(false)
  const refer = useRef(null)
  const showDeleteAttachmentPopup = (e: any, flag: boolean) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(flag)
  }
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>()
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  })

  const deleteAttchement = (e: any) => {
    e.stopPropagation()
    const req = {
      id: props.attachment.id,
      socket_client_id: props.clientId,
      list_id: props.currentApplicant.list_id,
      opening_id: props.currentApplicant.opening_id,
      recordID: props.currentApplicant.id,
    }
    if ((e.keyCode === 13 && !e.shiftKey) || e.button == 0) {
      e.preventDefault()
      deleteApplicantAttachment(req)
        .then((attachment: any) => {
          props.removeAttachment(attachment, props.currentApplicant.list_id)
        })
      
    }
  }
  return (
    <>
      <div
        className="flex flex-row items-center justify-end"
        ref={setReferenceElement}
      >
        <IconButton size="small" type="text" icon={<DeleteOutlined   />} onClick={(e: any) => {
          e.stopPropagation(), setOpen(!open)
        }} />
        {open ? (
          <Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>

            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <DeleteAttachmentPopup
                click={(e: any, flag: boolean) =>
                  showDeleteAttachmentPopup(e, flag)
                }
                attachment={props.attachment}
                // refer={refer}
                deleteAttchement={deleteAttchement}
              />
            </div>
          </Overlay>

        ) : null}
      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  currentApplicant: state.opening.currentApplicant,
  attachmentLoader: state.opening.attachmentLoader,
  clientId: state.opening.clientId,
})
const mapPropsTostate = (dispatch: Dispatch) => ({
  removeAttachment: (attachment: any, listID: string) => {
    dispatch(deleteAttachment(attachment, listID))
  },
})
export default connect(mapStateToProps, mapPropsTostate)(DeleteAttachment)
