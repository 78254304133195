import React, { useState } from "react"
import Button1 from "../button/Button1"
import { Candidate } from "../../../type/type"
import { SEARCH_RESULT } from "../../../constant"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
type MailTagPreviewType = {
    recipients: Candidate[],
    previewTag: string,
    onClose: (e: any) => void
}
const MailTagPreview = (props: MailTagPreviewType) => {
    const [searchedLabel, setSearchedLabel] = useState<string>("")
    let recipients = props.recipients
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
        setSearchedLabel(value)
    }
    let filteredRecipients = recipients
    if (searchedLabel.trim().length > 0) {
        filteredRecipients = recipients.filter((recipient) => {
            const searchLabel = searchedLabel.toUpperCase();
        return (
                recipient.first_name.toUpperCase().includes(searchLabel) ||
                recipient.last_name.toUpperCase().includes(searchLabel) ||
                recipient.email.toUpperCase().includes(searchLabel)
            )
        })
    }

    const recipientWithEmailTag =
        filteredRecipients.length > 0 ? (
            filteredRecipients.map((recipient, index) => (
                <div className="flex justify-between items-start mb-2" key={index}>
                    <div className="w-3/4 font-light">
                        <div className="font-medium truncate w-full">
                            <span className="mr-1">{recipient.first_name}</span>
                            <span>{recipient.last_name}</span>
                        </div>
                        <div className="truncate w-full">{recipient.email}</div>
                    </div>
                    <div className="justify-start items-center flex w-1/4">
                        <span className="truncate w-full">{recipient[props.previewTag]}</span>
                    </div>
                </div>
            ))
        ) : (
            <>{SEARCH_RESULT}</>
        );

    return (
        <>
            <div
                data-testid="modAL"
                className=" animate-popover_fadeIn  justify-center bg-black bg-opacity-50 items-center flex fixed inset-0  outline-none focus:outline-none"
            >
                <div className="flex items-center rounded relative w-full h-full justify-center  overflow-y-scroll z-20">
                    <div className="z-30 w-143 h-128 bg-gray-100 rounded py-2 px-6">
                        <div className="h-full flex flex-col">
                            <div className="text-lg py-2">{capitalizeFirstLetter("Preview mail merge tag")}</div>
                            <input
                                type="text"
                                className="p-2 h-10 w-full text-sm rounded inputCSS"
                                placeholder={"Search for recipient or merge tag"}
                                onChange={(e: any) => handleSearch(e)}
                                data-testid="preview-tag-search"
                            // ref={(input) => input && input.focus()}
                            />
                            <div className="flex justify-between items-start my-2 mr-1 font-medium">
                                <div className="w-3/4">
                                    <div>Recipient</div>
                                </div>
                                <div className="justify-start items-center flex w-1/4">
                                    {props.previewTag.charAt(0).toUpperCase() +
                                        props.previewTag.slice(1).replace("_", " ")}
                                </div>
                            </div>
                            <hr className="h-2"></hr>
                            <div className="h-full  overflow-y-scroll ">
                                {recipientWithEmailTag}
                            </div>
                            <div className="sticky bottom-0 ">
                                <hr className="h-2"></hr>
                                <div className=" justify-end items-end flex">
                                    <Button1 buttonName="ok" click={props.onClose} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MailTagPreview
