import { Form,  Space,  Tabs, Tag } from "antd"
import React, { useEffect, useState } from "react"
import JDIntegrations from "./JDIntegrations";
import JDInformation from "./JDInformations";
import JDSummary from "./JDSummary";
import { connect } from "react-redux";
import { Jd } from "../../type/type";
import { LabeledIconButton, SecondaryButton } from "../../ant/Button";
import { updateJD } from "../../lib/api"
import { CheckOutlined, ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { PUBLISH, PUBLISH_JOB, UNPUBLISH, UNPUBLISH_JOB, UNSAVED_WARNING } from "../../constant"
import { updateCurrentJd } from "../../redux/actions/opening/action"
import { Dispatch } from "redux"
import Read from "../../Read";
type BoardJDProps = {
  currentTrackerJD: Jd;
  updateCurrentJd: (JD: Jd) => void
};
const BoardJD = (props: BoardJDProps) => {
  const [JDFormData, setJDFormData] = useState(props.currentTrackerJD);
  const [form] = Form.useForm();
  const [JDData, newFormData] = useState({});
  const [savedChangesVisible, setSavedChangesVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUnsavedChanges, setUnsavedChangesStatus] = useState<boolean>(
    false
  )
  const handleOnChangeField = (field: { [key: string]: string }) => {
    setUnsavedChangesStatus(true)
    // Check if the field being changed is either experince_level_from or experince_level_to
    const updatedField: any = { ...field }
    if ('experience_level_from' in field) {
      updatedField.experience_level_from = parseInt(field.experience_level_from)
    }
    if ('experience_level_to' in field) {
      updatedField.experience_level_to = parseInt(field.experience_level_to)
    }
    if ('salary_range_from' in field) {
      updatedField.salary_range_from = parseInt(field.salary_range_from)
    }
    if ('salary_range_to' in field) {
      updatedField.salary_range_to = parseInt(field.salary_range_to);
    }
    newFormData({ ...JDData, ...updatedField })
    setJDFormData((prevData) => ({
      ...prevData,
      ...field,
    }));
  };

  useEffect(() => {
    form.setFieldsValue(props.currentTrackerJD)
    let Defaultlocations = props.currentTrackerJD.locations?.map(
      (location: any) => ({
        label: `${location.city}, ${location.state}, ${location.country}`,
        value: `${location.city}, ${location.state}, ${location.country}`,
        locationObj: location  // Add locationObj here
      }))
    form.setFieldsValue({ ...props.currentTrackerJD, "locations": Defaultlocations })

    setJDFormData(props.currentTrackerJD)
  }, [props.currentTrackerJD])

  const saveNewJd = () => {
    let formData = new FormData();
    const jdDataJSON = JSON.stringify(JDData);
    formData.append("jd_data", jdDataJSON);
    saveJD(formData);
  };
  const saveJD = (formData: FormData) => {
    // formData.append("company_logo", companyLogo)
    setLoading(true);

    form.validateFields().then(() => {
      setUnsavedChangesStatus(false)
      updateJD(props.currentTrackerJD.id, formData)
      .then((res) => {
        if (res) {
          props.updateCurrentJd(res)
          setUnsavedChangesStatus(false)
          setLoading(false) // Stop loading
          setSavedChangesVisible(true)
          setTimeout(() => {
            setSavedChangesVisible(false)
          }, 2000)

          newFormData({})
        }
      })
        .catch(() => {
          setUnsavedChangesStatus(false)
        setSavedChangesVisible(false);
          setLoading(false) // Stop loading
      });
    }).catch(() => {
      // setUnsavedChangesStatus(false)
      setSavedChangesVisible(false)
      setLoading(false) // Stop loading
    });

  };

  const handleJDStatusUpdate = () => {
    let formData = new FormData()
    let customObj: { [key: string]: string } = {}
    customObj["status"] = props.currentTrackerJD.status === "draft" || props.currentTrackerJD.status === UNPUBLISH ? PUBLISH : UNPUBLISH
    formData.append("jd_data", JSON.stringify(customObj))

    updateJD(props.currentTrackerJD.id, formData)
      .then((res) => {
        props.updateCurrentJd(res)
      })
      .catch((error) => {
        console.error("Error:", error)
      })
  }
  const DiscardChanges = () => {
    newFormData({})
    form.setFieldsValue(props.currentTrackerJD)
    setSavedChangesVisible(false)
    setLoading(false) // Stop loading
    setUnsavedChangesStatus(false)
  }
  const items = [
    {
      key: "1",
      label: "Job summary",
      children: (
        <JDSummary
          onChangeField={handleOnChangeField}
          JDFormData={JDFormData}
        />
      ),
    },
    {
      key: "2",
      label: "Job post information",
      children: <JDInformation form={form} JDFormData={JDFormData} onChangeField={handleOnChangeField}
      />,
    },
    {
      key: "3",
      label: "Integrations",
      children: <JDIntegrations />,
    },
  ];
  return (
    <>
      <div className="">
        <div className="h-12 bg-white flex justify-end items-center px-4 mx-4 rounded-t">
        <Read>
        {isUnsavedChanges && 
          <Tag icon={<ExclamationCircleOutlined />} color="warning">
            {capitalizeFirstLetter(UNSAVED_WARNING)}
          </Tag>
          }

        {loading ? (
            <Tag icon={<LoadingOutlined />} color="processing">
              {capitalizeFirstLetter("Saving")}
            </Tag>
        ) : (
          savedChangesVisible && (
                <Tag icon={<CheckOutlined />} color="success">
                  {capitalizeFirstLetter("saved")}
                </Tag>
          )
        )}
          <Space>
            {isUnsavedChanges && (
             <LabeledIconButton
             label={capitalizeFirstLetter("Discard")}
             className="h-7"
             onClick={DiscardChanges}
           />
            )}
            {props.currentTrackerJD.id ? (
              isUnsavedChanges ? (
                props.currentTrackerJD.status === UNPUBLISH ? (
                  <Space>
                    <LabeledIconButton
                      label={capitalizeFirstLetter("Save")}
                      className="h-7"
                      onClick={saveNewJd}
                    />
                    <LabeledIconButton
                      label={capitalizeFirstLetter(PUBLISH_JOB)}
                      className="h-7"
                      onClick={handleJDStatusUpdate}
                    />
                    <SecondaryButton  onClick={handleJDStatusUpdate}
                    ></SecondaryButton>
                  </Space>
                ) : (
                  <LabeledIconButton
                    label={capitalizeFirstLetter(props.currentTrackerJD.status === PUBLISH ? PUBLISH : "Save")}
                    className="h-7"
                    onClick={saveNewJd}
                  />
                )
              ) : (
                <LabeledIconButton
                  label={capitalizeFirstLetter(props.currentTrackerJD.status === PUBLISH ? UNPUBLISH_JOB : PUBLISH_JOB)}
                  className="h-7"
                  onClick={handleJDStatusUpdate}
                />
              )
            ) : null}
          </Space>
          </Read>

        </div>
        <div className="px-4" style={{
          height: "calc(-166px + 100vh)"
        }} >
          <div className="tracker-jd-tab  w-full  h-full  bg-white rounded-b border flex ">
      
          <Form
            initialValues={JDFormData}
            layout="vertical"
            className="w-full"
            form={form}
              onValuesChange={handleOnChangeField}
          >
            <Tabs
              size="small"
              tabPosition={"left"}
              className="overflow-y-hidden h-full w-full"
            >
              {" "}
              {items.map((tab) => (
                <Tabs.TabPane key={tab.key} tab={tab.label}>
                  {tab.children}
                </Tabs.TabPane>
              ))}
            </Tabs>
            </Form>
          </div>
        </div>
      </div >

    </>
  );
};
const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  currentTrackerJD: state.opening.currentTrackerJd,
  topic: state.opening.topicSubscription,
  applicationId: state.opening.applicationId,
  cal_id: state.opening.calendar.currentCalenderId,
  orgStatus: state.opening.orgStatus,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateCurrentJd: (JD: Jd) =>
    dispatch(updateCurrentJd(JD)),
})
export default connect(mapStateToProps, mapPropsToState)(BoardJD)
