import { AxiosError } from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import CloseIcon from "../../assets/Close";
import { Dispatch } from "redux";
// import CandidateIcon from "../../assets/Candidate";
import {
  registerCurrentOrg,
  showOrg,
  updateOrgNameId,
} from "../../redux/actions/opening/action";
import { createOrganisation } from "../../lib/api";
import { MAX_CHAR_ALLOWED, ORG_EXIST } from "../../constant";
import { loginUser, Scope } from "../../type/type.d";
import { Overlay } from "../../components/hook/Overlay";
import throwError from "../../service/throwError";
import { Button } from "antd"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
type CreateOrgProps = {
  loginUser: loginUser;
  close: () => void;
  addOrg(orgData: any): void;
  updateIdName(orgData: any): void;
  registerCurrentOrg(org: any): void;
};
class CreateOrg extends Component<CreateOrgProps & RouteComponentProps> {
  nameRef: React.RefObject<HTMLInputElement>;
  state = {
    orgName: "",
    nameLength: 50,
    isExceedLimit: false,
    blankName: false,
    orgExist: false,
    validOrgName: false,
    containsOnlyNumbers: false,
    containSpecialChar: false,
    notAtLeastOneChar: false,
    loading: false
  };
  constructor(props: any) {
    super(props);
    this.nameRef = React.createRef();
  }
  componentDidMount() {
    this.nameRef.current && this.nameRef.current.focus();
  }

  setOrgName = () => {
    if (this.nameRef.current) {
      if (this.nameRef.current.value) {
        this.setState({
          ...this.state,
          orgName: this.nameRef.current?.value.trim(),

          nameLength: 50 - this.nameRef.current.value.length,
        });
        if (this.nameRef.current?.value.trim().length > 50) {
          this.setState({
            ...this.state,
            isExceedLimit: true,
            blankName: false,
            orgExist: false,
          });
        } else {
          this.setState({
            ...this.state,
            isExceedLimit: false,
            blankName: false,
            orgExist: false,
          });
        }

        const mainRegex = /^[a-zA-Z0-9 ]*[a-zA-Z][a-zA-Z0-9 ]*$/;
        const numbersOnlyRegex = /^[0-9]+$/;
        const specialCharRegex = /[^a-zA-Z0-9 ]/;
        if (!mainRegex.test(this.nameRef.current?.value.trim())) {
          if (numbersOnlyRegex.test(this.nameRef.current?.value.trim())) {
            this.setState({
              ...this.state,
              orgName: this.nameRef.current?.value.trim(),
              containsOnlyNumbers: true,
              nameLength: 50 - this.nameRef.current.value.length,
              blankName: false,
              validOrgName: false,
            });
          } else if (
            specialCharRegex.test(this.nameRef.current?.value.trim())
          ) {
            this.setState({
              ...this.state,
              orgName: this.nameRef.current?.value.trim(),
              containSpecialChar: true,
              nameLength: 50 - this.nameRef.current.value.length,
              blankName: false,
              containsOnlyNumbers: false,

              validOrgName: false,
            });
          } else {
            this.setState({
              ...this.state,
              orgName: this.nameRef.current?.value.trim(),
              notAtLeastOneChar: true,
              nameLength: 50 - this.nameRef.current.value.length,
              blankName: false,
              containSpecialChar: false,

              validOrgName: false,
            });
          }
        } else {
          this.setState({
            ...this.state,
            orgName: this.nameRef.current?.value.trim(),
            notAtLeastOneChar: false,
            containsOnlyNumbers: false,
            containSpecialChar: false,
            nameLength: 50 - this.nameRef.current.value.length,
            validOrgName: true,
            blankName: false,
          });
        }
      } else {
        this.setState({
          ...this.state,
          nameLength: 50,
          notAtLeastOneChar: false,
          containsOnlyNumbers: false,
          containSpecialChar: false,
          validOrgName: false,
          blankName: false,
        });
      }
    }

  };

  createOrg = (e: any) => {

    e.preventDefault();
    this.setState({
      ...this.state,
      loading: true
    })

    if (this.nameRef.current?.value.trim() !== "") {
      if (!this.state.isExceedLimit && this.state.validOrgName == true) {
        const req = {
          type: true,
          name: this.state.orgName,
          user_id: this.props.loginUser.user.id,
          roles: [
            {
              name: "Admin",
              description: "this is admin role",
              isSuperRole: true,
            },
            {
              name: "Hr",
              description: "this is hr role",
              isSuperRole: false,
            },
            {
              name: "Hm",
              description: "this is hm role",
              isSuperRole: false,
            },
            {
              name: "Interviewer",
              description: "this is interviewer role",
              isSuperRole: false,
            },
          ],
        };
        createOrganisation(req)
          .then((orgRes) => {
            this.setState({
              ...this.state,
              loading: false
            })
            let neworg = {
              applicationId: orgRes.id,
              id: orgRes.user_id,
              username: orgRes.name,
              verified: true,
              data: {
                logo: "",
                description: "",
              },
              roles: ["OWNER"],
              display_name:orgRes.display_name?orgRes.display_name:"",
          career_page_url_suffix:orgRes.career_page_url_suffix?orgRes.career_page_url_suffix:""
            };
            this.props.addOrg(neworg);
            let updatedOrg = {
              applicationId: orgRes.id,
              id: orgRes.user_id,
              applicationName: orgRes.name,
              verified: true,
              data: {
                logo: "",
                description: "",
              },
              roles: ["OWNER"],
            };
            this.props.updateIdName(updatedOrg);
            this.props.history.push({
              pathname: `/o/${orgRes.id}/createCustomer`,
            });
          })
          .catch((reason: AxiosError) => {
            this.setState({
              ...this.state,
              loading: false
            })
            if (
              reason.response?.status === 424 &&
              reason.response?.data.error.flag == true
            ) {
              this.setState({ orgExist: true });
            } else {
              throwError(reason);
            }
          });
      }
    } else {
      this.setState({
        ...this.state,
        blankName: true,
        isExceedLimit: false,
        orgExist: false,
        validOrgName: 0,
      });
    }
  };
  render() {
    return (
      <Overlay scope={Scope.Dialog} onClose={this.props.close}>
        <div className="justify-center bg-black text-black bg-opacity-50 items-center flex z-50 fixed inset-0  outline-none focus:outline-none">
          <div className="flex items-center  rounded relative w-6/12 h-full justify-center overflow-y-scroll z-20">
            <div className="z-30 w-full bg-white rounded  mt-12  ">
              <form className="p-6 " onSubmit={this.createOrg}>
                <button
                  className="modal-close"
                  type="button"
                  data-testid="closeTestBtn"
                  onClick={this.props.close}
                >
                  <span className="modal-icon">
                    <CloseIcon />
                  </span>
                </button>
                <div className=" text-xs tracking-wider mb-4 mt-4 uppercase">
                  Organisation Name
                </div>{" "}
                <input
                  type="text"
                  name=""
                  className="inputCSS bg-gray-100 w-11/12    pl-2 pr-16 h-10 rounded  tracking-widest focus:bg-white"
                  onChange={this.setOrgName}
                  ref={this.nameRef}
                  data-testid="inputTest"
                  maxLength={50}
                />
                <span className="-ml-8 w-1">{this.state.nameLength}</span>
                <div className="text-red-400 text-sm h-10 flex items-center ">
                  {this.state.isExceedLimit ? MAX_CHAR_ALLOWED : null}
                  {this.state.blankName ? "Please fill the name field." : null}
                  {this.state.orgExist ? ORG_EXIST : null}
                  {!this.state.validOrgName == true
                    ? this.state.containsOnlyNumbers
                      ? "Organisation name should not contain only numbers at least one alphabet is required."
                      : this.state.containSpecialChar
                      ? "Organisation name should not contain special characters."
                      : this.state.notAtLeastOneChar
                      ? "Organisation name should contain at least one alphabet."
                      : null
                    : null}
                </div>
                <div className="flex flex-row justify-between items-center   ">
                  <div className="">
                    <Button type="primary" 
                      loading={this.state.loading}
                      onClick={this.createOrg}
                    >
                      {capitalizeFirstLetter("Create Organisation")}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}
const mapPropsTostate = (dispatch: Dispatch) => ({
  registerCurrentOrg: (org: any) => dispatch(registerCurrentOrg(org)),
  addOrg: (orgData: any) => dispatch(showOrg(orgData)),
  updateIdName: (orgData: any) => dispatch(updateOrgNameId(orgData)),
});
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps, mapPropsTostate)(withRouter(CreateOrg));
