import React, { useState } from "react";
import { connect } from "react-redux";
import { initCDbMembersCDB, InitLabel, Label } from "../../type/type.d";
import { CurrentCandidateInPool } from "../../redux/reducers/initialState";
import { CANDIDATES, SOCKET_CLIENT_ID } from "../../constant";
import { CheckOutlined, LoadingOutlined, TagOutlined } from "@ant-design/icons";
import { determineTextColor } from "../../utils/CalculateBrightnessOfColor";
import Mixed from "../../assets/Images/Mixed";
import BulkLabel from "../common/AddLabelPopover";
import { IconButton } from "../../ant/Button";
import { Dispatch } from "redux";
import {
  addLabelToPoolCandidateInATS,
  addLabelsToMultipleCandidatesInTalentPool,
  removeLabelFromTalentPoolCandidate,
  saveCandidateLabel,
  updateCandidateLabel,
} from "../../redux/actions/opening/action";
import {
  addLabelToCDBAndReview,
  attachBulkLabelsToCandidates,
  deleteLabelFromCandidate,
  editLabelOfCDB,
} from "../../lib/api";
import { deleteLabelFromCandidateReq } from "../../lib/apiReq";
import { throwSuccess } from "../../service/throwError";

// Define the prop types for the component
type Props = {
  candidateLabels: InitLabel;
  currentCandidateInPool: CurrentCandidateInPool;
  updateCandidateLabel(updatedLabel: any): void;
  saveCandidateLabel(label: any): void;
  addLabelToPoolCandidateInATS(data: any, candidateId: string): void;
  removeLabelFromTalentPoolCandidate: (cId: string[], label: string[]) => void;
  addLabelsToMultipleCandidatesInTalentPool: (data: any) => void;
  candidatePool: any;
  selectedRowKeys: string[];
  currentCandidateInEditMode: any;
  candidateDB: initCDbMembersCDB ;

};

const AddBulkLabelToCandidateInAtsCDB: React.FC<Props> = (props) => {
  const [loader, showLoader] = useState(false);
  const [loadedLabel, setLoadedLabel] = useState("");
  const [localLabels, setLocalLabels] = useState<any>([]);

  // Handle the creation of a new label
  const handleCreateLabel = (newLabel: { value: string; color: string }) => {
    const labelRequest = {
      name: newLabel.value,
      color: newLabel.color,
      entity: CANDIDATES,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };

    const clientId = sessionStorage.getItem(SOCKET_CLIENT_ID);

    addLabelToCDBAndReview(labelRequest).then((createdLabel) => {
      props.saveCandidateLabel(createdLabel);

      showLoader(true);
      const req: deleteLabelFromCandidateReq = {
        candidate_ids: props.selectedRowKeys,
        label_ids: [createdLabel.id],
        socket_client_id: clientId ? clientId : "",
      };
      attachBulkLabelsToCandidates(req)
        .then((res) => {
          if (res) {
            showLoader(false);
            throwSuccess("Labels attached successfully.");
            props.addLabelsToMultipleCandidatesInTalentPool(res);
          }
        })
        .catch(() => {
          showLoader(false);
        });
    });

    setLocalLabels([...localLabels, newLabel]);
  };

  // Handle the editing of an existing label
  const handleEditLabel = (updatedLabel: { id: string; value: string; color: string }) => {
    const request = {
      id: updatedLabel.id,
      color: updatedLabel.color,
      name: updatedLabel.value,
      entity: "candidates",
    };

    editLabelOfCDB(request).then((editedLabel) => {
      props.updateCandidateLabel(editedLabel);
    });

    setLocalLabels([...localLabels, updatedLabel]);
  };

  // Determine the icon to display based on label status
  const determineIcon = (label: Label) => {
    const selectedRowCount = props.selectedRowKeys.length;
  
    // Count how many of the selected candidates have the label
    const labelCount = props.selectedRowKeys.reduce((count, candidateId) => {
      // Check across all pages for the selected candidate
      const hasLabel = Object.keys(props.candidateDB).some((page) => {
        const pageData = props.candidateDB[page];
        const candidate = pageData[candidateId];
        if (candidate) {
          // Check if the label exists in the array of labels for the candidate
          return candidate.labels.some((labelObj: Label) => labelObj.id === label.id);
        }
        return false;
      });
  
      return hasLabel ? count + 1 : count;
    }, 0);
  
    const isLoading = label.id === loadedLabel;
  
    if (isLoading && loader) {
      return <LoadingOutlined style={{ color: determineTextColor(label.color) }} />;
    }
  
    if (labelCount === 0) {
      return null;
    } else if (labelCount === selectedRowCount) {
      return <CheckOutlined style={{ color: determineTextColor(label.color) }} />;
    } else {
      return (
        <div className="items-center justify-center flex">
          <Mixed fill={determineTextColor(label.color)} height={16} />
        </div>
      );
    }
  };

  // Add label to candidates
  const addLabelToCandidates = (labelData: Label) => {
    setLoadedLabel(labelData.id);
    const clientId = sessionStorage.getItem(SOCKET_CLIENT_ID) || "";
    const candidatesByLabelStatus = props.selectedRowKeys.reduce(
      (
        aggregatedCandidates: {
          candidateIdsWithLabel: string[];
          candidateIdsWithoutLabel: string[];
        },
        candidateId: string
      ) => {
        const hasLabel = Object.keys(props.candidateDB).some((page) => {    
          const pageData = props.candidateDB[page];
          return (
            pageData?.[candidateId]?.labels.some(
              (labelObj: Label) => labelObj.id === labelData.id
            )
          );
        });
    
        if (hasLabel) {
          aggregatedCandidates.candidateIdsWithLabel.push(candidateId);
        } else {
          aggregatedCandidates.candidateIdsWithoutLabel.push(candidateId);
        }
    
        return aggregatedCandidates;
      },
      { candidateIdsWithLabel: [] , candidateIdsWithoutLabel: [] }
    );
    
    const { candidateIdsWithLabel, candidateIdsWithoutLabel } = candidatesByLabelStatus;
    
    

    if (candidateIdsWithLabel.length === 0) {
      showLoader(true);
      const req: deleteLabelFromCandidateReq = {
        candidate_ids: props.selectedRowKeys,
        label_ids: [labelData.id],
        socket_client_id: clientId,
      };
      attachBulkLabelsToCandidates(req)
        .then((res) => {
          if (res) {
            throwSuccess("Labels attached successfully.");
            props.addLabelsToMultipleCandidatesInTalentPool(res);
            showLoader(false);
          }
        })
        .catch(() => {
          showLoader(false);
        });
    } else if (candidateIdsWithLabel.length === props.selectedRowKeys.length) {
      showLoader(true);
      const request = {
        candidate_ids: props.selectedRowKeys,
        label_ids: [labelData.id],
        socket_client_id: clientId,
      };

      deleteLabelFromCandidate(request)
        .then((response) => {
          showLoader(false);
          throwSuccess(`Removed label ${labelData.name}`)
          props.removeLabelFromTalentPoolCandidate(response.candidate_ids, response.label_ids);
        })
        .catch(() => {
          showLoader(false);
        });
    } else {
      showLoader(true);
      const req: deleteLabelFromCandidateReq = {
        candidate_ids: candidateIdsWithoutLabel,
        label_ids: [labelData.id],
        socket_client_id: clientId,
      };
      attachBulkLabelsToCandidates(req)
        .then((res) => {
          throwSuccess(`Added label ${labelData.name} for ${props.selectedRowKeys.length} candidates.`);
          props.addLabelsToMultipleCandidatesInTalentPool(res);
          showLoader(false);
        })
        .catch(() => {
          showLoader(false);
        });
    }
  };

  return (
    <div>
      <BulkLabel
        determineIcon={determineIcon}
        onAddLabel={addLabelToCandidates}
        element={      
        <IconButton icon={<TagOutlined /> }  tooltipTitle="Add label" className="h-7 w-7"/>       }
        onEditLabel={handleEditLabel}
        onCreateLabel={handleCreateLabel}
        defaultTitle="Create Label"
        initialLabels={Object.values(props.candidateLabels)}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentCandidateInPool: state.opening.currentCandidateInPool,
  candidateLabels: state.opening.candidateLabels,
  candidatePool: state.opening.candidatePool,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  candidateDB: state.opening.candidateDB,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addLabelsToMultipleCandidatesInTalentPool: (data: any) => dispatch(addLabelsToMultipleCandidatesInTalentPool(data)),
  saveCandidateLabel: (label: any) => dispatch(saveCandidateLabel(label)),
  updateCandidateLabel: (updatedLabel: any) => dispatch(updateCandidateLabel(updatedLabel)),
  removeLabelFromTalentPoolCandidate: (cId: string[], label: string[]) => dispatch(removeLabelFromTalentPoolCandidate(cId, label)),
  addLabelToPoolCandidateInATS: (data: any, candidateId: string) => dispatch(addLabelToPoolCandidateInATS(data, candidateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBulkLabelToCandidateInAtsCDB);
