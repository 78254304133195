import React from "react";
const MoveCardIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Move Card</title>
      <g
        id="Icons-/-16x16-/-Move-Card"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M14,1 C15.1045695,1 16,1.8954305 16,3 L16,4.5 C16,4.77614237 15.7761424,5 15.5,5 C15.2238576,5 15,4.77614237 15,4.5 L15,3 C15,2.44771525 14.5522847,2 14,2 L2,2 C1.44771525,2 1,2.44771525 1,3 L1,13 C1,13.5522847 1.44771525,14 2,14 L14,14 C14.5522847,14 15,13.5522847 15,13 L15,11.5 C15,11.2238576 15.2238576,11 15.5,11 C15.7761424,11 16,11.2238576 16,11.5 L16,13 C16,14.1045695 15.1045695,15 14,15 L2,15 C0.8954305,15 2.5773543e-16,14.1045695 1.2246468e-16,13 L7.34788079e-16,3 C5.99517329e-16,1.8954305 0.8954305,1 2,1 L14,1 Z M12.4748737,4.52512627 L15.7175144,7.76776695 C15.9127766,7.9630291 15.9127766,8.27961159 15.7175144,8.47487373 L12.4748737,11.7175144 C12.2796116,11.9127766 11.9630291,11.9127766 11.767767,11.7175144 C11.5725048,11.5222523 11.5725048,11.2056698 11.767767,11.0104076 L14.106961,8.67067966 L4.12132034,8.67157288 C3.84517797,8.67157288 3.62132034,8.44771525 3.62132034,8.17157288 C3.62132034,7.8954305 3.84517797,7.67157288 4.12132034,7.67157288 L14.1213203,7.67157288 C14.1534639,7.67157288 14.184899,7.67460602 14.2153551,7.68040172 L11.767767,5.23223305 C11.5725048,5.0369709 11.5725048,4.72038841 11.767767,4.52512627 C11.9630291,4.32986412 12.2796116,4.32986412 12.4748737,4.52512627 Z"
          id="Combined-Shape"
          fill="#161E25"
        ></path>
      </g>
    </svg>
  );
};
export default MoveCardIcon;
