import React, { useEffect, useState } from "react";
import { Divider, Typography, Avatar, Tag, Space } from "antd";
import { Integration, loginUser, Member, orgMember } from "../type/type";
import { renderCreatedAt } from "../utils/renderCreatedAt";
import { connect } from "react-redux";
import slack from "../images/slack.png";
import { OWNER, SLACK, UserType } from "../constant";
import UserProfileAction from "./UserProfileAction";
import { useHistory, useLocation } from "react-router-dom";
import TacitBaseModal from "../ant/TacitbaseModal";
import { SecondaryButton } from "../ant/Button";
import { generateMagicLink, resendMagicLink } from "../lib/api";
import { throwFrontEndError, throwSuccess } from "../service/throwError";
import ErrorMessage from "./ErrorMessage";
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter";
const { Text } = Typography;

type UserProfileProps = {
  integratedSlackMember: { [key: string]: Integration[] };
  members: orgMember;
  loginUser: loginUser;
};
const UserProfile = (props: UserProfileProps) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const member_id = queryParams.get("id");
  const [visible, setVisibility] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member | null>(null);
  const [generateButtonLoading, setGenerateButtonLoading] = useState(false);
  const [resendButtonLoading, setResendButtonLoading] = useState(false);
  const isOwner =
    props.members[props.loginUser.user.id].registrations[0].roles[0] === OWNER;

  useEffect(() => {
    if (member_id) {
      setSelectedMember(props.members[member_id]);
      setVisibility(true);
    }
  }, [member_id, props.members]);

  const handleCancel = () => {
    setVisibility(false);
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  };

  const generateLink = () => {
    setGenerateButtonLoading(true);
    const secrete = selectedMember?.registrations[0].data.invitation_secret
      ? selectedMember?.registrations[0].data.invitation_secret
      : "";
    generateMagicLink(secrete)
      .then(() => {
        throwSuccess("Invitation link generated successfully");
        setGenerateButtonLoading(false);
      })
      .catch(() => {
        throwFrontEndError("Invitation link generation failed");
        setGenerateButtonLoading(false);
      });
  };

  const resendLink = () => {
    setResendButtonLoading(true);
    const secrete = selectedMember?.registrations[0].data.invitation_secret
      ? selectedMember?.registrations[0].data.invitation_secret
      : "";
    resendMagicLink(secrete)
      .then(() => {
        setResendButtonLoading(false);
        throwSuccess("Invitation link resend successfully");
      })
      .catch(() => {
        setResendButtonLoading(false);
        throwFrontEndError("Invitation link resend failed");
      });
  };
  const role:any = selectedMember?.registrations[0]?.roles[0];
  const isGuest = role === capitalizeFirstLetter(UserType.GUEST);
  const tagColor = isGuest ? "#F2FFFC" : "processing";
  const textColor = isGuest ? "#009D79" : "#4B48A5"; 
  return (
    <>
      <TacitBaseModal
        className="review-modal-content"
        closable={false}
        content={
          <div className="px-6">
            <div className="flex mt-4 gap-6">
              <div>
                {selectedMember?.image_url ? (
                  <Avatar
                    src={selectedMember.image_url}
                    alt="user_image"
                    className="h-24 border border-gray-300   rounded-full w-24"
                  />
                ) : (
                  <Avatar
                    alt="user_image"
                    className="h-24 w-24 border border-gray-300 p-2 rounded-full flex items-center justify-center text-3xl font-bold"
                    style={{ color: "white", backgroundColor: "#f56a00" }}
                  >
                    { selectedMember?.first_name ?selectedMember?.first_name[0]:"U" || undefined}
                  </Avatar>
                )}
              </div>
              <div className="flex  flex-col">
                <Text style={{ fontWeight: "500", fontSize: "15px" }}>
                  {selectedMember?.first_name} {selectedMember?.last_name}
                </Text>
                <Text type="secondary">@{selectedMember?.username}</Text>
                <Tag
                  className="w-max h-6 mt-1 flex justify-center items-center"
                  bordered={false}
                  color={tagColor}
                  style={{
                    color: textColor,
                  }}
                >
                  {selectedMember?.registrations[0].roles[0]}
                </Tag>
              </div>
            </div>
            <div className="flex flex-col gap-4 mt-4">
              <div className="flex flex-col">
                <Text style={{ fontWeight: "500" }}>Email</Text>
                <Text type="secondary">{selectedMember?.email}</Text>
              </div>
              {(selectedMember?.registrations[0]?.roles[0] !== capitalizeFirstLetter(UserType.GUEST) && selectedMember?.mobile_phone) && (
                <div className="flex flex-col">
                  <Text style={{ fontWeight: "500" }}>Phone</Text>
                  <Text type="secondary">{selectedMember?.mobile_phone}</Text>
                </div>
              )}
              <div className="flex flex-col">
                <Text style={{ fontWeight: "500" }}>Joined on</Text>
                <Text type="secondary">
                  {renderCreatedAt(selectedMember?.insert_instant)}
                </Text>
              </div>

              {selectedMember?.registrations[0].data.activated &&
                props.integratedSlackMember[member_id ? member_id : ""] && (
                  <div className="flex gap-1 flex-col">
                    <Text style={{ fontWeight: "500" }}>Integration</Text>
                    <Text type="secondary">
                      <Space className="flex">
                        <div className=" border flex h-8 items-center justify-center rounded px-2 py-1 space-x-2">
                          <div>
                            <img width={14} src={slack} alt="slack user"></img>
                          </div>
                          <div className="flex justify-center items-center">
                            {props.integratedSlackMember[
                              member_id ? member_id : ""
                            ].map((integration: Integration) =>
                              integration.app_name == SLACK ? (
                                <Text className="text-xs">
                                  {integration.first_name}{" "}
                                  {integration.last_name}
                                </Text>
                              ) : (
                                <></>
                              )
                            )}
                          </div>
                        </div>
                      </Space>
                    </Text>
                  </div>
                )}
              { (role === capitalizeFirstLetter(UserType.GUEST) ||  role === capitalizeFirstLetter(UserType.AGENCY)) &&
                isOwner &&
                selectedMember?.registrations[0]?.data?.activated && (
                  <>
                    <Divider type="horizontal" className="w-full m-0" />
                    <div className="w-full flex flex-col">
                      <Text style={{ fontWeight: "500" }} className="mb-1">
                      {role === "Guest" ? "Secure share URL" : "Agency portal URL"}
                      </Text>
                      <div
                        className="rounded-md  p-2 "
                        style={{ backgroundColor: "#F9F9F9" }}
                      >
                       <ErrorMessage userType= {role}/>
                        <div className="flex gap-4 justify-end mt-4">
                          <SecondaryButton onClick={generateLink} loading={generateButtonLoading}>
                            Generate
                          </SecondaryButton>
                          <SecondaryButton onClick={resendLink} loading={resendButtonLoading}>
                            Resend
                          </SecondaryButton>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <Divider type="horizontal" className="w-full m-0" />
            </div>
            <UserProfileAction
                visible={visible}
                selectedMember={member_id ? member_id : ""}
              />
          </div>
        }
        title={
          <div className="header ">
            <div className="py-3 px-6 flex">Member Profile</div>
            <Divider type="horizontal" className="mx-0 mb-0 mt-0" />
          </div>
        }
        width={365}
        // height={500}
        footer={null}
        visibility={visible}
        centered
        onCancel={handleCancel}
      ></TacitBaseModal>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  integratedSlackMember: state.opening.integratedSlackMember,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(UserProfile);
