import { customeFilterType, searchDynamicQueryParameters } from "../type/type"

export function generateSearchQuery(query: string, filter_by: customeFilterType[] = [], page: number = 1, collection: string) {
    const searchParameters: searchDynamicQueryParameters = {
        filter_by: generateConcatenatedString(filter_by),
        page: page,
        per_page: 25,
        q: query,
        collection: collection
    }
    return searchParameters
}


export function generateConcatenatedString(filterArray: customeFilterType[]) {
    const concatenatedFilters = filterArray.map(filter => {
        return `${filter.column}:${filter.operator}${filter.value}`
    }).join(" && ")

    return concatenatedFilters
}

