import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CreateCandidateCard, getOpeningLists } from "../../../lib/api"
import { CurrentCandidateInEditMode } from "../../../redux/reducers/initialState";
import { Opening, OrganizedBoard, companyBoards, orgMember } from "../../../type/type"
import { NO_LIST_FOUND_IN_BOARD } from "../../../constant"
import SendToBoardPopup from "../../common/composeMail/SendToBoardPopup/SendToBoardPopup"
import { throwSuccess } from "../../../service/throwError";
type SendCandidateToBoardProps = {
  members: orgMember;
  applicationId: string;
  selectedItems: any;
  openings: OrganizedBoard;
  click: () => void;
  refer: any;
  name: string;
  clientId: string;
  currentCandidateInEditMode: CurrentCandidateInEditMode;
  companyBoard: companyBoards[]
};
const SendCandidateToBoard = (props: SendCandidateToBoardProps) => {
  const [listLength, setListLength] = useState<number>(0);
  let element = document.getElementById("errorInSend");
  const [companyOptions, setCompanyOptions] = useState<Record<string, any>>({})
  const [openings, setOpenings] = useState<Opening[]>([])
  const [selectedOpening, setSelectedOpening] = useState<string>("")
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (Object.values(props.companyBoard).length > 0) {
      const companyOptionsObject: Record<string, any> = {}
      Object.values(props.companyBoard).forEach((company: companyBoards) => {
        companyOptionsObject[company.company_id] = {
          ...company,
          "id": company.company_id,
          boards: company.boards.map((boardId: any) => props.openings.pageSection.openings[boardId])
        }
      })

      setCompanyOptions(companyOptionsObject)
      setOpenings(companyOptionsObject[Object.values(props.companyBoard)[0].company_id].boards.filter((o: Opening) => !o.access.comment_only
      ))
      setSelectedOpening(companyOptionsObject[Object.values(props.companyBoard)[0].company_id].boards[0].id)
    } else {
      if (element) element.innerHTML = "No board found";
    }
  }, []);
  useEffect(() => {
    if (selectedOpening) {
      getOpeningLists(selectedOpening).then((res) => {
        if (res) {
          setListLength(res.length)
          if (element) element.innerHTML = ""
        } else { setListLength(0); if (element) element.innerHTML = NO_LIST_FOUND_IN_BOARD }
      })
    }
  }, [selectedOpening]);

  const selectOpening = (openingId: string) => {
    setSelectedOpening(openingId)
  }
  const selectCompany = (openingId: string) => {
    setOpenings(companyOptions[openingId].boards.filter((o: Opening) => !o.access.comment_only
    ))
    setSelectedOpening(companyOptions[openingId].boards[0].id)

  };
  const sendToReview = () => {
    if (selectedOpening.trim().length > 0 && listLength > 0) {
      setLoading(true)
      const req = {
        candidates: [props.currentCandidateInEditMode.candidateId],
        opening_id: selectedOpening,
        socket_client_id: props.clientId,
      };
      CreateCandidateCard(req).then((res) => {
        if (res) {
          setLoading(false)

          props.click();
          throwSuccess(`Candidate sent to the board ${props.openings.pageSection.openings[selectedOpening].name}.`)
        }
      }).catch(() => {
        setLoading(false)

      });
    }
  };
  return (
    <SendToBoardPopup loading={loading} company={Object.values(companyOptions)} close={props.click} refer={props.refer} listLength={listLength} boards={openings} selectedBoard={selectedOpening} selectBoard={(e: string) => selectOpening(e)}
      selectCompany={(e: string) => selectCompany(e)} sendToBoard={sendToReview} />
  );
};
const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  clientId: state.opening.clientId,
  companyBoard: state.opening.companyBoards,
});

export default connect(mapStateToProps)(SendCandidateToBoard);
