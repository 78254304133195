import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DESCRIPTION, DETAIL_DESC, SOCKET_CLIENT_ID } from "../../../constant";
import { editApplicantDescription } from "../../../lib/api";
import { editApplicantDesc } from "../../../redux/actions/opening/action";
// import CardDescriptionPreview from './CardDescriptionPreview'
import parse from "html-react-parser";
import { CardDescriptionProps } from "../../../type/card";
import CardDescriptionInput from "./cardDescriptionInput/CardDescriptionInput";
import DescriptionIcon from "../../../assets/Description";
import Read from "../../../Read";
const CardDescription = (props: CardDescriptionProps) => {
  const [CardDesc, showCardDescInput] = useState(false);
  const [description, setDescription] = useState<string>("");

  const showDescHandle = (e: any) => {
    /* check if value has a hyperlink then link open to next tab and does not open editior  */
    if (e.target.nodeName != "A") {
      e.stopPropagation();
      showCardDescInput(!CardDesc);
    }
  };
  const setDescHandle = (e: any) => setDescription(e);

  /** on press cmd+enter, it call below function and extract value from event using innerHtml   */
  const editDesc = (e: any) => {
    const desc = {
      id: props.currentApplicant.id,
      description:
        e.keyCode == 13 && e.metaKey ? e.target.innerHTML : description,
      socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
    };
    if (
      (e.button == 0 && description.length > 0) ||
      (e.type == "blur" && description.length > 0) ||
      (e.keyCode == 13 && e.metaKey)
    ) {
      editApplicantDescription(desc)
        .then((applicant: any) => {
          props.editDesc(applicant);
          showCardDescInput(!CardDesc);
        })
      
    } else {
      showCardDescInput(!CardDesc);
    }
  };
  return (
    <React.Fragment>
      <div className="mb-2 ml-12 relative">
        <span className=" absolute mt-1 -ml-8" aria-hidden="true">
          <DescriptionIcon />
        </span>
        <div className="flex w-full">
          <div className="text-base normal-case tracking-wide font-semibold px-1">
            {DESCRIPTION}
          </div>
          <Read access={props.currentOpening.access}>
            <button
              data-testid="show-desc"
              onClick={showDescHandle}
              className="inline text-black px-4 py-1 bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40  rounded text-sm font-light float-right  w-auto focus:outline-none"
            >
              Edit
            </button>
          </Read>
        </div>
      </div>
      {CardDesc ? (
        <div className="ml-12">
          <CardDescriptionInput
            change={setDescHandle}
            description={props.currentApplicant.description}
            click={showDescHandle}
            create={editDesc}
          />
        </div>
      ) : (
        <div
          className="isEditable ml-12 mb-2 pl-1"
          onClick={showDescHandle}
          onKeyDown={() => {}}
          role="button"
          tabIndex={0}
        >
          <div
            className={` ${
              props.currentApplicant.description
                ? null
                : "bg-gray-300 bg-opacity-30 text-sm p-2 mb-2 hover:bg-gray-300 hover:bg-opacity-50"
                } cursor-pointer break-words rounded text-sm trix-content`}
          >
            {props.currentApplicant.description
              ? parse(props.currentApplicant.description)
              : DETAIL_DESC}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  clientId: state.opening.clientId,
  currentOpening: state.opening.currentOpening,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editDesc: (desc: string) => dispatch(editApplicantDesc(desc)),
});
export default connect(mapStateToProps, mapPropsToState)(CardDescription);
