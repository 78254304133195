import React, { useState } from "react"
import { CREATE_JOB_POST } from "../../../constant"
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../../ant/Button"
import JobPostCreation from "../../JdManagement/JobPostCreation"

const CreateJobPost = () => {
    const [showModal, setShowModal] = useState(false)

    const onCancle = () => {
        setShowModal(false)
    }


    return (
        <>
            {/* <Col onClick={() => setShowModal(true)} xs={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }}  > */}
            <LabeledIconButton style={{ width: "265px" }} onClick={() => setShowModal(true)} className="h-28  bg-gray-50 rounded border  p-1 mx-2 flex justify-center items-center " label={capitalizeFirstLetter(CREATE_JOB_POST)}>
            </LabeledIconButton>
            {/* </Col> */}
            {showModal &&
                <JobPostCreation open={showModal} onCancel={onCancle} />
            }
        </>
    )
}

export default CreateJobPost