import React from "react";
const ActivityIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Activity</title>
      <g
        id="Icons-/-16x16-/-Activities"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          id="Rectangle-Copy-7"
          stroke="#000000"
          strokeWidth="1"
          x="3.75"
          y="3.75"
          width="10.5"
          height="1"
          rx="0.5"
        ></rect>
        <rect
          id="Rectangle-Copy-8"
          stroke="#000000"
          strokeWidth="1"
          x="3.75"
          y="6.75"
          width="7.5"
          height="1"
          rx="0.5"
        ></rect>
        <rect
          id="Rectangle-Copy-9"
          stroke="#000000"
          strokeWidth="1"
          x="3.75"
          y="9.75"
          width="10.5"
          height="1"
          rx="0.5"
        ></rect>
        <rect
          id="Rectangle-Copy-10"
          stroke="#000000"
          strokeWidth="1"
          x="3.75"
          y="12.75"
          width="7.5"
          height="1"
          rx="0.5"
        ></rect>
        <rect
          id="Rectangle-Copy-7"
          fill="#161E25"
          x="1"
          y="3"
          width="1"
          height="1"
          rx="0.5"
        ></rect>
        <rect
          id="Rectangle-Copy-11"
          fill="#161E25"
          x="1"
          y="9"
          width="1"
          height="1"
          rx="0.5"
        ></rect>
      </g>
    </svg>
  );
};
export default ActivityIcon;
