import React from "react";

type CardCountProps = {
  applicantLength: number;
};
const CardCount = (props: CardCountProps) => {
  return (
    <React.Fragment>
      <div className=" ml-1.5 mr-1 mb-1.5 flex ">
        <div className=" w-auto border-2 border-white text-white px-2 py-1 rounded   text-xs h-5 flex items-center justify-center tracking-wider ">
          {props.applicantLength == 1
            ? props.applicantLength + " card"
            : props.applicantLength + " cards"}
        </div>
      </div>
    </React.Fragment>
  );
};
export default CardCount;
