import React from "react";

const AttachmentLoader = () => {
  return (
    <div className="animate-pulse1 h-20 border-r-2 mb-2">
      <div className=" my-2 h-20 cursor-pointer flex rounded hover:bg-gray-200">
        <div className="bg-gray-300 bg-opacity-25 font-extrabold rounded text-gray-600 text-lg text-center justify-center w-28 items-center flex"></div>
        <div className="flex flex-col w-full m-2">
          <div className="text-sm font-bold p-2 bg-gray-300"></div>
          <div className="my-2 text-xs font-medium text-gray-500 p-2 bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentLoader;
