import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import AttachmentIcon from "../../../assets/Attachment";
import {
  getApplicantAttachments,
  // updateApplicantAttachment,
} from "../../../lib/api";
import { initAttachment } from "../../../redux/actions/opening/action";
import { AttachmentProps } from "../../../type/cardActionButton";
import AttachmentPreview from "../../cardActions/addAttachment/AttachmentPreview";
import AttachmentLoader from "./AttachmentLoader";
import DeleteAttachment from "./DeleteAttachment";
import EditAttachment from "./EditAttachment";
import AttachmentPreviewOfMail from "./DownloadAttchament"
import { Attachment } from "../../../type/type"
import { Space } from "antd"
import OpenFileInNewTab from "./OpenFileInNewTab"

const CardAttachment = (props: AttachmentProps) => {
  const [preview, showPreview] = useState({
    show: false,
    presignedLink: "",
    fileName: "",
    objectKey: ""
  });
  const [loader, setloader] = useState(props.attachmentLoader);
  const showPreviewImg = (e: any, attachment: Attachment) => {
    e.preventDefault();
    e.stopPropagation();
    showPreview({
      show: !preview.show,
      presignedLink: attachment.presignedLink,
      fileName: attachment.name,
      objectKey: attachment.objectKey
    });
  };
  const closePreview = () => {
    showPreview({
      show: !preview.show,
      presignedLink: "",
      fileName: "",
      objectKey: ""
    });
  };

  useEffect(() => {
    if (props.currentApplicant.id) {
      getApplicantAttachments(props.currentApplicant.id).then(
        (attachments: any) => {
          let initAtachment = attachments ? attachments : [];
          props.initAttachment(initAtachment, props.currentApplicant.id);
        }
      );
    }
  }, [props.currentApplicant.id]);
  useEffect(() => {
    setloader(props.attachmentLoader);
  }, [props.attachmentLoader]);
  if (!props.attachment[props.currentApplicant.id]) {
    return null;
  }
  return (
    <>
      <div className=" mb-2 ml-12 relative">
        {props.attachment[props.currentApplicant.id].length > 0 ? (
          <>
            <div className=" absolute mt-1 -ml-8" aria-hidden="true">
              <AttachmentIcon />
            </div>
            <div className="flex justify-between w-full mb-2">
              <div className="text-base tracking-wide font-semibold px-1">
                Attachment
              </div>
            </div>
          </>
        ) : null}
        {props.attachment[props.currentApplicant.id].map(
          (attachment: any, i: number) => {
            return (
              <div
                className="my-2 h-auto cursor-pointer flex rounded justify-center items-center hover:bg-gray-200"
                onClick={(e: any) => showPreviewImg(e, attachment)}
                onKeyDown={() => { }}
                tabIndex={0}
                role="button"
                data-testid="show-preview"
                key={i}
              >
                <div className="bg-gray-300 bg-opacity-25 font-extrabold rounded text-gray-600 text-lg text-center justify-center w-28 h-20 items-center flex">
                  {
                    attachment.objectKey.split(".")[
                    attachment.objectKey.split(".").length - 1
                    ]
                  }
                </div>
                <div className="flex flex-col break-all  w-full m-2">
                  <div className="text-sm font-bold pl-2">
                    {attachment.name}
                  </div>
                  <div className="flex mt-1">
                    <Space>
                      <EditAttachment attachment={attachment} />
                      <AttachmentPreviewOfMail attachment={attachment}
                      />
                      <OpenFileInNewTab attachment={attachment} />
                      <DeleteAttachment attachment={attachment} />

                    </Space>
                  </div>


                </div>
              </div>
            );
          }
        )}
        {loader ? <AttachmentLoader /> : null}
      </div>

      {preview.show ? (
        <AttachmentPreview
          link={preview.presignedLink}
          closePreview={closePreview}
          fileName={preview.fileName}
        />
      ) : null}

    </>
  );
};
const mapStateToProps = (state: any) => ({
  attachment: state.opening.attachment,
  currentApplicant: state.opening.currentApplicant,
  attachmentLoader: state.opening.attachmentLoader,
  clientId: state.opening.clientId,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  initAttachment: (attachment: any, applicantId: string) => {
    dispatch(initAttachment(attachment, applicantId));
  },
});
export default connect(mapStateToProps, mapPropsTostate)(CardAttachment);
