import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import ReactTextareaAutosize from "react-textarea-autosize";
import { Dispatch } from "redux";
import CloseIcon from "../../../assets/Close";
import { editComment } from "../../../lib/api";
import { updateComment } from "../../../redux/actions/opening/action";
import { EditCommentProps } from "../../../type/card";
import EditCommentActionButton from "./editCommentActionButton/EditCommentActionButton";
import { SOCKET_CLIENT_ID } from "../../../constant";
const EditComment = (props: EditCommentProps) => {
  const commentRef = useRef<HTMLDivElement | null>(null);
  const [comment, setComment] = useState("");
  const [Size, ISExceeds] = useState(false);
  const [newInput, isChangeInInput] = useState(false);
  const [commentInput, showCommentInput] = useState({
    Input: false,
    style: "",
  }); 
  const [showFullText, setShowFullText] = useState(false);

  const handleShowMore = () => {
    setShowFullText(true);
  };

  const handleShowLess = () => {
    setShowFullText(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (commentRef.current && !commentRef.current.contains(e.target)) {
      showCommentInput({
        Input: false,
        style: "none",
      });
      return;
    }
  };
  const validateCommentLength = (e: any) => {
    e.target.value.length >= 5000 ? ISExceeds(true) : ISExceeds(false);
    setComment(e.target.value);
    isChangeInInput(true);
  };
  const updateComment = (e: any, commentId: string) => {
    if ((e.keyCode == 13 && e.metaKey) || e.button == 0) {
      const postComment = {
        body: comment,
        id: commentId,
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        opening_id: props.currentApplicant.opening_id,
        member_id: props.loginUser.user.id,
      };

      if (newInput && comment.trim().length > 0) {
        e.preventDefault();
        editComment(postComment, props.currentApplicant.id)
          .then((comment: any) => {
            if (comment) {
              props.updateComment(comment);
              showCommentInput({
                Input: false,
                style: "none",
              });
              isChangeInInput(false);
            }
          })
          
      }
    }
  };


  return (
    <React.Fragment>
      <div
        className={`${
          commentInput.Input
            ? "border-tacitbase-secondary3"
            : "border-tacitbase-tertiary1 border-opacity-20 "
          } border  h-auto rounded p-2 break-all   bg-white `}
        ref={commentRef}
      >
        {commentInput.Input ? (
          <>
            <ReactTextareaAutosize
              className=" h-8  w-full focus:outline-none resize-none rounded  text-sm  "
              maxLength={5000}
              defaultValue={props.comments.body.trim()}
              placeholder="Write a comment..."
              style={{ wordBreak: "break-word" }}
              onFocus={(f: any) => f.target.select()}
              onChange={validateCommentLength}
              onKeyDown={(e: any) => updateComment(e, props.comments.id)}
            />
            <div className=" flex flex-row items-center justify-between">
              <div className="flex items-center  mt-2 space-x-2 ">
                <button
                  className="submit-btn text-sm"
                  disabled={Size}
                  data-testid="update-comment"
                  style={{ wordBreak: "break-word" }}
                  onClick={(e: any) => updateComment(e, props.comments.id)}
                  onKeyDown={(e: any) => updateComment(e, props.comments.id)}
                >
                  <span className="text-xs">Save</span>
                </button>
                <span
                  className="cursor-pointer"
                  aria-hidden="true"
                  onClick={() => {
                    showCommentInput({
                      Input: false,
                      style: "",
                    });
                  }}
                >
                  <CloseIcon />
                </span>
              </div>
            </div>
          </>
        ) : (
          <div>
          <div               style={{ wordBreak: "break-word" }}

                className={`${props.comments.body.length > 300 && !showFullText ? "h-24" : "h-auto"
                  } overflow-hidden w-full focus:outline-none resize-none rounded text-sm`
                }

          >
                {props.comments.body
              .trim()
              .split("\n")
              .map((line: any, i: number) =>
                line == "" ? (
                  i == 0 ||
                    i == props.comments.body.length - 1 ? null : (
                    <span key={i}>
                      {line}
                      <br />
                      </span>
                  )
                ) : (
                  <span key={i}>
                      {line}
                      <br />
                    </span>
                )
              )}
              </div>
              {(props.comments.body.length
                > 300) && !showFullText && (
                <button style={{ color: '#4B48A5', textDecoration: 'underline' }} onClick={handleShowMore}>Show more</button>
          )}
              {(props.comments.body.length
                > 300) && showFullText && (
            <button style={{color:'#4B48A5',textDecoration:'underline'}} onClick={handleShowLess}>Show less</button>
          )}
        </div>        
        )}  
      </div>
      {commentInput.Input ? null : props.loginUser.user.id ==
        props.comments.member_id ? (
        <EditCommentActionButton
          showCommentInput={() =>
            showCommentInput({
              Input: true,
              style: "shadow-md",
            })
          }
          clickEdit={() => updateComment}
        />
      ) : null}

    </React.Fragment>

  );

};

const mapStateToProps = (state: any) => ({
  clientId: state.opening.clientId,
  currentApplicant: state.opening.currentApplicant,
  loginUser: state.opening.loginUser,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  updateComment: (comment: any) => dispatch(updateComment(comment)),
});
export default connect(mapStateToProps, mapPropsToState)(EditComment);
