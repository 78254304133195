import React, { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { RecordThreads, SendMail, loginUser, notificationServiceEntityDotRemovePayloadType } from "../../type/type";
import { getUserThreadOfMessage, markMailAsRead, readNotification, sendMail } from "../../lib/api"
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  addComposeMailInInboxThread,
  changeServiceNotificationsCountValue,
  getSelectedMailThread,
  markAsReadMail,
  unread_dot_remover,
} from "../../redux/actions/opening/action";
// import usermailsThread from "../mock/mailThread.json"
import {
  currentMailThreads,
  userMails,
} from "../../redux/reducers/initialState";
import DetailMail from "./DetailMail";
import { toast } from "react-toastify";
import { sendMailReq } from "../../lib/apiReq";
import Spinner from "../../loadingAnimation/Spinner";

type TmailThreadProps = {
  currentMailThread: currentMailThreads;
  selectedMailId: string;
  userMails: userMails;
  currentPage?: string;
  tab?: string;
  refKey: string;
  markAsReadMail: (
    currentMailId: string,
    tab: string,
    currentPage: string
  ) => void;
  removeMailNotificationDotsOnCard(payload:notificationServiceEntityDotRemovePayloadType): void;
  changeServiceNotificationsCount(payload:{}):void;
  loginUser: loginUser;
  notificationCenter: any;

  getSelectedMailThread: (
    currentMailId: string,
    threadMsg: RecordThreads
  ) => void;
  isCardThread?: boolean;
  addComposeMailInInboxThread(mailId: SendMail, selectedMailId: string): void;
};
const TmailThread = (props: TmailThreadProps) => {
  const [showMessages, setShowMessages] = useState(false);

  const handleShowMessages = () => {
    setShowMessages(!showMessages);
  };
  useEffect(() => {
    
   

    getUserThreadOfMessage(props.selectedMailId)
      .then((res) => {
        props.getSelectedMailThread(props.selectedMailId, res);
      })
    

    markMailAsRead(props.selectedMailId)
      .then((res) => {
        if (res) {
          if (props.tab && props.currentPage)
            props.markAsReadMail(
              props.selectedMailId,
              props.tab,
              props.currentPage
            );
        }
      })
      props.notificationCenter.unreadDotsofServices?.inbox?.mail?.[props.selectedMailId]&&

      readNotification(props.selectedMailId,"mail").then(()=>{
        props.removeMailNotificationDotsOnCard({service:"inbox",model_name:"mail",remove_id: props.selectedMailId})

                })
     
    setShowMessages(false);
  }, [props.selectedMailId]);

  const sendMailFromcard = (e: sendMailReq) => {
    sendMail(e).then((res) => {
      props.addComposeMailInInboxThread(res, props.selectedMailId);
      toast.update("customId", {
        render: () => <div className="text-sm">Mail sent</div>,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
    });
  };
  if (!props.currentMailThread[props.selectedMailId])
    return (
      <div className="flex items-center h-full justify-center">
        <Spinner />
      </div>
    );
  return (
    <>
      <div className="py-2 w-full border-b px-3 flex justify-between items-center">
        <div>
          {props.currentMailThread[props.selectedMailId].messages[0].subject}
        </div>
        <div className="text-xs border space-x-1 rounded px-2 py-1">
          {capitalizeFirstLetter(
            `${props.currentMailThread[props.selectedMailId].entity}`
          )}
          |
          {capitalizeFirstLetter(
            `${props.currentMailThread[props.selectedMailId].source.name}`
          )}
        </div>
      </div>
      {props.currentMailThread[props.selectedMailId].messages.length > 0 && (
        <DetailMail
          isCardThread={props.isCardThread ? true : false}
          record_id={props.currentMailThread[props.selectedMailId].source.id}
          mail={props.currentMailThread[props.selectedMailId].messages[0]}
          selectMailId={props.selectedMailId}
          entity={props.currentMailThread[props.selectedMailId].entity}
          service={props.currentMailThread[props.selectedMailId].service}
          callApi={(e: any) => sendMailFromcard(e)}
          source={props.currentMailThread[props.selectedMailId].source}
        />
      )}
      {showMessages ||
      props.currentMailThread[props.selectedMailId].messages.length == 2 ? (
        <div className="flex-col w-full">
          <hr className="w-full mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
        </div>
      ) : (
        //
        <></>
      )}
      {showMessages &&
        props.currentMailThread[props.selectedMailId].messages
          .slice(
            1,
            props.currentMailThread[props.selectedMailId].messages.length - 1
          )
          .map((message, index) => (
            <>
              <DetailMail
                isCardThread={props.isCardThread ? true : false}
                record_id={
                  props.currentMailThread[props.selectedMailId].source.id
                }
                selectMailId={props.selectedMailId}
                entity={props.currentMailThread[props.selectedMailId].entity}
                key={index}
                mail={message}
                service={props.currentMailThread[props.selectedMailId].service}
                source={props.currentMailThread[props.selectedMailId].source}
                callApi={(e: any) => sendMailFromcard(e)}
              />
              <div className="flex-col w-full">
                <hr className="w-full border-tacitbase-tertiary1 border-opacity-10"></hr>
              </div>
            </>
          ))}
      {!showMessages &&
        props.currentMailThread[props.selectedMailId].messages.length > 2 && (
          <div className="flex items-center justify-center my-2">
            <div className="flex-col">
              <hr className="w-3 mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
              <hr className="w-3 border-tacitbase-tertiary1 border-opacity-10 "></hr>
            </div>
            <button
              onClick={handleShowMessages}
              className="bg-color-LightGray rounded flex  justify-center items-center w-7 h-7"
              style={{ fontSize: "12px" }}
            >
              <span>
                {props.currentMailThread[props.selectedMailId].messages.length -
                  2}
              </span>
            </button>
            <div className="flex-col w-full">
              <hr className="w-full mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
              <hr className="w-full border-tacitbase-tertiary1 border-opacity-10"></hr>
            </div>
          </div>
        )}
      {props.currentMailThread[props.selectedMailId].messages.length > 1 && (
        <>
          <DetailMail
            isCardThread={props.isCardThread ? true : false}
            source={props.currentMailThread[props.selectedMailId].source}
            record_id={props.currentMailThread[props.selectedMailId].source.id}
            selectMailId={props.selectedMailId}
            entity={props.currentMailThread[props.selectedMailId].entity}
            mail={
              props.currentMailThread[props.selectedMailId].messages[
                props.currentMailThread[props.selectedMailId].messages.length -
                  1
              ]
            }
            service={props.currentMailThread[props.selectedMailId].service}
            callApi={(e: any) => sendMailFromcard(e)}
          />
          {/* <button className="ml-5 px-2 py-1 text-sm rounded border border-tacitbase-tertiary1 border-opacity-30 hover:bg-gray-100">
              <i className="fa fa-reply px-1 " aria-hidden="true"></i>
              Reply
            </button> */}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentMailThread: state.opening.currentMailThread,
  userMails: state.opening.userMails,
  loginUser: state.opening.loginUser,
  notificationCenter: state.opening.notificationCenter,


});
const mapPropsToState = (dispatch: Dispatch) => ({
  getSelectedMailThread: (currentMailId: string, threadMsg: RecordThreads) =>
    dispatch(getSelectedMailThread(currentMailId, threadMsg)),
  markAsReadMail: (currentMailId: string, tab: string, currentPage: string) =>
    dispatch(markAsReadMail(currentMailId, tab, currentPage)),
  addComposeMailInInboxThread: (threadMsg: SendMail, selectedMailId: string) =>
    dispatch(addComposeMailInInboxThread(threadMsg, selectedMailId)),
    changeServiceNotificationsCount: (payload: {}) =>
    dispatch(changeServiceNotificationsCountValue(payload)),
    removeMailNotificationDotsOnCard: (payload:notificationServiceEntityDotRemovePayloadType) =>
    dispatch(unread_dot_remover(payload)),
});
export default connect(mapStateToProps, mapPropsToState)(TmailThread);
