import React, { useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import Read from "../../../Read";
import { ListActionButtonProps } from "../../../type/boardPanal";
import { Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
import ListPopOver from "../../listPopup/listPopover/ListPopOver";
import Portal from "../../portal/Portal";

const ListActionButton = (props: ListActionButtonProps) => {
  const [modal, showModal] = useState(false);
  const [referenceElement, setReferenceElement] = useState<
    HTMLButtonElement | HTMLDivElement | null
  >(null);
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    modifiers: [
      {
        name: "flip",
        options: {
          fallbackPlacements: ["top", "left"],
        },
      },
    ],
  });
  return (
    <Read access={props.currentOpening.access}>
      <div
        className={ `mr-1 ${
          modal
            ? "bg-opacity-25  rounded"
            : "hover:bg-gray-300 hover:bg-opacity-50 rounded"
        }`}
      >
        <button
          className={`${
            modal ? " bg-gray-300 bg-opacity-50 rounded" : ""
          }   list-action-button  `}
          ref={setReferenceElement}
          data-testid="testBtn"
          onClick={() => showModal(!modal)}
        >
          <svg
            className="h-4 fill-current text-white cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M5 10a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4zm7 0a1.999 1.999 0 1 0 0 4 1.999 1.999 0 1 0 0-4z" />
          </svg>
        </button>
      </div>
      {modal ? (
        <Overlay onClose={() => showModal(!modal)} scope={Scope.Popover}>
          <Portal>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <ListPopOver
                listID={props.listID}
                close={(e: boolean) => {
                  showModal(e);
                }}
              />
            </div>
          </Portal>
        </Overlay>
      ) : null}
    </Read>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
});

export default connect(mapStateToProps)(ListActionButton);
