import React, { useState } from "react";
import { SEARCH_LABEL_PLACEHOLDER } from "../../../../constant";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import LabelPreview from "../labelPreview/LabelPreview";
import TacitbaseInput from "../../../../ant/Input";
import Emoji from "../../../Emoji";
type LabelInputProps = {
  click: (searchInput: string) => void;
  change?: (e: any) => void;
  update: (labelID: any) => void;
  createLabel?: () => void;
  flag?: boolean;
  close: (e: boolean) => void;
  usePortal?: boolean;
};
const LabelInput = (props: LabelInputProps) => {
  const [searchedLabel, getSearchedLabel] = useState("");
  const searchKeyword = (e: any) => {
    getSearchedLabel(e.target.value);
  };

  const onEmojiSelect = (emoji: string) => {
    getSearchedLabel((prev) => prev + emoji);
  };

  /* here check if key press is tab and focus is at last then close the popup and if key is enter then allow to create label  */
  const setFocusToParent = (e: any) => {
    if (e.key == "Tab") {
      document.getElementById("card-input")?.focus();
      props.close(false);
    } else {
      if (e.key == 13 || e.button == 0) {
        props.click(searchedLabel);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="my-2 mx-2">
        <TacitbaseInput
          placeholder={SEARCH_LABEL_PLACEHOLDER}
          onChange={(e: any) => searchKeyword(e)}
          value={searchedLabel}
          data-testid="label-search-input"
          suffix={
            <Emoji onEmojiSelect={onEmojiSelect} usePortal={props.usePortal} />
          }
        />
      </div>
      <div className="flex flex-col">
        <span className="text-xs my-2 mx-2">LABEL</span>
        <LabelPreview
          flag={props.flag}
          update={(label) => props.update(label)}
          searchedLabel={searchedLabel}
        />
        <button
          className="px-4 py-1.5 flex  bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 mb-2 mx-2  rounded text-sm font-light"
          onClick={() => props.click(searchedLabel)}
          data-testid="label-button"
          tabIndex={0}
          onKeyDown={(e: any) => setFocusToParent(e)}
        >
          {capitalizeFirstLetter("create a new label")}
        </button>
      </div>
    </React.Fragment>
  );
};
export default LabelInput;
