import React from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import DropDownWithIcon from "../common/DropDownWithIcon";
import "./pagination.scss";
type PaginationProps = {
  onPageChange: any;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className: string;
  selectedOption(e: number): void;
};
const Pagination = (props: PaginationProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 1) {
    return (
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        {paginationRange.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return <button key={"DOTS"} className="pagination-item dots">&#8230;</button>;
          }

          return (
            <button
              key={pageNumber}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </button>
          );
        })}
      </ul>
    );
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <>
      {" "}
      <ul
        className={classnames(
          "text-xs pagination-container flex items-center ",
          {
            [className]: className,
          }
        )}
      >
        {" "}
        <span className="pagination-item flex-shrink-0">Rows per pages </span>
        <div className="my-1">
          <DropDownWithIcon
            data={[25, 50, 75, 100]}
            defaultValue={pageSize}
            selectedOption={(e: number) => props.selectedOption(e)}
            width={"12"}
          />{" "}
        </div>
        <button
          className={classnames("text-xs pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <i className="fa fa-angle-left fa-lg" aria-hidden="true"></i>
        </button>
        {paginationRange.map((pageNumber: any) => {
          if (pageNumber === DOTS) {
            return <button key={pageNumber} className="pagination-item dots">&#8230;</button>;
          }

          return (
            <button
              key={pageNumber}
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              data-testid={pageNumber}
            >
              {pageNumber}
            </button>
          );
        })}
        <button
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <i className="fa fa-angle-right fa-lg" aria-hidden="true"></i>
        </button>
      </ul>
    </>
  );
};

export default Pagination;
