import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SEARCH_LABEL_PLACEHOLDER } from "../../../../constant";
import {
  attachLabelToCandidate,
  deleteLabelFromCandidate,
} from "../../../../lib/api";
import {
  addLabelToCurrentCandidateInCDB,
  removeLabelFromCandidate,
} from "../../../../redux/actions/opening/action";
import { CurrentCandidateInEditMode } from "../../../../redux/reducers/initialState";
import { InitLabel, Label } from "../../../../type/type";
import ShowLabelPreview from "../../../card/cardLabel/labelPreview/ShowLabelPreview";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import TacitbaseInput from "../../../../ant/Input";
import Emoji from "../../../Emoji";

type LabelInputOfCandidateProps = {
  click: (searchInput: string) => void;
  change?: (e: any) => void;
  update: (labelID: any) => void;
  createLabel?: (label: any) => void;
  flag?: boolean;
  usePortal?: boolean;
  close: (e: boolean) => void;
  candidateLabels: InitLabel;
  clientId: string;
  currentCandidateInEditMode: CurrentCandidateInEditMode;
  addLabelToCurrentCandidateInCDB(data: any, candidateId: string): void;
  removeLabelFromCandidate(data: string, candidateId: string[]): void;
};
const LabelInputOfCandidate = (props: LabelInputOfCandidateProps) => {
  const [searchedLabel, getSearchedLabel] = useState("");
  let candidateLabels = Object.values(props.candidateLabels);
  candidateLabels.sort((a: Label, b: Label) => a.name.localeCompare(b.name))
  candidateLabels = candidateLabels.filter((label: Label) => {
    return label.name.toUpperCase().includes(searchedLabel.toUpperCase());
  });
  const searchKeyword = (e: any) => {
    getSearchedLabel(e.target.value);
  };
  const onEmojiSelect = (emoji: string) => {
    getSearchedLabel((prev) => prev + emoji);
  };

  /* here check if key press is tab and focus is at last then close the popup and if key is enter then allow to create label  */
  const setFocusToParent = (e: any) => {
    if (e.key == "Tab") {
      document.getElementById("card-input")?.focus();
      props.close(false);
    } else {
      if (e.key == 13 || e.button == 0) {
        props.click(searchedLabel);
      }
    }
  };
  const addLabelToCandidate = (labelData: Label) => {
    let found: boolean = false;
    if (props.currentCandidateInEditMode.labels.length > 0) {
      found = props.currentCandidateInEditMode.labels.includes(labelData.id);
      if (found) {
        let req = {
          candidate_ids: [props.currentCandidateInEditMode.candidateId],
          label_ids: [labelData.id],
          socket_client_id: props.clientId,
        };
        deleteLabelFromCandidate(req).then((res) => {
          props.removeLabelFromCandidate(
            props.currentCandidateInEditMode.candidateId,
            res.label_ids
          );
        });
      }
    }
    if (!found) {
      let req = {
        candidate_id: props.currentCandidateInEditMode.candidateId,
        label_id: labelData.id,
        socket_client_id: props.clientId,
      };
      attachLabelToCandidate(req)
        .then((candidate_labels) => {
          props.addLabelToCurrentCandidateInCDB(
            candidate_labels.labels,
            props.currentCandidateInEditMode.candidateId
          );
        })
      
    }
  };

  return (
    <React.Fragment>
      <div className="my-2 mx-2">
        <TacitbaseInput
          type="text"
          placeholder={SEARCH_LABEL_PLACEHOLDER}
          onChange={(e: any) => searchKeyword(e)}
          value={searchedLabel}
          data-testid="label-search-input"
          suffix={
            <Emoji onEmojiSelect={onEmojiSelect} usePortal={props.usePortal} />
          }
        />
      </div>
      <div className="flex flex-col">
        <span className="text-xs my-2 mx-2">LABEL</span>
        <div className=" label-panal">
          {candidateLabels
            ? candidateLabels.map(
                (label: any) =>
                  label && (
                  <ShowLabelPreview
                    key={label.id}
                      clickOnLabel={(label: Label) =>
                        addLabelToCandidate(label)
                      }
                      label={label}
                      updateLabel={(label) => props.update(label)}
                      searchInArray={props.currentCandidateInEditMode.labels}
                      searchInObject={props.candidateLabels}
                    />
                  )
              )
            : null}
        </div>
        <button
          className="px-4 py-1.5 flex  bg-gray-300 bg-opacity-25 hover:bg-gray-300 hover:bg-opacity-40 mb-2 mx-2  rounded text-sm font-light"
          onClick={() => props.click(searchedLabel)}
          data-testid="label-button"
          tabIndex={0}
          onKeyDown={(e: any) => setFocusToParent(e)}
        >
          {capitalizeFirstLetter("create a new label")}
        </button>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  candidateLabels: state.opening.candidateLabels,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  clientId: state.opening.clientId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addLabelToCurrentCandidateInCDB: (data: any, candidateId: string) =>
    dispatch(addLabelToCurrentCandidateInCDB(data, candidateId)),
  removeLabelFromCandidate: (data: string, candidateId: string[]) =>
    dispatch(removeLabelFromCandidate(data, candidateId)),
});
export default connect(mapStateToProps, mapPropsToState)(LabelInputOfCandidate);
