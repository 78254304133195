import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import { getOutgoingInvitations } from "../../lib/api";
import { initOutgoingInvitations } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import CancelInvitation from "./CancelInvitation";
import TacitbaseAvtar from "../../ant/Avtar";
import { renderCreatedAt } from "../../utils/renderCreatedAt";

type InvitedMembersProps = {
  initOutgoingInvitations(invitations: any): void;
  loginUser: loginUser;
  applicationId: string;
  outgoingInvitations: any[];
  loading: boolean;
};

const InvitedMembers = (props: InvitedMembersProps) => {
  const [invitedMembers, setInvitedMembers] = useState<any>([]);

//getting InvitedMembers from api
  useEffect(() => {
    getOutgoingInvitations(props.loginUser.user.id, props.applicationId).then(
      (invitations: any) => {
        props.initOutgoingInvitations(invitations);
      }
    );
  }, [props.loginUser.user.id, props.applicationId]);

// when getting invited members set it into setInvitedMembers
  useEffect(() => {
    setInvitedMembers(props.outgoingInvitations);
  }, [props.outgoingInvitations]);


  // creating columns fro invited member table
  const columns = [
    {
      title: "Email",
      dataIndex: "invitee",
      key: "email",
      render: (invitee: any) => (
        <div className="flex gap-2">
          <TacitbaseAvtar content={invitee[0]?.email[0]} size="large" />
          <Tooltip title={invitee[0]?.email}>
            <div className="whitespace-nowrap overflow-hidden flex items-center justify-center overflow-ellipsis max-w-50">
              {invitee[0]?.email}
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => <div>{status}</div>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      key: "date",
      render: (date: string) => (
        <div className="flex">
          <div>{renderCreatedAt(date)}</div>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "revoke",
      key: "date",
      render: (_: any, record: any) => (
        <div className="flex justify-end">
          <CancelInvitation invitationId={record.id} />
        </div>
      ),
    },
  ];

  return (
    <div style={{ maxHeight: "calc(100vh - 339px)" }}>
      <Table
        columns={columns}
        dataSource={invitedMembers}
        scroll={{ x: "max-content", y: "calc(100vh - 280px)" }}
        size="small"
        pagination={false}
        loading={props.loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  outgoingInvitations: state.opening.outgoingInvitations,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initOutgoingInvitations: (invitations: any) =>
    dispatch(initOutgoingInvitations(invitations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitedMembers);
