import React from 'react';
import PricingCard from './PricingCard';
import { Plan } from '../../type/type';
import PricingCardSkeleton from './PricingCardSkeleton';

type PricingSectionProps = {
    plans:Plan[];
    freePlans:Plan[];
    loading: boolean;
}
const PricingSection = (props:PricingSectionProps) => {
  return (
    <div className="flex flex-col md:flex-row justify-center items-center gap-6 p-8 ">
    {
        props.loading ?(
            <PricingCardSkeleton />
        ):(
            <PricingCard
            plans={props.plans}
            freePlans = {props.freePlans}
           />
        )
    }
    
    </div>
  );
};

export default PricingSection;
