import React, { useState } from "react"
import MemberAddPopover from "../common/MemberPopupAnt"
import { CheckOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons"
import { Dispatch } from "redux"
import { assignManagerToCompany, removeManager } from "../../lib/api"
import { company, Member, orgMember } from "../../type/type"
import { addAssigneeToCompany, removeAssigneeToCompany } from "../../redux/actions/opening/action"
import { connect } from "react-redux"
import { Button } from "antd"
import getOrgMembers from "../../utils/getOrgMember"
export type AddManagerProps = {
    orgMembers: orgMember,
    addAssigneeToCompany(member: string[], id: string): void,
    removeAssigneeToCompany(memberID: string, applicantID: string): void,
    applicationId: string,
    workspaceId: string,
    companies: { [key: string]: company },

}
const AddManager = (props: AddManagerProps) => {
    const [loader, showLoader] = useState(false)
    const [loadedMember, setloadedMember] = useState("")

    const determineIcon = (member: Member) => {
        const memberCount = props.companies[props.workspaceId] &&
            props.companies[props.workspaceId].members.includes(member.id)
        const isLoading = member.id === loadedMember
        if (isLoading && loader) {
            // Show loading icon for the current label being processed
            return <LoadingOutlined />
        }
        if (!memberCount) {
            // If the member count is zero, return nothing
            return null
        } else if (memberCount) {
            // If the member count equals the total number of selected rows, return a checkmark icon
            return <CheckOutlined />
        }
    }
    const AddMemberToWorkpace = (member: Member) => {

        let found: boolean = false
        setloadedMember(member.id)
        found =
            props.companies[props.workspaceId].members && props.companies[props.workspaceId].members.includes(member.id)

        if (found) {
            showLoader(true)

            const req = {
                id: member.id,
                company_id: props.workspaceId,
                socket_client_id: "props.clientId",
            }
            removeManager(req)
                .then((res) => {
                    showLoader(false)

                    if (res) {
                        props.removeAssigneeToCompany(res.id, res.company_id)
                    }
                })

        }



        if (!found) {
            showLoader(true)

            const req = {
                company_id: props.workspaceId,
                members: [member.id],
                socket_client_id: "props.clientId",
            }
            assignManagerToCompany(req)
                .then((members) => {
                    showLoader(false)

                    props.addAssigneeToCompany(members.members, members.company_id)
                })

        }
    }
    return (
        <>
            <div>
                <MemberAddPopover defaultTitle="" initialMembers={getOrgMembers()} determineIcon={determineIcon} onAddMember={AddMemberToWorkpace} element={<Button  size="small"  icon={<PlusOutlined ></PlusOutlined>} />}
                />

            </div>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    orgMembers: state.opening.members,
    openingMembers: state.opening.openingMembers,
    applicationId: state.opening.applicationId,
    clientId: state.opening.clientId,
    companies: state.opening.companies,

})
const mapPropsToState = (dispatch: Dispatch) => ({
    addAssigneeToCompany: (members: string[], id: string) =>
        dispatch(addAssigneeToCompany(members, id)),
    removeAssigneeToCompany: (memberID: string, applicantID: string) =>
        dispatch(removeAssigneeToCompany(memberID, applicantID)),
})
export default connect(mapStateToProps, mapPropsToState)(AddManager)
