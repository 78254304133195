import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ADD_REVIEWER, CDBMember, MAX_CHAR_LIMIT_WARNING } from "../../../constant"
import { createReview } from "../../../lib/api";
import { Role } from "../../../lib/apiRes";
import { CurrentCandidateInEditMode } from "../../../redux/reducers/initialState";
import ReactTextareaAutosize from "react-textarea-autosize"
import {
  currentApplicant,
  Member,
  KeyWithArray,
  orgMember,
  OpeningMembers,
  loginUser,
  Scope,
} from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import PopupTitle from "../../common/popupTitle/PopupTitle";
import SearchedResultPopup from "../../subNavbar/addBoardMember/SearchedResultPopup";
import ShowMeberInInput from "../../subNavbar/addBoardMember/ShowMeberInInput";
import { Overlay } from "../../hook/Overlay";
import { createReviewReq } from "../../../lib/apiReq"
import { LabeledIconButton } from "../../../ant/Button"
import { throwSuccess } from "../../../service/throwError";

export type CreateIndividualReviewProps = {
  members: orgMember;
  openingMembers: OpeningMembers;
  currentApplicant: currentApplicant;
  applicantMembers: KeyWithArray;
  applicationId: string;
  clientId: string;
  refer: any;
  click: () => void;
  orgRoles: Role[];
  loginUser: loginUser;
  currentCandidateInEditMode: CurrentCandidateInEditMode;
};
const CreateIndividualReview = (props: CreateIndividualReviewProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [reviewers, setReviewers] = useState<string[]>([]);
  const refer = useRef(null)
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [searchedMember, getSearchedMember] = useState("");
  let oMember = Object.values(props.members).filter(
    (member: Member) =>
      member.registrations[0].data.activated &&
      member.id != props.loginUser.user.id &&
      (member.first_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.last_name.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.email.toUpperCase().includes(searchedMember.toUpperCase()) ||
        member.username.toUpperCase().includes(searchedMember.toUpperCase()))
  );
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
      getSearchedMember("");
    }
  }, [reviewers]);

  const searchKeyword = (e: any) => {
    getSearchedMember(e.target.value);
  };
  const handleInputChange = (member: Member) => {
    var updatedList = [...reviewers];
    if (!reviewers.includes(member.id)) {
      updatedList = [...reviewers, member.id];
    }
    setReviewers(updatedList);
  };
  const removeMemberFromSelectedList = (index: number) => {
    setReviewers((reviewers: string[]) => {
      reviewers.splice(index, 1);
      return [...reviewers];
    });
  };
  const validateCommentLength = (e: any) => {
    setComment(e.target.value)
  };
  const createReviews = () => {
    if (reviewers.length > 0) {
      setLoading(true)
      let req: createReviewReq
      req = {
        candidates: [props.currentCandidateInEditMode.candidateId],
        reviewers: reviewers,
        socket_client_id: props.clientId,
        labels: props.currentCandidateInEditMode.labels,
      }
      if (comment.trim()) {
        req.comments = [{
          body: comment.trim()
        }]
      }
      createReview(req)
        .then((res) => {
          setLoading(false)
          if (res) props.click();
          throwSuccess("Candidate sent for review." )
          setReviewers([]);
        })
     
    }
  };
  useEffect(() => {
    if (inputRef.current && inputRef.current.value.trim() == "") {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  if (!props.members) return null;
  return (
    <React.Fragment>
      <div
        className="animate-popover_fadeIn flex flex-col mt-1 z-20 w-96 bg-white text-gray-800 tracking-normal text-sm font-normal border border-tacitbase-tertiary1 border-opacity-30 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        ref={props.refer}
      >
        <div className="m-2">
          <PopupTitle PopupTitle={ADD_REVIEWER} close={props.click} />
        </div>
        <div className="justify-center items-center flex pl-2 ">
          <div className="w-full relative m-2 ">
            <div className="pr-2">
            <div
              className={`rounded inputCSSOnlyForFocus ${
                inputRef.current
                  ? "border border-tacitbase-secondary3"
                  : "border border-tacitbase-tertiary1 border-opacity-25"
                  }  flex flex-wrap items-center w-full mr-2 `}
            >
              <div
                className={`${
                  reviewers.length == 0 ? "w-full" : ""
                    } flex px-2 pb-1  flex-wrap`}
              >
                {reviewers &&
                  reviewers.map(
                    (memberID: string, index: number) =>
                      memberID && (
                        <ShowMeberInInput
                          key={index}
                          index={index}
                          memberId={memberID}
                          removeMemberFromSelectedList={(idx: number) =>
                            removeMemberFromSelectedList(idx)
                          }
                        />
                      )
                  )}
                <input
                  type="text"
                  className={`${
                    reviewers.length == 0 && "w-full"
                  } placeholder-italic outline-none text-sm mt-1`}
                  placeholder={"search email or name"}
                  onChange={(e: any) => searchKeyword(e)}
                  ref={inputRef}
                  onBlur={() => inputRef.current?.blur()}
                  data-testid="searchMember"
                />
              </div>
              </div>
            {searchedMember.trim() ? (
                <Overlay onClose={() => getSearchedMember("")
              } scope={Scope.Popover} reference={refer} >
              <SearchedResultPopup
              refer={refer}
                click={(member: Member) => handleInputChange(member)}
                selectedMember={reviewers}
                searchResult={oMember}
                searchInObject={{}}
                searchInObjectKey={CDBMember}
              /></Overlay>
              ) : null}
            </div>

            <div className="max-h-40 overflow-y-scroll mt-2">
              <ReactTextareaAutosize
                className={`border-tacitbase-tertiary1 border-opacity-20 border w-full py-2 px-2  rounded focus:outline-none resize-none text-sm block overflow-hidden `}
                onChange={validateCommentLength}
                tabIndex={0}
                minRows={3}
                data-testid="reviewComment"
                placeholder="write a comment(optional)"
              ></ReactTextareaAutosize>{comment.trim().length > 125 && <div data-testid="maxCommentLimit" className="text-red-500 py-2 text-sm">
                {capitalizeFirstLetter(MAX_CHAR_LIMIT_WARNING)}
              </div>}
            </div>
          </div>
        </div>
        <div className="justify-start flex mx-4 mt-2 mb-4">
          <LabeledIconButton type="primary" size="small" loading={loading} label={capitalizeFirstLetter("send to review")}
            onClick={createReviews} />
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(CreateIndividualReview);
