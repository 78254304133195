// import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import Email from "../../../assets/EmailCardThread";
import {
  loginUser,
  Mail,
  MailPreview,
  MailPreviewWithTag,
  // orgMember,
  RecordThreads,
} from "../../../type/type";
import moment from "moment";
import { DATE_TIME_FORMATE } from "../../../constant";
// import { capitalizeFirstLetter } from "../../common/capitalizeFirstLetter";
import AttachmentIcon from "../../../assets/Attachment";
import DetailMail from "../../Tmail/DetailMail";
import { getUserThreadOfMessage, sendMail } from "../../../lib/api";
import { Dispatch } from "redux";
import {
  addMailInCurrentThreadOfCurrentcard,
  initCardMailThread,
} from "../../../redux/actions/opening/action";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { toast } from "react-toastify";
import { currentMailThreads } from "../../../redux/reducers/initialState";
import { AxiosError } from "axios";
import throwError from "../../../service/throwError";
type CardMailPreviewType = {
  loginUser: loginUser;
  mail: MailPreview | MailPreviewWithTag;
  initCardMailThread: (mails: RecordThreads, mailId: string) => void;
  addMailInCurrentThreadOfCurrentcard: (mails: Mail, mailId: string) => void;
  currentApplicantMailThread: currentMailThreads;
};
const CardMailPreview = (props: CardMailPreviewType) => {
  const [showMessages, setShowMessages] = useState(false);

  const handleShowMessages = () => {
    setShowMessages(!showMessages);
  };
  const [mailId, setMailId] = useState<{ id: string; key: string }>({
    id: "",
    key: "",
  });
  // let thread: { [key: string]: RecordThreads } = mailThreas.record_threads;

  const getThreadOfMailId = (id: string, key: string) => {
    // if (mailId.id.trim().length > 0) {
    // props.initCardMailThread(thread[id], id);
    setMailId({ id: id, key: key });

    getUserThreadOfMessage(id)
      .then((res) => {
        props.initCardMailThread(res, id);
      })
     
    // }
  };
  const closeThread = () => {
    setMailId({ id: "", key: "" });
  };
  const sendMailFromcard = (e: any) => {
    sendMail(e)
      .then((res) => {
        if (res) {
          props.addMailInCurrentThreadOfCurrentcard(res.mails.success[0], mailId.id);
          toast.update("customId", {
            render: () => <div className="text-sm">Mail sent</div>,
            type: toast.TYPE.INFO,
            autoClose: 1000,
          });
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 500) {
          toast.update("customId", {
            render: () => <div className="text-sm">Mail sent</div>,
            type: toast.TYPE.INFO,
            autoClose: 1000,
          });
        } else throwError(reason);
      });
  };

  // if (!props.currentApplicantMailThread[mailId.id]) return null;

  return (
    <>
      <>
        <div className="mb-2 flex space-x-2 ml-12 ">
          <span className=" mt-1 -ml-9" aria-hidden="true">
            <div className="h-7 w-7 rounded">
              <Email size={"28"} />{" "}
            </div>
          </span>
          <div className="flex flex-col w-full">
            <div className="flex justify-between pr-2">
              {" "}
              <div className="inline text-sm break-words ">
                <span className="font-semibold cursor-default tracking-wide mr-1 ">
                  {props.mail.message.to[0]}{" "}
                </span>
                <span className="text-xs font-medium mr-1  text-gray-500">
                  {moment(props.mail.message.created_at).format(
                    DATE_TIME_FORMATE
                  )}{" "}
                </span>{" "}
              </div>
              <i className="fa fa-envelope text-xs"></i>
            </div>
            <div
              id={`tacitmail-${props.mail.message.id}}`}
              className={`rounded border-tacitbase-tertiary1 bg-white border-opacity-20 border justify-start items-start`}
            >
              <div className="flex space-x-2 justify-start items-center py-1 px-2 ">
                <div className="flex space-x-1 w-full  justify-between flex-shrink-0">
                  <div className="flex w-full truncate">
                    <div
                      className={`${
                        props.mail.message.read
                          ? "font-light text-gray-600"
                          : "font-medium "
                      } ${
                        props.mail.message.subject.length > 50 ? "w-full" : ""
                      } text-xs leading-5 flex-shrink-0   truncate`}
                    >
                      {props.mail.message.subject}
                    </div>
                    {props.mail.message.subject.length < 50 &&
                      props.mail.message.body && (
                        <>
                          <div
                            className={`${
                              props.mail.message.read
                                ? "font-normal text-gray-600"
                                : "font-light "
                            } text-xs leading-5`}
                          >
                            -
                          </div>

                          <div
                            className={`${
                              props.mail.message.read
                                ? "font-normal text-gray-600"
                                : "font-light "
                            } text-xs truncate leading-5`}
                          >
                            {props.mail.message.body}
                          </div>
                        </>
                      )}
                  </div>
                  <div
                    className={`font-light flex  text-xs justify-center items-center  leading-none`}
                  >
                    {props.mail.msg_count}
                  </div>
                </div>
              </div>

              <div className="flex space-x-2 text-xs justify-between items-center px-2 mb-1">
                <div className="flex space-x-1 justify-center items-center">
                  <AttachmentIcon />
                  <div className="text-xs">
                    {props.mail.message.attachments.length}
                  </div>
                </div>
                {mailId.id == props.mail.message.id ? null : (
                  <button
                    onClick={() =>
                      getThreadOfMailId(
                        props.mail.message.id,
                        props.mail.message.key
                      )
                    }
                    className="text-tacitbase-secondary3"
                  >
                    {capitalizeFirstLetter("view thread")}
                  </button>
                )}
              </div>

              {props.currentApplicantMailThread[mailId.id]
                ? mailId.id == props.mail.message.id
                  ? props.currentApplicantMailThread[mailId.id].messages
                      .length > 0 && (
                      <DetailMail
                        isCardThread={true}
                        mail={
                          props.currentApplicantMailThread[mailId.id]
                            .messages[0]
                        }
                        source={
                          props.currentApplicantMailThread[mailId.id].source
                        }
                        entity={
                          props.currentApplicantMailThread[mailId.id].entity
                        }
                        record_id={
                          props.currentApplicantMailThread[mailId.id].source.id
                        }
                        selectMailId={mailId.id}
                        service={
                          props.currentApplicantMailThread[mailId.id].service
                        }
                        callApi={(e: any) => sendMailFromcard(e)}
                      />
                    )
                  : null
                : null}
              {props.currentApplicantMailThread[mailId.id] ? (
                mailId.id == props.mail.message.id ? (
                  showMessages ||
                  (props.currentApplicantMailThread &&
                    props.currentApplicantMailThread[mailId.id].messages
                      .length == 2) ? (
                    <div className="flex-col w-full">
                      <hr className="w-full mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
                    </div>
                  ) : (
                    <></>
                    // <button className="ml-5 px-2 py-1 text-sm rounded border border-tacitbase-tertiary1 border-opacity-30 hover:bg-gray-100">
                    //   <i
                    //     className="fa fa-reply px-1 "
                    //     aria-hidden="true"
                    //   ></i>
                    //   Reply
                    // </button>
                  )
                ) : null
              ) : null}
              {props.currentApplicantMailThread[mailId.id] &&
                showMessages &&
                mailId.id == props.mail.message.id &&
                props.currentApplicantMailThread[mailId.id].messages
                  .splice(
                    1,
                    props.currentApplicantMailThread[mailId.id].messages
                      .length - 2
                  )
                  .map(
                    (mail: Mail) =>
                      mail && (
                        <>
                          <DetailMail
                            isCardThread={true}
                            mail={mail}
                            source={
                              props.currentApplicantMailThread[mailId.id].source
                            }
                            entity={
                              props.currentApplicantMailThread[mailId.id].entity
                            }
                            record_id={
                              props.currentApplicantMailThread[mailId.id].source
                                .id
                            }
                            selectMailId={mailId.id}
                            service={
                              props.currentApplicantMailThread[mailId.id]
                                .service
                            }
                            callApi={(e: any) => sendMailFromcard(e)}
                          />
                          <div className="flex-col w-full">
                            <hr className="w-full border-tacitbase-tertiary1 border-opacity-10"></hr>
                          </div>
                        </>
                      )
                  )}
              {props.currentApplicantMailThread[mailId.id]
                ? mailId.id == props.mail.message.id &&
                  props.currentApplicantMailThread[mailId.id].messages.length >
                    2 && (
                    <div className="flex items-center justify-center my-2">
                      <div className="flex-col">
                        <hr className="w-3 mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
                        <hr className="w-3 border-tacitbase-tertiary1 border-opacity-10 "></hr>
                      </div>
                      <button
                        onClick={handleShowMessages}
                        className="bg-color-LightGray rounded flex  justify-center items-center w-7 h-7"
                        style={{ fontSize: "12px" }}
                      >
                        <span>
                          {props.currentApplicantMailThread[mailId.id].messages
                            .length - 2}
                        </span>
                      </button>
                      <div className="flex-col w-full">
                        <hr className="w-full mb-0.5 border-tacitbase-tertiary1 border-opacity-10"></hr>
                        <hr className="w-full border-tacitbase-tertiary1 border-opacity-10"></hr>
                      </div>
                    </div>
                  )
                : null}
              {props.currentApplicantMailThread[mailId.id]
                ? mailId.id == props.mail.message.id &&
                  props.currentApplicantMailThread[mailId.id].messages.length >
                    1 && (
                    <>
                      <DetailMail
                        isCardThread={true}
                        source={
                          props.currentApplicantMailThread[mailId.id].source
                        }
                        record_id={
                          props.currentApplicantMailThread[mailId.id].source.id
                        }
                        selectMailId={mailId.id}
                        entity={
                          props.currentApplicantMailThread[mailId.id].entity
                        }
                        mail={
                          props.currentApplicantMailThread[mailId.id].messages[
                            props.currentApplicantMailThread[mailId.id].messages
                              .length - 1
                          ]
                        }
                        service={
                          props.currentApplicantMailThread[mailId.id].service
                        }
                        callApi={(e: any) => sendMailFromcard(e)}
                      />
                    </>
                  )
                : null}
              {mailId.id == props.mail.message.id && (
                <button
                  onClick={closeThread}
                  className="text-tacitbase-secondary3 px-2 mb-2 float-right text-xs"
                >
                  {capitalizeFirstLetter("close thread")}
                </button>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  currentApplicantMailThread: state.opening.currentApplicantMailThread,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initCardMailThread: (mails: RecordThreads, mailId: string) =>
    dispatch(initCardMailThread(mails, mailId)),
  addMailInCurrentThreadOfCurrentcard: (mail: Mail, mailId: string) =>
    dispatch(addMailInCurrentThreadOfCurrentcard(mail, mailId)),
});
export default connect(mapStateToProps, mapPropsToState)(CardMailPreview);
