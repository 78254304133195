import React, { useState } from "react";
import { Breadcrumb, DatePicker, Divider } from "antd";
import AnalyticsFilterPopup from "./AnalyticsFilterPopup";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import AnalyticsContent from "./AnalyticsContent";
import { colors, DateFormate, DIVIDER_HEIGHT } from "../../constant";
import { connect } from "react-redux";
import ContentHeader from "./ContentHeader";
import { ReloadOutlined } from "@ant-design/icons";
import Navbar from "../navbar/Navbar";
import { IconButton} from "../../ant/Button";
import NavSearch from "../navSearch/NavSearch";
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const startOfMonth = dayjs().startOf("month");

// Get the start of the previous month
const startOfPrevMonth = startOfMonth.subtract(1, "month");
const today = dayjs().format("YYYY-MM-DD");
const tomorrow = dayjs(today).add(1, "day").format("YYYY-MM-DD");

const disabledDate = (current: Dayjs): boolean => {
  // Disable dates after today
  return current.isAfter(dayjs(), "day");
};
export interface Filter {
  entity_type: string;
  entity_names: string[];
}
type analyticsMainPageProps = {
  applicationName: string;
  applicationId: string;
};

function AnalyticsMainPage(props: analyticsMainPageProps) {
  const [selectedDates, setSelectedDates] = useState<string>();
  const [selectedEndDates, setSelectedEndDates] = useState<string>(tomorrow);
  const [selectedFilter, setSelectedFilter] = useState<Filter>({
    entity_type: "organization_id",
    entity_names: [props.applicationId],
  });
  const [resetFilter, setResetFilter] = useState<boolean>(false);


  const handleDateChange = (dates: any[] | null) => {
    if (dates && dates.length > 0 && dates[0] && dates[1]) {
      const startDate = dates[0].format("YYYY-MM-DD");
      const endDate = dates[1].format("YYYY-MM-DD");
      setSelectedDates(startDate);
      setSelectedEndDates(endDate);
    } else {
      setSelectedDates("");
      setSelectedEndDates(tomorrow);
    }
  };
  const handleFilterChange = (value: Filter) => {
    setSelectedFilter(value);
  };

  function handleRefreshTab(){
    setSelectedFilter({
      entity_type:'',
      entity_names:[]
    });
    setSelectedDates("");
    setSelectedEndDates(tomorrow);
    setResetFilter(true);
    setTimeout(() => setResetFilter(false), 0);  }

  return (
    <>
    <ContentHeader>
        <div
          className="flex h-12 px-4 py-3 justify-between items-center" >
          <div
            className="flex items-center"          >
            <Breadcrumb
    items={[
      {
        title: 'Analytics',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <NavSearch/>
          <Divider type="vertical" style={{height:DIVIDER_HEIGHT,borderColor:`${colors.divider}`}} />

            <Navbar/>
            </div>
        </div>
      </ContentHeader>
      {/* <div className=" flex px-2 py-3 text-base font-semibold bg-white mb-1 items-center " >Analytics</div> */}
        <div className="flex flex-col">
          <div className="flex space-x-2 pb-2 pt-2 pr-4 bg-white items-center justify-end h-12" >
            
            <RangePicker
            value={[
              selectedDates ? dayjs(selectedDates) : null,
              selectedEndDates && selectedEndDates!=tomorrow? dayjs(selectedEndDates) : null,
            ]}
              onChange={handleDateChange}
              defaultPickerValue={[startOfPrevMonth, startOfPrevMonth]}
              format={DateFormate}
              disabledDate={disabledDate}
              className="w-60 h-7"
              // size="small"
            />
            <div className="flex">
              <AnalyticsFilterPopup onFilterChange={handleFilterChange} resetFilter={resetFilter} />
            </div>
            <Divider type="vertical" style={{height:DIVIDER_HEIGHT,borderColor:`${colors.divider}`}} />


            <IconButton
                          tooltipTitle="Refresh tab"
                            icon={
                              <ReloadOutlined  />
                            }
                            onClick={handleRefreshTab}
                            className="h-7 w-7"
                          />
          </div>
        </div>
      <AnalyticsContent
        entity_type={selectedFilter.entity_type}
        entity_names={selectedFilter.entity_names}
        start_time={selectedDates ? selectedDates : ""}
        end_time={selectedEndDates ? selectedEndDates : ""}
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

export default connect(mapStateToProps)(AnalyticsMainPage);
