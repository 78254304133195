import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
// import SortIcon from "../../../assets/Sort";
import { Scope } from "../../../type/type.d";
import { Overlay } from "../../hook/Overlay";
// import Button1 from "../button/Button1";
import SortingPopup from "./SortingPopup";
import { IconButton} from "../../../ant/Button";
import { FilterOutlined } from "@ant-design/icons";
type SortingProps = {
  c: (value: any) => void;
};
const Sorting = (props: SortingProps) => {
  const [open, setOpen] = useState(false);
  const refer=useRef(null);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  return (
    <div className="flex items-center  " ref={setReferenceElement}>
      <IconButton
        icon={<FilterOutlined />}
        onClick={() => setOpen(!open)}
        tooltipTitle="Apply sort filter"
      />
      {open ? (
        <Overlay scope={Scope.Popover} onClose={() => setOpen(!open)} reference={refer}>
          <div
            ref={setPoperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <SortingPopup  refer={refer} 
              click={(e: boolean) => setOpen(e)}
              getValue={props.c}
            />
          </div>
        </Overlay>
      ) : null}
    </div>
  );
};

export default Sorting;
