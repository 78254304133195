import React from 'react';

const AttachmentIcon = (props: {
    size?: string
}) => {
    return (
        <svg width={props.size ? props.size : "18"} height={props.size ? props.size : "18"}
            viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3695 2.66723C12.4771 0.774822 9.39543 0.774822 7.50503 2.66723L2.26172 7.90652C2.22757 7.94067 2.20949 7.98688 2.20949 8.03509C2.20949 8.0833 2.22757 8.12951 2.26172 8.16366L3.00302 8.90496C3.0369 8.93869 3.08277 8.95763 3.13059 8.95763C3.1784 8.95763 3.22427 8.93869 3.25815 8.90496L8.50146 3.66567C9.15235 3.01478 10.0182 2.65719 10.9383 2.65719C11.8584 2.65719 12.7242 3.01478 13.3731 3.66567C14.024 4.31656 14.3816 5.18241 14.3816 6.10049C14.3816 7.02058 14.024 7.88442 13.3731 8.53531L8.02936 13.8771L7.16351 14.7429C6.35391 15.5525 5.03806 15.5525 4.22846 14.7429C3.83672 14.3512 3.62177 13.8308 3.62177 13.2764C3.62177 12.7219 3.83672 12.2016 4.22846 11.8099L9.53003 6.51031C9.66463 6.37772 9.84141 6.30339 10.0303 6.30339H10.0323C10.2211 6.30339 10.3959 6.37772 10.5285 6.51031C10.6631 6.64491 10.7354 6.8217 10.7354 7.01054C10.7354 7.19737 10.6611 7.37415 10.5285 7.50674L6.19521 11.836C6.16105 11.8701 6.14297 11.9163 6.14297 11.9646C6.14297 12.0128 6.16105 12.059 6.19521 12.0931L6.9365 12.8344C6.97038 12.8682 7.01625 12.8871 7.06407 12.8871C7.11188 12.8871 7.15775 12.8682 7.19163 12.8344L11.5229 8.50317C11.9227 8.10339 12.1416 7.57304 12.1416 7.00853C12.1416 6.44402 11.9207 5.91165 11.5229 5.51388C10.6972 4.68821 9.35525 4.69022 8.52958 5.51388L8.0153 6.03018L3.23003 10.8134C2.90525 11.1363 2.6478 11.5205 2.47261 11.9436C2.29743 12.3667 2.20799 12.8204 2.20949 13.2784C2.20949 14.2085 2.57311 15.0824 3.23003 15.7393C3.91105 16.4183 4.80302 16.7579 5.69498 16.7579C6.58695 16.7579 7.47891 16.4183 8.15793 15.7393L14.3695 9.53174C15.2836 8.61567 15.7898 7.39625 15.7898 6.10049C15.7919 4.80272 15.2856 3.5833 14.3695 2.66723Z" fill="black" fillOpacity="0.85"/>
        </svg>
    );
};

export default AttachmentIcon;




