import React from "react";
import DomainSetting from "./domainSetting/DomainSetting";
import OrgSetting from "./OrgSetting";
import SlackCommunicationIntegration from "./domainSetting/communicationIntegration/SlackIntegration";
import { Divider, Typography } from "antd";
const { Text } = Typography;

const OrgSettingIndex = () => {
  return (
    <>
      <div className="p-4">
        {" "}
        <Text strong>Organization settings</Text>{" "}
      </div>
      <Divider type="horizontal" className="w-full m-0 " />
      <div
        className="w-full  overflow-y-scroll"
        style={{ height: "calc(-140px + 100vh)" }}
      >
        <div
          className="2xl:w-1/2 xl:3/4 lg:w-3/4 md:w-full sm:w-full h-full flex"
          id="link_1"
        >
          <div className="flex flex-col  w-full">
            <OrgSetting />
            <SlackCommunicationIntegration />
            <DomainSetting />
          </div>
        </div>
      </div>
    </>
  );
};

export default OrgSettingIndex;
