import React, { Component } from "react";
import {
  getApplicant, readNotification,

} from "../../lib/api";
import { connect } from "react-redux";
import {
  changeServiceNotificationsCountValue,
  clearCardData,
  getCardReward,
  initCurrentApplicant,
  initCurrentCardWatcher,
  initEntityUnreadNotifications,
  unread_dot_remover,
} from "../../redux/actions/opening/action";

import { Dispatch } from "redux";
import { currentApplicant, loginUser, notificationServiceEntityDotRemovePayloadType, Opening, Scope } from "../../type/type.d";
import { withRouter, RouteComponentProps } from "react-router";
import { NOTIFY_ARCHIVE, TACITBASE, TrackerRoute } from "../../constant";
import CardContent from "./cardContent/CardContent";
import CardInput from "./cardInput/CardInput";
import CardSideSection from "./cardSideSection/CardSideSection";
import {
  allNotifications,
  Transaction,
  WatcherSubscription,
} from "../../lib/apiRes";
import Read from "../../Read";
import { Overlay } from "../hook/Overlay"
type CardProps = {
  currentApplicant: currentApplicant;
  currentOpening: Opening;
  initCurrApplicant(applicant: currentApplicant): void;
  initEntityUnreadNotifications(notification: allNotifications[]): void;
  initCurrentCardWatcher(data: WatcherSubscription): void;
  removeCardNotificationDot(payload:notificationServiceEntityDotRemovePayloadType): void;
  changeServiceNotificationsCount(payload:{}):void;
  removeCardNotificationDot(payload:notificationServiceEntityDotRemovePayloadType): void;
  clearData: () => void;
  loginUser: loginUser;
  roles: string[];
  applicationId: string;  notificationCenter: any,

};
class Card extends Component<CardProps & RouteComponentProps> {
  cardRef: React.RefObject<HTMLInputElement>;

  state = {
    flag: false,
    // talent_partner: false,
    button: { ACCEPTED: false, SHARED: false, JOINED: false, OFFERED: false },
  };
  constructor(props: any) {
    super(props);
    this.cardRef = React.createRef();
  }
  componentDidMount() {
    let cardUrlParams: any = {};
    cardUrlParams = this.props.match.params;
    if (cardUrlParams.card_id != this.props.currentApplicant.id) {
      getApplicant(cardUrlParams.card_id)
        .then((res: any) => {
          const applicant: currentApplicant = res;
          document.title = `${applicant.name
            .charAt(0)
            .toUpperCase()}${applicant.name.slice(
            1
          )} on ${this.props.currentOpening.name
            .charAt(0)
            .toUpperCase()}${this.props.currentOpening.name.slice(1)}
        | ${TACITBASE} `;

          this.props.initCurrApplicant(applicant);
          // this.props.removeCardNotificationDot({service:"ats",model_name:"card",remove_id: cardUrlParams.card_id})
          this.props.notificationCenter.unreadDotsofServices?.ats?.cards[cardUrlParams.card_id]&&

          readNotification( cardUrlParams.card_id,"card").then(()=>{
            this.props.removeCardNotificationDot({service:"ats",model_name:"card",remove_id: cardUrlParams.card_id})
                    })
         
          
        })
        .catch((err: Error) => {
          console.log(err)
        });
    }
  }

  componentWillUnmount() {
    document.title = `${this.props.currentOpening.name} | ${TACITBASE} `;
    this.props.clearData();
  }

  cardRemove = () => {
    this.props.history.push({
      pathname: `/o/${this.props.applicationId}/b/${this.props.currentOpening.id}/${TrackerRoute}`,
    });
  };
  render() {
    return (
      <React.Fragment>
        <Overlay
          onClose={() => this.cardRemove()}
          scope={Scope.Dialog}
          reference={this.cardRef}
        >
          <div
            data-testid="cardRender"
            className=" animate-popover_fadeIn z-10 justify-center bg-black bg-opacity-50 items-center flex fixed inset-0  outline-none focus:outline-none text-black"
          >
            <button tabIndex={0} id="card-close"></button>
            <div className="flex items-start rounded  w-full h-full justify-center pt-12 overflow-y-scroll ">
              <div
                className=" w-192 bg-gray-100 rounded  "
               
              >
                <div className=" bg-yellow-50 ">
                  {this.props.currentApplicant ? (
                    this.props.currentApplicant.archive ? (
                      <div className="text-base w-full p-4 ml-12 text-black ">
                        {NOTIFY_ARCHIVE}
                      </div>
                    ) : null
                  ) : null}
                </div>
                <CardInput />
                <div className="flex flex-row ">
                  <CardContent />
                  <Read access={this.props.currentOpening.access}>
                    <CardSideSection />
                  </Read>
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  roles: state.opening.roles,
  applicationId: state.opening.applicationId,
  notificationCenter: state.opening.notificationCenter,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initCurrApplicant: (applicant: currentApplicant) =>
    dispatch(initCurrentApplicant(applicant)),
    changeServiceNotificationsCount: (payload: {}) =>
    dispatch(changeServiceNotificationsCountValue(payload)),
  initEntityUnreadNotifications: (notification: allNotifications[]) =>
    dispatch(initEntityUnreadNotifications(notification)),
  clearData: () => dispatch(clearCardData()),
  initCurrentCardReward: (Rewards: Transaction[], cardID: string) =>
    dispatch(getCardReward(Rewards, cardID)),
  initCurrentCardWatcher: (data: WatcherSubscription) =>
    dispatch(initCurrentCardWatcher(data)),
  removeCardNotificationDot: (payload:notificationServiceEntityDotRemovePayloadType) =>
    dispatch(unread_dot_remover(payload)),
   
});
export default connect(mapStateToProps, mapPropsToState)(withRouter(Card));
