import React from "react"
import EditListInput from "../editListInput/EditListInput";
//Api's
// Redux stuff
import { connect } from "react-redux";
import { useRef } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import ListActionButton from "../listActionButton/ListActionButton";
import ShowCardPreview from "../showCardPreview/ShowCardPreview";
import { ShowListsProps } from "../../../type/boardPanal";
import CardCount from "../list/CardCount";

const ShowLists = (props: ShowListsProps) => {
  let cardreferenceElement = useRef();
  return (
    <div className="flex h-auto max-h-full" key={props.key}>
      <Draggable draggableId={props.listID} index={props.index}>
        {(provided) => (
          <div
            className="list-content"
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div
              className="list-header my-1 ml-2  flex flex-col  "
              {...provided.dragHandleProps}
            >
              <div className="flex justify-between w-full  items-start" >
                <EditListInput
                  listName={props.listName}
                  listID={props.listID}
                  isLock={props.isListLock}
                />
                              <ListActionButton listID={props.listID} />

              </div>
              {props.applicants ? (
              <CardCount applicantLength={props.applicants.length} />
            ) : null}
            </div>           
            <Droppable droppableId={props.listID}>
              {(provided) => (
                <div
                  className="list-card mb-1"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="ml-2.5 mr-1 ">
                    <ShowCardPreview
                      applicants={props.applicants}
                      listID={props.listID}
                      cardreferenceElement={cardreferenceElement}
                    />
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>

          </div>
        )}
      </Draggable>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  lists: state.opening.lists,
});

export default connect(mapStateToProps)(ShowLists);
