import React, { useRef, useState } from "react";
import CloseIcon from "../../../assets/Close";
import TickIcon from "../../../assets/Tick";
import ProgressBar from "./ProgressRoundBar";

// Define the props expected by the FileSelection component
interface FileSelectionProps {
  onFileSelect: (file: File) => void; // Callback for handling file selection
  apiCalled: boolean; // Flag to indicate if API is called for file upload
  apiSuccess: boolean; // Flag to indicate if API upload was successful
  uploadProgress: number; // Progress percentage for file upload
  selectedFileData: any; // Data for the selected file
  setSelectedFileData: (data: any) => void; // Function to set selected file data
  handleRemove: () => void; // Callback to handle file removal
  setFileFormatError:(value:string)=>void
}

// FileSelection component definition
const FileSelection: React.FC<FileSelectionProps> = ({
  onFileSelect,
  apiCalled,
  apiSuccess,
  uploadProgress,
  selectedFileData,
  handleRemove,setFileFormatError
}) => {
  // State variables
  const [highlight, setHighlight] = useState(false);
  const [dragging, setDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Function to handle drag over event
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  // Function to handle drag leave event
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  // Function to handle drop event
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    // Check if there is more than one file
    if (files.length > 1) {
      setFileFormatError("Only one file can be selected at a time.");
      return;
    } 
    setHighlight(false);
    const file = e.dataTransfer.files[0];
    onFileSelect(file);
  };

  // Function to handle file change event
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    file && onFileSelect(file);
  };

  return (
    <div
      className={`file-selection ${highlight ? "highlight" : ""} flex w-full bg-white `}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      style={{ height: "80%" }}
    >
      <div className="w-full h-full">
        <div
          className={`${
            selectedFileData.file ? "" : "bg-gray-100 bg-opacity-20"
          } border border-dashed border-tacitbase-tertiary1 border-opacity-20 bg-gray-100 bg-opacity-20  rounded h-full flex items-center justify-center `}
        >
          {/* Display progress bar and "In progress" message if API is called */}
          {apiCalled && !apiSuccess ? (
            <div className="flex flex-col justify-center items-center relative w-full">
              <ProgressBar percentage={uploadProgress} />
              <div className="text-tacitbase-tertiary1 text-opacity-50 ">
                In progress
              </div>
              <div className="text-tacitbase-tertiary1 text-opacity-50 bg-gray-200 text-md  p-1 rounded absolute -bottom-8  flex items-center justify-center space-x-2">
                <div> {selectedFileData.name}</div>
                <button onClick={() => handleRemove()}>
                  <CloseIcon size="10" />
                </button>
              </div>
            </div>
          ) : null}

          {/* Display success icon and "Upload completed" message if API upload was successful */}
          {apiSuccess && (
            <div className="flex flex-col justify-center items-center  relative w-full">
              <div className="h-14 w-14 bg-tacitbase-secondary5 rounded-full flex items-center justify-center">
                <TickIcon fill="white" />
              </div>
              <div className="text-tacitbase-tertiary1 text-opacity-50">
                Upload completed
              </div>
              <div className="text-tacitbase-tertiary1 text-opacity-50 bg-gray-200 text-md  p-1 rounded absolute -bottom-8  flex items-center justify-center space-x-2">
                <div> {selectedFileData.name}</div>
                <button onClick={() => handleRemove()}>
                  <CloseIcon size="10" />
                </button>
              </div>
            </div>
          )}

          {/* Display file upload area if API is not called */}
          {!apiCalled && !apiSuccess && (
            <div
              className="w-full h-full flex items-center justify-center"
              tabIndex={0}
              role="button"
              onClick={() => fileInputRef.current?.click()}
              onKeyDown={() => {}}
            >
              <div className="flex flex-col justify-center items-center space-y-2">
                <div>
                  <i
                    className="fa fa-cloud-upload text-5xl text-tacitbase-tertiary1 text-opacity-30"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="text-tacitbase-tertiary1 text-opacity-50">
                  {dragging
                    ? "Drop that file down low"
                    : "Drag and drop files here "}
                </div>
                <div className="flex items-center justify-center text-tacitbase-tertiary1 text-opacity-25 space-x-2">
                  <div>
                    <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                  </div>
                  <div className="text-tacitbase-tertiary1 text-opacity-40">
                    or
                  </div>
                  <div>
                    <hr className="w-16 border border-tacitbase-tertiary1 border-opacity-30" />
                  </div>
                </div>
                <div className="">
                  <input
                    type="file"
                    ref={fileInputRef}
                    id="CSVFileInput"
                    data-testid="input-attachment"
                    style={{ display: "none" }}
                    onChange={(event) => handleFileChange(event)}
                    accept=".csv"
                  />
                  <div className="text-tacitbase-tertiary1 text-opacity-50">
                    Click to browse files
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileSelection;
