import React from "react";
import { connect } from "react-redux";
import { TALENT_PARTNER } from "../../../../constant";
import { CardPreviewSideSectionMenuProps } from "../../../../type/card";
import CardPreviewSideSectionMenuItem from "../sideMenuItem/CardSideSectionMenuItem";

const CardSideSectionMenu = (props: CardPreviewSideSectionMenuProps) => {
  let listMenu;

  listMenu = props.list.map((list, i: number) => {
    if (list.name == "Members") {
      if (props.roles[0] !== TALENT_PARTNER) {
        return (
          <CardPreviewSideSectionMenuItem
            key={i}
            menuName={list.name}
            icon={list.icon}
            isActive={list.active}
            popup={list.popup}
            click={() => props.click(list.name)}
            points={list.points}
            RewardBtn={props.RewardBtn}
          />
        );
      } else {
        return <></>;
      }
    } else {
      return (
        <CardPreviewSideSectionMenuItem
          key={i}
          menuName={list.name}
          icon={list.icon}
          isActive={list.active}
          popup={list.popup}
          click={() => props.click(list.name)}
          points={list.points}
          RewardBtn={props.RewardBtn}
        />
      );
    }
  });
  return (
    <React.Fragment>
      <div className="uppercase text-xs font-medium ">
        {props.listName}
        <div
          className="items-center  mt-2 pr-2 cursor-pointer text-sm font-normal"
          aria-disabled
        >
          {listMenu}
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  roles: state.opening.roles,
});
export default connect(mapStateToProps)(CardSideSectionMenu);
