import React from "react";

import emailVerificationImg from "../../images/EmailVerification.png";
import Image from "../../components/common/image/Image";
import EmailVerified from "../../components/signUp/verification/EmailVerified";
import { Grid } from "antd";
import { connect } from "react-redux";

const { useBreakpoint } = Grid;
const VerifyPhone = () => {
  const screens = useBreakpoint();

  return (
    <>

        <div className="flex  pt-6    w-full h-full xl:overflow-y-scroll space-x-4 2xl:space-x-0 ">
          <div className="w-1/12"></div>
          {screens.xl && (
            <div className="w-8/12  2xl:w-7/12  flex  justify-start items-center">
              <Image src={emailVerificationImg} size="full" />
            </div>
          )}
          <div className="xl:w-5/12 2xl:3/12 flex xl:justify-end justify-center h-full items-start w-full">
            <div
              className="bg-white     xl:p-8 p-4 rounded xl:mt-10 mt-4   "
              style={{
                width: 463,
                boxShadow: "2px 4px 10px 0px #00000040",
                border: "1px solid #D9D9D9",
              }}
            >
              <EmailVerified />
            </div>
          </div>
        <div className="w-1/12"></div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(VerifyPhone);
