import React from "react";

interface ProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ percentage }) => {
  const borderGradient = `conic-gradient( #7152F2 ${percentage}%,rgba(243, 244, 246, 1)   0)`;

  return (
    <div
    data-testid="progress-bar"
      className="relative h-14 w-14  rounded-full  overflow-hidden"
      style={{ backgroundImage: borderGradient }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "48px",
          height: "48px",
          background: "#ffff",
          borderRadius: "50%",
          fontSize: "1.85em",
          fontWeight: 300,
          color: "rgba(255, 255, 255, 0.75)",
        }}
      >
        <span className=" text-xs text-tacitbase-tertiary1 font-semibold ">
          {" "}
          {`${percentage}%`}
        </span>
      </div>
    </div>
  );
};

export default ProgressBar;
