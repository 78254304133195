import { AxiosError } from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { TrixEditor } from "react-trix";
import { addAttachmentToMail, sendMail } from "../../../lib/api";
import { sendMailReq } from "../../../lib/apiReq";
import throwError from "../../../service/throwError";
import {
  Attachment,
  Candidate,
  loginUser,
  Member,
  orgMember,
  Scope,
  SendMail,
} from "../../../type/type.d";
import SearchedResultPopup from "../../subNavbar/addBoardMember/SearchedResultPopup";
import ShowMeberInInput from "../../subNavbar/addBoardMember/ShowMeberInInput";
import MailNotification from "../../Tmail/MailNotification";
import { createFormData } from "../../../utils/createFormData";
import UploadedFile from "./ShowProgress"
import { Overlay } from "../../hook/Overlay";
import DynamicMailTag from "./DynamicMailTag"
import SetSubject from "../../setSubject/SetEmailsubject";
import { Button, Divider, Dropdown, Form, Menu, Tooltip } from "antd";
import { DeleteOutlined, FunctionOutlined, PaperClipOutlined, SendOutlined } from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import Chatbox from "./MailChatBox";
// import EditorSkeleton from "../../JdManagement/EditorSkeleton";
// import { Editor } from "draft-js";
// import { customFormats, customModules } from "../../../constant";

interface ComposeMailType{
  close(): void;
  service: string;
  members: orgMember;
  entity: string;
  record_id: Candidate[],
  toAddress: string[];
  loginUser: loginUser;
  clientId: string;
  refer:any,
  reduxActionCall?: (res: SendMail) => void;
  getDisableState?: (value: boolean) => void;
  getJobDescription?: (description: string) => void;
}

const ComposeMail = (props: ComposeMailType) => {
  const bccinputRef = useRef<HTMLInputElement>(null);
  const ccinputRef = useRef<HTMLInputElement>(null);
  const refer=useRef(null);

  const [ccMembers, setCcMember] = useState<string[]>([]);
  const [bccMembers, setBccMember] = useState<string[]>([]);
  const [searchedCcMember, getSearchedCcMember] = useState("");
  const [searchedBccMember, getSearchedBccMember] = useState("");
  const [addCC, setCC] = useState<boolean>(false);
  const [addBcc, setBcc] = useState<boolean>(false);
  const [mailBody, setMailBody] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [editable, setIsEditable] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showMoreToAddress, setShowMoreToAddress] = useState<boolean>(false);
  // const [progress, setProgress] = useState(0);
  const [dynamicKeys] = useState(["first_name", "last_name"]);
  const [trixInstance, setTrixInstance] = useState<any>();
  const [selectedDynamicKeys, addDynamicKeys] = useState<string[]>([]);
  const [uploadedAttchments, setUploadedAttachments] = useState<Attachment[]>(
    []
  );
  let fileInput = useRef<HTMLInputElement>(null);


  const [chatBox, setShowChatBox] = useState(false);


  const handleChatBoxFromParent = (value: boolean) => {
    setShowChatBox(value);
  };

  // const handleLoading = (value: boolean) => {
  //   setLoading(value);
  // };
  // const [loading, setLoading] = useState(false);


  let orgMembers = Object.values(props.members)
    .filter((m: Member) => m.email != props.loginUser.user.email)
    .filter(
      (member: Member) =>
        member.registrations[0].data.activated &&
        (searchedCcMember.trim().length > 0 ||
          searchedBccMember.trim().length > 0) &&
        (member.first_name?.toUpperCase()
          .includes(
            searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
          ) ||
          member.last_name?.toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ) ||
          member.email
            .toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ) ||
          member.username?.toUpperCase()
            .includes(
              searchedCcMember.toUpperCase() || searchedBccMember.toUpperCase()
            ))
  )
  const removeMemberFromSelectedCc = (index: number) => {
    setCcMember((a: string[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };
  const discardMail = () => {
    setCC(false);
    setBcc(false);
    props.close();
  };
  const removeMemberFromSelectedBcc = (index: number) => {
    setBccMember((a: string[]) => {
      a.splice(index, 1);
      return [...a];
    });
  };
  const searchKeywordInCc = (e: any) => {
    setShowMoreToAddress(false);
    getSearchedCcMember(e.target.value.trim());

  };
  const searchKeywordInBcc = (e: any) => {
    setShowMoreToAddress(false);
    getSearchedBccMember(e.target.value.trim());
  };
  const handleInputChangeOfCc = (member: Member) => {
    setShowMoreToAddress(false);
    var updatedList = [...ccMembers];
    if (!ccMembers.includes(member.id)) {
      updatedList = [...ccMembers, member.id];
    }
    setCcMember(updatedList);
  };
  const handleInputChangeOfBcc = (member: Member) => {
    setShowMoreToAddress(false);

    var updatedList = [...bccMembers];
    if (!bccMembers.includes(member.id)) {
      updatedList = [...bccMembers, member.id];
    }
    setBccMember(updatedList);
  };
  useEffect(() => {
    if (bccinputRef.current) {
      bccinputRef.current.value = "";
      bccinputRef.current.focus();
    }
    getSearchedBccMember("");
  }, [bccMembers]);
  useEffect(() => {
    if (ccinputRef.current) {
      ccinputRef.current.value = "";
      ccinputRef.current.focus();
    }
    getSearchedCcMember("");
  }, [ccMembers]);
  const send = () => {
    return new Promise<boolean>((resolve) => {
      toast.warn(
        <MailNotification
          undo={() => {
            toast.dismiss(), resolve(false);
          }}
        />,
        {
          position: toast.POSITION.TOP_RIGHT,
          toastId: "customId",
          autoClose: false,
        }
      );
      setTimeout(() => {
        resolve(true);
      }, 3000);
    });
  };
  const sentMailAnyway = async () => {
    if (subject.trim().length > 0) {
      setIsEditable(true);
      let callApi = await send();
      if (callApi) {
        var editorElement: any = document.querySelector("trix-editor");
        if (editorElement) editorElement.setAttribute("disabled", true);
        toast.update("customId", {
          render: () => <div className="text-sm"> Sending mail</div>,
          type: toast.TYPE.INFO,
        });
        let ccMembersMail = ccMembers.map(
          (id: string) => props.members[id].email
        );
        let bccMembersMail = bccMembers.map(
          (id: string) => props.members[id].email
        );

        let attachments: { objectKey: string; name: string }[] =
          uploadedAttchments.map((attachment: Attachment) => {
            return { objectKey: attachment.objectKey, name: attachment.name };
          });
        const records = props.record_id.map(candidate => {
          const values = selectedDynamicKeys.reduce((acc: any, key) => {
            if (Object.prototype.hasOwnProperty.call(candidate, key)) {
              acc[key] = candidate[key]
            }
            return acc
          }, {})

          return {
            id: candidate.id,
            address: [candidate.email],
            values: values
          }
        })
        let reqObb: sendMailReq = {
          service: props.service,
          entity: props.entity,
          input: {
            keys: selectedDynamicKeys,
            records: records
          },
          message: {
            cc: [...ccMembersMail],
            bcc: [...bccMembersMail],
            to: props.toAddress,
            subject: subject,
            body: mailBody,
            attachments: attachments,
          },
        };

        sendMail(reqObb)
          .then((res) => {
            if (res) {
              props.close();
              setIsEditable(false);
              props.reduxActionCall && props.reduxActionCall(res);
              toast.update("customId", {
                render: () => <div className="text-sm">Mail sent</div>,
                type: toast.TYPE.INFO,
                autoClose: 1000,
              });
            }
          })
          .catch((reason: AxiosError) => {
            setIsEditable(false);
            if (editorElement) editorElement.removeAttribute("disabled");
            if (
              reason.response?.status == 500 ||
              reason.response?.status == 400
            ) {
              toast.update("customId", {
                render: () => (
                  <div className="text-sm">
                    {reason.response?.data.error.message}
                  </div>
                ),
                type: toast.TYPE.ERROR,
                autoClose: 1000,
              });
            } else throwError(reason);
          });
      } else {
        setIsEditable(false);
        if (editorElement) editorElement.removeAttribute("disabled");
      }
    } 
  };

  function uploadFile(file: any) {
    const files: any = [];
    const formData = createFormData(file, props.entity)
    formData.append("socket_client_id", props.clientId);
    for (let i = 0; i < file.target.files.length; i++) {
      formData.append(`file`, file.target.files[i]);
      const fileData = {
        name: file.target.files[i].name,
        progress: 0,
      };
      files.push(fileData);
    }

    setUploadedFiles(uploadedFiles.concat(files));
    const onUploadProgress = (progressEvent: any) => {
      const progress = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // setProgress(progress);
      const updatedFiles = [...files];
      updatedFiles.forEach((fileData) => {
        fileData.progress = progress;
      });
      setUploadedFiles(uploadedFiles.concat(files));
    };

    addAttachmentToMail(formData, onUploadProgress)
      .then((res: any) => {
        setUploadedAttachments([...uploadedAttchments, ...res]);
      })
  }

  const setEditorReady = (editor: any) => {
    setTrixInstance(editor)
    editor.insertString("");
  };


  const handleChange = ( text: string) => {
    setShowMoreToAddress(false);
    let updatedDynamicKeys: string[] = dynamicKeys.filter((key) => text.includes(key))
    addDynamicKeys([...updatedDynamicKeys])
    setMailBody(text);
  };

  useEffect(() => {
    const element: any = document.getElementsByClassName(
      "trix-button-group trix-button-group--file-tools"
    )
   if (element && element[0]) element[0].remove() 
  }, []);
  const handleInsertValue = (key: any) => {
    if (trixInstance) {
      trixInstance.insertString(`{{${key}}}`)
    }// insert the dynamic key at the current cursor position
    addDynamicKeys((prev) => [...prev, key])
  };

  const visibleAddresses = showMoreToAddress
    ? props.toAddress
    : [props.toAddress[0]];

  const setHeight = () => {
    const composeMailBox = document.getElementById("composeMailbox")
    const mailheader = document.getElementById("mailheader")
    const mailSendButton = document.getElementById("mailSendButton")
    const composeMailEditor = document.getElementById("composeMailEditor")
    const composeMailAddress = document.getElementById("composeMailAddress")
    const trix: any = document.getElementsByClassName("composeMail")
    if (composeMailEditor && composeMailAddress && mailSendButton && composeMailBox && mailheader) {
      const middleBox = composeMailBox?.offsetHeight - mailheader?.offsetHeight - mailSendButton?.offsetHeight
      if (trix.length > 0) {
        const element = trix[0]
        element.style.height = middleBox - composeMailAddress.offsetHeight - 60 + "px"
      }
      composeMailEditor.style.maxHeight =
        middleBox + "px"
    }


  }
  useEffect(() => {
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => window.removeEventListener("resize", setHeight);
  }, [document.getElementById("composeMailAddress")?.offsetHeight, document.getElementById("mailSendButton")?.offsetHeight, showMoreToAddress, ccMembers, bccMembers, addCC, addBcc])


  const menu = (
    <Menu>
      {dynamicKeys.map((tag) => (
        <Menu.Item key={tag}>
          <DynamicMailTag
            key={tag}
            handleInsertValue={handleInsertValue}
            item={tag}
            selectedDynamicKeys={selectedDynamicKeys}
            records={props.record_id}
          />
        </Menu.Item>
      ))}
    </Menu>
  );
  const clearInputofCcAndBcc = () => {
    getSearchedCcMember("")
    getSearchedBccMember("")
    if (ccinputRef.current) {
      ccinputRef.current.value = ""
    }
    if (bccinputRef.current) {
      bccinputRef.current.value = ""
    }
  }
  function validation(){
    if(subject.length >0){
      return false;
    }
    else{
      return true;
    }
  }
  // console.log("Subject:",subject)
  // console.log("Body:",mailBody);
  // console.log(setSubject);

  // after click on create a with AI button function will call and open chatBox to give additional info to the ai api
//   const handleChatBox = (
//     event: React.MouseEvent<HTMLButtonElement, MouseEvent>
//   ) => {
//     event.preventDefault();
//         setShowChatBox(!chatBox);
//   };

  return (
    <div
       className={`flex flex-col sm:flex-row h-full w-full overflow-auto `}
       style={{height:'600px',zIndex:1}}

      >
    <>
      {/* <div className="bottom-0 absolute rounded border right-0  mr-3 mb-2 shadow-lbr flex max-h-full w-128"> */}
      <div
        className={`flex ${
          editable ? "bg-gray-100" : "bg-white"
          } flex-col w-full rounded overflow-hidden`}
        id="composeMailbox"
        ref={props.refer}
        style={{height:'600px'}}
      >
        <div id="mailheader" className={`flex justify-between  text-xs ${props.record_id.length == 1 ? "text-black bg-opacity-20" : "text-tacitbase-secondary3 "} p-2 rounded-t flex`} style={{backgroundColor:'#F0EFFE'}}>
          {props.toAddress.length == 1 ?

            <div className="flex space-x-1">
              <div className="flex-shrink-0">{capitalizeFirstLetter("New message")}</div>
              <div className="truncate w-80">
                <span className="mr-1">{props.record_id[0].first_name}</span>
                <span>{props.record_id[0].last_name}</span>
              </div>
            </div>
            : capitalizeFirstLetter(`You are in Bulk mode (${props.record_id.length})`)
          }
          {/* <button onClick={discardMail}>
            {" "}
            <CloseIcon fill={`${props.record_id.length == 1 ? "#000" : "#fff"}`} />
          </button> */}
        </div>
        <div className="h-full p-2" id="composeMailBody">
          <div id="composeMailAddress">
            <div className="flex space-x-2 mb-1 items-center justify-start px-2 text-xs overflow-hidden ">
              <div className="w-6 flex justify-start items-end ">To:</div>
              <div
                className={`p-1  items-end font-medium w-full `}
              >
                <div className="flex max-w-full space-y-1 items-center flex-wrap  max-h-40 overflow-y-scroll">
                  {visibleAddresses &&
                    visibleAddresses.map(
                      (items) =>
                        items && (
                          <div key={items} className="flex  mr-1 space-x-1  border px-2 py-1 rounded" style={{backgroundColor:'#F0EFFE'}}>
                            <div>{items}</div>
                            {/* {visibleAddresses.length > 1 && <button>
                              <CloseIcon size="12px" />
                            </button>} */}
                          </div>
                        )
                    )}
                  {props.toAddress.length > 1 && !showMoreToAddress && (
                  <button onClick={() => setShowMoreToAddress(true)}>
                    {props.toAddress.length - 1}
                    {"more"}
                  </button>
                )}
                </div>

              </div>
            {addBcc && addCC ? null : (
              <div className="ml-14 flex px-2 items-start space-x-1 text-sm justify-start text-tacitbase-secondary3">
                {/* <div>Add</div> */}
                {!addCC && (
                    <Tooltip title="Add Cc recipients">
                  <button data-testid="AddCC" onClick={() => setCC(!addCC)} className="hover:underline">
                    Cc
                  </button>
                  </Tooltip>

                )}
                {!addBcc && (
                  <Tooltip title="Add Bcc recipients">
                  <button data-testid="AddBcc" onClick={() => setBcc(!addBcc)} className="hover:underline">
                    Bcc
                  </button>
                  </Tooltip>

                )}
              </div>
            )}
                        </div>
                        <Divider className="mt-0 mb-1"/>


            <div className="flex flex-col space-y-2">
            {addCC && (
                <div className="w-full mt-2 ">
                  <div className="flex space-x-2 px-2 text-xs ">
                    <div className="w-6 flex justify-start">Cc:</div>
                    <div
                      className={`rounded inputCSSOnlyForFocus ${
                        ccinputRef.current
                          ? "border border-purple-400"
                          : "border border-gray-500 border-opacity-25"
                      } flex flex-wrap  items-center w-full `}
                    >
                      <div
                        className={`${
                          ccMembers.length == 0 ? "w-full" : ""
                        } flex px-2 pb-1 flex-wrap `}
                      >
                        {ccMembers
                          ? ccMembers.map(
                              (memberID: string, index: number) =>
                                memberID && (
                                  <ShowMeberInInput
                                    key={index}
                                    index={index}
                                    memberId={memberID}
                                    removeMemberFromSelectedList={(
                                      idx: number
                                    ) => removeMemberFromSelectedCc(idx)}
                                  />
                                )
                            )
                          : null}
                        <div className="w-96 relative">
                          <input
                            type="text"
                            className={`${
                              ccMembers.length == 0 ? "w-full" : ""
                            } placeholder-italic outline-none text-sm mt-1`}
                            onChange={(e: any) => searchKeywordInCc(e)}
                            ref={ccinputRef}
                            disabled={editable ? true : false}
                            onFocus={() => setShowMoreToAddress(false)}
                            onBlur={() => ccinputRef.current?.blur()}
                            data-testid="ccMemberInput"
                          />
                          <div>
                            {searchedCcMember.trim() ? (
                              <Overlay onClose={() => clearInputofCcAndBcc()
                              } scope={Scope.Popover} reference={refer} >
                              <SearchedResultPopup
                              refer={refer}
                                click={(member: Member) =>
                                  handleInputChangeOfCc(member)
                                }
                                selectedMember={ccMembers}
                                searchResult={orgMembers}
                                searchInObject={{}}
                                searchInObjectKey={""}
                              /></Overlay>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {addBcc && (
                <div className="w-full ">
                  <div className="flex space-x-2 px-2 text-xs ">
                    <div className="w-6 flex justify-start items-center">Bcc:</div>
                    <div
                      className={`rounded inputCSSOnlyForFocus flex flex-wrap  items-center w-full border ${
                        bccinputRef.current
                          ? "border border-purple-400"
                          : "border border-gray-500 border-opacity-25"
                      }`}
                    >
                      <div
                        className={`${
                          bccMembers.length == 0 ? "w-full" : ""
                        } flex px-2 pb-1 flex-wrap `}
                      >
                        {bccMembers
                          ? bccMembers.map(
                              (memberID: string, index: number) =>
                                memberID && (
                                  <ShowMeberInInput
                                    key={index}
                                    index={index}
                                    memberId={memberID}
                                    removeMemberFromSelectedList={(
                                      idx: number
                                    ) => removeMemberFromSelectedBcc(idx)}
                                  />
                                )
                            )
                          : null}
                        <div className="w-96 relative">
                          <input
                            type="text"
                            className={`${
                              bccMembers.length == 0 ? "w-full" : ""
                            } placeholder-italic outline-none text-sm mt-1`}
                            onChange={(e: any) => searchKeywordInBcc(e)}
                            ref={bccinputRef}
                            disabled={editable ? true : false}
                            onFocus={() => setShowMoreToAddress(false)}
                            onBlur={() => bccinputRef.current?.blur()}
                            data-testid="bccMemberInput"
                          />
                          <div>
                            {searchedBccMember.trim() ? (
                              <Overlay onClose={() => clearInputofCcAndBcc()
                              } scope={Scope.Popover} reference={refer} >
                              <SearchedResultPopup
                              refer={refer}
                                click={(member: Member) =>
                                  handleInputChangeOfBcc(member)
                                }
                                selectedMember={bccMembers}
                                searchResult={orgMembers}
                                searchInObject={{}}
                                searchInObjectKey={""}
                              /></Overlay>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Form>
            <div className="flex space-x-2 px-2 mt-1  items-start justify-start text-xs overflow-y-auto ">
                {/* <div className="pt-2.5">
                  Subject:
                </div> */}
                <div className="w-full text-xs ">
                  <SetSubject onchange={setSubject} />
                </div>
                {/* <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: "#009D79",
                          colorPrimaryHover: "#009D79",
                          colorPrimaryActive: "#009D79"
                        },
                      },
                    }}
                  >
                    <Button
                    icon={<GPT/>}
                    style={{ backgroundColor: "#009D79", color: "#fff" }}
                    size="middle"       
                    disabled={validation()}
                    onClick={handleChatBox}
                    className="mail-Gpt"
                    />
                  </ConfigProvider> */}

                {/* <input
                type="text"
                className={`w-full inputCSS rounded p-1 text-sm mt-1`}
                onChange={(e: any) => setSubject(e.target.value)}
                disabled={editable ? true : false}
                onFocus={() => setShowMoreToAddress(false)}
                data-testid="subjectOfMail"
              /> */}
            </div>
            </Form>
          </div>
          <div className="mx-2 h-full overflow-y-auto" id="composeMailEditor">
              <TrixEditor
                onChange={handleChange}
                onEditorReady={setEditorReady}
                mergeTags={[]}
                value={mailBody}
                className={`composeMail w-full text-sm overflow-y-scroll max-h-100  h-100 trix-content resize-none rounded ${
                  editable ? "bg-gray-100" : "bg-white"
                } p-2  placeholder-gray-600`}
            />       
            {/* {loading ? (
              <EditorSkeleton />
            ) : (
                <div className="editor-description border rounded mb-2" style={{
                  height: "calc(-200px + 67vh)"
                }}>
                <Editor
                  initialValue="<p>Job Description</p>"
                  value={props.data.job_description}
                  onChange={handleChange}
                  modules={customModules}
                  formats={customFormats}
                  placeholder="Type your job description here..."
                />
              </div>
            )} */}
               </div>

          {/* </div> */}
        </div>
        <div className="sticky bottom-2 px-2 " id="mailSendButton">
          <div className="flex flex-col space-y-1 px-2">
              {uploadedFiles.map((file: any, index) => (
                <UploadedFile
                  key={index}
                  name={file.name}
                  progress={file.progress}
                  onClose={() => {
                    const files = [...uploadedFiles];
                    files.splice(index, 1);
                    setUploadedFiles(files);
                  }}
                />
              ))}
            </div>
          {/* <div className="px-2 text-xs">
            Add placeholder text into your message.
            <div className="text-xs underline">Available tags</div>
            <div className="flex space-x-2 my-2">
              {dynamicTags}
            </div>

          </div> */}
          <div className="justify-between w-full flex p-1 mb-2 sticky">
          <Tooltip title="Discard mail">
          <Button onClick={discardMail} type="text" icon={<DeleteOutlined style={{color:'#4B48A5'}}/> }>
            </Button>
            </Tooltip>
            <div className="flex items-center space-x-2">
            <Tooltip title="Add additional tag">
            <Dropdown overlay={menu} trigger={['click']}>
              <Button 
              type="text"
              icon={<FunctionOutlined style={{color:'#4B48A5'}}/>}
              // onClick={}
              />
              </Dropdown>
              </Tooltip>
            <div style={{marginLeft:'8px'}}>
                <input
                  type="file"
                  ref={fileInput}
                  style={{ display: "none" }}
                  onChange={(file: any) => uploadFile(file)}
                  multiple
                />
               <Tooltip title="Attach files">
                <Button
                  className="items-center cursor-pointer focus:outline-none border-none"
                  onClick={() => fileInput.current?.click()}
                  data-testid="add-attachment"
                  type="text"
                  icon={<PaperClipOutlined  style={{color:'#4B48A5'}}/>}
                />
                </Tooltip>
              </div>
              <Button
                disabled={validation()}
                type="primary"
                // testId="sendMail"
                onClick={sentMailAnyway}
                icon={<SendOutlined />}
                size="middle" 
                style={{marginLeft:'16px'}}
              >Send</Button>{" "}

            </div>

            
          </div>
        </div>
      </div>
      {chatBox && (
        
          <Chatbox
            handleChatBoxFromParent={handleChatBoxFromParent}
            getJobDescription={props.getJobDescription||(() => {})}
            title={subject}
            handleLoading={()=>{}}
            getDisableState={props.getDisableState||(() => {})}
          />
        )}
        
    </>
    </div>

  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
  loginUser: state.opening.loginUser,
  candidateDB: state.opening.candidateDB,
});
export default connect(mapStateToProps)(ComposeMail);
