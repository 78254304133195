import React from "react"
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import type { PDFDocumentProxy } from 'pdfjs-dist/legacy/build/pdf'
import {
  getDocument,
  GlobalWorkerOptions,
  version,
  AnnotationMode
} from 'pdfjs-dist/legacy/build/pdf'
import {
  NullL10n,
  EventBus,
  PDFLinkService,
  PDFViewer,
} from 'pdfjs-dist/web/pdf_viewer'
import { Divider, Modal, Space, Spin, Typography } from "antd"

const { Text } = Typography

// import styles from './PDFRenderer.less'
import { CloseOutlined, CloudDownloadOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons'
import { LabeledIconButton } from "../../ant/Button"
import { Overlay } from "../hook/Overlay"
import { Scope } from "../../type/type.d"
import { bytes } from "../../utils/bytes"
import Title from "antd/es/typography/Title"
// import { DEFAULT_SCALE } from "pdfjs-dist/types/web/ui_utils"


GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${version}/legacy/build/pdf.worker.min.js`

const BASE_ZOOM_LEVELS = [0.06, 0.12, 0.24, 0.48, 1, 1.5, 2, 4, 6, 8]
const DEFAULT_ZOOM_LEVELS_INDEX = 4 // index of BASE_ZOOM_LEVELS
const MAX_ZOOM_LEVEL_INDEX = BASE_ZOOM_LEVELS.length - 1

export const PDFRenderer = (props: { url: string, onCloseViewer: any, title: string, attachmentOfwhom: string }) => {
  const { url, onCloseViewer, title } = props
  const [loading, setLoading] = useState(true)
  const [zoomLevel, setZoomLevel] = useState(DEFAULT_ZOOM_LEVELS_INDEX)
  const isMaxZoomLevel = zoomLevel === MAX_ZOOM_LEVEL_INDEX
  const isMinZoomLevel = zoomLevel === 0
  const mounted = useRef(false)
  const viewerContainerRef = useRef<HTMLDivElement>(null)
  const pdfViewerRef = useRef<PDFViewer | null>(null)
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  const [pdfSize, setPdfSize] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const eventBus = useMemo(() => new EventBus(), [])
  const pdfLinkService = useMemo(
    () =>
      new PDFLinkService({
        eventBus,
      }),
    [eventBus],
  )
  useEffect(() => {
    // To control firing setState asynchronously
    mounted.current = true

    if (viewerContainerRef.current && pdfLinkService) {
      const container = viewerContainerRef.current
      pdfViewerRef.current = new PDFViewer({
        annotationMode: AnnotationMode.ENABLE,
        container,
        eventBus,
        linkService: pdfLinkService,
        renderer: 'canvas',
        l10n: NullL10n,
        maxCanvasPixels: 16777216
      })
      pdfLinkService.setViewer(pdfViewerRef.current)
    }

    return () => {
      mounted.current = false
    }
  }, [mounted, eventBus, pdfLinkService])

  // Fetch the PDF and activate pdfjs
  useEffect(() => {
    const fetchPdf = async () => {
      if (!url) {
        setError("We’re currently processing your document. This may take a few moments. Please wait and try again shortly.");
        setLoading(false);
        return;
      }
      let pdfDocument: PDFDocumentProxy | null = null
      try {
        pdfDocument = await getDocument({
          url,
          cMapUrl: `https://unpkg.com/pdfjs-dist@${version}/cmaps/`,
          cMapPacked: true,
          standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${version}/standard_fonts/`,
        }).promise
        if (pdfDocument) {
          // setTotalPages(pdfDocument.numPages)
        }
      } catch (err) {
        let error: Error
        if (!(err instanceof Error)) {
          error = new Error(
            typeof err === 'string'
              ? err
              : 'Unknown error fetching PDF document',
          )
        } else {
          error = err
        }

        // Redact attachment URL for data privacy
        error.message = error.message.replace(new RegExp(url, 'g'), '')
        // Redact the stack in case the url or url parts are contained within it
        error.stack = undefined


      }

      if (mounted.current && pdfViewerRef.current && pdfDocument) {
        pdfViewerRef.current.setDocument(pdfDocument)
        pdfLinkService.setDocument(pdfDocument, null)
        const page = await pdfDocument.getPage(1)
        const fixedWidth = 800 // Your desired fixed width
        const fixedHeight = 1100 // Your desired fixed height
        const scale = Math.min(fixedWidth / page.view[2], fixedHeight / page.view[3])
        pdfViewerRef.current._setScale(scale)
        pdfViewerRef.current.update()
        setLoading(false)
        const blob = await pdfDocument.getData()
        const dataSize = blob.byteLength
        setPdfSize(dataSize);
        // let textContent: string = "";
        setPdfBlob(new Blob([blob], { type: 'application/pdf' }));
        // const pageTextContent = await page.getTextContent()
        // const pageText: any = pageTextContent.items.map((textItem: any) => textItem.str).join(' ')
        // textContent += pageText + '\n'
        // console.log(textContent)
        // page.getAnnotations().then((annotations) => {
        //   annotations.forEach((annotation) => {
        //     if (annotation.url) {
        //       // Handle link annotations
        //       console.log('Link annotation URL:', annotation.url)
        //     }
        //     // Handle other types of annotations as needed
        //   })
        // });
      }
    }

    fetchPdf()

  }, [url, pdfLinkService])


  const zoomOut = useCallback(() => {
    if (!pdfViewerRef.current) {
      return
    }
    const nextLevel = zoomLevel - 1
    const evaluatedNextLevel = nextLevel < 0 ? 0 : nextLevel
    pdfViewerRef.current.currentScale = BASE_ZOOM_LEVELS[evaluatedNextLevel]
    setZoomLevel(evaluatedNextLevel)
  }, [zoomLevel])

  const zoomIn = useCallback(() => {
    if (!pdfViewerRef.current) {
      return
    }
    const nextLevel = zoomLevel + 1
    const evaluatedNextLevel =
      nextLevel > MAX_ZOOM_LEVEL_INDEX ? MAX_ZOOM_LEVEL_INDEX : nextLevel
    pdfViewerRef.current.currentScale = BASE_ZOOM_LEVELS[evaluatedNextLevel]
    setZoomLevel(evaluatedNextLevel)
  }, [zoomLevel])
  const downloadPdf = useCallback(() => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'document.pdf')
      document.body.appendChild(link)
      link.click();
    }
  }, [pdfBlob]);

  return (

    <>
     {error && !loading && (
        <Modal
        // title="Error"
        visible={!!error}
        footer={null}
        closable={false} 
        centered
      >
              <div className="flex justify-center h-40 items-center w-full bg-white">
               
                <span className="flex flex-col space-y-2 items-center justify-center h-full">
              <Spin tip="" size="default" />
              <Text style={{textAlign: 'center'}}>
                  {error}
                </Text>     
                <LabeledIconButton label={<span style={{ textDecoration: 'underline' }}>Cancel</span>}  type="link" onClick={onCloseViewer} />
  
                     </span>
              </div>
              </Modal>
            )}
    {!error && 
      <Overlay scope={Scope.Universal} onClose={onCloseViewer}>
      <div
          className="animate-popover_fadeIn z-10 justify-start bg-black bg-opacity-80 items-start flex flex-col fixed inset-0  outline-none focus:outline-none "
      >  <div
        className="flex items-center justify-center w-full sticky  top-0"

        >
            <div className=" bg-gray-600  flex justify-between items-center h-14 p-2.5 w-full text-white leading-4"  >
              <div className="flex flex-col w-full ">
                <Space>
                  <Title ellipsis={{ tooltip: props.attachmentOfwhom }} level={5} style={{ color: "white", margin: 0, minWidth: 100, maxWidth: 300 }} className="text-white">{props.attachmentOfwhom} </Title>
                </Space>
                <Space>

                  <Text ellipsis={{ tooltip: props.attachmentOfwhom }} style={{ minWidth: 100, maxWidth: 300, margin: 0 }} className="text-white font-normal text-xs">{title} </Text>
                  <Divider type="vertical" style={{ borderColor: "white" }} />
                  <Text className="text-white font-normal text-xs">{bytes(pdfSize)} </Text>                </Space>

              </div>
              <div className="space-x-2 w-full" >
              <LabeledIconButton label="" disabled={isMinZoomLevel} onClick={zoomOut} icon={<ZoomOutOutlined />} />

              <LabeledIconButton label="" disabled={isMaxZoomLevel} onClick={zoomIn} icon={<ZoomInOutlined />} />

              <LabeledIconButton label="" onClick={downloadPdf} icon={<CloudDownloadOutlined />} />

              </div>

              <LabeledIconButton className="w-full" type="text" onClick={onCloseViewer} label="" icon={<CloseOutlined style={{ color: "#fff" }} />} />
            {/* </div> */}
          </div>
        </div>
        <div
          className="pdfViewer  w-full h-full"
          style={{
            bottom: "0",
            overflowX: "hidden",
            overflowY: "scroll",
            position: "absolute",
            top: 56,
          }}
        >

          {loading && (
              <div className=" flex justify-center h-full items-center w-full">
                <Spin size="large" tip="gvbhnjm," />
              </div>
          )}
{/* 
            {error && !loading && (
              <div className="flex justify-center h-full items-center w-full bg-white">
               
                <span className="flex flex-col space-y-2 items-center justify-center h-full">
              <Spin tip="" size="large" />
              <Text style={{ color: "white", fontSize: "16px" }}>
                  {error}
                </Text>            </span>
              </div>
            )} */}
          <div className="wrapper">

            <div
                className="absolute viewerContainer pb-14"
              ref={viewerContainerRef}
            >
              <div
              /></div>

          </div >
        </div >
      </div>
      </Overlay>
}

     
    </>

  )
}
