import React, { useState } from "react";
import { connect } from "react-redux";
import { loginUser } from "../../type/type";
import { Button, Form, Input, Space } from "antd"
import {
  checkUsernameExist,
  generateUserName,
  updateUserInformation,
} from "../../lib/api";
import { AxiosError } from "axios";
import throwError from "../../service/throwError";
import { updateUser } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useHistory } from "react-router-dom";

type UsernameProps = {
  loginUser: loginUser;
  updateUser(user: any): void;
  UsernameForPreview(name: string): void;
};

const SetupUsername: React.FC<UsernameProps> = ({
  loginUser,
  updateUser,
  UsernameForPreview,
}) => {
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState<string | null>(null);
  const [loadingState,setLoadingState]=useState({generateButton:false,NextButton:false})

  const [form] = Form.useForm();
const history=useHistory()
  const fetchUsername = () => {
    setLoadingState({...loadingState,generateButton:true})

    if (loginUser.user.email) {
      generateUserName(loginUser.user.email)
        .then((res) => {
          if (res) {
            form.setFieldsValue({ username: res });
            setUsername(res);
            UsernameForPreview(res);
            setLoadingState({...loadingState,generateButton:false})

          }
        })
        .catch((reason: AxiosError) => {
          throwError(reason);
        });
    }
  };
  const UserNavigationToOrgName = async () => {
    history.push("/o")
  };


  const onFinish = async (values: any) => {
    try {
      await form.validateFields();

      let formData = new FormData();
      formData.set(
        "user",
        `{"user":{"id":"${loginUser.user.id}","username":"${values.username}"}}`
      );
      setLoadingState({...loadingState,NextButton:true})

      const res =values?.username?.trim().length>0? await updateUserInformation(loginUser.user.id, formData):null
      updateUser(res);
      setLoadingState({...loadingState,NextButton:false})

      UserNavigationToOrgName();
    } catch (error) {
      console.error("Error in form validation or updateUserInformation:", error);
    }
  };

  return (
    <div>
      <div className=" flex flex-col space-y-6">
      <div className="w-full flex items-center justify-center font-semibold md:text-lg text-base">
          {capitalizeFirstLetter("Account setup")}
        </div>
        <div className="flex flex-col space-y-4 lg:text-sm text-xs">
          <div>
          <div className="tracking-wide">
          Hey <span className="font-medium">{loginUser.user.firstName}</span>,
        </div>
        <div>
          Choose a unique username, it&apos;s a great way to personalize your
          experience and contact with your team.
        </div>
          </div>
       
       
        <div className="flex flex-col my-4">
          <Form
            name="normal_login"
            className="login-form  w-full"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            form={form}
          >
            <div className="flex flex-col w-full space-x-0 space-y-0">
              <Form.Item extra="Unique name people can use to tag you in conversations."
                name="username"
                label="User name"
                help={usernameError}
                rules={[
                  {
                    required: true,                          

                    message: "Please input your unique username!",
                  },
                  {
                    validator: async (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      }

                      const usernameExists =value.trim().length>0? await checkUsernameExist(value):null;
                      if (usernameExists) {
                        return Promise.reject("Username already exists!");
                      }

                      if (/\s/.test(value)) {
                        return Promise.reject("Username cannot contain spaces!");
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                className=" font-medium w-full relative"
                >
                  <Space.Compact style={{ width: '100%' }}>
                  <Input
                      placeholder="Dr-strange"
                    value={username}
                    onChange={(e) => {
                      setUsernameError(null);
                      setUsername(e.target.value);
                      UsernameForPreview(e.target.value);
                    }}
                    />
                    <Button onClick={fetchUsername}
                    
                    type="primary"
                    style={{
                      backgroundColor: "#F0EFFE",
                      color: "#4B48A5",
                      height: "30px",
                      width: "98px",
                    }}
                    className="hover:border-purple-400 mr-2"
                    >{"Generate"}</Button>
                  </Space.Compact>

              </Form.Item>
</div>
            <div className="flex justify-end w-full">
                <Button
                htmlType="submit"
                className="w-max" loading={loadingState.NextButton}
                  type="primary"
              >
                Next
              </Button>
            </div>
          </Form>
        </div></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateUser: (user: any) => dispatch(updateUser(user)),
});

export default connect(mapStateToProps, mapPropsToState)(SetupUsername);
