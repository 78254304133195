import React, { useEffect, useState } from "react";
import { Button, Empty, Timeline } from "antd";
// import NotificationCenter from "./NotificationCenter";
import {
  getRTUUpdatesOfOrg,
  getUserLastVisitedTime,
  setUserLastVisitedTime,
} from "../../lib/api";
import NotificationEventHandler from "./NotificationEventHandler";
import { NotificatioRTU } from "../../lib/apiRes";
import {
  addDots,
  initNotificationCenterUnread,
  showDotOrNotOnUpdates,
} from "../../redux/actions/opening/action";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { loginUser, notificationsPagination } from "../../type/type";
import moment from "moment";
import { initialUpdatesPagination } from "../../redux/reducers/initialState";

interface State {
  notificationData: NotificatioRTU[];
  groupedItems: { [key: string]: NotificatioRTU[] };
  searchBy: string;
  lastVisitedTime: number;
  newNotificationsHighlight: boolean;
  activeTab: string;
}
type timelineNotificationProps = {
  initUpdates: (notifications: NotificatioRTU[],pagination:notificationsPagination) => void;
  notificationCenter: any;
  initUnreadDots: (payload: any) => void;
  loginUser: loginUser;
  showDotOnUpdates: (showDot: boolean) => void;
  showDot: boolean;
  filtervalue?:string;
  activeTab?: string;

};

const TimelineComponent = (props: timelineNotificationProps) => {
  const [state, setState] = useState<State>({
    notificationData: [],
    groupedItems: {},
    searchBy: "",
    activeTab: "all",
    lastVisitedTime: moment(new Date()).valueOf(),
    newNotificationsHighlight: false,
  });
  const [pagination,setPagination]=useState<notificationsPagination>({
    page: 1,
  per_page: 10,
  page_count: 0,
  total_count: 0

  })
  const [buttonLoading,setButtonLoading]=useState(false);
  const fetchData =async (page:number) => {
    // Fetch RTU updates
    props.filtervalue
      ? getRTUUpdatesOfOrg(
          `type=${props.activeTab}&service=${props.filtervalue}&page=${page}&per_page=${pagination.per_page}`
        ).then((rtuUpdatesResponse) => {
          props.initUpdates(rtuUpdatesResponse.items,{page:rtuUpdatesResponse.page,per_page:rtuUpdatesResponse.per_page,page_count:rtuUpdatesResponse.page_count,total_count:rtuUpdatesResponse.total_count}),
          setPagination({page:rtuUpdatesResponse.page,per_page:rtuUpdatesResponse.per_page,page_count:rtuUpdatesResponse.page_count,total_count:rtuUpdatesResponse.total_count})
        state.activeTab=='unread'&&(
          props.initUnreadDots(rtuUpdatesResponse))
        })
      : getRTUUpdatesOfOrg(`type=${props.activeTab}&page=${page}&per_page=${pagination.per_page}`).then(
          (rtuUpdatesResponse) => {
            props.initUpdates(rtuUpdatesResponse.items,{page:rtuUpdatesResponse.page,per_page:rtuUpdatesResponse.per_page,page_count:rtuUpdatesResponse.page_count,total_count:rtuUpdatesResponse.total_count}),
            setPagination({page:rtuUpdatesResponse.page,per_page:rtuUpdatesResponse.per_page,page_count:rtuUpdatesResponse.page_count,total_count:rtuUpdatesResponse.total_count})

            state.activeTab=='unread'&&(
              props.initUnreadDots(rtuUpdatesResponse))
          }
        );
  };
  useEffect(() => {
    setPagination(initialUpdatesPagination)
    props.initUnreadDots([])
    fetchData(1);
    state.activeTab != "unread" &&
      setUserLastVisitedTime(new Date().getTime()).then(() => {
        getTime();

      });
    return () => {
      // Cleanup or perform any necessary actions on component unmount or route change
      props.showDotOnUpdates(false);

      // Set user last visited time when navigating away
    };
  }, [props.filtervalue, props.activeTab]);


  const getTime =()=>{
    getUserLastVisitedTime().then((res) => {
      //   console.log(,"time");

      setState((prevState) => ({
        ...prevState,
        lastVisitedTime: res?.time,
      }));
    });
  }
  useEffect(() => {
    getTime();

    return () => {
      // Cleanup or perform any necessary actions on component unmount or route change
      props.showDotOnUpdates(false);

      // Set user last visited time when navigating away
      setUserLastVisitedTime(new Date().getTime()).then(() => {
      });
    };
  }, []);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      notificationData: props.notificationCenter.unread,
    }));
  }, [props.notificationCenter.unread]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      groupedItems: state.notificationData?.reduce((acc: any, item: any) => {
        const date = new Date(item.date).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }

        acc[date].push(item);
        // acc[date].sort(function (a:any,b:any){ return new Date(a.date).getTime()>new Date(b.date).getTime()})

        return acc;
      }, {}),
    }));
  }, [state.notificationData]);
  useEffect(() => {
    const newNotifications = state.notificationData?.filter(
      (item: any) => state.lastVisitedTime < moment(item.date).valueOf()
    );
    setState((prevState) => ({
      ...prevState,
      newNotificationsHighlight: newNotifications?.length > 0,
    }));
  }, [state.lastVisitedTime]);
  useEffect(() => {
    setTimeout(() => {
      props.showDotOnUpdates(false);
    }, 1000);
  }, [state.newNotificationsHighlight == true]);

  return (
    <>
      <div className="w-full flex   rounded h-full">
        <div className="w-full text-center flex flex-col">
          <div className="mx-4 h-auto">
            {/* <NotificationCenter
           
              notifications={[]}
              apiReqtype={(value) =>
                setState((prev) => ({ ...prev, activeTab: value }))
              } 
              apiReqService={(value) =>
                setState((prev) => ({ ...prev, searchBy: value }))
              }
            /> */}
            {/* <hr /> */}
          </div>
          <div className="my-2 flex-1 overflow-y-scroll">
            <div
              className="w-full "
              // style={{ maxHeight: "71vh" }}
            >
              <div className="py-3 w-auto mx-4">
                <Timeline mode="right" reverse={false}>
                  {state.groupedItems
                    ? Object.entries(state.groupedItems).map(
                        ([date, items]) => {
                          return (
                            <Timeline.Item
                              key={date}
                              position=""
                              label={<div>{date}</div>}
                            >
                              <div className="flex flex-col w-full">
                                {items
                                  .sort(
                                    (itemA, itemB) =>
                                      new Date(itemB.date).getTime() -
                                      new Date(itemA.date).getTime()
                                  )
                                  .map((item: any,i:number) => (
                                    <div
                                      key={i} 
                                      className={`${
                                        state.lastVisitedTime <
                                        moment(item.date).valueOf()
                                          ? "bg-tacitbase-secondary3 bg-opacity-10 hover:bg-opacity-20"
                                          : "bg-white hover:bg-gray-100"
                                      } p-4 rounded mb-2 w-full `}
                                      // style={{ boxShadow: "0px 1px 1px 0px #00000040" }}
                                    >
                                      {/* <div>{state.lastVisitedTime}{"/////"}{moment(item.date).valueOf()}</div> */}

                                      <NotificationEventHandler item={item} />
                                    </div>
                                  ))}
                              </div>
                            </Timeline.Item>
                          );
                        }
                      )
                    : null}
                </Timeline>
                <div className="flex items-center justify-center " >
                <Button
                // loading={this.state.loading} // Use the loading prop
                type={"primary"}
                htmlType="submit"  hidden={pagination.page_count<=pagination.page}
                className="  my-3" onClick={()=>{setButtonLoading(true),
                  setTimeout(() => {
                    fetchData(pagination.page+1).then(()=>setButtonLoading(false))
                  }, 400);
                  }} loading={buttonLoading}
              >
                Show More
              </Button>  </div>            </div>
            </div>
          </div>
        </div>
      </div>
      {Object.entries(state.groupedItems)?.length === 0 && (
        <div className="h-full w-full items-center justify-center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No notifications available at this moment</span>}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  notificationCenter: state.opening.notificationCenter,
  loginUser: state.opening.loginUser,
  showDot: state.opening.notificationCenter.showDotOnUpdates,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initUpdates: (notifications: NotificatioRTU[],pagination:notificationsPagination) =>
    dispatch(initNotificationCenterUnread(notifications,pagination)),
  initUnreadDots: (payload: any) => dispatch(addDots(payload)),
  showDotOnUpdates: (showDot: boolean) =>
    dispatch(showDotOrNotOnUpdates(showDot)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimelineComponent);
