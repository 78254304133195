import React from "react";
const MoveUpIcon = (props: any) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title> Move up</title>
      <g
        id="Icons-/-16x16-/-Move-1-up"
        stroke={`${props.stroke ? props.stroke : ""}`}
        strokeWidth="1"
        fill={`${props.fill ? props.fill : ""}`}
        fillRule="evenodd"
      >
        <path
          d="M8.30330086,0.903805922 L12.5459415,5.14644661 C12.7412037,5.34170876 12.7412037,5.65829124 12.5459415,5.85355339 C12.3506794,6.04881554 12.0340969,6.04881554 11.8388348,5.85355339 L8.49910678,2.51435931 L8.5,14.5 C8.5,14.7761424 8.27614237,15 8,15 C7.72385763,15 7.5,14.7761424 7.5,14.5 L7.5,2.5 C7.5,2.46785644 7.50303315,2.43642132 7.50882884,2.40596522 L4.06066017,5.85355339 C3.86539803,6.04881554 3.54881554,6.04881554 3.35355339,5.85355339 C3.15829124,5.65829124 3.15829124,5.34170876 3.35355339,5.14644661 L7.59619408,0.903805922 C7.79145622,0.708543776 8.10803871,0.708543776 8.30330086,0.903805922 Z"
          id="Combined-Shape"
          fill={`${props.fill ? props.fill : "#161E25"}`}
        ></path>
      </g>
    </svg>
  );
};
export default MoveUpIcon;
