import React from "react";
const EmailIcon = (props: any) => {
  return (
    <svg
      width={`${props.size ? props.size : "50"}`}
      height={`${props.size ? props.size : "50"}`}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="5" fill="#F26046" />
      <g filter="url(#filter0_d_320_15)">
        <path
          d="M39.5714 11H10.4286C9.78447 11 9.16676 11.295 8.71131 11.8201C8.25587 12.3452 8 13.0574 8 13.8V36.2C8 36.9426 8.25587 37.6548 8.71131 38.1799C9.16676 38.705 9.78447 39 10.4286 39H39.5714C40.2155 39 40.8332 38.705 41.2887 38.1799C41.7441 37.6548 42 36.9426 42 36.2V13.8C42 13.0574 41.7441 12.3452 41.2887 11.8201C40.8332 11.295 40.2155 11 39.5714 11ZM36.9 13.8L25 23.292L13.1 13.8H36.9ZM10.4286 36.2V15.074L24.3079 26.148C24.5111 26.3106 24.7526 26.3977 25 26.3977C25.2474 26.3977 25.4889 26.3106 25.6921 26.148L39.5714 15.074V36.2H10.4286Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_320_15"
          x="8"
          y="11"
          width="42"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="5" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_320_15"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_320_15"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default EmailIcon;
