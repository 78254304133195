import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getUser } from './lib/api';
import { connect } from 'react-redux';

const withVerificationChecks = (WrappedComponent) => {
    const ComponentWithVerification = ({ loginUser, ...props }) => {
        const [verificationStatus, setVerificationStatus] = useState(null);
        const [checkedPaths, setCheckedPaths] = useState([]);
        const location = useLocation();

        useEffect(() => {
            const checkVerification = async () => {

                if (!checkedPaths.includes(location.pathname)) {
                    setCheckedPaths([...checkedPaths, location.pathname]);
                    if (loginUser && loginUser.user && loginUser.user.id) {
                        const res = await getUser(loginUser.user.id);
                        if (!res?.verified) {
                            setVerificationStatus('email');
                        } else if (!res?.data?.verifiedPhone) {
                            setVerificationStatus('phone');
                        } else if (!res?.username) {
                            setVerificationStatus('username');
                        } else {
                            setVerificationStatus('verified');
                        }
                    }
                }
            };

            checkVerification();
        }, []);     
        if (verificationStatus === 'email' && location.pathname !== "/register/verification/email") {

            return <Redirect to="/register/verification/email" />;
        }

        if (verificationStatus === 'phone' && location.pathname !== "/register/verification/phone") {
            return <Redirect to="/register/verification/phone" />;
        }

        if (verificationStatus === 'username' && location.pathname !== "/register/setup/username") {
            return <Redirect to="/register/setup/username" />;
        }

        if (verificationStatus === 'verified') {
            return <Redirect to="/o" />;
        }

        // if (verificationStatus === "verified") {
        //     // Optionally render a loading spinner or fallback UI while checking
        //     return <Redirect to="/o" />;
        // }

        return <WrappedComponent {...props} />;
    };

    ComponentWithVerification.displayName = `withVerificationChecks(${getDisplayName(WrappedComponent)})`;

    return ComponentWithVerification;
};

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const mapStateToProps = (state) => ({
    loginUser: state.opening.loginUser,
});

export default (WrappedComponent) => connect(mapStateToProps)(withVerificationChecks(WrappedComponent));