import React, { useEffect } from "react";
import { connect } from "react-redux";
import ContentHeader from "../analyticsVersion1/ContentHeader";
import { getIntegratedSlackMember, getOrgintegrations } from "../../lib/api";
import { Dispatch } from "redux";
import {
  addChatIntegration,
  integratedSlackMember,
} from "../../redux/actions/opening/action";
import { Integration } from "../../lib/apiRes";
import { con } from "../..";
import SideNavBar from "../../setting_v1/SideNavBar";
import { Breadcrumb, Divider } from "antd";
import Navbar from "../navbar/Navbar";
import NavSearch from "../navSearch/NavSearch";
import { colors, DIVIDER_HEIGHT } from "../../constant";

type settingHeaderProps = {
  addIntegrations: (integration: Integration[]) => void;
};

const SettingHeader = (props: settingHeaderProps) => {

  // getting slack member on first rendering
  useEffect(() => {
    getIntegratedSlackMember().then((Slackmembers) => {
      con?.store?.dispatch(integratedSlackMember(Slackmembers));
    });

    getOrgintegrations().then((integration) => {
      props.addIntegrations(integration);
    });
  }, []);
  return (
    <>
      <ContentHeader>
        <div
          className="flex h-12 px-4 py-3 justify-between items-center " >
          <div
            className="flex items-center "          >
            <Breadcrumb
    items={[
      {
        title: 'Settings',
      }
    ]}
  />
          </div>
          <div className="flex flex-row items-center">
            <NavSearch/>
          <Divider
                          type="vertical"
                          style={{
                            height: DIVIDER_HEIGHT,
                            borderColor: `${colors.divider}`,
                          }}
                        />
            <Navbar/>
          </div>
        </div>
      </ContentHeader>
      <SideNavBar />
    </>
  );
};

const mapPropsToState = (dispatch: Dispatch) => ({
  addIntegrations: (integration: Integration[]) =>
    dispatch(addChatIntegration(integration)),
});
export default connect(null, mapPropsToState)(SettingHeader);
