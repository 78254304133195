import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { useState } from "react";
import PopupTitle from "../../../common/popupTitle/PopupTitle";
import { CANDIDATES, MAX_ATTACHMENT_SIZE, UPLOAD_FROM_COMPUTER } from "../../../../constant"
import {
  addAttachmentToCurrentCandidateInDb,
  attachmentLoader,
} from "../../../../redux/actions/opening/action";
import { uploadAttachmentOfCandidate } from "../../../../lib/api";
import { CurrentCandidateInEditMode } from "../../../../redux/reducers/initialState";
import { Attachment } from "../../../../type/type";
import { OrgInfo } from "../../../../lib/apiRes";

export type AddAttachmentProps = {
  click: (e: boolean) => void;
  members: any;
  addMember(member: any, applicantID: string): void;
  removeMember(memberID: string, applicantID: string): void;
  refer: any;
  addAttachements: any;
  attachmentLoader: () => void;
  clientId: string;
  name: string;
  addAttachmentToCurrentCandidateInDb(attachment: any): void;
  currentCandidateInEditMode: CurrentCandidateInEditMode;
  org:OrgInfo,
  user:any,
};
const AddAttachmentToCandidate = (props: AddAttachmentProps) => {
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (props.refer.current && !props.refer.current.contains(e.target)) {
      props.click(false);
      return;
    }
  };
  let fileInput = useRef<HTMLInputElement>(null);
  const [file_Exceed, isExceed] = useState(false);
  const onFileChange = (file: any) => {
    if (file.target.files[0].size > 10000000) {
      isExceed(true);
    } else {
      isExceed(false);
      const formData = new FormData();
      for (let i = 0; i < file.target.files.length; i++) {
        formData.append(`file`, file.target.files[i]);
      }
      props.attachmentLoader();
      uploadAttachmentOfCandidate(
        formData,
        CANDIDATES,
        props.currentCandidateInEditMode.candidateId,
        props.org.id,
        props.user.user.id
      ).then((res) => {
        props.addAttachmentToCurrentCandidateInDb(res);
        props.attachmentLoader();
        props.click(false);
      });
    }
  };

  return (
    <React.Fragment>
      <div
        className="flex flex-col h-auto mt-1 z-10 w-72 bg-white text-gray-800 tracking-normal  text-sm font-normal border border-gray-300  rounded"
        ref={props.refer}
      >
        <div className="my-3">
          <PopupTitle PopupTitle={props.name} close={props.click} />
        </div>
        {file_Exceed && (
          <div className="bg-red-50 p-4 mx-2">
            {MAX_ATTACHMENT_SIZE}
          </div>
        )}
        <div className="m-3">
          <input
            type="file"
            ref={fileInput}
            data-testid="input-attachment"
            style={{ display: "none" }}
            onChange={(file: any) => onFileChange(file)}
            multiple
          />
          <button
            className="items-center bg-gray-200 w-full rounded  cursor-pointer focus:outline-none border-none py-2"
            onClick={() => fileInput.current?.click()}
            data-testid="add-attachment"
          >
            {UPLOAD_FROM_COMPUTER}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  selectedReview: state.opening.currentCandidateInReview,
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  user:state.opening.loginUser,
  org:state.opening.currentOrg,

});
const mapPropsTostate = (dispatch: Dispatch) => ({
  addAttachmentToCurrentCandidateInDb: (attachment: Attachment[]) =>
    dispatch(addAttachmentToCurrentCandidateInDb(attachment)),
  attachmentLoader: () => dispatch(attachmentLoader()),
});
export default connect(
  mapStateToProps,
  mapPropsTostate
)(AddAttachmentToCandidate);
