import React from "react";

const EyeIconSvg = (props:any) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9541 8.11734C15.9308 8.06467 15.3661 6.812 14.1108 5.55667C12.4381 3.884 10.3255 3 8.00012 3C5.67479 3 3.56212 3.884 1.88945 5.55667C0.634116 6.812 0.0667824 8.06667 0.0461157 8.11734C0.0157911 8.18555 0.00012207 8.25936 0.00012207 8.334C0.00012207 8.40865 0.0157911 8.48246 0.0461157 8.55067C0.0694491 8.60334 0.634116 9.85534 1.88945 11.1107C3.56212 12.7827 5.67479 13.6667 8.00012 13.6667C10.3255 13.6667 12.4381 12.7827 14.1108 11.1107C15.3661 9.85534 15.9308 8.60334 15.9541 8.55067C15.9845 8.48246 16.0001 8.40865 16.0001 8.334C16.0001 8.25936 15.9845 8.18555 15.9541 8.11734ZM8.00012 12.6C5.94812 12.6 4.15545 11.854 2.67145 10.3833C2.06255 9.7778 1.54451 9.08731 1.13345 8.33334C1.5444 7.5793 2.06245 6.88879 2.67145 6.28334C4.15545 4.81267 5.94812 4.06667 8.00012 4.06667C10.0521 4.06667 11.8448 4.81267 13.3288 6.28334C13.9389 6.88864 14.4581 7.57915 14.8701 8.33334C14.3895 9.23067 12.2955 12.6 8.00012 12.6ZM8.00012 5.13333C7.36722 5.13333 6.74853 5.32101 6.2223 5.67263C5.69606 6.02425 5.28591 6.52403 5.0437 7.10875C4.8015 7.69347 4.73813 8.33689 4.86161 8.95763C4.98508 9.57837 5.28985 10.1486 5.73738 10.5961C6.18491 11.0436 6.75509 11.3484 7.37583 11.4719C7.99657 11.5953 8.63999 11.532 9.22471 11.2898C9.80943 11.0476 10.3092 10.6374 10.6608 10.1112C11.0124 9.58493 11.2001 8.96624 11.2001 8.33334C11.1992 7.48491 10.8618 6.67149 10.2619 6.07157C9.66197 5.47164 8.84854 5.13422 8.00012 5.13333ZM8.00012 10.4667C7.57819 10.4667 7.16573 10.3416 6.8149 10.1071C6.46408 9.87273 6.19064 9.53954 6.02918 9.14973C5.86771 8.75991 5.82546 8.33097 5.90778 7.91714C5.99009 7.50332 6.19327 7.12319 6.49163 6.82484C6.78998 6.52649 7.1701 6.32331 7.58393 6.24099C7.99776 6.15868 8.4267 6.20093 8.81651 6.36239C9.20633 6.52386 9.53951 6.79729 9.77392 7.14812C10.0083 7.49895 10.1335 7.9114 10.1335 8.33334C10.1335 8.89913 9.90869 9.44175 9.50862 9.84183C9.10854 10.2419 8.56592 10.4667 8.00012 10.4667Z"
        fill={`${props.color?props.color:"#161E25"}`}
      />
    </svg>
  );
};

export default EyeIconSvg;
