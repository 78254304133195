import React, { useEffect, useState } from "react";
import TacitBaseModal from "../../tacitbaseAnt/TacitbaseModal";
import { Form, Select } from "antd"
import { connect } from "react-redux";
import {  newOrganisation } from "../../lib/apiRes";
import {
  inviteMember,
} from "../../lib/api";
import { addInvitation } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import ShowError from "../../service/ShowError";
import { AxiosError } from "axios";
import {  UserAddOutlined } from "@ant-design/icons"
import { loginUser, orgMember } from "../../type/type";
import { OWNER } from "../../constant";
import { throwFrontEndError, throwSuccess } from "../../service/throwError";
import { LabeledIconButton } from "../../ant/Button"
import Fulluser from "./Fulluser";
import Guestuser from "./Guestuser";
import Agency from "./Agency";
import { UserType } from "../../constant";
type inviteMemberModalProps = {
  initCurrentOrg: newOrganisation;
  members: orgMember;
  loginUser: loginUser;
  outgoingInvitations: any[];
  addOutgoingInvitation(invitation: any): void;
};

export type fullInvitee = {
  email: string;
};
 
export type guestUser = {
  email: string;
  first_name?: string;
  last_name?: string;
}

export type agency = {
  agency_name:string;
  first_name: string;
  last_name: string;
  phone?:number;
  email:string;
};

const InviteMemberModal = (props: inviteMemberModalProps) => {
  const [visibility, setVisibility] = useState(false);
  const [userType, setUserType] = useState(""); // State to manage selected user type
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const [form] = Form.useForm();
  const [invitee, setInvitee] = useState<fullInvitee[] | guestUser[] |agency[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [loadingSendButton, setLoadingSendButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [disableButton, setDisableButton] = useState(true);

  // Enable the send invitation button for guest member
  useEffect(() => {
    setDisableButton(!(invitee.length > 0 && userType.length > 0));
  }, [invitee,userType]);

const values = Form.useWatch([], form);
useEffect(() => {
userType!==UserType.FULL&&
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisableButton(false))
      .catch(() => setDisableButton(true));
  }, [form, values]);

  const handleInputChange = (value: string) => {
    setErrorMessage(""); // Clear the error message if successful
    setInputValue(value);
  };
 // Simplified validateEmail function
const validateEmail = (email: string, inviteeList: fullInvitee[], members: any[], outgoingInvitations: any[]) => {
  const emailRegex = /^[a-zA-Z0-9]+(?:[-._+][a-zA-Z0-9]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+$/;

  if (!email.trim()) return "Please input work email!";
  if (inviteeList.find((obj) => obj.email === email.trim())) return "Email already added";
  if (members.some((member: any) => member.email === email.trim())) return "Member already exists";
  if (outgoingInvitations.some((invitation: any) => invitation.invitee[0]?.email === email.trim())) return "Email already invited";
  if (!emailRegex.test(email)) return "Invalid email format";
  return ""; // Return empty string if email is valid
};

  const handleTokenAdd = (input:string) => {
    
    const errorMessage = validateEmail(input, invitee, Object.values(props.members), props.outgoingInvitations);
    if (errorMessage) setErrorMessage(errorMessage);
    else {
      setInvitee([...invitee, { email: input.trim() }]);
      setInputValue("");
      setErrorMessage("");
    }
  };
  const handleTokenClose = (emailToRemove: string) => {    
    const updatedEmails = invitee.filter((obj) => obj.email !== emailToRemove);
    setInvitee(updatedEmails);
  };

  const handleOk = () => {
    inviteUser();
  };

  // validator function that validates the email
  const EmailValidator = (_:any,value:any)=>{
    const err = validateEmail(value.trim(), invitee, Object.values(props.members), props.outgoingInvitations);
    if(err){
      return Promise.reject(err);
    }
    return Promise.resolve();
  }

  const handleCancel = () => {
    form.resetFields();
    setInvitee([]);
    setInputValue("");
    setUserType("");
    setVisibility(false);
  };


  const inviteUser = () => {
    let guestInvitee: guestUser[] = [];
    let agencyInvitee: agency[] = [];
    form.validateFields().then((values:any)=>{
      if(userType === UserType.GUEST){
        guestInvitee = [{
          email: values.email.trim(),
          first_name: values.first_name.trim(),
          last_name: values.last_name.trim(),
        }]
        setInvitee(guestInvitee);
      }else if (userType === UserType.AGENCY) {
        agencyInvitee = [{
          agency_name: values.agency_name,
          email: values.email.trim(),
          first_name: values.first_name.trim(),
          last_name: values.last_name.trim(),
          phone: values.phone,
        }];
        setInvitee(agencyInvitee);
      }
        setLoadingSendButton(true),
        inviteMember({
        invitee:userType === UserType.GUEST ? guestInvitee : (userType === UserType.AGENCY ? agencyInvitee: invitee),
        user_type: userType,
        subscription_item_id: subscriptionId,
        user_source: "tacitbase",
        })
        .then((res) => {
          res.map((invitation) => {
            props.addOutgoingInvitation(invitation);
          });
          throwSuccess("Your invitation has been sent!")
          form.resetFields();
          setInputValue("")
          setInvitee([]);
          // setUserType("");
          if(userType === UserType.GUEST){
            setUserType(UserType.GUEST);
          }
          else{
            setUserType("");
          }
          setDisableButton(true);

          setVisibility(false);
          setLoadingSendButton(false);
        })
        .catch((reason: AxiosError) => {
          setLoadingSendButton(false);
          if (reason.response?.status === 409) {
            toast.error(
              <ShowError
                msg={`The member is already invited to join the ${props.initCurrentOrg.application.name}.`}
              />,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            setVisibility(false);
          }
         else if (reason.response && reason.response.status === 422){
          throwFrontEndError(reason.response?.data?.error?.message)
          }
          else{
            throwFrontEndError(reason.response?.data.error.type)
          }
        })
      })
    
  };

  const content = (
    <div>
      <Form
        layout="vertical"
        form={form}
        autoComplete="off"
        initialValues={{ required_field: "" }}
      >
        <Form.Item>
          Add an email ID or slack account to collaborate with your team
          members.
        </Form.Item>
        <Form.Item name="type" label="User type" required
        >
          <Select
            style={{ width: "100%" }}
            value={userType}
            onChange={(value, option: any) => {
              setUserType(value), setSubscriptionId(option.key);
            }}
            popupMatchSelectWidth={false}
            // defaultValue={userType}
          >
            <Select.Option value = "full">Member</Select.Option>
            <Select.Option value = "guest">Guest</Select.Option>
            <Select.Option value = "agency">Agency</Select.Option>
          </Select>
        </Form.Item>

        <>
          {userType === UserType.FULL && (
           <Fulluser
           errorMessage = {errorMessage}
           invitee = {invitee}
           handleInputChange = {handleInputChange}
           handleTokenAdd = {handleTokenAdd}
           handleTokenClose = {handleTokenClose}
           inputValue = {inputValue}
             />
          )} 
          {userType === UserType.GUEST &&(
            <Guestuser EmailValidator = {EmailValidator}/>
          )}
          {userType === UserType.AGENCY &&(
              <Agency EmailValidator = {EmailValidator} form = {form}/>
          )}
        </>
      </Form>
    </div>
  );

  return (
    <>
      {props.members?.[props?.loginUser.user.id]?.registrations[0]?.roles?.length >
        0 &&
        props.members[props.loginUser.user.id].registrations[0].roles[0] ==
          OWNER && (
          <TacitBaseModal
            visibility={visibility}
            destroyOnClose={true}
            loadingOkButton={loadingSendButton}
            ModalTriggerButtonName={
              <LabeledIconButton
                icon={<UserAddOutlined />}
                type="primary"
                onClick={() => {
                  setVisibility(!visibility);
                }}
                label="Invite"
              >
              </LabeledIconButton>
            }
            title="Invite team members"
            content={content}
            okText="Send"
            disabledOkButton={disableButton}
            cancelText="Cancel"
            onOk={handleOk}
            onCancel={handleCancel}
          />
         )} 
    </>
  );
};

const mapStateToProps = (state: any) => ({
  members: state.opening.members,
  outgoingInvitations: state.opening.outgoingInvitations,

  loginUser: state.opening.loginUser,
  initCurrentOrg: state.opening.currentOrg,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  addOutgoingInvitation: (invitation: any) =>
    dispatch(addInvitation(invitation)),
});
export default connect(mapStateToProps, mapPropsToState)(InviteMemberModal);
