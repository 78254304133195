import { Divider, Form, Popover, Select, Space, Spin, Tooltip, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { IconButton, LabeledIconButton, SecondaryButton } from "../../ant/Button"
import MemberCard from "../../tacitbaseAnt/TacitbaseMember"
import { Overlay } from "../hook/Overlay"
import { MemberItem } from "../../lib/apiRes"
import { IntegratedMember, Integration, Member, notificationsPagination, Scope } from "../../type/type.d"
import { connectSlackOfSingleUser, getAppwiseMembers, getRefreshSlackMember } from "../../lib/api"
import slack from "../../images/slack.png"
import { connect } from "react-redux"
import { Dispatch } from "redux"
import { connectSlackOfMember } from "../../redux/actions/opening/action"
import { CloseOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons"
import FormItem from "../../ant/FormItem"
import {  SLACK, SLACK_APP_CONNECTION_WARNING, SLACK_BUTTON } from "../../constant"
const { Text } = Typography
const defaultMemberType: MemberItem = {
    id: "",
    name: "",
    first_name: "",
    last_name: "",
    image_512: "",
    email: "",
    is_bot: false,
}
const ConnectMembertoSlack = (props: {
    integrations: Integration[],
    member: Member, connectSlackOfMember: (member: IntegratedMember[]) => void, integratedSlackMember: { [key: string]: Integration[] }
}) => {
    const [visible, setVisible] = useState(false)
    const [loader, showLoader] = useState(false)
    const [pagination, setPagination] = useState<notificationsPagination>({
        page: 1,
        per_page: 5,
        page_count: 0,
        total_count: 0,
    })
    const [searchQuery, setSearchQuery] = useState("") // State variable to track search query

    const [members, setMembers] = useState<MemberItem[]>([]) // State to store the members
    const [selectedMembers, setSelectedMembers] = useState<MemberItem>(defaultMemberType) // State to store the members
    const [loading, setLoading] = useState({ search: false, pagination: false })
    const [selectedApp] = useState(SLACK) // State to manage the selected app
    const [refreshing, setRefreshing] = useState(false) // New state for refresh loading


    const handleSelectSlackUsers = (value: any) => {
        const member = members.find((member) => member.email === value.value)
        if (member) {
            setSelectedMembers(member)
        }

    }

    useEffect(() => {
        if (visible) {

            fetchMembers(selectedApp, 1, null, false)
        }

        if (!visible) {
            setMembers([])
            setSelectedMembers(defaultMemberType)
            showLoader(false)
            setSearchQuery("")
        }
    }, [visible])
    const onOpenChange = (visible: boolean) => {
        // console.log(visible)
        setVisible(visible)
        // if (visible)
        //     fetchMembers(selectedApp, 1)

    }
    const handleSearch = (searchBy: string) => {
        setSearchQuery(searchBy)
        setMembers([])
        setLoading({ pagination: false, search: true })
        setTimeout(() => {
            fetchMembers(selectedApp, pagination.page, searchBy, false)
        }, 1000)
    }
    const handlePopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const target = e.target
        if (
            target &&
            target instanceof HTMLDivElement &&
            target.scrollTop + target.clientHeight === target.scrollHeight
        ) {
            let page = pagination.page + 1
            if (page <= pagination.page_count) {
                setLoading({ pagination: true, search: false })
                setTimeout(() => {
                    if (searchQuery)
                        fetchMembers(selectedApp, page, searchQuery, true)
                    else fetchMembers(selectedApp, page, null, true)

                }, 2000)
            }
        }
    }
    const fetchMembers = (app: string, page: number, searchBy?: string | null, isScrollable?: boolean) => {
        let ep = ""
        if (searchBy) {
            ep = `page=${page}&per_page=${pagination.per_page}&client=${app}&search=${searchBy}`
        } else {
            ep = `page=${page}&per_page=${pagination.per_page}&client=${app}`
        }

        getAppwiseMembers(ep)
            .then((response) => {
                setPagination({
                    page: response.page,
                    per_page: response.per_page,
                    page_count: response.page_count,
                    total_count: response.total_count,
                })
                setLoading({ pagination: false, search: false })


                if (searchBy && !isScrollable) {
                    setMembers([...response.items])
                } else {
                    setMembers([...members, ...response.items])
                }
            })

            .catch(() => {
                return true
            })
    }
    const connectMemberToSlack = () => {
        showLoader(true)
        let req = {
            integration_users: [
                {
                    "user_id": selectedMembers?.id,
                    "tacitbase_user_id": props.member.id,
                    "user_email": selectedMembers?.email,
                    "tacitbase_email": props.member.email,
                    "user_type": "full"
                }
            ]
        }
        connectSlackOfSingleUser(req).then((res) => {
            if (res) {
                setVisible(false)
                props.connectSlackOfMember(res)
                showLoader(false)
            }
        }).catch(() => {
            showLoader(false)

        })
    }
    const labelRender = (props: any) => {
        const { label, value } = props

        if (label) {
            return value
        }
    }

    const handleRefresh = async () => {
      setRefreshing(true) // Set refreshing to true before the API call
      try {
          await getRefreshSlackMember();
      } catch {
          console.log('Failed to refresh Slack members.');
      } finally {
          setRefreshing(false) // Set refreshing to false after the API call
      }
  }


  const content = (
    <>
      {" "}
      <Divider className="my-2" />
      <div className="w-72 ">
        <Space direction="vertical" className="w-72">
          <Form layout="vertical">
            <FormItem label="Select account">
              <div className="flex flex-row space-x-1">
              <Select
                labelRender={labelRender}
                className="h-7"
                notFoundContent={
                  loading.search ? (
                    <div className="flex justify-center py-4">
                      <Spin size="small" />
                    </div>
                  ) : (
                    "No matching results"
                  )
                }
                style={{ width: "100%" }}
                virtual={false}
                showSearch
                onChange={(value: any) => handleSelectSlackUsers(value)}
                onSearch={handleSearch}
                onPopupScroll={(e) => handlePopupScroll(e)}
                filterOption={false}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    {loading.pagination && (
                      <div className="flex justify-center items-center ">
                        <Spin className="flex justify-center items-center " />
                      </div>
                    )}
                  </div>
                )}
                // defaultValue={selectedMember}
                // value={selectedMember}
                labelInValue={true}
              >
                {members.map((member, index) => (
                  <Select.Option key={index} value={member.email}>
                    <MemberCard
                      firstName={member?.first_name}
                      id={member?.id}
                      lastName={member?.last_name}
                      image={member.image_512}
                      display_name={`${member.first_name} ${member.last_name}`}
                      email={member.email ? member.email : ""}
                    />
                  </Select.Option>
                ))}
              </Select>
              <IconButton
                                icon={refreshing?<LoadingOutlined/>:<ReloadOutlined />}
                                tooltipTitle="Sync member"
                                onClick={handleRefresh}
                                style={{ marginTop: 8 }}
                            />    
                            </div>        </FormItem>
          </Form>
          <Space className="flex justify-end">
            {/* <LabeledIconButton label="Cancel" onClick={() => { }} /> */}
            <LabeledIconButton
              type="primary"
              label="Connect"
              loading={loader}
              onClick={connectMemberToSlack}
            />
          </Space>
        </Space>
      </div>
    </>
  );
  const hide = () => {
    setVisible(false);
    setMembers([]);
  };

  useEffect(() => {
    if (!visible) {
      setVisible(false);
      setMembers([]);
      setSelectedMembers(defaultMemberType);
    }
  }, [visible]);
  const titleContentDefault = (
    <div className="flex w-full bg-white items-center">
      <Text className="w-full flex justify-center">
        <Text>{SLACK_BUTTON}</Text>
      </Text>
      <LabeledIconButton
        type="text"
        label=""
        onClick={hide}
        icon={<CloseOutlined />}
      />
    </div>
  );
  const isSlackIntegrated =
    props.integrations &&
    props.integrations.find((integration) => integration.app_name === SLACK) !==
      undefined;

  return (
    <>
      {isSlackIntegrated ? (
        <Popover
          title={titleContentDefault}
          content={
            <Overlay onClose={() => {}} scope={Scope.Modal}>
              {content}
            </Overlay>
          }
          trigger="click"
          open={visible}
          placement="bottom"
          onOpenChange={(isVisible) => onOpenChange(isVisible)}
        >
          <Space>
            <SecondaryButton
              onClick={() => setVisible(true)}
              className=" flex justify-start"
              width={162}
            >
              <div>
                <img width={14} src={slack} alt="slack user" />
              </div>
              <div className="flex justify-center items-center">
                <Text
                  className="text-xs"
                  ellipsis
                >
                  {SLACK_BUTTON}
                </Text>
              </div>
            </SecondaryButton>
          </Space>
        </Popover>
      ) : (
        <Tooltip title={SLACK_APP_CONNECTION_WARNING}>
          <div>
            <Space>
            <SecondaryButton 
            icon={ <img width={14} src={slack} alt="slack user" />}
            onClick={()=>{}} disabled               width={162}
            >
                    {SLACK_BUTTON}
              </SecondaryButton>
            </Space>
          </div>
        </Tooltip>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  integratedSlackMember: state.opening.integratedSlackMember,
  integrations: state.opening?.chatsIntegration,
});
const mapPropsTostate = (dispatch: Dispatch) => ({
  connectSlackOfMember: (members: IntegratedMember[]) =>
    dispatch(connectSlackOfMember(members)),
});
export default connect(mapStateToProps, mapPropsTostate)(ConnectMembertoSlack);
