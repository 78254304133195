import React from "react";
import { LABELS } from "../../../constant";
import Button from "../../common/button/Button";

// Redux stuff
import { connect } from "react-redux";

import { CardPreviewLabelProps } from "../../../type/card";
const CardLabel = (props: CardPreviewLabelProps) => {
  if (!props.applicantLabels[props.currentApplicant.id]) return null;

  return (
    <React.Fragment>
      {props.applicantLabels[props.currentApplicant.id].length > 0 ? (
        <div className="mr-2 mb-2 w-full">
          <div className="uppercase  flex-wrap text-gray-500 text-xs font-medium tracking-wider max-w-full mb-1">
            {LABELS}
          </div>
          <div className="flex flex-wrap ">
            {props.applicantLabels[props.currentApplicant.id].map(
              (labelID: string, index: number) =>
                props.labels[labelID] ? (
                  <div className="mb-1 truncate " key={index} role="listitem">
                    <Button
                      tabindex={-1}
                      buttonName={props.labels[labelID].name}
                      color={props.labels[labelID].color}
                    />
                  </div>
                ) : null
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  labels: state.opening.labels,
  currentApplicant: state.opening.currentApplicant,
  applicantLabels: state.opening.applicantLabels,
});
export default connect(mapStateToProps)(CardLabel);
