import moment from "moment";
import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux";
import ArrowDownIcon from "../../assets/ArrowDown";
import { DATE_TIME_FORMATE } from "../../constant";
import { seperateMailAddress } from "../../utils/seperateMailAddress";
import { Mail, Scope } from "../../type/type.d";
import { Overlay } from "../hook/Overlay";
import Portal from "../portal/Portal";
type ShowMailDetailsPopupProps = {
  mail: Mail;
};
const ShowMailDetailsPopup = (props: ShowMailDetailsPopupProps) => {
  const [open, setOpen] = useState<boolean>(false);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  let address = seperateMailAddress(props.mail.from);
  const refer=useRef(null)
  return (
    <>
      <div
        ref={setReferenceElement}
        onClick={() => setOpen(!open)}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <button className="bg-color-grey bg-opacity-50 rounded-sm">
          <ArrowDownIcon />
        </button>
      </div>
      {open ? (
        <Overlay onClose={() => setOpen(!open)} scope={Scope.Popover} reference={refer}>
          <Portal>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
              className=""
            >
              <div className="w-96 text-xs border bg-white shadow-sm p-1  rounded" ref={refer}>
                <div className="w-full flex space-x-3 mb-0.5">
                  <div className="w-16 flex-shrink-0 justify-end flex items-end text-gray-500">
                    from:
                  </div>
                  <div className={"  truncate"}>
                    <span className=" font-semibold leading-none">
                      {address.name}
                    </span>
                    <span
                      style={{ fontSize: "11px" }}
                      className="font-light truncate overflow-hidden"
                    >
                      {address.email}
                    </span>
                  </div>{" "}
                </div>
                <div className="w-full flex space-x-3 mb-0.5">
                  <div className="w-16 justify-end flex items-start text-gray-500">
                    to:
                  </div>
                  <div className="flex flex-col">
                    {props.mail.to.map((address: string) => address &&
                      <div className="truncate" key={address}>{address}</div>
                    )}
                  </div>
                </div>
                {props.mail.cc.length > 0 && (
                  <div className="w-full flex space-x-3 mb-0.5">
                    <div className="w-16 justify-end flex items-start text-gray-500">
                      cc:
                    </div>
                    <div>
                      {props.mail.cc.map(
                        (cc: string) =>
                          cc && <div key={cc} className="truncate">{cc}</div>
                      )}
                    </div>
                  </div>
                )}

                <div className="w-full flex space-x-3 mb-0.5 space-x-1">
                  <div className="w-16 justify-end flex items-end text-gray-500">
                    date:
                  </div>
                  <div className="truncate">
                    {" "}
                    {moment(props.mail.created_at).format(DATE_TIME_FORMATE)}
                  </div>
                </div>
                <div className="w-full flex space-x-3 mb-0.5">
                  <div className="w-16 justify-end flex items-end text-gray-500">
                    signed-by:
                  </div>
                  <div className="truncate">{props.mail.domain}</div>
                </div>
              </div>
            </div>
          </Portal>
        </Overlay>
      ) : null}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(ShowMailDetailsPopup);
