import React, { useRef, useState } from "react";
import { connect } from "react-redux";
// import SettingsIcon from "../../assets/Settings";
import { Scope } from "../../type/type.d";
import { Overlay } from "../hook/Overlay";
import EditTablePopup from "./EditTablePopup";
import { DatabaseOutlined } from "@ant-design/icons";
import { LabeledIconButton } from "../../ant/Button";
// type EditTableProps = { loginUser: loginUser };
const EditTable = () => {
  const [modal, showModal] = useState(false);
const refer=useRef(null);
  return (
    <>
      <div className="flex justify-end items-end ">
        <LabeledIconButton
          data-testid="button"
          onClick={() => showModal(!modal)}
          icon={<DatabaseOutlined />}
          label="Add custom column"
          className="h-7"
        />
        {modal ? (
          <Overlay onClose={() => showModal(!modal)} scope={Scope.Popover} reference={refer}>
            <EditTablePopup close={() => showModal(!modal)}  refer={refer}/>
          </Overlay>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
});

export default connect(mapStateToProps)(EditTable);
