import React from "react"
import PopupTitle from "../../popupTitle/PopupTitle"
import DropDownWithIcon from "../../DropDownWithIcon"
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter"
import { OrganizedBoard, orgMember } from "../../../../type/type"
import { connect } from "react-redux"
import { LabeledIconButton } from "../../../../ant/Button"

type SendToBoardPopupProps = {
    members: orgMember,
    openings: OrganizedBoard,
    refer: any,
    boards: any,
    company: any,
    selectedBoard: string,
    sendToBoard: () => void,
    selectBoard: (e: string) => void,
    selectCompany: (e: string) => void,
    listLength: any,
    close: () => void,
    loading: boolean
}
const SendToBoardPopup = (props: SendToBoardPopupProps) => {

    return (
        <>
            <div
                className="flex flex-col h-auto mb-2 z-10 w-72 bg-white text-gray-800 tracking-normal text-sm font-normal border border-gray-300 rounded"
                ref={props.refer}
            >
                <div className="my-3">
                    <PopupTitle PopupTitle={"send to tracker"} close={props.close} />
                </div>
                <div className="mx-3 mb-2">
                    <label className="font-medium">{capitalizeFirstLetter("select workspace")}</label>
                    <DropDownWithIcon
                        showKey={"company_name"}
                        data={props.company}
                        selectedOption={(e: string) => props.selectCompany(e)}
                        defaultValue="select job post"
                    /></div>

                <div className="mx-3">
                    <label className="font-medium">{capitalizeFirstLetter("select job post")}</label>
                    <DropDownWithIcon
                        showKey={"name"}
                        data={props.boards}
                        selectedOption={(e: string) => props.selectBoard(e)}
                        defaultValue="select job post"
                    />
                    <div className="flex flex-wrap mt-3 ">
                        {props.selectedBoard && props.listLength > 0 ? (
                            <>
                                <div className="text-xs">
                                    {capitalizeFirstLetter(
                                        `candidate card will be created in the first list of board`
                                    )}
                                    <span className="font-semibold mx-1">
                                        {props.openings.pageSection.openings[props.selectedBoard].name}
                                    </span>
                                    <div className="text-xs py-2">{capitalizeFirstLetter("If a candidate profile contains labels, description, or attachments, they will be automatically included within the card.")}
                                    </div>
                                </div>
                            </>
                        ) : null}{" "}
                        <div id="errorInSend" className="text-xs"></div>
                    </div>
                </div>
                <div className="mx-2 mb-3 flex  justify-end">
                    <LabeledIconButton type="primary" size="small" label={capitalizeFirstLetter("send")}
                        onClick={props.sendToBoard} loading={props.loading} data-testid="sendToBoard">
                    </LabeledIconButton>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    members: state.opening.members,
    applicationId: state.opening.applicationId,
    openings: state.opening.openings,
    selectedReview: state.opening.currentCandidateInReview,
})

export default connect(mapStateToProps)(SendToBoardPopup)
